import { useTheme } from "../../hooks/useTheme";

interface Props {
  width?: number;
}

export const SavesIcon = (props: Props) => {
  const theme = useTheme();
  return (
    <svg
      width={props.width ? props.width : 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="46"
        height="46"
        rx="23"
        stroke="url(#paint0_linear_2058_3896)"
        stroke-width="2"
      />
      <path
        d="M29.36 15.6836C30.4141 15.8062 31.1875 16.7157 31.1875 17.7775V32.625L24 29.0312L16.8125 32.625V17.7775C16.8125 16.7157 17.5849 15.8062 18.64 15.6836C22.2014 15.2702 25.7986 15.2702 29.36 15.6836Z"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2058_3896"
          x1="0"
          y1="24.0005"
          x2="47.9991"
          y2="24.0005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
