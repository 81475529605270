import { useState } from "react";
import styled from "styled-components";

const SegmentedControlWrapper = styled.div`
  display: flex;
  border: 1px solid ${(p) => p.theme.color.card.divider};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  overflow: hidden;
  width: fit-content;
`;

const SegmentButton = styled.button<{ isActive: boolean }>`
  min-width: 86px;
  padding: 4px ${(p) => p.theme.spacing.xl} 2px;
  border: none;
  color: ${(p) =>
    p.isActive
      ? p.theme.color.typography.secondaryHeading
      : p.theme.color.typography.text};
  background-color: ${(p) =>
    p.isActive ? p.theme.color.card.callout : "transparent"};
  font-size: ${(p) => p.theme.typography.size.xs};
  font-weight: ${(p) =>
    p.isActive
      ? p.theme.typography.weight.bold
      : p.theme.typography.weight.semi};
  font-family: ${(p) => p.theme.typography.bodyFamily};
  cursor: pointer;
  border-right: 1px solid ${(p) => p.theme.color.card.divider};

  &:hover {
    background-color: ${(p) => p.theme.color.card.callout};
  }
`;

interface SegmentedControlProps {
  options: string[];
  onChange: (selected: string) => void;
}

const MultipleSegmentedControl: React.FC<SegmentedControlProps> = ({
  options,
  onChange,
}) => {
  const [activeOption, setActiveOption] = useState(options[0]);

  const handleOptionClick = (option: string) => {
    setActiveOption(option);
    onChange(option);
  };

  return (
    <SegmentedControlWrapper>
      {options.map((option) => (
        <SegmentButton
          key={option}
          isActive={activeOption === option}
          onClick={() => handleOptionClick(option)}
        >
          {option}
        </SegmentButton>
      ))}
    </SegmentedControlWrapper>
  );
};

export default MultipleSegmentedControl;
