import styled from "../../styles";

const CheckCircleSVG = ({
  size,
  checked,
}: {
  size?: "l";
  checked: boolean;
}) => (
  <svg
    width={size && size === "l" ? 10 : 8}
    height={size && size === "l" ? 8 : 6}
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1L2.875 5L1 3.18182"
      stroke={checked ? "white" : "transparent"}
      stroke-width={size && size === "l" ? 1.5 : 1.2}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  checked: boolean;
  size?: "l";
  color?: string;
}

const StyledCheckCircle = styled(CheckCircleSVG)<Props>`
  path {
    transition: opacity 0.2s;
    opacity: ${(p) => (p.checked ? 1 : 0)};
    stroke: ${(p) => (p.color ? p.color : p.theme.color.primary)};
  }
`;

export const CheckCircle = (props: Props) => {
  return (
    <CircleWrap {...props}>
      <StyledCheckCircle {...props} />
    </CircleWrap>
  );
};

const CircleWrap = styled.div<Props>`
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;
  height: ${(p) => (p.size && p.size === "l" ? 20 : 16)}px;
  width: ${(p) => (p.size && p.size === "l" ? 20 : 16)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  overflow: hidden;
  background-color: ${(p) =>
    p.checked ? (p.color ? p.color : p.theme.color.primary) : "transparent"};
  border: 1px solid
    ${(p) =>
      p.checked
        ? p.color
          ? p.color
          : p.theme.color.primary
        : p.theme.color.typography.secondary};
  user-select: none;
  box-sizing: border-box;
`;
