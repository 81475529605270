import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as authReducer } from "./auth/slice";
import { reducer as listingsReducer } from "./listings/slice";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: [
    "accessToken",
    "isLoggedIn",
    "activeCreatorId",
    "activeBrandId",
    "account",
  ],
};

const rootReducer = combineReducers({
  listings: listingsReducer,
  auth: persistReducer(authPersistConfig, authReducer),
});

export default rootReducer;
