import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const CopySVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.75 5.5V3.25C13.75 2.65326 13.5129 2.08097 13.091 1.65901C12.669 1.23705 12.0967 1 11.5 1H3.25C2.65326 1 2.08097 1.23705 1.65901 1.65901C1.23705 2.08097 1 2.65326 1 3.25V11.5C1 12.0967 1.23705 12.669 1.65901 13.091C2.08097 13.5129 2.65326 13.75 3.25 13.75H5.5M13.75 5.5H15.25C15.8467 5.5 16.419 5.73705 16.841 6.15901C17.2629 6.58097 17.5 7.15326 17.5 7.75V15.25C17.5 15.8467 17.2629 16.419 16.841 16.841C16.419 17.2629 15.8467 17.5 15.25 17.5H7.75C7.15326 17.5 6.58097 17.2629 6.15901 16.841C5.73705 16.419 5.5 15.8467 5.5 15.25V13.75M13.75 5.5H7.75C7.15326 5.5 6.58097 5.73705 6.15901 6.15901C5.73705 6.58097 5.5 7.15326 5.5 7.75V13.75"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const CopyIcon = styled(CopySVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
