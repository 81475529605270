import { GraphQLClient } from "graphql-request";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";

export default function useGqlClient() {
  const token = useSelector(authSelectors.accessToken);
  const activeCreator = useSelector(authSelectors.activeCreatorId);
  const activeBrand = useSelector(authSelectors.activeBrandId);

  let endpoint = "https://stag-nibble-api.onrender.com/graphql";

  if (process.env.NODE_ENV === "production") {
    if (
      window.location.hostname === "app.wenibble.com" ||
      window.location.hostname === "web.nibbleapp.com" ||
      window.location.hostname === "web.joliapp.com"
    ) {
      endpoint = "https://api.wenibble.com/graphql";
    } else {
      endpoint = "https://stag-nibble-api.onrender.com/graphql";
    }
  }

  // endpoint = "http://localhost:8080/graphql";

  const headers = {
    authorization: `Bearer ${token}`,
    "X-CreatorID": activeCreator ? activeCreator : "",
    "X-BrandID": activeBrand ? activeBrand : "",
  };

  return new GraphQLClient(
    endpoint,
    token
      ? {
          headers,
        }
      : {}
  );
}
