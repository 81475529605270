import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const NoEntrySVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_9162_5368)">
      <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        stroke="#E6437D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.4502 2.44922L9.5502 9.54922"
        stroke="#E6437D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9162_5368">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
  color?: string;
}

export const NoEntry = styled(NoEntrySVG)<Props>`
  margin-bottom: -2px;
  path {
    stroke: ${(p) =>
      p.color
        ? p.color
        : p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : "#fff"};
  }
`;
