import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import styled from "../styles";
import { Flex } from "./Flex";
import { lightTheme } from "../styles/theme";

interface Props {
  labels: string[];
  content: React.ReactNode[];
}

export const Tabs = (props: Props) => {
  return (
    <Wrap>
      <Tab.Group>
        <Tab.List>
          <Flex>
            {props.labels.map((t) => (
              <Tab as={Fragment} key={t.toString()}>
                {({ selected }) => (
                  <Flex
                    direction="column"
                    style={{ marginRight: lightTheme.spacing.xl }}
                  >
                    <StyledButton selected={selected}>{t}</StyledButton>
                    <Underline selected={selected} />
                  </Flex>
                )}
              </Tab>
            ))}
          </Flex>
        </Tab.List>
        <Tab.Panels>
          {props.content.map((c, index) => (
            <Tab.Panel key={index}>{c}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Wrap>
  );
};

const Wrap = styled.div`
  div {
    :focus-visible {
      outline-width: 0px !important;
      border-width: 0px !important;
      outline: none !important;
    }

    :visible {
      outline-width: 0px !important;
      border-width: 0px !important;
      outline: none !important;
    }

    button {
      :focus-visible {
        outline-width: 0px !important;
        border-width: 0px !important;
        outline: none !important;
      }

      :visible {
        outline-width: 0px !important;
        border-width: 0px !important;
        outline: none !important;
      }
    }
  }
`;

const StyledButton = styled.button<{ selected: boolean }>`
  padding: ${(p) => p.theme.spacing.s} 0px 0;
  background-color: transparent;
  border-width: 0px !important;
  outline-width: 0px !important;
  font-size: ${(p) => p.theme.typography.size.m};
  font-family: ${(p) => p.theme.typography.bodyFamily};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  color: ${(p) => p.theme.color.typography.heading};
  user-select: none;

  cursor: pointer;

  transition: all 0.2s;
`;

export const Underline = styled.div<{ selected?: boolean }>`
  width: ${(p) => p.theme.spacing.xxxl};
  height: 2.5px;
  border-radius: 4px;
  background-color: ${(p) =>
    p.selected ? p.theme.color.primary : "transparent"};
  transition: all 0.2s;
  margin-top: ${(p) => p.theme.spacing.xs};
`;
