import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const TeamSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.751 17.128C14.604 17.3757 15.4878 17.5009 16.376 17.5C17.8044 17.5021 19.2142 17.1764 20.497 16.548C20.5349 15.6517 20.2796 14.7675 19.7698 14.0293C19.2599 13.2912 18.5234 12.7394 17.6717 12.4575C16.82 12.1757 15.8997 12.1791 15.0502 12.4674C14.2006 12.7557 13.4683 13.313 12.964 14.055M13.751 17.128V17.125C13.751 16.012 13.465 14.965 12.964 14.055M13.751 17.128V17.234C11.8265 18.3931 9.62161 19.0038 7.375 19C5.044 19 2.863 18.355 1.001 17.234L1 17.125C0.999234 15.7095 1.46961 14.3339 2.33698 13.2153C3.20435 12.0966 4.41937 11.2984 5.79049 10.9466C7.16161 10.5948 8.61082 10.7093 9.90966 11.2721C11.2085 11.8349 12.2831 12.814 12.964 14.055M10.751 4.375C10.751 5.27011 10.3954 6.12855 9.76249 6.76149C9.12955 7.39442 8.27111 7.75 7.376 7.75C6.4809 7.75 5.62245 7.39442 4.98952 6.76149C4.35658 6.12855 4.001 5.27011 4.001 4.375C4.001 3.47989 4.35658 2.62145 4.98952 1.98851C5.62245 1.35558 6.4809 1 7.376 1C8.27111 1 9.12955 1.35558 9.76249 1.98851C10.3954 2.62145 10.751 3.47989 10.751 4.375ZM19.001 6.625C19.001 7.32119 18.7244 7.98887 18.2322 8.48116C17.7399 8.97344 17.0722 9.25 16.376 9.25C15.6798 9.25 15.0121 8.97344 14.5198 8.48116C14.0276 7.98887 13.751 7.32119 13.751 6.625C13.751 5.92881 14.0276 5.26113 14.5198 4.76884C15.0121 4.27656 15.6798 4 16.376 4C17.0722 4 17.7399 4.27656 18.2322 4.76884C18.7244 5.26113 19.001 5.92881 19.001 6.625Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const TeamIcon = styled(TeamSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
