import { Link } from "react-router-dom";
import styled, { css } from "../styles";
import theme from "../styles/theme";
import withMargin, { WithMarginProp } from "../styles/withMargin";

type sizes = typeof theme.typography.size;
type colors = typeof theme.color.typography;

type LinkProps = {
  isInline?: boolean;
  size?: keyof sizes;
  textDecoration?: string;
  colorPreset?: keyof colors;
  weight?: keyof typeof theme.typography.weight;
};

export const TextLink = styled(Link)<LinkProps & WithMarginProp>`
  color: inherit;
  text-decoration: ${(p) => (p.textDecoration ? p.textDecoration : "none")};
  font-weight: ${(p) =>
    p.weight
      ? p.theme.typography.weight[p.weight]
      : p.theme.typography.weight.semi};
  color: ${(p) =>
    p.colorPreset
      ? p.theme.color.typography[p.colorPreset]
      : p.color
      ? p.color
      : p.theme.color.primary};
  font-size: ${(p) =>
    p.size ? p.theme.typography.size[p.size] : p.theme.typography.size.m};

  ${withMargin}
`;

export const ExternalTextLink = styled.a<LinkProps & WithMarginProp>`
  ${(p) =>
    p.isInline
      ? css`
          display: inline-block;
          color: ${(p) => p.theme.color.primary};
          text-decoration: underline;
        `
      : css`
          color: ${(p) => p.theme.color.primary};
          text-decoration: none;
        `}
  font-weight: ${(p) => p.theme.typography.weight.semi};
  font-size: ${(p) =>
    p.size ? p.theme.typography.size[p.size] : p.theme.typography.size.m};

  outline: none !important;
  border: none !important;
  cursor: pointer;

  ${withMargin}
`;

export const FakeTextLink = styled.span<LinkProps & WithMarginProp>`
  color: inherit;
  text-decoration: ${(p) => (p.textDecoration ? p.textDecoration : "none")};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.primary};
  font-size: ${(p) =>
    p.size ? p.theme.typography.size[p.size] : p.theme.typography.size.m};
  cursor: pointer;

  ${withMargin}
`;
