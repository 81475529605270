import { useState, useEffect } from "react";

export type ThemeNames = "light" | "dark";

export const useColorScheme = () => {
  const [theme, setTheme] = useState<"light" | "dark">("light");

  useEffect(() => {
    const userPreference = window.localStorage.getItem("theme");
    window.addEventListener("storage", (e: any) => {
      const theme = window.localStorage.getItem("theme");
      if (theme) {
        setTheme(theme === "dark" ? "dark" : "light");
      }
    });

    if (userPreference) {
      setTheme(userPreference === "dark" ? "dark" : "light");
    } else {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        setTheme("dark");
      }

      const updateScheme = (e: MediaQueryListEvent) => {
        e.matches ? setTheme("dark") : setTheme("light");
      };

      const mql = window.matchMedia("(prefers-color-scheme: dark)");

      try {
        mql.addEventListener("change", (e) => updateScheme(e));
      } catch (e1) {
        try {
          mql.addListener((e) => updateScheme(e));
        } catch (e2) {
          console.error(e2);
        }
      }

      return () => {
        try {
          mql.removeEventListener("change", (e) => updateScheme(e));
        } catch (e1) {
          try {
            // old Safari
            mql.removeListener((e) => updateScheme(e));
          } catch (e2) {
            console.error(e2);
          }
        }
      };
    }
  }, []);

  return theme;
};
