import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const MailIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.514 6.864c.273 0 .553-.143.84-.43L15.302.548C15.047.306 14.579.186 13.9.186H2.895c-.58 0-.98.111-1.204.335l5.982 5.913c.282.287.563.43.84.43Zm-7.39 4 4.833-4.779L1.11 1.307c-.104.196-.157.526-.157.99v7.548c0 .483.057.822.171 1.018Zm1.976 1.1h11.005c.57 0 .969-.11 1.197-.328l-4.929-4.881-.451.458c-.46.437-.93.656-1.408.656-.483 0-.953-.219-1.409-.656l-.458-.458-4.915 4.874c.26.223.716.335 1.368.335Zm12.783-1.108c.11-.2.164-.537.164-1.011V2.298c0-.46-.05-.784-.15-.97l-4.834 4.757 4.82 4.771Z"
      fill="#9570ED"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const MailIcon = styled(MailIconSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
