import { fromUnixTime } from "date-fns";
import { BrandListingsQuery, ListingType } from "../graphql/generated";

export const getListingStartDate = (
  event: BrandListingsQuery["listings"]["listings"][0]
): Date => {
  if (event.startDate) {
    return fromUnixTime(event.startDate);
  } else if (event.type === ListingType.Event) {
    return fromUnixTime(event.eventTimeSlots[0]);
  } else if (event.publishDate) {
    return fromUnixTime(event.publishDate);
  }
  return new Date("1970-01-01");
};

export const getListingEndDate = (
  event: BrandListingsQuery["listings"]["listings"][0]
): Date => {
  if (event.endDate) {
    return fromUnixTime(event.endDate);
  } else if (event.type === ListingType.Event) {
    return fromUnixTime(event.eventTimeSlots[event.eventTimeSlots.length - 1]);
  }
  return new Date("2100-01-01");
};
