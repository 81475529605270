import { useTheme } from "../../hooks/useTheme";

interface Props {
  width?: number;
}

export const EngagementIcon = (props: Props) => {
  const theme = useTheme();
  return (
    <svg
      width={props.width ? props.width : 56}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.66699"
        y="1"
        width="46"
        height="46"
        rx="23"
        stroke="url(#paint0_linear_2041_561)"
        stroke-width="2"
      />
      <path
        d="M23.667 26H25.667C26.1974 26 26.7061 25.7893 27.0812 25.4142C27.4563 25.0391 27.667 24.5304 27.667 24C27.667 23.4696 27.4563 22.9609 27.0812 22.5858C26.7061 22.2107 26.1974 22 25.667 22H22.667C22.067 22 21.567 22.2 21.267 22.6L15.667 28"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.667 32L21.267 30.6C21.567 30.2 22.067 30 22.667 30H26.667C27.767 30 28.767 29.6 29.467 28.8L34.067 24.4C34.4529 24.0354 34.6781 23.5323 34.6931 23.0016C34.7081 22.4709 34.5117 21.9559 34.147 21.57C33.7823 21.1841 33.2793 20.9589 32.7486 20.9439C32.2178 20.9289 31.7029 21.1254 31.317 21.49L27.117 25.39"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.667 27L20.667 33"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.1671 20.5C32.8671 19.8 33.6671 18.9 33.6671 17.8C33.737 17.1889 33.5985 16.5722 33.2741 16.0496C32.9496 15.5271 32.4582 15.1295 31.8795 14.9211C31.3008 14.7128 30.6687 14.706 30.0857 14.9018C29.5026 15.0976 29.0028 15.4846 28.6671 16C28.3102 15.5246 27.8102 15.1761 27.2406 15.0058C26.671 14.8354 26.0618 14.8522 25.5024 15.0536C24.943 15.2549 24.463 15.6303 24.1326 16.1246C23.8023 16.619 23.6392 17.2061 23.6671 17.8C23.6671 19 24.4671 19.8 25.1671 20.6L28.6671 24L32.1671 20.5Z"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2041_561"
          x1="0.666992"
          y1="24.0005"
          x2="48.6661"
          y2="24.0005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
