import { useTheme } from "../../hooks/useTheme";

interface Props {
  width?: number;
}

export const SharesIcon = (props: Props) => {
  const theme = useTheme();
  return (
    <svg
      width={props.width ? props.width : 48}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="44"
        height="44"
        rx="22"
        stroke="url(#paint0_linear_2058_4016)"
        stroke-width="2"
      />
      <path
        d="M18.5005 23L15.9971 14.8646C21.9855 16.6063 27.6327 19.3576 32.6951 23C27.633 26.6424 21.9861 29.3937 15.998 31.1355L18.5005 23ZM18.5005 23H25.3755"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2058_4016"
          x1="0"
          y1="23.0005"
          x2="45.9992"
          y2="23.0005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
