import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../config";
import { useTheme } from "../hooks/useTheme";
import { Flex } from "./Flex";
import { NewTag } from "./Tag";
import { Text } from "./Text";

interface Props {
  active: boolean;
  label: string;
  onClick?: any;
  hideInactiveBorder?: true;
  badgeCount?: number;
  textAlign?: "left" | "center";
  textSize?: "s" | "m";
  new?: boolean;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  underLineWidth?: number;
}

const Underline = styled.div<{ isActive?: boolean; lineWidth?: number }>`
  width: ${(p) => (p.lineWidth ? `${p.lineWidth}px` : p.theme.spacing.xxl)};
  height: 2px;
  background-color: ${(p) =>
    p.isActive ? p.theme.color.primary : "transparent"};
  margin-top: 6px;
`;

const StyledTab = styled.div<Props>`
  padding: ${(p) => p.theme.spacing.s} 2px 0px;
  padding-left: ${(p) => (p.textAlign === "left" ? "2px" : p.theme.spacing.m)};
  padding-right: ${(p) => p.theme.spacing.m};
  position: relative;

  justify-content: ${(p) => (p.textAlign === "left" ? "flex-start" : "center")};
  align-items: center;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  opacity: ${(p) => (p.isDisabled ? 0.5 : 1)};
  cursor: ${(p) => (p.isDisabled ? "normal" : "pointer")};

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
    padding-left: ${(p) =>
      p.textAlign === "left" ? "2px" : p.theme.spacing.s};
    padding-right: ${(p) =>
      p.textAlign === "left" ? "2px" : p.theme.spacing.s};
  }

  :focus,
  focus-visible {
    outline: none;
  }

  user-select: none;
`;

export const Badge = styled.span`
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
  padding: 3px;
  margin-bottom: 3px;
  border-radius: 100%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.xxs};
  border-radius: 100%;
  margin-left: ${(p) => p.theme.spacing.s};
  color: #fff;
  font-weight: ${(p) => p.theme.typography.weight.bold};
  background-color: ${(p) => p.theme.color.primary};
  color: #fff;
`;

export const Tab = (props: Props) => {
  const theme = useTheme();
  return (
    <StyledTab {...props}>
      <Flex align="center" direction="column">
        <Flex align="center" justify="center" direction="row">
          {props.icon ? (
            <Flex align="center" justify="center" margin="0 s xxs 0">
              {props.icon}
            </Flex>
          ) : null}
          <Text
            weight={props.active ? "bold" : "semi"}
            color={
              props.active
                ? theme.color.typography.secondaryHeading
                : theme.color.typography.text
            }
            margin="0"
          >
            {props.label}
          </Text>
          {props.badgeCount && props.badgeCount > 0 ? (
            <Badge>{props.badgeCount && props.badgeCount}</Badge>
          ) : null}
        </Flex>
        {props.new ? (
          <NewWrap>
            <NewTag />
          </NewWrap>
        ) : null}
        <Underline
          isActive={props.active}
          lineWidth={props.underLineWidth}
          style={{
            marginBottom: -4,
          }}
        />
      </Flex>
    </StyledTab>
  );
};

const NewWrap = styled.div`
  position: absolute;
  top: 4px;
  right: -32px;
`;
