import { useEffect, useState } from "react";
import styled, { css } from "../styles";
import Loader from "./Loader";

const LoadingWrap = styled.div<{ fullHeight?: true }>`
  display: flex;
  ${(p) =>
    p.fullHeight
      ? css`
          height: 100vh;
        `
      : css`
          height: 100%;
        `};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.xl} 0;
`;

const Loading = (props: { defer?: boolean; fullHeight?: true }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.defer) {
      let timer = setTimeout(() => setShow(true), 500);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setShow(true);
    }
  }, [props.defer]);

  return (
    <LoadingWrap fullHeight={props.fullHeight}>
      {show && <Loader />}
    </LoadingWrap>
  );
};

export default Loading;
