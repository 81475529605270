import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { Card } from "../../../components/Card";
import { Flex } from "../../../components/Flex";
import Loader from "../../../components/Loader";
import { Logo } from "../../../components/Logo";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { useTheme } from "../../../hooks/useTheme";
import styled from "../../../styles";

export function ListingInvites() {
  const [isDesktop, setIsDesktop] = useState(false);
  let { listingId } = useParams<{ listingId: string }>();
  const theme = useTheme();

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();

    const android = /(android)/g.test(ua);
    const iOS = /(ipad|iphone|ipod)/g.test(ua);

    if (android || iOS) {
      const appUrl = listingId
        ? `com.nibble.app://listingDetails/${listingId}`
        : "com.nibble.app://";

      window.location.href = appUrl;

      setTimeout(() => {
        if (android) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.nibble.app";
        } else if (iOS) {
          window.location.href =
            "https://apps.apple.com/gb/app/nibble-app/id1637099318";
        }
      }, 1000);
    } else {
      setIsDesktop(true);
    }
  }, [listingId]);

  return (
    <Wrap>
      <View margin="xl 0 0 0">
        <Logo />
      </View>
      {!isDesktop ? (
        <Loader />
      ) : (
        <Content>
          <Card
            padding="xxl xxxl xxl"
            style={{ borderRadius: theme.misc.borderRadiusLarge }}
          >
            <Flex
              align="center"
              direction="column"
              margin="0 0 0"
              style={{ maxWidth: 300 }}
            >
              <QRCode
                bgColor={theme.color.card.background}
                fgColor={theme.color.typography.heading}
                size={128}
                value={
                  listingId
                    ? `com.nibble.app://listingDetails/${listingId}`
                    : "com.nibble.app://"
                }
              />
              <Text align="center" margin="xl 0 0">
                Scan the QR code on your phone to open the invite on Joli
              </Text>
            </Flex>
          </Card>
        </Content>
      )}
    </Wrap>
  );
}

const Wrap = styled.div`
  flex: 1;
  display: flex;
  max-width: 100%;
  align-self: center;
  flex-direction: column;
  align-items: center;
  color: ${(p) => p.theme.color.typography.text};
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: -120px ${(p) => p.theme.spacing.m} 0;
`;
