import { styled } from "../../styles";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 500px;
  max-width: 100%;
  align-self: center;
  color: ${(p) => p.theme.color.typography.text};
  /* text-align: center; */
`;

export const BookingResponse = () => {
  return <Wrap>{/* <BookingDetails /> */}</Wrap>;
};
