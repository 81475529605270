import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const RescheduleSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11Z"
      stroke="#E6437D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 15C5.93913 15 4.92172 15.4214 4.17157 16.1716C3.42143 16.9217 3 17.9391 3 19V21"
      stroke="#E6437D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.4 20.9001C17.2815 21.0261 18.1803 20.8714 18.9691 20.4581C19.7578 20.0449 20.3966 19.3939 20.7948 18.5974C21.1931 17.8009 21.3306 16.8994 21.188 16.0204C21.0453 15.1414 20.6297 14.3296 20.0001 13.6999C19.284 12.9838 18.3127 12.5815 17.3 12.5815C16.2873 12.5815 15.316 12.9838 14.5999 13.6999C13.8019 14.4979 13.3999 15.548 13.3999 16.592L13.3999 19.1001"
      stroke="#E6437D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 16.7002L13.4001 19.1003L15.8001 16.7002"
      stroke="#E6437D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const RescheduleIcon = styled(RescheduleSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.theme.color.primary};
  }
`;
