import styled from "../styles";
import { darkTheme } from "../styles/theme";
import { Flex } from "./Flex";

const BurgerPatties = styled.div`
  max-height: 2px;
  height: 100%;
  border-radius: 99px;
  width: 26px;
  transform-origin: 2.5px;
  position: relative;
  background: ${darkTheme.color.typography.secondary};
  margin: 0 auto;
`;

const BurgerMenu = styled(Flex)<{ showMenu?: boolean }>`
  cursor: pointer;
  width: 26px;
  height: 16px;
  border: 1px solid ${darkTheme.color.typography.secondary}90;
  padding: 8px;
  border-radius: 6px;
`;

function MobileMenu({
  showMenu,
  menuToggle,
}: {
  showMenu: boolean;
  menuToggle: (x: boolean) => void;
}) {
  return (
    <BurgerMenu
      justify="space-between"
      direction="column"
      onClick={() => menuToggle(!showMenu)}
      showMenu={showMenu}
    >
      <BurgerPatties />
      <BurgerPatties />
      <BurgerPatties />
    </BurgerMenu>
  );
}

export default MobileMenu;
