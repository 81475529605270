import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

interface Props extends React.SVGProps<SVGSVGElement> {
  opacity?: number;
}

const PauseIconSVG = (props: Props) => (
  <svg
    width="40"
    height="52"
    viewBox="0 0 40 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.75"
      d="M4.86523 51.4531C3.40039 51.4531 2.29688 51.082 1.55469 50.3398C0.832031 49.5977 0.470703 48.4941 0.470703 47.0293V4.63672C0.470703 3.17188 0.841797 2.07813 1.58398 1.35547C2.32617 0.613281 3.41992 0.242188 4.86523 0.242188H11.8965C13.3418 0.242188 14.4355 0.603516 15.1777 1.32617C15.9199 2.04883 16.291 3.15234 16.291 4.63672V47.0293C16.291 48.4941 15.9199 49.5977 15.1777 50.3398C14.4355 51.082 13.3418 51.4531 11.8965 51.4531H4.86523ZM27.7168 51.4531C26.2324 51.4531 25.1191 51.082 24.377 50.3398C23.6543 49.5977 23.293 48.4941 23.293 47.0293V4.63672C23.293 3.17188 23.6641 2.07813 24.4062 1.35547C25.1484 0.613281 26.252 0.242188 27.7168 0.242188H34.7188C36.1836 0.242188 37.2773 0.603516 38 1.32617C38.7422 2.04883 39.1133 3.15234 39.1133 4.63672V47.0293C39.1133 48.4941 38.7422 49.5977 38 50.3398C37.2773 51.082 36.1836 51.4531 34.7188 51.4531H27.7168Z"
      fill="white"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const PauseIcon = styled(PauseIconSVG)<Props>`
  margin-left: 2px;
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
