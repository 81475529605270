import styled from "../styles";
import { Flex } from "./Flex";

const ShareLinkWrap = styled(Flex)`
  background: ${(p) => p.theme.color.typography.secondary}16;
  border-radius: 8px;
  outline: none;
  border: none;
`;

const ShareLinkInput = styled.input`
  border: none;
  color: ${(p) => p.theme.color.typography.text};
  background-color: transparent;
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  font-size: ${(p) => p.theme.typography.size.s};
  margin-right: 10px;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  border: none;
`;

export const ShareLink = ({ link }: { link: string }) => {
  return (
    <ShareLinkWrap>
      <ShareLinkInput autoFocus={false} type="text" value={link} />
    </ShareLinkWrap>
  );
};
