import {
  add,
  addMonths,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatDistanceToNow,
  fromUnixTime,
  getUnixTime,
  isBefore,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MatchParams } from ".";
import { BookingDetails } from "../../../components/Brand/BookingDetails";
import { StackOnMobile } from "../../../components/Brand/ContactDetails";
import { CardLink } from "../../../components/CardLink";
import { Chevron } from "../../../components/Chevron";
import { Dropdown } from "../../../components/Dropdown";
import { Flex } from "../../../components/Flex";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { Underline } from "../../../components/Tabs";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import {
  LARGE_DESKTOP_BREAKPOINT,
  MEDIUM_DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "../../../config";
import {
  BookingStatus,
  BrandBookingsQuery,
  ListingType,
  useBrandBookingsQuery,
  useBrandLocationsQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 ${(p) => p.theme.spacing.l};
`;

const DesktopOnly = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, minmax(0, 1fr));

  & > div:nth-child(7n) {
    border-right: 1px solid transparent;
  }
`;

const CalendarHeaderWrap = styled.div`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  overflow: hidden;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const HeaderCell = styled.div`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  background-color: ${({ theme }) => theme.color.card.callout};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: ${({ theme }) => theme.color.typography.text};
  user-select: none;
  font-size: ${({ theme }) => theme.typography.size.xs};
  text-transform: uppercase;
`;

const DayCell = styled.div<{ isPast: boolean }>`
  padding: ${(p) => p.theme.spacing.s};
  border-right: 1px solid ${({ theme }) => theme.color.card.divider};
  border-bottom: 1px solid ${({ theme }) => theme.color.card.divider};
  height: 130px;
  overflow-y: scroll;
  opacity: ${(p) => (p.isPast ? 0.5 : 1)};
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 32px;
    overflow-y: unset;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const MonthNavWrap = styled(Flex)`
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing.m};
  margin-top: ${(p) => p.theme.spacing.m};
  margin-left: ${(p) => p.theme.spacing.xs};
  user-select: none;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 auto;
    justify-content: space-between;
    width: 100%;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
    box-sizing: border-box;
  }
`;

interface BookingCardProps {
  id: string;
  preferredProfile: BrandBookingsQuery["bookings"]["bookings"][0]["creator"]["preferredProfile"];
  location: BrandBookingsQuery["bookings"]["bookings"][0]["location"];
  confirmedTimeslot?: BrandBookingsQuery["bookings"]["bookings"][0]["confirmedTimeslot"];
  trimmedName?: string;
}

export const UpcomingBookings = () => {
  const grouped: { [key: string]: [BookingCardProps] } = {};
  const [activeBookingId, setActiveBookingId] = useState<string | null>(null);
  const today = useMemo(() => new Date(), []);
  const [displayedDate, setDisplayedDate] = useState(new Date());
  const startDay = startOfWeek(startOfMonth(displayedDate), {
    weekStartsOn: 1,
  });
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const endDay = endOfWeek(endOfMonth(displayedDate), { weekStartsOn: 1 });
  const days = eachDayOfInterval({ start: startDay, end: endDay });
  const window = useWindowSize();
  const history = useHistory();
  let idMatch = useRouteMatch<MatchParams>("/b/bookings/:page/:id");
  const brand = useSelector(authSelectors.activeBrand);
  const client = useGqlClient();
  const [locationFilter, setLocationFilter] = useState<string[]>([]);

  const { data: locationsData, isLoading: locationsIsLoading } =
    useBrandLocationsQuery(client, {
      brandId: brand ? brand.id : "",
    });

  const { data } = useBrandBookingsQuery(client, {
    bookingStartDate: getUnixTime(startDay),
    statuses: [
      BookingStatus.BookingStatusApproved,
      BookingStatus.BookingStatusCompleted,
    ],
    count: 500,
  });

  const bookings = data?.bookings.bookings;

  useEffect(() => {
    const q = new URLSearchParams(history.location.search);
    const timeslot = q.get("ts") as unknown as number;

    if (!timeslot) {
      return;
    }

    const date = fromUnixTime(timeslot);

    setDisplayedDate(date);
  }, [history.location.search, setSelectedDay]);

  useEffect(() => {
    if (idMatch) {
      setActiveBookingId(idMatch.params.id || null);
    }
  }, [idMatch]);

  useEffect(() => {
    if (locationsData?.brand?.locations?.locations) {
      setLocationFilter(
        locationsData.brand.locations.locations.map((l) => l.id)
      );
    }
  }, [locationsData]);

  const handlePrevMonth = () => {
    setDisplayedDate((prevDate) => subMonths(prevDate, 1));
  };

  const handleNextMonth = () => {
    setDisplayedDate((prevDate) => addMonths(prevDate, 1));
  };

  const handleDayClick = (day: Date) => {
    if (window && window.width && window.width > MOBILE_BREAKPOINT) {
      return;
    }
    setSelectedDay(day);
  };

  const anytimeBookings =
    bookings &&
    bookings
      .filter(
        (b) =>
          b.type === ListingType.RedeemAnytime &&
          !b.redeemedAt &&
          b.creator.preferredProfile &&
          (!locationFilter ||
            (b?.location?.id && locationFilter.includes(b.location.id)))
      )
      .sort((a, b) => a.createdAt - b.createdAt);

  bookings &&
    bookings
      .filter(
        (e) =>
          e.confirmedTimeslot &&
          e.creator.preferredProfile &&
          e.location &&
          (!locationFilter ||
            (e?.location?.id && locationFilter.includes(e.location.id)))
      )
      .sort((a, b) => a.confirmedTimeslot!.date - b.confirmedTimeslot!.date)
      .forEach((b) => {
        const key = format(
          startOfDay(fromUnixTime(b.confirmedTimeslot!.date)),
          "EEEE, do MMMM y"
        );

        const trimmedName = b.creator.preferredProfile!.name;

        if (grouped[key]) {
          grouped[key].push({
            id: b.id,
            preferredProfile: b.creator.preferredProfile,
            location: b.location,
            confirmedTimeslot: b.confirmedTimeslot,
            trimmedName,
          });
        } else {
          grouped[key] = [
            {
              id: b.id,
              preferredProfile: b.creator.preferredProfile,
              location: b.location,
              confirmedTimeslot: b.confirmedTimeslot,
              trimmedName,
            },
          ];
        }
      });

  const selectedDayBookings =
    bookings &&
    bookings.filter((b) => {
      return (
        b.confirmedTimeslot &&
        b.creator.preferredProfile &&
        isSameDay(fromUnixTime(b.confirmedTimeslot!.date), selectedDay!)
      );
    });

  if (locationsIsLoading) {
    return <Loading />;
  }

  return (
    <Wrap>
      <Modal
        isOpen={activeBookingId ? true : false}
        setIsOpen={() => setActiveBookingId(null)}
        onClose={() => {
          history.replace("/b/bookings/upcoming");
          setActiveBookingId(null);
        }}
        maxWidth={960}
        noPadding
      >
        <BookingDetails bookingId={activeBookingId ? activeBookingId : null} />
      </Modal>

      <CalendarWrap>
        <HeaderWrap align="center" justify="space-between" margin="xs s xs">
          <Flex align="center" justify="center">
            <MonthNavWrap>
              <ChevronWrap
                onClick={handlePrevMonth}
                style={{ marginBottom: -3, marginRight: -6 }}
              >
                <Chevron width={9} direction="left" />
              </ChevronWrap>
              <Flex style={{ width: 180 }} justify="center">
                <Text size="l" weight="bold" margin="0 l">
                  {format(displayedDate, "MMMM")}{" "}
                  <span style={{ fontWeight: 400 }}>
                    {format(displayedDate, "yyy")}
                  </span>
                </Text>
              </Flex>
              <ChevronWrap onClick={handleNextMonth}>
                <Chevron width={9} />
              </ChevronWrap>
            </MonthNavWrap>
            <DesktopOnly>
              {displayedDate.getMonth() !== today.getMonth() ? (
                <TodayButton onClick={() => setDisplayedDate(today)}>
                  Today
                </TodayButton>
              ) : null}
            </DesktopOnly>
          </Flex>
          <DesktopOnly>
            {locationsData?.brand?.locations?.locations?.length ? (
              <Dropdown
                showSelectAll
                disableOptionSort
                options={[
                  ...locationsData.brand.locations.locations
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => ({
                      label: item.name,
                      value: item.id,
                    })),
                ]}
                width={240}
                selectedOptions={locationFilter}
                setSelectedOptions={(value) => setLocationFilter(value)}
                renderLabel={() => {
                  if (
                    !locationsData?.brand?.locations?.locations ||
                    locationFilter.length === 0
                  ) {
                    return "0 locations";
                  }

                  if (
                    locationFilter.length ===
                    locationsData.brand.locations.locations.length
                  ) {
                    return "All locations";
                  }

                  return `${locationFilter.length} locations`;
                }}
                dropdownType="text"
                selectionMode="multiple"
              />
            ) : null}
          </DesktopOnly>
        </HeaderWrap>
        <StackOnMobile>
          <Calendar>
            <DesktopOnly>
              <CalendarHeaderWrap>
                <Grid>
                  <HeaderCell>Monday</HeaderCell>
                  <HeaderCell>Tuesday</HeaderCell>
                  <HeaderCell>Wednesday</HeaderCell>
                  <HeaderCell>Thursday</HeaderCell>
                  <HeaderCell>Friday</HeaderCell>
                  <HeaderCell>Saturday</HeaderCell>
                  <HeaderCell>Sunday</HeaderCell>
                </Grid>
              </CalendarHeaderWrap>
            </DesktopOnly>
            <MobileOnly>
              <CalendarHeaderWrap>
                <Grid>
                  <HeaderCell>Mo</HeaderCell>
                  <HeaderCell>Tu</HeaderCell>
                  <HeaderCell>We</HeaderCell>
                  <HeaderCell>Th</HeaderCell>
                  <HeaderCell>Fr</HeaderCell>
                  <HeaderCell>Sa</HeaderCell>
                  <HeaderCell>Su</HeaderCell>
                </Grid>
              </CalendarHeaderWrap>
            </MobileOnly>
            <Grid>
              {days.map((day, index) => {
                const key = format(startOfDay(day), "EEEE, do MMMM y");
                const dayBookings = grouped[key] || [];
                const isPast = isBefore(endOfDay(day), new Date());
                const isActive = isSameDay(day, selectedDay); // Check if the day is the active (selected) day

                return (
                  <DayCell
                    key={index}
                    isPast={isPast}
                    onClick={() => handleDayClick(day)}
                  >
                    <DayNumberWrap active={isActive}>
                      <span>{format(day, "d")}</span>
                      <BookingsIndicator dots={dayBookings.length} />
                    </DayNumberWrap>
                    {dayBookings.map((b) => (
                      <DesktopOnly>
                        <CardLink to={`/b/bookings/upcoming/${b.id}`}>
                          <BookingsCard key={b.id}>
                            <Flex
                              align="center"
                              margin="0 0 xs"
                              className="booking-card-name"
                            >
                              <NameLine />
                              <Text
                                size="xs"
                                isCompact
                                margin="0 0 0 s"
                                weight="bold"
                                truncate
                              >
                                {b.trimmedName}
                              </Text>
                            </Flex>
                            <Flex direction="row" align="center">
                              {b.confirmedTimeslot && (
                                <Text isCompact margin="0" size="xs">
                                  {format(
                                    fromUnixTime(b.confirmedTimeslot.date),
                                    "HH:mm"
                                  )}
                                </Text>
                              )}
                              {b.location && (
                                <Text
                                  isCompact
                                  margin="0 0 0 xs"
                                  size="xs"
                                  truncate
                                >
                                  {" - "}
                                  {b.location.name}
                                </Text>
                              )}
                            </Flex>
                          </BookingsCard>
                        </CardLink>
                      </DesktopOnly>
                    ))}
                  </DayCell>
                );
              })}
            </Grid>
            <MobileOnly>
              {selectedDay && (
                <View margin="l s s">
                  <View margin="0 0 l">
                    <Text size="m" weight="bold" margin="0">
                      {format(selectedDay, "MMMM do")} bookings
                    </Text>
                    <Underline selected={true} />
                  </View>
                  {selectedDayBookings && selectedDayBookings.length > 0 ? (
                    selectedDayBookings.map((b) => (
                      <CardLink to={`/b/bookings/upcoming/${b.id}`}>
                        <BookingsCard key={b.id}>
                          <Flex
                            align="center"
                            margin="0 0 xs"
                            className="booking-card-name"
                          >
                            <NameLine />
                            <Text
                              size="xs"
                              isCompact
                              margin="0 0 0 s"
                              weight="bold"
                              truncate
                            >
                              {b.creator.preferredProfile!.name}
                            </Text>
                          </Flex>
                          <Flex direction="row" align="center">
                            {b.confirmedTimeslot && (
                              <Text isCompact margin="0" size="xs">
                                {format(
                                  fromUnixTime(b.confirmedTimeslot.date),
                                  "HH:mm"
                                )}
                              </Text>
                            )}
                            {b.location && (
                              <Text isCompact margin="0 0 0 xs" size="xs">
                                {" - "}
                                {b.location.name}
                              </Text>
                            )}
                          </Flex>
                        </BookingsCard>
                      </CardLink>
                    ))
                  ) : (
                    <Text margin="l 0 0" colorPreset="secondary">
                      No bookings
                    </Text>
                  )}
                </View>
              )}
            </MobileOnly>
          </Calendar>
          {anytimeBookings && anytimeBookings.length > 0 ? (
            <AnytimeWrap>
              <View>
                <View margin="0 0 l" flex={1}>
                  <Text size="m" weight="bold" margin="0">
                    Anytime Redeem
                  </Text>
                  <Underline selected={true} />
                </View>
                {anytimeBookings.map((b) => (
                  <CardLink to={`/b/bookings/upcoming/${b.id}`}>
                    <BookingsCard key={b.id}>
                      <Flex
                        align="center"
                        margin="0 0 xs"
                        className="booking-card-name"
                      >
                        <NameLine />
                        <Text
                          size="xs"
                          isCompact
                          margin="0 0 0 s"
                          weight="bold"
                          truncate
                        >
                          {b.creator.preferredProfile!.name}
                        </Text>
                      </Flex>
                      <Flex direction="row" align="center">
                        {b.approvedAt && (
                          <Text isCompact margin="0" size="xs">
                            Expires in{" "}
                            {formatDistanceToNow(
                              add(fromUnixTime(b.approvedAt), { days: 30 })
                            )}
                          </Text>
                        )}
                      </Flex>
                    </BookingsCard>
                  </CardLink>
                ))}
              </View>
            </AnytimeWrap>
          ) : null}
        </StackOnMobile>
      </CalendarWrap>
    </Wrap>
  );
};

const AnytimeWrap = styled.div`
  min-width: 220px;
  margin-left: ${(p) => p.theme.spacing.m};

  @media (min-width: ${MEDIUM_DESKTOP_BREAKPOINT}px) {
    min-width: 250px;
  }

  @media (min-width: ${LARGE_DESKTOP_BREAKPOINT}px) {
    min-width: 250px;
    margin-left: ${(p) => p.theme.spacing.l};
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.m};
    padding-bottom: ${(p) => p.theme.spacing.s};
    background-color: ${(p) => p.theme.color.card.background};
    box-shadow: ${(p) => p.theme.shadow.card};
    border-radius: ${(p) => p.theme.misc.borderRadius};
  }
`;

const Calendar = styled.div`
  box-shadow: none;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
`;

const CalendarWrap = styled.div`
  background-color: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s} 0;
`;

const BookingsCard = styled(View)`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  border: 1px solid ${(p) => p.theme.color.card.divider};
  margin-bottom: ${(p) => p.theme.spacing.s};
  cursor: pointer;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};

    .booking-card-name {
      margin-bottom: ${(p) => p.theme.spacing.s};
    }
  }
`;

const TodayButton = styled.div`
  border: 2px solid ${(p) => p.theme.color.divider};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  font-size: 14px;
  font-family: ${(p) => p.theme.typography.bodyFamily};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  padding: 5px ${(p) => p.theme.spacing.l} 3px;
  margin-left: ${(p) => p.theme.spacing.l};
  user-select: none;
  cursor: pointer;
  color: ${(p) => p.theme.color.typography.text};
  transition: background 300ms ease-out-in;

  :hover {
    background: ${(p) => p.theme.color.card.callout};
  }
`;

const BookingsIndicator = ({ dots }: { dots: number }) => {
  const maxDots = dots > 3 ? 3 : dots;

  return (
    <Flex direction="row" justify="center" margin="0 0 xs" style={{ gap: 3 }}>
      {Array.from({ length: maxDots }).map((_, index) => (
        <Dot className="dot" key={index} />
      ))}
    </Flex>
  );
};

const Dot = styled.div`
  height: 3px;
  width: 3px;
  background-color: ${(p) => p.theme.color.primary};
  border-radius: 999px;
  display: block;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

export const NameLine = styled.div`
  min-width: 3px;
  width: 3px;
  height: 14px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.color.primary};
`;

const DayNumberWrap = styled.span<{ active: boolean }>`
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
  padding: calc(${(p) => p.theme.spacing.s} + 1px);
  margin-bottom: ${(p) => p.theme.spacing.xs};
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 200ms ease-out;
  background-color: ${(p) =>
    p.active ? p.theme.color.primary : `trasnsparent`};
  color: ${(p) => (p.active ? `#fff` : `${p.theme.color.typography.text}`)};

  span {
    font-weight: ${(p) => p.theme.typography.weight.bold};
    font-size: ${(p) => p.theme.typography.size.m};
  }

  .dot {
    background: ${(p) => (p.active ? "#fff" : p.theme.color.primary)};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-radius: ${(p) => p.theme.misc.borderRadius};
  }
`;

const ChevronWrap = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderWrap = styled(Flex)`
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  box-sizing: border-box;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    justify-content: center;
    padding: 0;
  }
`;
