import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const BoltIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="11"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.640625 7.44922C0.640625 7.33203 0.691406 7.20703 0.792969 7.07422L6.11914 0.423828C6.2168 0.298828 6.32227 0.222656 6.43555 0.195312C6.55273 0.167969 6.65625 0.179687 6.74609 0.230469C6.83984 0.277344 6.9043 0.355469 6.93945 0.464844C6.97852 0.574219 6.96875 0.703125 6.91016 0.851562L5.16406 5.59766H8.47461C8.59961 5.59766 8.70117 5.63672 8.7793 5.71484C8.85742 5.78906 8.89648 5.88281 8.89648 5.99609C8.89648 6.11719 8.8457 6.24219 8.74414 6.37109L3.41797 13.0273C3.32031 13.1484 3.21289 13.2227 3.0957 13.25C2.98242 13.2812 2.87891 13.2715 2.78516 13.2207C2.69531 13.1738 2.63086 13.0957 2.5918 12.9863C2.55664 12.877 2.56836 12.7461 2.62695 12.5938L4.37305 7.84766H1.0625C0.9375 7.84766 0.835938 7.81055 0.757812 7.73633C0.679688 7.6582 0.640625 7.5625 0.640625 7.44922Z"
      fill="black"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const BoltIcon = styled(BoltIconSVG)<Props>`
  margin-bottom: -2px;
  path {
    fill: ${(p) =>
      p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.color
        ? p.color
        : p.theme.color.stats.positiveValue};
  }
`;
