import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Callout } from "../../../components/Callout";
import { CloseIcon } from "../../../components/CloseIcon";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H1 } from "../../../components/Heading";
import { PostIcon } from "../../../components/icons/Instagram/PostIcon";
import { ReelIcon } from "../../../components/icons/Instagram/ReelIcon";
import { StoryIcon } from "../../../components/icons/Instagram/StoryIcon";
import { NoEntry } from "../../../components/icons/NoEntry";
import { PauseIcon } from "../../../components/icons/PauseIcon";
import { PicturesIcon } from "../../../components/icons/PictureIcon";
import { PlayIcon } from "../../../components/icons/PlayIcon";
import { TickIcon } from "../../../components/icons/TickIcon";
import { LocationImage } from "../../../components/LocationImage";
import { Radio } from "../../../components/RadioButton";
import { StyledMarkdown } from "../../../components/StyledMarkdown";
import { Text } from "../../../components/Text";
import { ExternalTextLink } from "../../../components/TextLink";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT, XL_DESKTOP_BREAKPOINT } from "../../../config";
import {
  ListingOfferInput,
  Platform,
  useBrandLocationsQuery,
  useDietaryPreferencesQuery,
  useGetBrandHandlesQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import styled, { css } from "../../../styles";

interface SlideoutDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  images: string[];
  instagramOffers: ListingOfferInput[];
  tiktokOffers: ListingOfferInput[];
  name: string;
  description: string;
  locations: string[];
  instagramHandles: string[];
  tiktokHandles: string[];
  reels: number;
  posts: number;
  stories: number;
  tiktoks: number;
  dietaryPreferences?: string[] | null;
  ageRestrictions: number | null;
  videoUrl?: string;
}

export const Preview = (props: SlideoutDrawerProps) => {
  if (!props.isOpen) return null;

  return <AnimatedDrawer {...props} />;
};

const AnimatedDrawer: React.FC<Omit<SlideoutDrawerProps, "isOpen">> = ({
  onClose,
  images,
  name,
  description,
  locations,
  ageRestrictions,
  dietaryPreferences,
  instagramHandles,
  tiktokHandles,
  reels,
  posts,
  stories,
  tiktoks,
  instagramOffers,
  tiktokOffers,
  videoUrl,
}) => {
  const theme = useTheme();
  const client = useGqlClient();
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>(
    Platform.Instagram
  );

  const videoRef = useRef<HTMLVideoElement>(null);
  const [playVideo, setPlayVideo] = useState<boolean | null>(null);

  const { data: dietaryPreferencesData } = useDietaryPreferencesQuery(client);
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const { data: brandHandles } = useGetBrandHandlesQuery(client, {
    id: activeBrandId ?? "",
  });
  const { data: brandLocationsData } = useBrandLocationsQuery(client, {
    brandId: activeBrandId ?? "",
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [activeOffer, setActiveOffer] = useState<ListingOfferInput | null>(
    null
  );

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const scrollPosition = container.scrollLeft;
      const itemWidth = container.clientWidth;
      const newIndex = Math.round(scrollPosition / itemWidth);
      setCurrentIndex(newIndex);
    };

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (selectedPlatform === Platform.Instagram) {
      if (instagramOffers.length === 0) {
        setActiveOffer(null);
      } else if (instagramOffers.length > 1) {
        setActiveOffer(instagramOffers[1]);
      } else {
        setActiveOffer(instagramOffers[0]);
      }
    } else {
      if (tiktokOffers.length === 0) {
        setActiveOffer(null);
      } else if (tiktokOffers.length > 1) {
        setActiveOffer(tiktokOffers[1]);
      } else {
        setActiveOffer(tiktokOffers[0]);
      }
    }
  }, [selectedPlatform, instagramOffers, tiktokOffers]);

  const handleIndicatorClick = (index: number) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const itemWidth = container.clientWidth;
    container.scrollTo({
      left: index * itemWidth,
      behavior: "smooth",
    });
    setCurrentIndex(index);

    if (index !== 0 && videoRef.current) {
      setPlayVideo(false);
    }
  };

  const handleWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    if (!videoRef.current || playVideo === null) {
      return;
    }
    playVideo ? videoRef.current.play() : videoRef.current.pause();
  }, [playVideo]);

  const carouselLength = videoUrl ? images.length + 1 : images.length;

  const instagramHandle =
    brandHandles && brandHandles.brand && brandHandles.brand.instagramHandle
      ? brandHandles.brand.instagramHandle
      : instagramHandles[0];
  const tiktokHandle =
    brandHandles && brandHandles.brand && brandHandles.brand.tikTokHandle
      ? brandHandles.brand.tikTokHandle
      : tiktokHandles[0];

  const renderLocationText = () => {
    if (!brandLocationsData || !locations || locations.length === 0) {
      return "No locations selected";
    }

    if (locations.length === 1) {
      const singleLocation =
        brandLocationsData?.brand?.locations.locations.find((l) =>
          locations.includes(l.id)
        );
      return singleLocation ? singleLocation.address : "Select locations";
    }

    return "Multiple locations";
  };

  return (
    <BackgroundWrapper onClick={handleWrapperClick}>
      <DrawerWrapper>
        <div style={{ position: "relative" }}>
          <CloseWrapper
            onClick={playVideo ? () => setPlayVideo(false) : onClose}
          >
            <CloseIcon color={"#fff"} />
          </CloseWrapper>
          <div style={{ position: "relative" }}>
            {carouselLength > 0 ? (
              <ImageScrollContainer ref={scrollContainerRef}>
                {videoUrl ? (
                  <VideoWrapper isPlaying={playVideo}>
                    <PlayButtonWrap onClick={() => setPlayVideo(!playVideo)}>
                      {playVideo ? (
                        <PauseIcon width={38} height={38} />
                      ) : (
                        <PlayIcon width={38} height={38} />
                      )}
                    </PlayButtonWrap>
                    <Video
                      muted
                      ref={videoRef}
                      src={videoUrl}
                      onClick={() => setPlayVideo(!playVideo)}
                      onEnded={() => {
                        if (!videoRef.current) {
                          return;
                        }
                        setPlayVideo(false);
                        videoRef.current.currentTime = 0;
                      }}
                    />
                  </VideoWrapper>
                ) : null}
                {images.map((image, index) => (
                  <LocationImage key={index} height={260} imageUrl={image} />
                ))}
                {carouselLength > 1 && (
                  <IndicatorWrapper>
                    {Array.from({ length: carouselLength }).map((_, index) => (
                      <Indicator
                        key={index}
                        active={index === currentIndex}
                        onClick={() => handleIndicatorClick(index)}
                      />
                    ))}
                  </IndicatorWrapper>
                )}
              </ImageScrollContainer>
            ) : (
              <div
                style={{
                  height: 260,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: theme.color.card.callout,
                }}
              >
                <PicturesIcon colorPreset="secondary" />
              </div>
            )}
          </div>
          <View padding="m l">
            <Callout type="info">
              <Text size="s" margin="0" style={{ lineHeight: "1.3" }}>
                This is a simplified preview of what an influencer will see on
                their Joli app.
              </Text>
            </Callout>
            <H1 margin="l 0 xs">{name}</H1>
            <Text margin="0 0 m" colorPreset="secondary">
              {renderLocationText()}
            </Text>
            <StyledMarkdown markdown={description} />
            <TagsWrap>
              {dietaryPreferences &&
                dietaryPreferences.length > 0 &&
                dietaryPreferencesData?.dietaryPreferences
                  .filter((dp) => dietaryPreferences.includes(dp.id))
                  .map((dp) => (
                    <DietaryPreferencesWrap key={dp.id}>
                      <Flex margin="0 xs 0 0" justify="center" align="center">
                        <TickIcon
                          color={theme.color.typography.secondary}
                          width={20}
                        />
                      </Flex>
                      <Text size="xs" weight="semi" margin="0">
                        {dp.label}
                      </Text>
                    </DietaryPreferencesWrap>
                  ))}
              {ageRestrictions && ageRestrictions > 0 ? (
                <AgeRestrictionsWrap>
                  <Flex
                    margin="0 xs 0 0"
                    justify="center"
                    align="center"
                    style={{ marginTop: -3 }}
                  >
                    <NoEntry width={16} color={theme.color.primary} />
                  </Flex>
                  <Text size="xs" weight="semi" margin="xxs 0">
                    {ageRestrictions}+ Only
                  </Text>
                </AgeRestrictionsWrap>
              ) : null}
            </TagsWrap>
            <View margin="l 0 0 0">
              {instagramHandle && (
                <Flex direction="row" align="center" margin="s 0">
                  <Text size="s" weight="semi" margin="0">
                    Instagram
                  </Text>
                  <ExternalTextLink
                    size="s"
                    margin="0 0 0 l"
                    href={`https://instagram.com/${instagramHandle}`}
                    target="_blank"
                  >
                    @{instagramHandle}
                  </ExternalTextLink>
                </Flex>
              )}
              {tiktokHandle && (
                <Flex direction="row" align="center" margin="s 0">
                  <Text size="s" weight="semi" margin="0">
                    TikTok
                  </Text>
                  <ExternalTextLink
                    size="s"
                    margin="0 0 0 l"
                    href={`https://tiktok.com/@${tiktokHandle}`}
                    target="_blank"
                  >
                    @{tiktokHandle}
                  </ExternalTextLink>
                </Flex>
              )}
            </View>
          </View>
          {instagramOffers.length > 0 && tiktokOffers.length > 0 ? (
            <>
              <CardDivider margin="0 0 l" />
              <View margin="0 l m">
                <Text size="m" weight="bold" margin="0 0 m 0">
                  Platform
                </Text>
                <Flex align="center" margin="0">
                  {instagramOffers && instagramOffers.length > 0 && (
                    <PlatformButton
                      title="Instagram"
                      active={selectedPlatform === Platform.Instagram}
                      onClick={() => {
                        setSelectedPlatform(Platform.Instagram);
                      }}
                    />
                  )}
                  {tiktokOffers && tiktokOffers.length > 0 && (
                    <PlatformButton
                      title="TikTok"
                      active={selectedPlatform === Platform.Tiktok}
                      onClick={() => {
                        setSelectedPlatform(Platform.Tiktok);
                      }}
                    />
                  )}
                </Flex>
              </View>
            </>
          ) : null}
          <CardDivider margin="xl 0 l" />
          <View margin="0 l l">
            <Text size="m" weight="bold" margin="0 0 s 0">
              Offer
            </Text>
            {activeOffer ? (
              <Text margin="0" weight="semi">
                {activeOffer.offer}{" "}
                {activeOffer.maxGuests
                  ? activeOffer.maxGuests === 1
                    ? "for you and 1 guest"
                    : `for you and ${activeOffer.maxGuests} guests`
                  : ""}
              </Text>
            ) : (
              <Text margin="0" weight="semi">
                No offers available
              </Text>
            )}
          </View>
          <CardDivider margin="l 0 l" />
          <View margin="0 l xl">
            <Text size="m" weight="bold" margin="l 0 m 0">
              Deliverables
            </Text>
            {selectedPlatform === Platform.Instagram && (
              <>
                {stories > 0 && (
                  <Flex justify="space-between" align="center" margin="0 0 l">
                    <Flex align="center" direction="row">
                      <StoryIcon width={26} height={26} colorPreset="text" />
                      <Text margin="0 0 0 m" weight="semi">
                        Instagram Story
                      </Text>
                    </Flex>
                    <Text size="m" margin="0" colorPreset="link" weight="bold">
                      x{stories}
                    </Text>
                  </Flex>
                )}
                {posts > 0 && (
                  <Flex justify="space-between" align="center" margin="0 0 l">
                    <Flex align="center" direction="row">
                      <PostIcon width={26} height={26} colorPreset="text" />
                      <Text margin="0 0 0 m" weight="semi">
                        Instagram Post
                      </Text>
                    </Flex>
                    <Text size="m" margin="0" colorPreset="link" weight="bold">
                      x{posts}
                    </Text>
                  </Flex>
                )}
                {reels > 0 && (
                  <Flex justify="space-between" align="center" margin="0 0 m">
                    <Flex align="center" direction="row">
                      <ReelIcon width={26} height={26} colorPreset="text" />
                      <Text margin="0 0 0 m" weight="semi">
                        Instagram Reel
                      </Text>
                    </Flex>
                    <Text size="m" margin="0" colorPreset="link" weight="bold">
                      x{reels}
                    </Text>
                  </Flex>
                )}
              </>
            )}

            {selectedPlatform === Platform.Tiktok && (
              <>
                {tiktoks > 0 && (
                  <Flex justify="space-between" align="center" margin="0">
                    <Flex align="center" direction="row">
                      <ReelIcon width={26} height={26} colorPreset="text" />
                      <Text margin="0 0 0 m" weight="semi">
                        TikTok Video
                      </Text>
                    </Flex>
                    <Text size="l" margin="0" colorPreset="link" weight="bold">
                      x{tiktoks}
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </View>
        </div>
      </DrawerWrapper>
    </BackgroundWrapper>
  );
};

const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(p) => p.theme.spacing.s};
  margin-top: ${(p) => p.theme.spacing.m};
`;

const DietaryPreferencesWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  height: 24px;
`;

const AgeRestrictionsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.input.hover};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  height: 24px;
`;

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 999;
  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const DrawerWrapper = styled.div`
  position: fixed;
  top: ${(p) => p.theme.spacing.xl};
  right: ${(p) => p.theme.spacing.xl};
  width: 390px;
  bottom: ${(p) => p.theme.spacing.xl};
  z-index: 1000;
  overflow: scroll;
  animation: slideIn 0.2s ease-in-out;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-shadow: ${(p) => p.theme.shadow.card};
  background-color: ${(p) => p.theme.color.card.background};

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: 100%; // Full height on mobile
    top: 0;
    right: 0;
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    width: 420px;
    top: ${(p) => p.theme.spacing.xl};
    right: ${(p) => p.theme.spacing.xl};
    bottom: ${(p) => p.theme.spacing.xl};
  }
`;

const PlatformButton = ({
  title,
  active,
  onClick,
}: {
  title: string;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <PlatformButtonWrap selected={active} onClick={onClick}>
      <Radio width={10} height={10} active={active} />
      <Text size="m" weight="bold" margin="0 0 0 m">
        {title}
      </Text>
    </PlatformButtonWrap>
  );
};

const PlatformButtonWrap = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};
  cursor: pointer;
  margin-right: ${(p) => p.theme.spacing.m};
  width: 100%;
  display: flex;
  align-items: center;

  ${(p) =>
    p.selected
      ? css`
          border-color: ${p.theme.color.primary};
          box-shadow: inset 0 0 0 1px ${p.theme.color.primary};
        `
      : null}
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${(p) => p.theme.spacing.m};
  left: ${(p) => p.theme.spacing.m};
  width: 32px;
  height: 32px;
  display: flex;
  text-decoration: none;
  border-radius: 999px;
  box-shadow: ${(p) => p.theme.shadow.imageFloatingIcon};
  justify-content: center;
  align-items: center;
  background-color: #000000cc;
  padding: 2px;
  z-index: 3;

  &:hover {
    background: #000;
  }
`;

const VideoWrapper = styled.div<{ isPlaying: boolean | null }>`
  position: relative;
  width: 100%;
  height: ${(p) =>
    p.isPlaying ? `calc(100vh - ${p.theme.spacing.xl})` : "260px"};
  transition: height 250ms ease-out;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const PlayButtonWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: all 150ms linear;
  border-radius: ${(p) => p.theme.misc.borderRadius};
`;

const ImageScrollContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.m};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Indicator = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(p) =>
    p.active ? "#FFFFFF" : "rgba(255, 255, 255, 0.5)"};
  margin: 0 ${(p) => p.theme.spacing.xxs};
  transition: background-color 0.2s ease;
  cursor: pointer;
`;
