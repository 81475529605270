import { useGoogleLogin } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "../../components/CTA";
import { Card } from "../../components/Card";
import { CardDivider } from "../../components/Divider";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { Logo } from "../../components/Logo";
import { Page } from "../../components/Page";
import { Text } from "../../components/Text";
import { ExternalTextLink, TextLink } from "../../components/TextLink";
import { View } from "../../components/View";
import { CheckCircle } from "../../components/icons/CheckCircle";
import { FacebookIconCircle } from "../../components/icons/FacebookIconCircle";
import { GoogleIcon } from "../../components/icons/GoogleIcon";
import config, { MOBILE_BREAKPOINT } from "../../config";
import {
  AuthType,
  useAcceptBrandInviteMutation,
  useBrandInviteQuery,
  useLoginMutation,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { useTheme } from "../../hooks/useTheme";
import { authSelectors } from "../../store/auth/selector";
import { actions } from "../../store/auth/slice";
import styled, { css } from "../../styles";

const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: auto auto;
`;

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  margin-top: -50px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing.xxxl};
`;

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useHash() {
  return new URLSearchParams(useLocation().hash.substring(1));
}

export function Invite() {
  const query = useQuery();
  const hash = useHash();
  const client = useGqlClient();
  const token = query.get("invite-token");
  const brandInviteQuery = useBrandInviteQuery(
    client,
    {
      token: token ? token : "",
    },
    {
      enabled: !!token,
    }
  );

  const acceptInvite = useAcceptBrandInviteMutation(client);
  const loginMutation = useLoginMutation(client);

  const dispatch = useDispatch();
  const history = useHistory();

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const { track } = useAnalytics();

  const [error, setError] = React.useState<string | null>(null);

  const stateHash = (+new Date().setHours(0, 0, 0, 0)).toString(36);

  const [loginMode, setLoginMode] = useState<"email" | "facebook" | "google">(
    "email"
  );
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailPasswordErr, setEmailPasswordErr] = useState<string>("");
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (brandInviteQuery.data) {
      setEmail(brandInviteQuery.data?.brandInvite.email);
    }
  }, [brandInviteQuery.data]);

  useEffect(() => {
    const error = query.get("error");
    if (error) {
      setError("Failed to login with Facebook, please try again");

      Sentry.captureException(
        `${query.get("error")} - ${query.get("error_reason")} - ${query.get(
          "error_description"
        )}`
      );
      return;
    }

    const state = hash.get("state");
    if (!state) {
      return;
    }

    const stateObj = JSON.parse(state);
    if (stateObj["hash"] !== stateHash) {
      setError("Failed to login with Facebook, please try again");
      return;
    }

    const accessToken = hash.get("access_token");
    if (!accessToken) {
      return;
    }

    const code = query.get("c");

    loginMutation.mutate(
      {
        fbToken: accessToken,
        code,
        authType: AuthType.AuthTypeBrand,
        brandInviteToken: stateObj.inviteToken,
        isSignup: isLoggedIn ? false : true,
      },
      {
        onSuccess: (data) => {
          dispatch(actions.loginSuccess({ mutation: data, type: "brand" }));

          track(data.authenticate.signup ? "Signup success" : "Login success");

          history.push("/confirm-details");
          if (brandInviteQuery.data) {
            dispatch(
              actions.setActiveBrand({
                brandId: brandInviteQuery.data.brandInvite.brand.id,
              })
            );
          }
        },
        onError: (error) => {
          Sentry.captureException(error);
          setError(
            "Oops, that didn't work. Make sure you've selected a page AND the Instagram business account"
          );
          track("Facebook login failure");
          dispatch(actions.loginFailure());
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash.toString()]);

  const googleLogin = useGoogleLogin({
    onSuccess: (res) => {
      setLoginMode("google");
      loginMutation.mutate(
        {
          googleToken: res.access_token,
          authType: AuthType.AuthTypeBrand,
          brandInviteToken: query.get("invite-token"),
          isSignup: isLoggedIn ? false : true,
        },
        {
          onSuccess: (data) => {
            dispatch(actions.loginSuccess({ mutation: data, type: "brand" }));

            track(
              data.authenticate.signup ? "Signup success" : "Login success"
            );

            history.push("/confirm-details");
            if (brandInviteQuery.data) {
              dispatch(
                actions.setActiveBrand({
                  brandId: brandInviteQuery.data.brandInvite.brand.id,
                })
              );
            }
          },
          onError: (error) => {
            Sentry.captureException(error);
            setError("Failed to login with Google");
            track("Google login failure");
            dispatch(actions.loginFailure());
          },
        }
      );
    },
  });

  if (brandInviteQuery.error || error) {
    track("Invite error", {
      token: token ? token : undefined,
      userHref: window.location.href,
    });
    return (
      <Page>
        <Header>
          <Logo />
        </Header>
        <CenteredContainer>
          <Wrap>
            <Text align="center" colorPreset="secondary">
              <b>Something went wrong</b>
              <br />
              Your invite may have expired
            </Text>
          </Wrap>
        </CenteredContainer>
      </Page>
    );
  }

  if (brandInviteQuery.isLoading || !brandInviteQuery.data) {
    return (
      <Page>
        <Header>
          <Logo />
        </Header>
        <CenteredContainer>
          <Wrap>
            <Loading />
          </Wrap>
        </CenteredContainer>
      </Page>
    );
  }

  return (
    <Page>
      <Header>
        <Logo />
      </Header>
      <CenteredContainer>
        <Wrap>
          <StyledCard margin="0 0 0 0">
            <H1 margin="0">
              Join {`${brandInviteQuery.data.brandInvite.brand.name}`}
            </H1>
            <Text margin="s 0 0 0">
              <b>{`${brandInviteQuery.data.brandInvite.invitedBy.firstName} ${brandInviteQuery.data.brandInvite.invitedBy.lastName}`}</b>{" "}
              has invited you to their Joli account
            </Text>
            {isLoggedIn ? (
              <Button
                onClick={() => {
                  if (acceptInvite.isLoading) {
                    return;
                  }

                  acceptInvite.mutate(
                    {
                      token: token ? token : "",
                    },
                    {
                      onSuccess: (res) => {
                        track("Invite accepted");
                        dispatch(
                          actions.setActiveBrand({
                            brandId: res.acceptBrandInvite.id,
                          })
                        );
                        history.push("/b");
                      },

                      onError: () => {},
                    }
                  );
                }}
                margin="l 0 0 0"
              >
                {acceptInvite.isLoading ? "Loading..." : "Accept Invite"}
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setLoginMode("facebook");
                    dispatch(actions.fbLoginStart());
                    dispatch(
                      actions.setActiveBrand({
                        brandId: brandInviteQuery.data.brandInvite.brand.id,
                      })
                    );
                    track("Login start");

                    const uri = encodeURI(
                      `https://www.facebook.com/v10.0/dialog/oauth?client_id=${config.facebookAppId}&redirect_uri=${window.location.origin}/brand-invites&response_type=token&scope=public_profile&state={"hash": "${stateHash}", "inviteToken": "${token}"}`
                    );

                    window.location.href = uri;
                  }}
                  style={{
                    background: "#3C72EA",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  margin="l 0 l 0"
                >
                  <Flex align="center" justify="center" margin="0 s 0 0">
                    <FacebookIconCircle />
                  </Flex>
                  {acceptInvite.isLoading && loginMode === "facebook"
                    ? "Loading..."
                    : "Sign up with Facebook"}
                </Button>
                <Button
                  margin="0"
                  onClick={() => {
                    setLoginMode("google");
                    googleLogin();
                  }}
                  style={{
                    background: "#fff",
                    border: `1px solid ${theme.color.button.secondaryBorder}`,
                    color: "#445363",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Flex align="center" justify="center" margin="0 s 0 0">
                    <GoogleIcon />
                  </Flex>
                  {acceptInvite.isLoading && loginMode === "google"
                    ? "Loading..."
                    : "Sign up with Google"}
                </Button>
                <Flex justify="center" margin="m 0" align="center">
                  <CardDivider />
                  <Text
                    style={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                    size="s"
                    colorPreset="secondary"
                    margin="0 xxl"
                  >
                    OR
                  </Text>
                  <CardDivider />
                </Flex>

                <div style={{ position: "relative" }}>
                  {brandInviteQuery.data.brandInvite.email && (
                    <AbsoluteWrap>
                      <CheckCircle checked={true} />
                    </AbsoluteWrap>
                  )}
                  <Input
                    margin="0 0 s 0"
                    isDisabled={
                      brandInviteQuery.data.brandInvite.email ? true : false
                    }
                    onChange={(e) => {
                      setEmailPasswordErr("");
                      setEmail(e.currentTarget.value);
                    }}
                    value={email}
                    name="email"
                  />
                </div>
                <Input
                  margin="l 0 0 0"
                  placeholder="Create a password"
                  type="password"
                  onChange={(e) => {
                    setEmailPasswordErr("");
                    setPassword(e.currentTarget.value);
                  }}
                  value={password}
                />
                <Flex justify="space-between" margin="m 0 0">
                  <Text size="s" colorPreset="secondary" margin="0">
                    Must be at least 8 characters
                  </Text>
                  <TextLink size="s" to="/forgot-password" margin="0">
                    Forgot password
                  </TextLink>
                </Flex>
                {emailPasswordErr ? (
                  <Alert padding="s m" margin="0 0 xl">
                    <Flex>
                      <Text margin="0 m 0 0">⚠️</Text>
                      <Text margin="0">{emailPasswordErr}</Text>
                    </Flex>
                  </Alert>
                ) : null}
                <ReCAPTCHA
                  size="invisible"
                  sitekey={config.recaptchaSiteKey}
                  ref={recaptchaRef}
                />
                <Button
                  margin="l 0 0 0"
                  onClick={async () => {
                    setLoginMode("email");
                    if (!email || !password) {
                      setEmailPasswordErr(
                        "Please enter your email and password"
                      );
                      return;
                    }

                    const recaptchaToken =
                      await recaptchaRef.current?.execute();

                    if (!recaptchaToken) {
                      alert("Something went wrong, please try again");
                      track("Recaptcha error");
                      Sentry.captureException("Recaptcha error");
                      return;
                    }

                    loginMutation.mutate(
                      {
                        email,
                        password,
                        authType: AuthType.AuthTypeBrand,
                        brandInviteToken: query.get("invite-token"),
                        isSignup: isLoggedIn ? false : true,
                        recaptchaToken: recaptchaToken,
                      },
                      {
                        onSuccess: (data) => {
                          dispatch(
                            actions.loginSuccess({
                              mutation: data,
                              type: "brand",
                            })
                          );

                          track(
                            data.authenticate.signup
                              ? "Signup success"
                              : "Login success"
                          );

                          history.push("/confirm-details");
                          if (brandInviteQuery.data) {
                            dispatch(
                              actions.setActiveBrand({
                                brandId:
                                  brandInviteQuery.data.brandInvite.brand.id,
                              })
                            );
                          }
                        },
                        onError: (error) => {
                          Sentry.captureException(error);
                          setError("Failed to login with Google");
                          track("Google login failure");
                          dispatch(actions.loginFailure());
                        },
                      }
                    );
                  }}
                >
                  {loginMutation.isLoading && loginMode === "email"
                    ? "Loading..."
                    : "Get started"}
                </Button>
                <Text
                  size="xs"
                  align="center"
                  margin="l 0 0"
                  colorPreset="secondary"
                >
                  By continuing you agree to Joli's{" "}
                  <ExternalTextLink
                    size="xs"
                    href="https://joliapp.com/legal/privacy-policy"
                  >
                    Privacy Policy
                  </ExternalTextLink>{" "}
                  and{" "}
                  <ExternalTextLink
                    size="xs"
                    href="https://docs.google.com/document/d/1Pk7mgoDz2jW73MHmM0wOlrRiyUtJyuvkqZSqXpeZ_0Q/edit?usp=sharing"
                  >
                    Terms of Service
                  </ExternalTextLink>
                </Text>
              </>
            )}
          </StyledCard>
          <Text align="center">
            This invite was intended for{" "}
            <Text
              margin="0"
              isInline
              weight="bold"
            >{`${brandInviteQuery.data.brandInvite.email}`}</Text>
          </Text>
        </Wrap>
      </CenteredContainer>
    </Page>
  );
}

const Alert = styled(View)<{ type?: "positive" | "warning" }>`
  background-color: ${(p) => p.theme.color.warning}30;
  border-left: 3px solid ${(p) => p.theme.color.warning};

  ${(p) =>
    p.type === "positive"
      ? css`
          background-color: ${(p) => p.theme.color.tags.lightGreen};
          border-left: 3px solid ${(p) => p.theme.color.tags.darkGreen}CC;
        `
      : css``}

  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;

  color: ${(p) => p.theme.color.warning};
`;

const AbsoluteWrap = styled.div`
  position: absolute;
  top: 0;
  right: ${(p) => p.theme.spacing.m};
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
`;
