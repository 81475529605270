import * as React from "react";
import styled from "../styles";

function ArrowSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.9em"
      height="0.9em"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill-rule="evenodd"
        d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
      />
    </svg>
  );
}

interface Props {
  direction?: "left" | "down" | "up";
  color?: string;
  size?: "s" | "m";
}

export const Arrow = styled(ArrowSVG)<Props>`
  path {
    fill: ${(p) => (p.color ? p.color : p.theme.color.typography.secondary)};
  }

  ${(p) =>
    p.direction === "down" &&
    `
    transform: rotate(180deg); 
    padding-left: 2px;
  `}

  ${(p) =>
    p.direction === "up" &&
    `
    transform: rotate(0deg);
    padding-right: 2px;
  `}

  ${(p) =>
    p.size === "s" &&
    `
    height: 0.8em;
    width: 0.8em;
  `}
`;
