import { NavLink } from "react-router-dom";
import { Card } from "../components/Card";
import { ExternalCardLink } from "../components/CardLink";
import { Chevron } from "../components/Chevron";
import { Flex } from "../components/Flex";
import { H1 } from "../components/Heading";
import { Logo } from "../components/Logo";
import { LogoCloud } from "../components/LogoCloud";
import { Subtitle } from "../components/Subtitle";
import { Text } from "../components/Text";
import { View } from "../components/View";
import { BusinessIcon } from "../components/icons/BusinessIcon";
import { FollowersIcon } from "../components/icons/FollowersIcon";
import { MOBILE_BREAKPOINT } from "../config";
import WaveEmoji from "../images/wave-emoji.png";
import styled from "../styles";
import { SignupCard } from "./brands/signup/CreateBrand";

const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: ${(p) => p.theme.spacing.l};
  }
`;

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  margin: auto 0;
`;

const PageWrap = styled.div`
  flex: 1;
  max-width: 500px;
`;

const CardContent = styled.div`
  flex: 1;
`;

const CardRight = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrap = styled.div`
  flex: 1;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: ${(p) => p.theme.spacing.xxxl};
  flex: 1;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const HideOnMobile = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

export function Signup() {
  return (
    <Wrap>
      <Flex margin="0 0 0" align="center" direction="column" justify="center">
        <Logo />
      </Flex>
      <Grid>
        <HideOnMobile>
          <LogoCloud faded />
        </HideOnMobile>
        <CenteredContainer>
          <PageWrap>
            <ContentWrap>
              <SignupCard>
                <Flex align="center">
                  <H1 margin="0 s 0 0">Welcome to Joli</H1>{" "}
                  <div>
                    <img alt="wave icon" width={30} src={WaveEmoji} />
                  </div>
                </Flex>
                <Subtitle margin="0 0 l">
                  To get started, please let us know what best describes you
                </Subtitle>
                <NavLink style={{ textDecoration: "none" }} to="/signup/b">
                  <Card hover padding="l l" margin="s 0 0 0">
                    <Flex>
                      <CardContent>
                        <Flex
                          direction="row"
                          justify="flex-start"
                          align="center"
                        >
                          <Flex
                            align="center"
                            justify="center"
                            margin="0 m 0 0"
                          >
                            <BusinessIcon />
                          </Flex>
                          <View>
                            <Text
                              size="l"
                              colorPreset="heading"
                              margin="0"
                              weight="bold"
                              isCompact
                            >
                              I'm a business
                            </Text>
                            <Text size="s" margin="0" colorPreset="secondary">
                              Login or create a free account
                            </Text>
                          </View>
                        </Flex>
                      </CardContent>
                      <CardRight>
                        <Chevron width={16} height={16} />
                      </CardRight>
                    </Flex>
                  </Card>
                </NavLink>
                <ExternalCardLink
                  style={{ textDecoration: "none" }}
                  href="https://joliapp.com/download"
                >
                  <Card hover padding="m" margin="l 0 0 0">
                    <Flex>
                      <CardContent>
                        <Flex
                          direction="row"
                          justify="flex-start"
                          align="center"
                        >
                          <Flex
                            align="center"
                            justify="center"
                            margin="0 m 0 0"
                          >
                            <FollowersIcon />
                          </Flex>
                          <View margin="0 0 0 0">
                            <Text
                              size="l"
                              colorPreset="heading"
                              margin="0"
                              weight="bold"
                              isCompact
                            >
                              I'm a content creator
                            </Text>
                            <Text size="s" margin="0" colorPreset="secondary">
                              Download the mobile app for creators
                            </Text>
                          </View>
                        </Flex>
                      </CardContent>
                      <CardRight>
                        <Chevron width={16} height={16} />
                      </CardRight>
                    </Flex>
                  </Card>
                </ExternalCardLink>
              </SignupCard>
            </ContentWrap>
          </PageWrap>
        </CenteredContainer>
      </Grid>
    </Wrap>
  );
}
