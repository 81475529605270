import { useTheme } from "../../hooks/useTheme";

interface Props {
  width?: number;
}

export const ImpressionsIcon = (props: Props) => {
  const theme = useTheme();

  return (
    <svg
      width={props.width ? props.width : 56}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="46"
        height="46"
        rx="23"
        stroke="url(#paint0_linear_2058_4008)"
        stroke-width="2"
      />
      <path
        d="M14.25 18.5833V16.4167C14.25 15.842 14.4783 15.2909 14.8846 14.8846C15.2909 14.4783 15.842 14.25 16.4167 14.25H18.5833"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.417 14.25H31.5837C32.1583 14.25 32.7094 14.4783 33.1157 14.8846C33.5221 15.2909 33.7503 15.842 33.7503 16.4167V18.5833"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.7503 29.4166V31.5833C33.7503 32.1579 33.5221 32.709 33.1157 33.1154C32.7094 33.5217 32.1583 33.75 31.5837 33.75H29.417"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5833 33.75H16.4167C15.842 33.75 15.2909 33.5217 14.8846 33.1154C14.4783 32.709 14.25 32.1579 14.25 31.5833V29.4166"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0003 25.0833C24.5986 25.0833 25.0837 24.5983 25.0837 24C25.0837 23.4017 24.5986 22.9166 24.0003 22.9166C23.402 22.9166 22.917 23.4017 22.917 24C22.917 24.5983 23.402 25.0833 24.0003 25.0833Z"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.417 24C16.417 24 19.1253 18.5834 24.0003 18.5834C28.8753 18.5834 31.5837 24 31.5837 24C31.5837 24 28.8753 29.4167 24.0003 29.4167C19.1253 29.4167 16.417 24 16.417 24Z"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2058_4008"
          x1="0"
          y1="24.0005"
          x2="47.9991"
          y2="24.0005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
