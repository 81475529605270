import { useCallback, useEffect, useState } from "react";
import {
  GetBookingQuery,
  useGenerateBookingNoteQuery,
} from "../graphql/generated";
import useAnalytics from "../hooks/useAnalytics";
import useGqlClient from "../hooks/useGqlClient";
import styled from "../styles";
import { Flex } from "./Flex";
import { Text } from "./Text";
import { TextArea } from "./TextArea";
import { SparklesIcon } from "./icons/SparklesIcon";

interface Props {
  rows?: number;
  placeholder?: string;
  bookingNoteText: string;
  setBookingNoteText: (e: string) => void;
  booking: GetBookingQuery["booking"];
  approvedTimeslot: string | null;
}

export const AutoSuggestBookingNotes = ({
  rows,
  placeholder,
  bookingNoteText,
  setBookingNoteText,
  booking,
  approvedTimeslot,
}: Props) => {
  const [infoTouchedByHuman, setInfoTouchedByHuman] = useState(false);
  const [showGenerateTextButton, setShowGenerateTextButton] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const client = useGqlClient();
  const { track } = useAnalytics();

  const { data: bookingsNote } = useGenerateBookingNoteQuery(
    client,
    {
      bookingId: booking.id,
      approvedTimeslot,
    },
    {
      enabled: !!approvedTimeslot,
    }
  );

  useEffect(() => {
    if (bookingsNote && bookingsNote.generateBookingNote) {
      setGeneratedText(bookingsNote.generateBookingNote);
    }
  }, [bookingsNote]);

  const startTyping = useCallback(
    (text: string) => {
      if (bookingNoteText.length > 0 || infoTouchedByHuman) return;

      let i = 0;
      let newText = bookingNoteText; // Initialize newText with the current bookingNoteText
      const intervalId = setInterval(() => {
        if (infoTouchedByHuman) {
          clearInterval(intervalId);
          return;
        }
        if (i === text.length) {
          clearInterval(intervalId);
        } else {
          newText += text.charAt(i); // Append the next character to newText
          setBookingNoteText(newText); // Update the state with the accumulated newText
          i++;
        }
      }, 5);

      return () => clearInterval(intervalId);
    },
    [bookingNoteText, infoTouchedByHuman, setBookingNoteText] // Make sure to include setBookingNoteText if it's not stable across re-renders
  );

  useEffect(() => {
    if (!generatedText) return;
    if (infoTouchedByHuman) {
      setShowGenerateTextButton(false);
      return;
    }
    setShowGenerateTextButton(true);
  }, [generatedText, infoTouchedByHuman]);

  return (
    <div style={{ position: "relative" }}>
      <TextArea
        rows={rows ? rows : 3}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setInfoTouchedByHuman(true);
          setBookingNoteText(e.currentTarget.value);
        }}
        defaultValue={bookingNoteText}
      />
      {showGenerateTextButton ? (
        <AbsoluteWrap align="center">
          <AiWrap align="center">
            <SparklesIcon height={18} colorPreset="link" />
            <Text size="s" margin="0 0 0 xs">
              Auto generate message
            </Text>
            <Text
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              size="s"
              weight="semi"
              margin="0 0 0 s"
              onClick={() => {
                startTyping(generatedText);
                setShowGenerateTextButton(false);
                track("Booking note generated");
              }}
            >
              Try it
            </Text>
          </AiWrap>
        </AbsoluteWrap>
      ) : null}
    </div>
  );
};

const AbsoluteWrap = styled(Flex)`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.xl};
  left: 0;
  right: 0;
`;

const AiWrap = styled(Flex)`
  border: 0.5px solid ${(p) => p.theme.color.button.secondaryBorder}90;
  box-shadow: ${(p) => p.theme.shadow.filters};
  border-radius: 999px;
  margin: 0 auto;
  color: ${(p) => p.theme.color.typography["text"]};
  background: ${(p) => p.theme.color.card.background};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.m};
`;
