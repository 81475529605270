import { Link } from "react-router-dom";
import styled, { css } from "../styles";
import withMargin from "../styles/withMargin";
import { Flex } from "./Flex";

export interface Step {
  name: string;
  link: string;
  status: "complete" | "current" | "upcoming";
}

interface Props {
  steps: Step[];
  margin?: string;
}

const StepWrap = styled.div<{ status: string }>`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  background-color: ${(p) => p.theme.color.primary}44;
  height: 11px;
  width: 11px;
  margin: 0 ${(p) => p.theme.spacing.s} 0 ${(p) => p.theme.spacing.s};

  ${(p) =>
    p.status === "current" &&
    css`
      height: 10px;
      width: 10px;
      background-color: ${(p) => p.theme.color.primary};
      border: 1px solid ${(p) => p.theme.color.highlight};
    `}

  ${(p) =>
    p.status === "complete" &&
    css`
      background-color: ${(p) => p.theme.color.primary};
    `}
`;

const Wrap = styled.div`
  ${withMargin}
`;

export function Steps(props: Props) {
  return (
    <Wrap margin={props.margin}>
      <Flex align="center" justify="center">
        {props.steps.map((step) => {
          if (step.status === "complete") {
            return (
              <Link to={step.link}>
                <StepWrap status={step.status} />
              </Link>
            );
          }

          return <StepWrap status={step.status} />;
        })}
      </Flex>
    </Wrap>
  );
}
