import { useEffect, useState } from "react";
import { ListingCard } from "../.";
import { SingleCheckBox } from "../../../../components/Checkbox";
import { Flex } from "../../../../components/Flex";
import { H4 } from "../../../../components/Heading";
import { PostIcon } from "../../../../components/icons/Instagram/PostIcon";
import { ReelIcon } from "../../../../components/icons/Instagram/ReelIcon";
import { StoryIcon } from "../../../../components/icons/Instagram/StoryIcon";
import { InstagramIcon } from "../../../../components/icons/InstagramIcon";
import { TrashIcon } from "../../../../components/icons/TrashIcon";
import NumberCounter from "../../../../components/NumberCounter";
import { Underline } from "../../../../components/Tabs";
import { Text } from "../../../../components/Text";
import { Tooltip } from "../../../../components/Tooltip";
import { View } from "../../../../components/View";
import { ListingOfferInput, Platform } from "../../../../graphql/generated";
import styled from "../../../../styles";
import Offers from "./Offers";
import { Tags } from "./Tags";
interface InstagramOffersProps {
  handles: string[];
  setHandles: React.Dispatch<React.SetStateAction<string[]>>;
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  posts: number;
  setPosts: React.Dispatch<React.SetStateAction<number>>;
  requirePostAndReel: boolean;
  setRequirePostAndReel: React.Dispatch<React.SetStateAction<boolean>>;
  reels: number;
  setReels: React.Dispatch<React.SetStateAction<number>>;
  stories: number;
  setStories: React.Dispatch<React.SetStateAction<number>>;
  instagramOffers: ListingOfferInput[];
  setInstagramOffers: React.Dispatch<React.SetStateAction<ListingOfferInput[]>>;
  instagramOffersRef?: React.RefObject<HTMLDivElement>;
}

export const InstagramOffers = ({
  handles,
  setHandles,
  tags,
  setTags,
  posts,
  setPosts,
  reels,
  setReels,
  stories,
  setStories,
  requirePostAndReel,
  setRequirePostAndReel,
  instagramOffers,
  setInstagramOffers,
  instagramOffersRef,
}: InstagramOffersProps) => {
  const [requirePostAndReelError, setRequirePostAndReelError] = useState(false);

  useEffect(() => {
    if (!requirePostAndReel && (posts === 0 || reels === 0)) {
      setRequirePostAndReelError(true);
    } else {
      setRequirePostAndReelError(false);
    }
  }, [requirePostAndReel, posts, reels]);

  if (instagramOffers.length === 0) {
    return (
      <AddOfferWrap
        margin="0"
        onClick={() =>
          setInstagramOffers([
            {
              platform: Platform.Instagram,
              minFollowers: 1500,
              maxFollowers: Infinity,
              maxGuests: 1,
              offer: "Complimentary meal",
              toggleProductValue: null,
              toggleProductId: null,
            },
          ])
        }
      >
        + Add Instagram Offers
      </AddOfferWrap>
    );
  }

  return (
    <ListingCard>
      <Flex align="flex-start" justify="space-between">
        <View>
          <H4 margin="0 0 m">Instagram Offers</H4>
          <Underline selected={true} />
        </View>
        <TrashWrap onClick={() => setInstagramOffers([])}>
          <TrashIcon width={16} />
        </TrashWrap>
      </Flex>
      <Flex margin="xl 0 0" justify="space-between" align="center">
        <Flex align="center" direction="row">
          <InstagramIcon width={28} height={28} />
          <View margin="0 0 0 m">
            <Text weight="bold" margin="xxs 0 0" size="s" isCompact>
              Instagram
            </Text>
            <Text colorPreset="secondary" margin="0" isCompact size="xs">
              Create offers for Instagram influencers
            </Text>
          </View>
        </Flex>
        <Flex>
          {/* <Text size="s" colorPreset="secondary" weight="semi" margin="0 s 0 0">
            What do I include?
          </Text> */}
          {/* <Tooltip>
            <Text margin="0">Hello</Text>
          </Tooltip> */}
        </Flex>
      </Flex>
      <Offers
        offers={instagramOffers}
        setOffers={setInstagramOffers}
        platform={Platform.Instagram}
        ref={instagramOffersRef}
      />
      <View margin="xxl 0 m">
        <Tags
          tags={tags}
          handles={handles}
          setTags={setTags}
          setHandles={setHandles}
          platform={Platform.Instagram}
        />
      </View>
      <View margin="xl 0 m">
        <Text weight="bold" margin="0 0 0">
          Deliverables
        </Text>
        <Text colorPreset="secondary" size="s" margin="0 0 l" isCompact>
          What content would you like in return
        </Text>
        <Flex align="center" direction="row">
          <Grid>
            <Flex align="center" justify="center">
              <StoryIcon width={28} height={28} colorPreset="text" />
            </Flex>
            <Text weight="semi" margin="0">
              Instagram Story
            </Text>
            <NumberCounter
              value={stories}
              onIncrease={() => setStories(stories + 1)}
              onDecrease={() => setStories(stories - 1)}
            />
            <Flex align="center" justify="center">
              <ReelIcon width={28} height={28} colorPreset="text" />
            </Flex>
            <Text weight="semi" margin="0">
              Instagram Reel
            </Text>
            <NumberCounter
              value={reels}
              onIncrease={() => setReels(reels + 1)}
              onDecrease={() => setReels(reels - 1)}
            />
            <Flex align="center" justify="center">
              <PostIcon width={28} height={28} colorPreset="text" />
            </Flex>
            <Text weight="semi" margin="0">
              Instagram Post
            </Text>
            <NumberCounter
              value={posts}
              onIncrease={() => setPosts(posts + 1)}
              onDecrease={() => setPosts(posts - 1)}
            />
          </Grid>
        </Flex>
        <Flex
          direction="row"
          align="center"
          margin="xxl 0 0 xs"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setRequirePostAndReel(!requirePostAndReel);
          }}
        >
          <SingleCheckBox checked={!requirePostAndReel} />
          <View margin="0 0 0 l" style={{ flex: 1 }}>
            <Flex>
              <Text
                weight="semi"
                margin="0 s 0 0"
                style={{ userSelect: "none" }}
              >
                Allow Instagram Post or Instagram Reel
              </Text>
              <Tooltip>
                <Text size="s" margin="0">
                  This option allows the influencer to choose between creating
                  either a Post or a Reel.
                </Text>
                <Text size="xs" margin="0" colorPreset="secondary">
                  Please make sure you also select at least 1 Reel and 1 Post.
                </Text>
              </Tooltip>
            </Flex>
          </View>
        </Flex>
        {requirePostAndReelError && (
          <Text colorPreset="error" size="s" margin="l 0 0">
            At least 1 Instagram Post and 1 Instagram Reel are required when
            this option is selected
          </Text>
        )}
      </View>
    </ListingCard>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 140px auto;
  gap: ${(p) => p.theme.spacing.m};
  align-items: center;
`;

const TrashWrap = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.typography.text};
      }
    }
  }
`;

const AddOfferWrap = styled(Flex)`
  cursor: pointer;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};
  border: 1px dashed ${(p) => p.theme.color.typography.secondary};
  color: ${(p) => p.theme.color.typography.secondary};
  font-weight: ${(p) => p.theme.typography.weight.semi};

  &:hover {
    border: 1px dashed ${(p) => p.theme.color.typography.text};
    color: ${(p) => p.theme.color.typography.heading};
  }
`;
