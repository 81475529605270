import styled from "../styles";
import { Flex } from "./Flex";
import { Text } from "./Text";

const ProgressBarBg = styled.div`
  background-color: ${(p) => p.theme.color.card.background};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  width: 100px;
  height: 8px;
`;

const ProgressBarFg = styled.div<{ width: number }>`
  background-color: ${(p) => p.theme.color.primary};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  width: ${(p) => p.width}px;
  height: 8px;
`;

export interface Steps {
  totalSteps: number;
  currentStep: number;
}

export function ProgressBar(props: { steps: Steps }) {
  return (
    <Flex align="center">
      <ProgressBarBg>
        <ProgressBarFg
          width={(100 / props.steps.totalSteps) * props.steps.currentStep}
        />
      </ProgressBarBg>
      <Text margin="0 0 0 s">{`${props.steps.currentStep}/${props.steps.totalSteps}`}</Text>
    </Flex>
  );
}
