import React from "react";
import styled from "../styles";
import theme from "../styles/theme";

type colors = typeof theme.color.typography;

interface Props {
  colorPreset?: keyof colors;
  height?: number;
}

const LogoMarkSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 123 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M100.92 87.0599C96.5301 86.9899 93.04 83.4499 93.09 79.0899C93.14 74.7699 96.7301 71.2299 101.01 71.2799C105.42 71.3299 108.94 74.9099 108.88 79.2999C108.82 83.6199 105.23 87.1199 100.92 87.0599Z"
      fill="white"
    />
    <path
      d="M19.0301 69.8399C23.4201 69.8799 26.9401 73.4099 26.9201 77.7599C26.9001 82.1199 23.32 85.6499 18.97 85.6199C14.61 85.5899 11.07 81.9999 11.12 77.6599C11.17 73.3299 14.7401 69.7999 19.0301 69.8399Z"
      fill="white"
    />
    <path
      d="M68.0699 108.55C68.0699 112.95 64.5699 116.46 60.1899 116.46C55.8299 116.46 52.3 112.93 52.3 108.55C52.3 104.21 55.88 100.65 60.23 100.67C64.56 100.69 68.0699 104.22 68.0699 108.55Z"
      fill="white"
    />
    <path
      d="M43.6199 29.25C43.5999 33.58 40.0599 37.1099 35.7499 37.0999C31.3799 37.0899 27.8299 33.53 27.8599 29.18C27.8899 24.85 31.3899 21.3 35.6199 21.31C40.1499 21.32 43.6399 24.78 43.6199 29.24V29.25Z"
      fill="white"
    />
    <path
      d="M93.85 31.09C93.85 35.41 90.32 38.95 86 38.96C81.6 38.97 78.06 35.4199 78.07 31.0099C78.08 26.7099 81.64 23.1899 85.96 23.1799C90.32 23.1799 93.84 26.71 93.84 31.09H93.85Z"
      fill="white"
    />
    <path
      d="M39.62 84.4C28.22 92.08 22.29 102.81 21.78 116.42H37.5601C37.9601 107.82 41.96 101.32 49.34 96.9099C52.51 95.0199 56.0099 94.1099 59.6899 93.9699V78.1699C52.4099 78.3599 45.65 80.33 39.62 84.39V84.4Z"
      fill="white"
    />
    <path
      d="M35.5601 52.2299C25.3901 42.9799 13.57 39.7599 0.22998 42.4899L3.96997 57.8199C12.42 56.1699 19.68 58.51 25.71 64.64C28.3 67.27 30.01 70.4499 31.02 73.9999L46.37 70.26C44.46 63.24 40.9399 57.1299 35.5699 52.2399L35.5601 52.2299Z"
      fill="white"
    />
    <path
      d="M66.23 37.39C71.2 24.58 69.96 12.3799 62.6 0.919922L49.64 9.92993C54.22 17.2199 54.65 24.8299 51.1 32.6699C49.58 36.0299 47.22 38.7799 44.28 40.9899L53.3 53.96C59.17 49.65 63.6 44.17 66.23 37.39Z"
      fill="white"
    />
    <path
      d="M88.72 62.08C102.45 62.76 113.63 57.74 122.19 47.15L109.56 37.6899C104.09 44.3399 96.9899 47.1399 88.4399 46.2399C84.7699 45.8599 81.42 44.48 78.4 42.39L68.9301 55.03C74.8701 59.24 81.47 61.72 88.73 62.08H88.72Z"
      fill="white"
    />
    <path
      d="M72.03 91.7599C75.95 104.94 84.3999 113.81 97.2299 118.37L101.96 103.31C93.8699 100.36 88.8799 94.5899 86.8799 86.2299C86.0199 82.6399 86.2 79.0299 87.18 75.4799L72.1099 70.7499C70.1099 77.7499 69.96 84.7999 72.03 91.7599Z"
      fill="white"
    />
    <path
      d="M85.72 39.1799C81.14 39.1799 77.54 36.1999 77.54 31.5099C77.54 25.0999 84.41 19.9 91.21 19.9C96.13 19.9 98.42 22.6999 98.42 26.4799C98.42 32.7699 92.3 39.1799 85.72 39.1799Z"
      fill="white"
    />
    <path
      d="M43.61 29.0699C43.84 33.6399 41.04 37.3899 36.36 37.6199C29.96 37.9399 24.42 31.3399 24.08 24.5399C23.84 19.6199 26.5201 17.1999 30.3001 17.0099C36.5901 16.6999 43.29 22.4999 43.61 29.0699Z"
      fill="white"
    />
    <path
      d="M23.16 71.07C27.09 73.42 28.6499 77.8299 26.2299 81.8499C22.9299 87.3499 14.37 88.28 8.53 84.78C4.31 82.25 3.78995 78.67 5.72995 75.43C8.96995 70.03 17.51 67.69 23.16 71.07Z"
      fill="white"
    />
    <path
      d="M56.63 101.44C60.7 99.3399 65.2701 100.33 67.4201 104.5C70.3601 110.2 66.64 117.97 60.59 121.09C56.22 123.35 52.9001 121.9 51.1701 118.55C48.2901 112.96 50.79 104.45 56.64 101.44H56.63Z"
      fill="white"
    />
    <path
      d="M92.6 81.38C91.41 76.96 93.35 72.7099 97.88 71.4899C104.07 69.8299 110.88 75.1099 112.64 81.6799C113.92 86.4299 111.8 89.3699 108.15 90.3499C102.07 91.9799 94.2899 87.73 92.5899 81.38H92.6Z"
      fill="white"
    />
  </svg>
);

export const LogoMark = styled(LogoMarkSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.theme.color.typography.heading};
  }
`;
