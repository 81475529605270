import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { Card } from "../../../components/Card";
import { Flex } from "../../../components/Flex";
import Loader from "../../../components/Loader";
import { Logo } from "../../../components/Logo";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { useTheme } from "../../../hooks/useTheme";
import styled from "../../../styles";

export function ManageBooking() {
  const [isDesktop, setIsDesktop] = useState(false);
  let { bookingId } = useParams<{ bookingId: string }>();
  const theme = useTheme();

  useEffect(() => {
    if (!bookingId) {
      return;
    }

    var ua = navigator.userAgent.toLowerCase();

    var android = /(android)/g.test(ua);
    var iOS = /(ipad|iphone|ipod)/g.test(ua);

    if (android || iOS) {
      window.location.href = `com.nibble.app://bookingDetails/${bookingId}`;
    } else {
      setIsDesktop(true);
    }
  }, [bookingId]);

  return (
    <Wrap>
      <View margin="m 0 0 0">
        <Logo />
      </View>
      {!isDesktop ? (
        <Loader />
      ) : (
        <Content>
          <Card
            padding="xl xxxl xxl"
            style={{ borderRadius: theme.misc.borderRadiusLarge }}
          >
            <Flex align="center" direction="column" margin="0 0 m">
              <Text size="xxxl" weight="bold" colorPreset="heading" margin="0">
                Open the Joli app
              </Text>
              <Text align="center" margin="0 0 xxl">
                Scan the QR code on your phone to manage your booking
              </Text>
              <QRCode
                bgColor={theme.color.card.background}
                fgColor={theme.color.typography.heading}
                size={128}
                value={`com.nibble.app://bookingDetails/${bookingId}`}
              />
            </Flex>
          </Card>
        </Content>
      )}
    </Wrap>
  );
}

const Wrap = styled.div`
  flex: 1;
  display: flex;
  max-width: 100%;
  align-self: center;
  flex-direction: column;
  align-items: center;
  color: ${(p) => p.theme.color.typography.text};
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: -120px ${(p) => p.theme.spacing.m} 0;
`;
