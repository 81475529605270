import React from "react";
import styled from "styled-components";
import { Text } from "./Text";

interface ProgressCircleProps {
  currentStep: number;
  totalSteps: number;
}

const CircleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
`;

const Circle = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

const BgCircle = styled.circle`
  fill: none;
  stroke: ${(p) => p.theme.color.button.secondaryBorder};
  stroke-width: 6;
`;

const FgCircle = styled.circle`
  fill: none;
  stroke: ${(p) => p.theme.color.primary};
  stroke-width: 6;
  stroke-dasharray: 126; /* This is 2 * π * radius, where radius is 20 */
  stroke-linecap: round;
`;

const ProgressTextWrap = styled.div`
  position: absolute;
`;

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  currentStep,
  totalSteps,
}) => {
  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const progress = (currentStep / totalSteps) * 100;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <CircleContainer>
      <Circle viewBox="0 0 48 48">
        <BgCircle cx="24" cy="24" r="20" />
        <FgCircle
          cx="24"
          cy="24"
          r="20"
          strokeDashoffset={offset}
          strokeDasharray={circumference}
        />
      </Circle>
      <ProgressTextWrap>
        <Text margin="0" size="s" colorPreset="link" weight="semi">
          {currentStep}/{totalSteps}
        </Text>
      </ProgressTextWrap>
    </CircleContainer>
  );
};

export default ProgressCircle;
