import { MOBILE_BREAKPOINT } from "../config";
import { GetBookingQuery } from "../graphql/generated";
import useAnalytics from "../hooks/useAnalytics";
import { useWindowSize } from "../hooks/useWindowSize";
import styled from "../styles";
import Avatar from "./Avatar";
import { Flex } from "./Flex";
import { H3 } from "./Heading";
import { Text } from "./Text";
import { ExternalTextLink } from "./TextLink";

const LeftAlign = styled.div`
  text-align: left;
`;

function formatNumber(num: number) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return num.toString();
}

const TruncatedH3 = styled(H3)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreferredProfile = (props: {
  preferredProfile: GetBookingQuery["booking"]["creator"]["preferredProfile"];
  avatarSize?: number;
}) => {
  const window = useWindowSize();
  const { track } = useAnalytics();

  const isMobile = window && window.width && window.width <= MOBILE_BREAKPOINT;

  if (!props.preferredProfile) {
    return null;
  }

  return (
    <Flex margin="0 0 m 0" align="center">
      <Avatar
        margin="0 l 0 0"
        borderSize={0}
        size={props.avatarSize ? props.avatarSize : 58}
        withShadow={false}
        url={props.preferredProfile.avatar}
      />
      <LeftAlign>
        <TruncatedH3 margin="0">{props.preferredProfile.name}</TruncatedH3>
        <Flex direction="row" align="center">
          {props.preferredProfile.username && (
            <ExternalTextLink
              target="_blank"
              href={`${props.preferredProfile.permalink}`}
              rel="noreferrer"
              onClick={() =>
                track("Social media profile clicked", {
                  type: props.preferredProfile?.type,
                })
              }
            >
              @
              {isMobile && props.preferredProfile.username.length > 18
                ? props.preferredProfile.username.slice(0, 18) + "..."
                : props.preferredProfile.username}
            </ExternalTextLink>
          )}
          <Text
            size="s"
            colorPreset="secondary"
            margin="0 0 0 xs"
            style={{ marginTop: 1 }}
          >
            ({formatNumber(props.preferredProfile.followers)})
          </Text>
        </Flex>
      </LeftAlign>
    </Flex>
  );
};
