import { Flex } from "./Flex";
import { ToggleButton } from "./ToggleButton";

interface Props {
  margin?: string;
  padding?: string;
  onChange: (days: number[]) => void;
  initialValue?: number[];
}

const options = [
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
  { label: "Sun", value: 0 },
];

export function DayPicker(props: Props) {
  return (
    <Flex wrap="wrap" margin="s 0 s">
      {options.map((option, index) => {
        const selectedDays = props.initialValue || [];
        return (
          <ToggleButton
            size="s"
            margin="0 m m 0"
            title={option.label}
            onClick={() => {
              const newDays = selectedDays.includes(option.value)
                ? selectedDays.filter((day) => day !== option.value)
                : [...selectedDays, option.value];
              props.onChange(newDays);
            }}
            active={selectedDays.includes(option.value)}
            key={index}
          />
        );
      })}
    </Flex>
  );
}
