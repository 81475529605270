import React, { useRef, useEffect } from "react";
import { CanvasRenderer } from "echarts/renderers";
import { init, getInstanceByDom, use } from "echarts/core";
import { LineChart } from "echarts/charts";
import {
  LegendComponent,
  GridComponent,
  TooltipComponent,
} from "echarts/components";
import type { ComposeOption, SetOptionOpts } from "echarts/core";
import type { LineSeriesOption } from "echarts/charts";
import type { GridComponentOption } from "echarts/components";
import { CSSProperties } from "styled-components";

// Register the required components
use([
  TooltipComponent,
  LegendComponent,
  LineChart,
  GridComponent,
  CanvasRenderer, // If you only need to use the canvas rendering mode, the bundle will not include the SVGRenderer module, which is not needed.
]);

// Combine an Option type with only required components and charts via ComposeOption
export type EChartsOption = ComposeOption<
  LineSeriesOption | GridComponentOption
>;

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
}

export const ReactECharts = ({
  option,
  style,
  settings,
  theme,
}: ReactEChartsProps): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    if (chartRef.current !== null) {
      init(chartRef.current, theme);
    }
  }, [theme]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option, settings);
    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  return (
    <div>
      <div
        ref={chartRef}
        style={{ width: "100%", height: "220px", ...style }}
      />
    </div>
  );
};
