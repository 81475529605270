import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { Card } from "../../components/Card";
import { Button } from "../../components/CTA";
import { CardDivider } from "../../components/Divider";
import { Dropdown } from "../../components/Dropdown";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { TrashIcon } from "../../components/icons/TrashIcon";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { Subtitle } from "../../components/Subtitle";
import { Text } from "../../components/Text";
import { UpgradePlan } from "../../components/UpgradePlan";
import { View } from "../../components/View";
import { MOBILE_BREAKPOINT } from "../../config";
import {
  AccountType,
  BillingPlanType,
  useCreateBrandInviteMutation,
  useDeleteBrandAccountMutation,
  useDeleteBrandInviteMutation,
  useGetBrandQuery,
  useUpdateAcccountMutation,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 600px;
  text-align: left;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

export interface MatchParams {
  page: string;
}

export const BrandTeam = () => {
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const brand = useSelector(authSelectors.activeBrand);
  const client = useGqlClient();
  const personalAccount = useSelector(authSelectors.account);
  const { track } = useAnalytics();
  const [showUpgradeOverlay, setShowUpgradeOverlay] = useState(false);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);

  const [email, setEmail] = useState("");
  const createBrandInvite = useCreateBrandInviteMutation(client);
  const deleteBrandInvite = useDeleteBrandInviteMutation(client, {});

  const brandQuery = useGetBrandQuery(client, {
    id: activeBrandId ? activeBrandId : "",
  });

  useEffect(() => {
    if (!brandQuery.data || !brandQuery.data.brand) {
      return;
    }

    const data = brandQuery.data;

    const currentPlan = data.billingPlans.find(
      (bp) => bp.id === data.brand!.billingPlanId
    );

    const shouldShowUpgradePrompt =
      currentPlan &&
      currentPlan.planType === BillingPlanType.BillingPlanTypeStarter
        ? true
        : false;

    setShowUpgradePrompt(shouldShowUpgradePrompt);
  }, [brandQuery.data]);

  if (brandQuery.isLoading || !brandQuery.data || !personalAccount) {
    return <Loading />;
  }

  if (brandQuery.error || !brandQuery.data.brand) {
    return (
      <Wrap>
        <H1 margin="xl 0">Settings</H1>
        <Text>Something went wrong</Text>
      </Wrap>
    );
  }

  if (showUpgradePrompt) {
    return (
      <>
        <Flex style={{ marginTop: "96px" }} direction="column" align="center">
          <Text
            isCompact
            weight="semi"
            margin="0"
            onClick={() => setShowUpgradeOverlay(true)}
            style={{ cursor: "pointer" }}
          >
            Upgrade plan to access team management
          </Text>
        </Flex>
        {showUpgradeOverlay && (
          <UpgradePlan onCancel={() => setShowUpgradeOverlay(false)} />
        )}
      </>
    );
  }

  return (
    <Wrap>
      <H1 margin="0 0 0">Team</H1>
      <Subtitle margin="xs 0 l 0">Manage who has access to your app</Subtitle>

      <StyledCard margin="0 0 xl 0">
        <Flex align="center" justify="space-between">
          <View style={{ flex: 1 }}>
            <Input
              inputSize="s"
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
              placeholder="Enter their email address"
            />
          </View>
          <View margin="0 0 0 m">
            <Button
              size="s"
              onClick={() => {
                if (createBrandInvite.isLoading || !brand) {
                  return;
                }

                createBrandInvite.mutate(
                  {
                    email,
                    brandId: brand.id,
                  },
                  {
                    onSuccess: () => {
                      toast.success("Team member invited");
                      track("Team member invited");
                      setEmail("");
                      brandQuery.refetch();
                    },
                    onError: (error) => {
                      Sentry.captureException(error);
                    },
                  }
                );
              }}
              isDisabled={email.trim().length > 0 ? false : true}
            >
              Invite team member
            </Button>
          </View>
        </Flex>
        <CardDivider margin="l 0 l 0" />
        {brandQuery.data.brand.accounts
          .sort((a, b) => {
            if (a.id === personalAccount.id) {
              return -1;
            }

            return 0;
          })
          .map((account) => {
            return (
              <AccountItem
                accountId={account.id}
                accountEmail={account.email}
                accountName={`${account.firstName} ${account.lastName}`}
                onRefresh={() => brandQuery.refetch()}
                accountType={account.type}
              />
            );
          })}
        {brandQuery.data.brand.invites.map((invite) => {
          return (
            <Row
              margin="0 0 0 0"
              justify="space-between"
              align="center"
              style={{ overflow: "hidden" }}
            >
              <div>
                <Text weight="bold" margin="0">
                  {invite.email}
                </Text>
              </div>
              <Flex align="center" style={{ position: "relative" }}>
                <PendingText
                  className="pending-text"
                  size="s"
                  margin="0"
                  colorPreset="secondary"
                >
                  Invite pending
                </PendingText>
                <TrashWrap
                  className="trash-icon"
                  onClick={() =>
                    deleteBrandInvite.mutate(
                      {
                        brandInviteId: invite.id,
                      },
                      {
                        onError: (e) => {
                          Sentry.captureException(e);
                        },
                        onSuccess: (res) => {
                          toast.success("Invite deleted");
                          track("Delete invite", {
                            id: res.deleteBrandInvite?.id
                              ? res.deleteBrandInvite.id
                              : undefined,
                            brand: brand ? brand.name : undefined,
                          });
                          brandQuery.refetch();
                        },
                      }
                    )
                  }
                >
                  <TrashIcon height={16} width={16} colorPreset="secondary" />
                </TrashWrap>
              </Flex>
            </Row>
          );
        })}
      </StyledCard>
    </Wrap>
  );
};

const Row = styled(Flex)`
  justify-content: space-between;
  position: relative;
  padding: ${(p) => p.theme.spacing.s} 0;

  &:hover {
    .trash-icon {
      transform: translateX(0);
      opacity: 1;
    }
    .pending-text {
      transform: translateX(-32px); // Adjust this value as needed
    }
  }
`;

interface AccountItemProps {
  accountId: string;
  accountName: string;
  accountEmail: string;
  accountType: AccountType;
  onRefresh: () => void;
}

function AccountItem(props: AccountItemProps) {
  const personalAccount = useSelector(authSelectors.account);

  const client = useGqlClient();
  const updateAccount = useUpdateAcccountMutation(client);
  const deleteBrandAccount = useDeleteBrandAccountMutation(client);
  const { track } = useAnalytics();

  return (
    <Row margin="0 0 0 0" justify="space-between" align="center">
      <div>
        <Text weight="bold" margin="0">
          {`${props.accountName}`}{" "}
          {props.accountType === AccountType.AccountTypeAdmin ? (
            <Text
              weight="semi"
              colorPreset="secondary"
              size="s"
              margin="0"
              isCompact
              isInline
            >
              {" "}
              Admin
            </Text>
          ) : null}
        </Text>
        <Text margin="0" isCompact colorPreset="secondary">
          {props.accountEmail}
        </Text>
      </div>
      {personalAccount && props.accountId !== personalAccount.id ? (
        <Dropdown
          dropdownType="actions"
          selectionMode="single"
          renderLabel={() => {
            return "Actions";
          }}
          selectedOptions={[]}
          setSelectedOptions={(selectedOptions) => {
            const selectedValue = selectedOptions[0];

            if (selectedValue === "admin") {
              updateAccount.mutate(
                {
                  id: props.accountId,
                  type: AccountType.AccountTypeAdmin,
                },
                {
                  onSuccess: () => {
                    toast.success("Team member updated");
                    props.onRefresh();
                  },
                }
              );
              return;
            }

            if (selectedValue === "remove") {
              deleteBrandAccount.mutate(
                {
                  accountId: props.accountId,
                },
                {
                  onSuccess: () => {
                    toast.success("Team member removed");
                    track("Team member removed", {
                      accountId: props.accountId,
                      accountEmail: props.accountEmail,
                    });
                    props.onRefresh();
                  },
                }
              );
            }
          }}
          options={[
            {
              label: "Make admin",
              value: "admin",
            },
            {
              label: "Remove",
              value: "remove",
            },
          ]}
        />
      ) : null}
    </Row>
  );
}

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.l}
    ${(p) => p.theme.spacing.l};
  overflow: visible;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }
`;

const TrashWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${(p) => p.theme.spacing.m};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateX(100%);
  opacity: 0;
  position: absolute;
  right: 0;
  top: 2px;
  width: 32px;
  transform: translateX(100%);
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      filter: brightness(0.25);
      cursor: pointer;
    }
  }
`;

const PendingText = styled(Text)`
  transition: transform 0.3s ease-in-out;
`;
