import { useDispatch, useSelector } from "react-redux";
import useAnalytics from "../../hooks/useAnalytics";
import { authSelectors } from "../../store/auth/selector";
import { actions } from "../../store/auth/slice";
import styled from "../../styles";

export const SignupBrandSwitcher = (props: {
  setShowAccountSwitcher: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const account = useSelector(authSelectors.account);
  const dispatch = useDispatch();
  const { track } = useAnalytics();

  return (
    <>
      {account && account.brands && account.brands.length > 0 && (
        <Switcher>
          {account.brands.map((b, i) => (
            <Row
              key={i}
              onClick={() => {
                dispatch(actions.setActiveBrand({ brandId: b.id }));
                track("Switch brand", { brand: b.name });
                props.setShowAccountSwitcher(false);
              }}
            >
              <Title>{b.name}</Title>
              {b.instagram && <Username>@{b.instagram.username}</Username>}
            </Row>
          ))}
        </Switcher>
      )}
    </>
  );
};

const Switcher = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const Row = styled.div`
  padding: ${(p) => p.theme.spacing.m} 0;
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background: ${(p) => p.theme.color.card.divider};
  }

  cursor: pointer;
`;

const Title = styled.p`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.heading};
  font-size: ${(p) => p.theme.typography.size.m};
  line-height: 1.3em;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  margin: 0;
`;

const Username = styled.p`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.secondary};
  font-size: ${(p) => p.theme.typography.size.s};
  line-height: 1.3em;
  margin: 0;
`;
