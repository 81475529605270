import { ReactECharts, ReactEChartsProps } from "./Chart";
import React from "react";

import {
  isSameDay,
  differenceInCalendarDays,
  format,
  isYesterday,
} from "date-fns";
import { useTheme } from "../hooks/useTheme";

export interface ChartData {
  label: string;
  lineColor: "primary" | "secondary";
  data: number[][];
}

interface Props {
  data: ChartData[];
  name?: string;
}

const WrappedCharts = (props: Props) => {
  const theme = useTheme();

  if (
    props.data.length === 0 ||
    props.data[0].data.length === 0 ||
    !props.data[0].data[0][0]
  ) {
    return null;
  }

  const firstDateUnix = props.data[0].data[0][0];
  const firstDate = new Date(firstDateUnix);

  const lastDateUnix = props.data[0].data[props.data[0].data.length - 1][0];
  const lastDate = new Date(lastDateUnix);

  const option: ReactEChartsProps["option"] = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      padding: 2,
      show: true,
      bottom: 8,
      icon: "circle",
      textStyle: {
        color: theme.color.typography.heading,
      },
    },
    grid: {
      left: "0%",
      right: "3%",
      top: "10%",
      bottom: "15%",
      containLabel: true,
    },
    xAxis: {
      type: "time",
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        formatter: function kFormatter(value: any, index: any) {
          if (index === 1) {
            if (differenceInCalendarDays(new Date(), firstDate) <= 30) {
              return `${differenceInCalendarDays(
                new Date(),
                firstDate
              )} days ago`;
            }

            return format(value, "d MMM");
          }

          if (value === lastDateUnix) {
            if (isSameDay(lastDate, new Date())) {
              return "Today";
            }

            if (isYesterday(lastDate)) {
              return "Yesterday";
            }

            return format(lastDate, "d MMM");
          }

          return "";
        },
        fontSize: 12,
        showMaxLabel: true,
        show: true,
        color: theme.color.typography.secondary,
        fontWeight: "bold",
        padding: 4,
      },
    },
    yAxis: {
      type: "value",
      splitNumber: 3,
      axisLabel: {
        formatter: function kFormatter(value: any, index: any) {
          return value > 999
            ? value % 1000 === 0
              ? (value / 1000).toFixed(0) + "k"
              : (value / 1000).toFixed(1) + "k"
            : value;
        },
        fontSize: 14,
        fontWeight: "bold",
        color: "#788AA5",
      },
      splitLine: {
        lineStyle: {
          color: theme.color.card.divider,
          width: 1,
          type: "dashed",
        },
      },
    },
    series: props.data.map((data) => {
      return {
        data: data.data,
        name: data.label,
        color: data.lineColor === "primary" ? "#5F7AE9" : "#5f7ae980",
        type: "line",
        smooth: true,
        symbol: "none",
        animationDuration: 0,
      };
    }),
  };
  return <ReactECharts option={option} />;
};

export const Charts = React.memo(WrappedCharts);
