import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const SettingsSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.18747 1.94C7.27747 1.398 7.74747 1 8.29747 1H10.8905C11.4405 1 11.9105 1.398 12.0005 1.94L12.2135 3.221C12.2765 3.595 12.5265 3.907 12.8585 4.091C12.9325 4.131 13.0055 4.174 13.0785 4.218C13.4035 4.414 13.7985 4.475 14.1535 4.342L15.3705 3.886C15.6199 3.79221 15.8945 3.78998 16.1453 3.87971C16.3962 3.96945 16.6071 4.14531 16.7405 4.376L18.0365 6.623C18.1695 6.8537 18.2164 7.12413 18.1688 7.38617C18.1212 7.6482 17.9822 7.88485 17.7765 8.054L16.7735 8.881C16.4805 9.122 16.3355 9.494 16.3435 9.873C16.3449 9.95799 16.3449 10.043 16.3435 10.128C16.3355 10.506 16.4805 10.878 16.7735 11.119L17.7775 11.946C18.2015 12.296 18.3115 12.901 18.0375 13.376L16.7395 15.623C16.6063 15.8536 16.3957 16.0296 16.145 16.1195C15.8943 16.2094 15.6199 16.2074 15.3705 16.114L14.1535 15.658C13.7985 15.525 13.4035 15.586 13.0775 15.782C13.005 15.8261 12.9316 15.8688 12.8575 15.91C12.5265 16.093 12.2765 16.405 12.2135 16.779L12.0005 18.06C11.9105 18.603 11.4405 19 10.8905 19H8.29647C7.74647 19 7.27747 18.602 7.18647 18.06L6.97347 16.779C6.91147 16.405 6.66147 16.093 6.32947 15.909C6.25532 15.8681 6.18197 15.8258 6.10947 15.782C5.78447 15.586 5.38947 15.525 5.03347 15.658L3.81647 16.114C3.5672 16.2075 3.29283 16.2096 3.04217 16.1199C2.7915 16.0302 2.58078 15.8545 2.44747 15.624L1.15047 13.377C1.01741 13.1463 0.970515 12.8759 1.01812 12.6138C1.06573 12.3518 1.20476 12.1152 1.41047 11.946L2.41447 11.119C2.70647 10.879 2.85147 10.506 2.84447 10.128C2.8429 10.043 2.8429 9.95799 2.84447 9.873C2.85147 9.493 2.70647 9.122 2.41447 8.881L1.41047 8.054C1.20501 7.88489 1.06614 7.64843 1.01854 7.38662C0.970938 7.12481 1.01768 6.8546 1.15047 6.624L2.44747 4.377C2.58065 4.14614 2.79148 3.97006 3.04238 3.88014C3.29328 3.79021 3.56796 3.79229 3.81747 3.886L5.03347 4.342C5.38947 4.475 5.78447 4.414 6.10947 4.218C6.18147 4.174 6.25547 4.132 6.32947 4.09C6.66147 3.907 6.91147 3.595 6.97347 3.221L7.18747 1.94Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5935 10C12.5935 10.7956 12.2774 11.5587 11.7148 12.1213C11.1522 12.6839 10.3892 13 9.59351 13C8.79786 13 8.03479 12.6839 7.47219 12.1213C6.90958 11.5587 6.59351 10.7956 6.59351 10C6.59351 9.20435 6.90958 8.44129 7.47219 7.87868C8.03479 7.31607 8.79786 7 9.59351 7C10.3892 7 11.1522 7.31607 11.7148 7.87868C12.2774 8.44129 12.5935 9.20435 12.5935 10Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const SettingsIcon = styled(SettingsSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
