import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Callout } from "../../../../components/Callout";
import { Chevron } from "../../../../components/Chevron";
import { Dropdown } from "../../../../components/Dropdown";
import { Flex } from "../../../../components/Flex";
import Loading from "../../../../components/Loading";
import { Text } from "../../../../components/Text";
import { View } from "../../../../components/View";
import {
  DmnBookingTypesQuery,
  useBrandLocationsQuery,
  useDmnBookingTypesQuery,
} from "../../../../graphql/generated";
import useGqlClient from "../../../../hooks/useGqlClient";
import { useTheme } from "../../../../hooks/useTheme";
import { authSelectors } from "../../../../store/auth/selector";
import styled from "../../../../styles";

type LocationInfo = {
  id: string;
  name: string;
  validBookingTypes: { id: string; name: string }[];
  invalidBookingTypes: { id: string; name: string }[];
};

export const AccessCollinsPicker = (props: {
  locations: string[];
  dmnBookingTypeId: string;
  setDmnBookingTypeId: (dmnBookingTypeId: string) => void;
  numberOfVisitors: number;
}) => {
  const client = useGqlClient();

  const activeBrandId = useSelector(authSelectors.activeBrandId);

  const { data: locationsData } = useBrandLocationsQuery(client, {
    brandId: activeBrandId ?? "",
  });
  const [validDmnBookingTypes, setValidDmnBookingTypes] = useState<
    DmnBookingTypesQuery["dmnBookingTypes"] | undefined
  >(undefined);
  const [invalidDmnBookingTypes, setInvalidDmnBookingTypes] = useState<
    DmnBookingTypesQuery["dmnBookingTypes"] | undefined
  >(undefined);
  const [showInvalidDmnBookingTypes, setShowInvalidDmnBookingTypes] =
    useState(false);
  const theme = useTheme();

  const {
    data: dmnBookingTypes,
    isLoading: dmnBookingTypesLoading,
    isError: dmnBookingTypesError,
  } = useDmnBookingTypesQuery(client, {
    locationIDs: props.locations,
    numberOfPeople: props.numberOfVisitors,
  });

  useEffect(() => {
    if (!dmnBookingTypes) {
      return;
    }

    const validBookingTypes = dmnBookingTypes.dmnBookingTypes.filter(
      (bt) => bt.missingLocations.length === 0
    );
    const inValidBookingTypes = dmnBookingTypes.dmnBookingTypes.filter(
      (bt) => bt.missingLocations.length > 0
    );

    setValidDmnBookingTypes(validBookingTypes);
    setInvalidDmnBookingTypes(inValidBookingTypes);
  }, [dmnBookingTypes]);

  if (dmnBookingTypesLoading) {
    return <Loading />;
  }

  if (dmnBookingTypesError) {
    return <Text colorPreset="error">Something went wrong</Text>;
  }

  const locationsWithBookingTypes: LocationInfo[] = [];

  // Iterate through each location
  props.locations.forEach((locationId) => {
    console.log(`Processing location ID: ${locationId}`);

    locationsWithBookingTypes.push({
      id: locationId,
      name:
        locationsData?.brand?.locations.locations.find(
          (l) => l.id === locationId
        )?.name || "",
      validBookingTypes: [],
      invalidBookingTypes: [],
    });

    const selectedBookingType = props.dmnBookingTypeId
      ? [
          dmnBookingTypes?.dmnBookingTypes.find(
            (bt) => bt.id === props.dmnBookingTypeId
          ),
        ]
      : [];

    console.log("Selected Booking Types:", selectedBookingType);

    selectedBookingType.forEach((bt) => {
      // if the locationId is not in the bt.missingLocations, then add it to the validLocations
      if (!bt?.missingLocations.some((ml) => ml.id === locationId)) {
        const location = locationsWithBookingTypes.find(
          (l) => l.id === locationId
        );
        if (location) {
          location.validBookingTypes.push({
            id: bt?.id || "",
            name: bt?.name || "",
          });
        }
      } else {
        const location = locationsWithBookingTypes.find(
          (l) => l.id === locationId
        );
        if (location) {
          location.invalidBookingTypes.push({
            id: bt?.id || "",
            name: bt?.name || "",
          });
        }
      }
    });
  });

  return (
    <div style={{ position: "relative", userSelect: "none" }}>
      <Text weight="bold" margin="xxl 0 s">
        Access Collins Booking Type
      </Text>
      <Dropdown
        width={420}
        selectionMode="single"
        renderLabel={() => {
          if (props.dmnBookingTypeId === "") {
            return "Select booking types";
          }

          return (
            dmnBookingTypes?.dmnBookingTypes.find(
              (bt) => bt.id === props.dmnBookingTypeId
            )?.name || ""
          );
        }}
        selectedOptions={[props.dmnBookingTypeId]}
        setSelectedOptions={(selectedOptions) => {
          props.setDmnBookingTypeId(selectedOptions[0]);
        }}
        disableOptionSort
        options={
          validDmnBookingTypes
            ?.sort((a, b) => b.validLocations.length - a.validLocations.length)
            .map((b) => ({
              label: b.name,
              value: b.id,
              annotation:
                b.validLocations.length > 0 ? (
                  <ValidLocationWrapper>
                    <Text size="xxs" isInline margin="0" weight="semi">
                      {b.validLocations.length === 1
                        ? `${b.validLocations.length} location`
                        : `${b.validLocations.length} locations`}
                    </Text>
                  </ValidLocationWrapper>
                ) : null,
            })) ?? []
        }
      />
      {validDmnBookingTypes && validDmnBookingTypes.length === 0 ? (
        <View margin="s xs 0">
          <Text margin="0" size="xs" colorPreset="error">
            No booking types available for the selected locations and the number
            of guests ({props.numberOfVisitors} people)
          </Text>
        </View>
      ) : null}
      {locationsWithBookingTypes.filter(
        (l) =>
          l.validBookingTypes.length === 0 && l.invalidBookingTypes.length > 0
      ).length > 0 ? (
        <View margin="s xs 0">
          <Text margin="0" size="xs" colorPreset="error">
            All locations must have at least one booking type
          </Text>
        </View>
      ) : null}
      {invalidDmnBookingTypes && invalidDmnBookingTypes.length > 0 ? (
        <View margin="l 0 0">
          <Callout
            type="info"
            align="flex-start"
            header="Booking types must be available for all selected locations"
            children={
              <>
                <OptionsToggleWrap
                  onClick={() =>
                    setShowInvalidDmnBookingTypes(!showInvalidDmnBookingTypes)
                  }
                >
                  <Text margin="0" size="s">
                    {showInvalidDmnBookingTypes
                      ? "Hide invalid booking types"
                      : "Show invalid booking types"}
                  </Text>
                  <Chevron
                    size="s"
                    color={theme.color.secondary}
                    direction="down"
                  />
                </OptionsToggleWrap>
                {showInvalidDmnBookingTypes &&
                  invalidDmnBookingTypes.map((bt) => {
                    return (
                      <Flex margin="l 0 s" key={bt.id} direction="column">
                        <Text
                          key={bt.id}
                          size="s"
                          weight="bold"
                          margin="0 0 0 0"
                          isCompact
                        >
                          {bt.name}
                        </Text>
                        <Text size="xs" margin="0 0 0" colorPreset="secondary">
                          Unavailable at:{" "}
                          {bt.missingLocations.map((l, index) => {
                            return (
                              <span key={l.id}>
                                {l.name}
                                {index < bt.missingLocations.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          })}
                        </Text>
                      </Flex>
                    );
                  })}
              </>
            }
          />
        </View>
      ) : null}
    </div>
  );
};

const ValidLocationWrapper = styled.div`
  background-color: ${(p) => p.theme.color.card.callout};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
`;

const OptionsToggleWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color = ${(p) => p.theme.color.secondary};
`;
