import { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import config from "../../../config";

export function TikTokRedirect() {
  const [hasRedirected, setHasRedirected] = useState(false);

  useEffect(() => {
    if (!hasRedirected) {
      window.location.href = `${
        config.apiEndpoint
      }/oauth/tiktok?redirect_uri=${encodeURIComponent(
        `${window.location.origin}/app-connect-tiktok/connect/`
      )}&scope=user.info.basic,user.info.profile,user.info.stats,video.list`;

      setHasRedirected(true);
    }
  }, [hasRedirected]);

  return <Loading />;
}
