import { MOBILE_BREAKPOINT } from "../config";
import styled from "../styles";
import withMargin from "../styles/withMargin";

export const H1 = styled.h1`
  color: ${(p) => p.theme.color.typography.heading};
  font-size: 30px;
  line-height: 1em;
  ${withMargin}

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 28px;
  }
`;

export const H2 = styled.h2`
  color: ${(p) => p.theme.color.typography.heading};
  font-size: 24px;
  line-height: 1em;
  ${withMargin}
`;

export const H3 = styled.h3`
  color: ${(p) => p.theme.color.typography.heading};
  line-height: 1.3em;
  ${withMargin}
`;

export const H4 = styled.h4`
  color: ${(p) => p.theme.color.typography.heading};
  font-size: ${(p) => p.theme.typography.size.l};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  line-height: 1em;

  ${withMargin}
`;
