import React, { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { H1 } from "../components/Heading";
import { Text } from "../components/Text";
import { TextLink } from "../components/TextLink";
import { MOBILE_BREAKPOINT } from "../config";
import { useActivateAccountMutation } from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { useTheme } from "../hooks/useTheme";
import styled, { css } from "../styles";

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
    margin: 96px ${(p) => p.theme.spacing.l};
    padding-bottom: 120px;
  }
`;

const PageWrap = styled.div`
  max-width: 340px;
`;

const PincodeDigit = styled.div<{ isFocused: boolean }>`
  width: 4ch;
  color: ${(p) => p.theme.color.typography.text};
  padding: ${(p) => p.theme.spacing.xs};
  border-radius: 9px;
  text-align: center;
  margin-right: 12px;
  border: 2px solid ${(p) => p.theme.color.typography.secondary};
  background-color: ${(p) => p.theme.color.card.background};
  text-transform: uppercase;
  outline: none;

  ${(p) =>
    p.isFocused &&
    css`
      border: 2px solid ${(p) => p.theme.color.typography.success};
    `}
`;

const PincodeInput = styled.input`
  opacity: 0;
  width: 1;
  height: 0;
  margin: 0;
  line-height: 0;
`;

const PincodeWrap = styled.div`
  display: flex;
`;

let pincodeTimeout: NodeJS.Timeout | null = null;

export const PendingApproval = () => {
  const theme = useTheme();
  const history = useHistory();

  const { show: showIntercom } = useIntercom();

  const [accessCode, setAccessCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [inputFocused, setInputFocused] = useState(true);

  const pincodeInputRef = useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const activateAccount = useActivateAccountMutation(client, {});

  return (
    <CenteredContainer>
      <PageWrap>
        <H1 margin="0 0 xl 0">Welcome onboard</H1>
        <Text>We can't wait to show you how Joli works.</Text>
        <Text>
          We're rolling out more invites each day and we'll drop you a message
          as soon as your account is ready 😎
        </Text>
        <br />
        <Text margin="0 0 s 0">Got an invite code? Enter it below</Text>

        <PincodeWrap
          onClick={() => {
            if (pincodeInputRef.current) {
              pincodeInputRef.current.focus();
              pincodeInputRef.current.scrollIntoView();
            }
          }}
        >
          {[1, 2, 3, 4].map((x) => {
            return (
              <PincodeDigit
                key={x}
                isFocused={
                  x === accessCode.length + 1 ||
                  (x === 1 && accessCode.length === 0 && inputFocused)
                }
              >
                <Text
                  style={{ opacity: accessCode.length >= x ? 1 : 0 }}
                  margin="0"
                  size="xxxl"
                >
                  {accessCode[x - 1] ? accessCode[x - 1] : "x"}
                </Text>
              </PincodeDigit>
            );
          })}
        </PincodeWrap>
        {errorMsg && (
          <Text colorPreset="warning" margin="m 0 0 0">
            {errorMsg}
          </Text>
        )}
        <PincodeInput
          type="number"
          pattern="[0-9]*"
          max={9999}
          value={accessCode}
          onFocus={() => {
            setInputFocused(true);
          }}
          onBlur={() => {
            setInputFocused(false);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setErrorMsg("");
            if (pincodeTimeout) {
              clearTimeout(pincodeTimeout);
            }

            setAccessCode(e.currentTarget.value);

            if (e.currentTarget.value.length >= 4) {
              const value = e.currentTarget.value;
              pincodeTimeout = setTimeout(() => {
                activateAccount.mutate(
                  { accessCode: value },
                  {
                    onSuccess: (res) => {
                      history.go(0);

                      queryClient.resetQueries(["Accounts"], {
                        exact: false,
                      });
                    },
                    onError: (err) => {
                      setAccessCode("");
                      setErrorMsg("Invalid code");
                    },
                  }
                );
              }, 1000);
            }
          }}
          ref={pincodeInputRef}
        />

        <Text
          size="s"
          margin="xxxl 0 0 0"
          color={theme.color.typography.secondary}
        >
          {/* <TextLink
            to="#"
            onClick={() => {
              dispatch(actions.logout());
              hardShutdown();
              track("Logout");
            }}
          >
            Logout
          </TextLink> */}
          <TextLink
            to="#"
            onClick={() => {
              showIntercom();
            }}
          >
            Chat to us
          </TextLink>
        </Text>
      </PageWrap>
    </CenteredContainer>
  );
};
