import React from "react";
import styled from "../../styles";
import { Logo } from "../../components/Logo";
import { Card } from "../../components/Card";
import { H1 } from "../../components/Heading";
import { Text } from "../../components/Text";
import { Input } from "../../components/Input";
import { Button } from "../../components/CTA";
import useGqlClient from "../../hooks/useGqlClient";
import { useResetPasswordMutation } from "../../graphql/generated";
import { View } from "../../components/View";
import { Flex } from "../../components/Flex";
import { useParams } from "react-router-dom";
import { MOBILE_BREAKPOINT } from "../../config";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
  align-items: center;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
`;

const Alert = styled(View)`
  background-color: ${(p) => p.theme.color.warning}30;
  border-left: 3px solid ${(p) => p.theme.color.warning};
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;

  color: ${(p) => p.theme.color.warning};
`;

export function ResetPassword() {
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");

  const { token } = useParams<{ token: string }>();

  const client = useGqlClient();
  const resetPassword = useResetPasswordMutation(client);

  return (
    <Wrap>
      <Logo />
      <ContentWrap>
        <StyledCard>
          <H1 margin="0 0 s 0">Reset password</H1>

          {resetPassword.isSuccess ? (
            <>
              <Text margin="0">Your password has been successfully reset</Text>
              <Button
                margin="l 0 0 0"
                onClick={() => {
                  window.location.href = "/b/login";
                }}
              >
                Log in
              </Button>
            </>
          ) : (
            <>
              <Text margin="0">
                Your new password must be at least 8 characters
              </Text>

              {resetPassword.error ? (
                <Alert padding="s m" margin="m 0 m 0">
                  <Flex>
                    <Text margin="0 m 0 0">⚠️</Text>
                    <Text margin="0">
                      Something went wrong - this link may have expired
                    </Text>
                  </Flex>
                </Alert>
              ) : null}
              <Input
                margin="l 0 l 0"
                placeholder="Enter your new password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                }}
              />
              <Input
                margin="l 0 l 0"
                type="password"
                placeholder="Confirm your new password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.currentTarget.value);
                }}
              />

              <Button
                margin="0 0 0 0"
                onClick={() => {
                  if (resetPassword.isLoading) {
                    return;
                  }

                  if (password !== confirmPassword) {
                    alert("Your passwords do not match");
                    return;
                  }

                  resetPassword.mutate({
                    password,
                    token,
                  });
                }}
              >
                {resetPassword.isLoading ? "Loading..." : "Reset password"}
              </Button>
            </>
          )}
        </StyledCard>
      </ContentWrap>
    </Wrap>
  );
}

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }
`;
