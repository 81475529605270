import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const DownloadIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 13.5002C0 13.2729 0.0903058 13.0549 0.251051 12.8941C0.411797 12.7334 0.629814 12.6431 0.857143 12.6431H11.1429C11.3702 12.6431 11.5882 12.7334 11.7489 12.8941C11.9097 13.0549 12 13.2729 12 13.5002C12 13.7275 11.9097 13.9456 11.7489 14.1063C11.5882 14.267 11.3702 14.3574 11.1429 14.3574H0.857143C0.629814 14.3574 0.411797 14.267 0.251051 14.1063C0.0903058 13.9456 0 13.7275 0 13.5002ZM2.82257 6.89421C2.98331 6.73352 3.20129 6.64325 3.42857 6.64325C3.65585 6.64325 3.87383 6.73352 4.03457 6.89421L5.14286 8.00249V1.50021C5.14286 1.27288 5.23316 1.05486 5.39391 0.894118C5.55465 0.733372 5.77267 0.643066 6 0.643066C6.22733 0.643066 6.44535 0.733372 6.60609 0.894118C6.76684 1.05486 6.85714 1.27288 6.85714 1.50021V8.00249L7.96543 6.89421C8.0445 6.81234 8.13908 6.74704 8.24365 6.70212C8.34823 6.6572 8.4607 6.63355 8.57451 6.63257C8.68832 6.63158 8.80119 6.65326 8.90653 6.69636C9.01187 6.73946 9.10757 6.8031 9.18805 6.88358C9.26853 6.96406 9.33218 7.05977 9.37528 7.1651C9.41837 7.27044 9.44006 7.38331 9.43907 7.49712C9.43808 7.61093 9.41444 7.72341 9.36952 7.82798C9.32459 7.93256 9.25929 8.02714 9.17743 8.10621L6.606 10.6776C6.44526 10.8383 6.22728 10.9286 6 10.9286C5.77272 10.9286 5.55474 10.8383 5.394 10.6776L2.82257 8.10621C2.66188 7.94547 2.57161 7.72749 2.57161 7.50021C2.57161 7.27292 2.66188 7.05495 2.82257 6.89421Z"
      fill="#9570ED"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const DownloadIcon = styled(DownloadIconSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
