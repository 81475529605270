import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import withPadding, { WithPaddingProp } from "../styles/withPadding";

export const View = styled.div<
  { isBlurry?: boolean; flex?: number } & WithMarginProp & WithPaddingProp
>`
  ${(p) =>
    p.isBlurry
      ? css`
          filter: blur(${p.theme.misc.blur});
          -webkit-filter: blur(${p.theme.misc.blur});
        `
      : ``};

  ${(p) =>
    p.flex
      ? css`
          flex: ${p.flex};
        `
      : ``};

  ${withMargin}
  ${withPadding}
`;
