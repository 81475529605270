import {
  addDays,
  addMonths,
  addQuarters,
  differenceInDays,
  endOfMonth,
  endOfQuarter,
  format,
  fromUnixTime,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isWeekend,
  max,
  min,
  startOfMonth,
  startOfQuarter,
  subDays,
  subMonths,
  subQuarters,
} from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import { AddIcon } from "../../../components/AddIcon";
import { Chevron } from "../../../components/Chevron";
import { Flex } from "../../../components/Flex";
import { BellIcon } from "../../../components/icons/BellIcon";
import { InfoIcon } from "../../../components/icons/InfoIcon";
import MultipleSegmentedControl from "../../../components/MultipleSegmentedControl";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BrandListingsQuery,
  ListingKeyDatesQuery,
  ListingStatus,
  useListingKeyDatesQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import styled from "../../../styles";
import {
  getListingEndDate,
  getListingStartDate,
} from "../../../utils/listings";
import { StatusTag } from "./ListView";

const CALENDAR_HEADER_OFFSET = 8;
const LANE_HEIGHT = 72;
const EVENT_LANE_HEIGHT = 44;
const CHARS_PER_DAY = 10;
const MIN_CARD_WIDTH_DAYS = 1;

enum DisplayPeriod {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
}

type ExtendedListing = BrandListingsQuery["listings"]["listings"][0] & {
  adjustedStartDate: Date;
  displayStartDate: Date;
  adjustedEndDate: Date;
  displayEndDate: Date;
};

type ExtendedKeyEvent = ListingKeyDatesQuery["listingKeyDates"][0] & {
  adjustedStartDate: Date;
  displayStartDate: Date;
  adjustedEndDate: Date;
  displayEndDate: Date;
};

const ListingEvent = React.memo(
  ({
    listing,
    laneIndex,
    days,
  }: {
    listing: ExtendedListing;
    laneIndex: number;

    days: { date: Date; day: string; weekDay: string }[];
  }) => {
    const eventStart = listing.displayStartDate;
    const eventEnd = listing.displayEndDate;

    const startIndex = days.findIndex((day) => isSameDay(day.date, eventStart));
    const endIndex = days.findIndex((day) => isAfter(day.date, eventEnd));

    const left = `${(startIndex / days.length) * 100}%`;
    const width = `${
      (((endIndex === -1 ? days.length : endIndex) - startIndex) /
        days.length) *
      100
    }%`;

    const startsBeforeRange = isBefore(
      listing.adjustedStartDate,
      listing.displayStartDate
    );
    const endsAfterRange = isAfter(
      listing.adjustedEndDate,
      listing.displayEndDate
    );

    // Only render if the event overlaps with the current month view
    if (endIndex !== 0 || startIndex !== -1) {
      return (
        <EventContainer
          key={listing.id}
          left={left}
          width={width}
          top={`${laneIndex * LANE_HEIGHT + CALENDAR_HEADER_OFFSET}px`}
        >
          <div style={{ position: "relative" }}>
            <StartsBeforeRangeOverlay startsBeforeRange={startsBeforeRange} />
            <EndsAfterRangeOverlay endsAfterRange={endsAfterRange} />
            <StyledLink to={`/b/listings/${listing.id}`}>
              <View>
                <ListingCard
                  startsBeforeRange={startsBeforeRange}
                  endsAfterRange={endsAfterRange}
                >
                  <Flex
                    direction="row"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <div style={{ whiteSpace: "nowrap" }}>
                      <Text
                        weight="bold"
                        size="s"
                        margin="0"
                        colorPreset="secondaryHeading"
                      >
                        {listing.name}
                      </Text>
                      <Text
                        margin="0 0 0"
                        size="xs"
                        colorPreset="secondary"
                        weight="semi"
                      >
                        {listing.startDate
                          ? `${format(
                              fromUnixTime(listing.startDate),
                              "dd/MM/yyyy"
                            )} - `
                          : listing.publishDate
                          ? `${format(
                              fromUnixTime(listing.publishDate),
                              "dd/MM/yyyy"
                            )} - `
                          : "Until "}
                        {listing.endDate
                          ? format(fromUnixTime(listing.endDate), "dd/MM/yyyy")
                          : "Ongoing"}
                      </Text>
                    </div>
                    <Flex align="center" justify="center" margin="0 0 0 xxl">
                      <StatusTag status={listing.status} />
                    </Flex>
                  </Flex>
                </ListingCard>
              </View>
            </StyledLink>
          </div>
        </EventContainer>
      );
    }

    return null;
  }
);

const KeyEventComponent = React.memo(
  ({
    event,
    laneIndex,
    days,
    topOffset,
  }: {
    event: ExtendedKeyEvent;
    laneIndex: number;
    days: { date: Date; day: string; weekDay: string }[];
    topOffset: number;
  }) => {
    const theme = useTheme();
    const eventStart = event.displayStartDate;
    const eventEnd = event.displayEndDate;
    const cardRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const [textPosition, setTextPosition] = useState<"inside" | "right">(
      "inside"
    );

    const startIndex = days.findIndex((day) => isSameDay(day.date, eventStart));
    const endIndex = days.findIndex((day) => isAfter(day.date, eventEnd));

    const left = `${(startIndex / days.length) * 100}%`;
    const width = `${
      (((endIndex === -1 ? days.length : endIndex) - startIndex) /
        days.length) *
      100
    }%`;

    const startsBeforeRange = isBefore(
      event.displayStartDate,
      event.adjustedStartDate
    );
    const endsAfterRange = isAfter(event.displayEndDate, event.adjustedEndDate);

    useEffect(() => {
      if (cardRef.current && textRef.current) {
        const cardWidth = cardRef.current.offsetWidth;
        const textWidth = textRef.current.offsetWidth;
        setTextPosition(textWidth > cardWidth ? "right" : "inside");
      }
    }, [event.name]);

    return (
      <EventContainer
        left={left}
        width={width}
        top={`${topOffset + laneIndex * EVENT_LANE_HEIGHT}px`}
      >
        <KeyEventCard
          ref={cardRef}
          priority={event.priority}
          startsBeforeRange={startsBeforeRange}
          endsAfterRange={endsAfterRange}
        >
          <Flex>
            <KeyEventText
              ref={textRef}
              weight="semi"
              size="xxs"
              margin="0"
              colorPreset="secondaryHeading"
              style={{
                left: textPosition === "right" ? "100%" : "0",
                transform:
                  textPosition === "right"
                    ? "translateY(-50%)"
                    : "translate(0, -50%)",
                paddingLeft: theme.spacing.s,
              }}
            >
              {event.priority && <BellIcon />}
              {event.name}
            </KeyEventText>
          </Flex>
        </KeyEventCard>
      </EventContainer>
    );
  }
);

const KeyEventCard = styled.div<{
  priority: boolean;
  startsBeforeRange: boolean;
  endsAfterRange: boolean;
}>`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  margin: 0
    ${(p) => (p.startsBeforeRange || p.endsAfterRange ? 0 : p.theme.spacing.xs)};
  border-radius: 6px;
  background: ${(p) =>
    p.priority ? p.theme.color.primary : p.theme.color.typography.secondary}15;
  box-sizing: border-box;
  white-space: nowrap;
  box-shadow: ${(p) => p.theme.shadow.card};
  overflow: visible;
  border-left: 3px solid
    ${(p) =>
      p.priority ? p.theme.color.primary : p.theme.color.typography.secondary};
  position: relative;
  min-width: ${100 / CHARS_PER_DAY}%;
  min-height: 26px;
  border-top-right-radius: ${(p) => (p.endsAfterRange ? 0 : 6)};
  border-bottom-right-radius: ${(p) => (p.endsAfterRange ? 0 : 6)};

  ${(p) =>
    p.startsBeforeRange &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(
          to right,
          transparent,
          ${p.theme.color.typography.secondary}15
        );
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    `}

  ${(p) =>
    p.endsAfterRange &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(
          to left,
          transparent,
          ${p.theme.color.typography.secondary}15
        );
      }
    `}
`;

const KeyEventText = styled(Text)`
  position: absolute;
  top: 50%;
  white-space: nowrap;
  overflow: visible;
`;

export const CalendarView = ({
  listings,
}: {
  listings: BrandListingsQuery["listings"]["listings"];
}) => {
  const currentDate = useMemo(() => new Date(), []);
  const [displayedDate, setDisplayedDate] = useState(currentDate);
  const [displayedPeriod, setDisplayedPeriod] = useState(DisplayPeriod.Monthly);
  const containerRef = useRef<HTMLDivElement>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const client = useGqlClient();
  const { data: keyDatesData } = useListingKeyDatesQuery(client);

  const handlePrevPeriod = useCallback(() => {
    setDisplayedDate((prevDate) =>
      displayedPeriod === DisplayPeriod.Monthly
        ? subMonths(prevDate, 1)
        : subQuarters(prevDate, 1)
    );
  }, [displayedPeriod]);

  const handleNextPeriod = useCallback(() => {
    if (displayedPeriod === DisplayPeriod.Monthly) {
      setDisplayedDate((prevDate) => addMonths(prevDate, 1));
    } else {
      setDisplayedDate((prevDate) => addQuarters(prevDate, 1));
    }
  }, [displayedPeriod]);

  const updateWidth = useCallback(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, [updateWidth]);

  const { firstDay, lastDay } = useMemo(() => {
    const first =
      displayedPeriod === DisplayPeriod.Monthly
        ? startOfMonth(displayedDate)
        : startOfQuarter(displayedDate);
    const last =
      displayedPeriod === DisplayPeriod.Monthly
        ? endOfMonth(displayedDate)
        : endOfQuarter(displayedDate);
    return { firstDay: first, lastDay: last };
  }, [displayedDate, displayedPeriod]);

  const adjustedListingEvents = useMemo(() => {
    return listings
      .map(
        (event): ExtendedListing => ({
          ...event,
          adjustedStartDate: getListingStartDate(event),
          displayStartDate: max([getListingStartDate(event), firstDay]),
          adjustedEndDate: getListingEndDate(event),
          displayEndDate: min([getListingEndDate(event), lastDay]),
        })
      )
      .filter((event) => isAfter(event.displayEndDate, event.displayStartDate))
      .sort((a, b) => {
        if (
          a.status === ListingStatus.StatusActive &&
          b.status !== ListingStatus.StatusActive
        )
          return -1;
        if (
          a.status !== ListingStatus.StatusActive &&
          b.status === ListingStatus.StatusActive
        )
          return 1;
        if (
          a.status === ListingStatus.StatusExpired &&
          b.status !== ListingStatus.StatusExpired
        )
          return -1;
        if (
          a.status !== ListingStatus.StatusExpired &&
          b.status === ListingStatus.StatusExpired
        )
          return 1;
        return a.displayStartDate.getTime() - b.displayStartDate.getTime();
      });
  }, [listings, firstDay, lastDay]);

  const adjustedKeyEvents = useMemo(() => {
    if (!keyDatesData) return [];

    const keyEvents: ListingKeyDatesQuery["listingKeyDates"] =
      keyDatesData.listingKeyDates;

    return keyEvents
      .map((event) => ({
        ...event,
        adjustedStartDate: fromUnixTime(event.startDate),
        displayStartDate: max([fromUnixTime(event.startDate), firstDay]),
        adjustedEndDate: fromUnixTime(event.endDate),
        displayEndDate: min([fromUnixTime(event.endDate), lastDay]),
      }))
      .filter((event) => event.displayEndDate >= event.displayStartDate)
      .sort(
        (a, b) => a.displayStartDate.getTime() - b.displayStartDate.getTime()
      );
  }, [firstDay, lastDay, keyDatesData]);

  const listingLanes = useMemo(
    () => assignLanes(adjustedListingEvents),
    [adjustedListingEvents]
  );
  const keyEventLanes = useMemo(
    () => assignLanes(adjustedKeyEvents, true),
    [adjustedKeyEvents]
  );

  const keyEventsTopOffset = useMemo(() => {
    const listingLanesCount = listingLanes.length > 0 ? listingLanes.length : 1;
    return listingLanesCount * LANE_HEIGHT + CALENDAR_HEADER_OFFSET + 100; // 200px gap between key events and listing events
  }, [listingLanes]);

  const noEventRanges = useMemo(
    () => getNoEventRanges(adjustedListingEvents, firstDay, lastDay),
    [adjustedListingEvents, firstDay, lastDay]
  );

  const days = useMemo(() => {
    const start = firstDay;
    const end = lastDay;
    const daysArray = [];
    let currentDate = start;

    while (currentDate <= end) {
      daysArray.push({
        date: currentDate,
        day: format(currentDate, "d"),
        weekDay: format(currentDate, "EEE"),
        isWeekend: isWeekend(currentDate),
        isToday: isToday(currentDate),
        dateShortText: format(currentDate, "d MMM"),
        monthName: format(currentDate, "MMMM"),
      });
      currentDate = addDays(currentDate, 1);
    }

    return daysArray;
  }, [firstDay, lastDay]);

  return (
    <CalendarContainer>
      <CalendarHeader>
        <Flex align="center" justify="space-between" margin="0 0 m">
          <Flex align="center" justify="center">
            <MonthNavWrap>
              <ChevronWrap
                onClick={handlePrevPeriod}
                style={{ marginBottom: -3, marginRight: -6 }}
              >
                <Chevron width={9} direction="left" />
              </ChevronWrap>
              <Flex style={{ width: 180 }} justify="center">
                <Text size="l" weight="bold" margin="0 l">
                  {displayedPeriod === DisplayPeriod.Monthly
                    ? format(displayedDate, "MMMM")
                    : `Q${format(displayedDate, "Q")}`}{" "}
                  <span style={{ fontWeight: 400 }}>
                    {format(displayedDate, "yyyy")}
                  </span>
                </Text>
              </Flex>
              <ChevronWrap onClick={handleNextPeriod}>
                <Chevron width={9} />
              </ChevronWrap>
            </MonthNavWrap>
            <DesktopOnly>
              {displayedDate.getMonth() !== currentDate.getMonth() ? (
                <TodayButton onClick={() => setDisplayedDate(currentDate)}>
                  Today
                </TodayButton>
              ) : null}
            </DesktopOnly>
          </Flex>
          <Flex>
            <MultipleSegmentedControl
              options={Object.values(DisplayPeriod)}
              onChange={(selected: string) => {
                setDisplayedPeriod(selected as DisplayPeriod);
              }}
            />
          </Flex>
        </Flex>
        <DayHeaderContainer>
          {days.map(
            (
              { day, weekDay, isWeekend, isToday, dateShortText, monthName },
              index
            ) => (
              <DayHeaderColumn
                key={dateShortText}
                isWeekend={isWeekend}
                dateText={dateShortText}
                isToday={isToday}
                isHovered={hoverIndex === index}
                style={{
                  flex: 1,
                  width: containerWidth / days.length,
                }}
              >
                <div
                  style={{
                    opacity:
                      displayedPeriod === DisplayPeriod.Monthly ||
                      (displayedPeriod === DisplayPeriod.Quarterly &&
                        (weekDay === "Mon" || Number(day) === 1))
                        ? 1
                        : 0,
                  }}
                >
                  <Text
                    margin={"0"}
                    size={
                      displayedPeriod === DisplayPeriod.Monthly ? "xs" : "s"
                    }
                    weight={
                      displayedPeriod === DisplayPeriod.Monthly && !isToday
                        ? "normal"
                        : "semi"
                    }
                    align="center"
                    colorPreset={isToday ? "link" : "text"}
                    style={{
                      opacity:
                        displayedPeriod === DisplayPeriod.Monthly ||
                        (displayedPeriod === DisplayPeriod.Quarterly &&
                          Number(day) === 1)
                          ? 1
                          : 0,
                    }}
                  >
                    {Number(day) === 1 &&
                    displayedPeriod === DisplayPeriod.Quarterly
                      ? monthName
                      : weekDay[0]}
                  </Text>
                  <Text
                    margin="0"
                    size="xs"
                    weight="bold"
                    align="center"
                    style={{
                      opacity:
                        displayedPeriod === DisplayPeriod.Quarterly &&
                        weekDay !== "Mon"
                          ? 0
                          : 1,
                    }}
                    colorPreset={
                      isToday
                        ? "link"
                        : displayedPeriod === DisplayPeriod.Monthly
                        ? "secondaryHeading"
                        : "secondary"
                    }
                  >
                    {day}
                  </Text>
                </div>
              </DayHeaderColumn>
            )
          )}
        </DayHeaderContainer>
      </CalendarHeader>
      <CalendarBody
        ref={containerRef}
        style={{
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflowX: "hidden",
        }}
      >
        <CalendarContent
          columnCount={days.length}
          minHeight={keyEventsTopOffset + keyEventLanes.length * LANE_HEIGHT}
        >
          {days.map(({ dateShortText, isWeekend, isToday }, index) => (
            <ContainerColumn
              key={dateShortText}
              isWeekend={isWeekend}
              isToday={isToday}
              showDayIndicator={displayedPeriod !== DisplayPeriod.Monthly}
              top={`${listingLanes.length * LANE_HEIGHT}px`}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <StyledLink to={`/b/create-listing`}>
                <div className="add-button-wrap">
                  <AddEventButtonContainer>
                    <AddIcon color="#fff" />
                  </AddEventButtonContainer>
                </div>
              </StyledLink>
            </ContainerColumn>
          ))}
          {listingLanes.map((lane, laneIndex) =>
            lane.map((event) => (
              <ListingEvent
                key={event.id}
                listing={event}
                laneIndex={laneIndex}
                days={days}
              />
            ))
          )}
          {keyEventLanes.map((lane, laneIndex) =>
            lane.map((event) => (
              <KeyEventComponent
                key={event.name}
                event={event}
                laneIndex={laneIndex}
                days={days}
                topOffset={keyEventsTopOffset}
              />
            ))
          )}
          {noEventRanges.map((range, index) => (
            <NoEventsIndicator
              key={index}
              startDate={range.start}
              endDate={range.end}
              firstDay={firstDay}
              lastDay={lastDay}
            />
          ))}
        </CalendarContent>
      </CalendarBody>
    </CalendarContainer>
  );
};

const assignLanes = <
  T extends {
    displayStartDate: Date;
    displayEndDate: Date;
    name?: string;
  }
>(
  events: T[],
  isKeyEvent: boolean = false
) => {
  const lanes: T[][] = [];
  const laneEndTimes: number[] = [];
  const laneEndTimesWithText: number[] = [];

  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    const eventStart = event.displayStartDate.getTime();
    let eventEnd = event.displayEndDate.getTime();

    // For key events, calculate the end time based on text length
    if (isKeyEvent && event.name) {
      const textLength = event.name.length;
      // Calculate extra days needed for text, but with a smaller ratio
      const extraDaysForText = Math.ceil(textLength / CHARS_PER_DAY);
      // Use a minimum of 1 day and a maximum of 3 days for text overflow
      const daysNeeded = Math.min(
        Math.max(extraDaysForText, MIN_CARD_WIDTH_DAYS),
        3
      );
      const adjustedEnd = addDays(event.displayStartDate, daysNeeded - 1);
      eventEnd = Math.max(eventEnd, adjustedEnd.getTime());
    }

    let laneFound = false;
    for (let j = 0; j < laneEndTimes.length; j++) {
      if (isKeyEvent) {
        if (eventStart > laneEndTimesWithText[j]) {
          lanes[j].push(event);
          laneEndTimes[j] = eventEnd;
          // Add a maximum of 2 extra days for text overflow
          const textOverflowDays = Math.min(
            Math.ceil((event.name?.length || 0) / CHARS_PER_DAY),
            2
          );
          const textOverflowEnd = addDays(
            new Date(eventEnd),
            textOverflowDays
          ).getTime();
          laneEndTimesWithText[j] = textOverflowEnd;
          laneFound = true;
          break;
        }
      } else {
        if (eventStart > laneEndTimes[j]) {
          lanes[j].push(event);
          laneEndTimes[j] = eventEnd;
          laneEndTimesWithText[j] = eventEnd;
          laneFound = true;
          break;
        }
      }
    }

    if (!laneFound) {
      lanes.push([event]);
      laneEndTimes.push(eventEnd);
      if (isKeyEvent) {
        // Add a maximum of 2 extra days for text overflow
        const textOverflowDays = Math.min(
          Math.ceil((event.name?.length || 0) / CHARS_PER_DAY),
          2
        );
        const textOverflowEnd = addDays(
          new Date(eventEnd),
          textOverflowDays
        ).getTime();
        laneEndTimesWithText.push(textOverflowEnd);
      } else {
        laneEndTimesWithText.push(eventEnd);
      }
    }

    // Exit early if all events are assigned
    if (lanes.flat().length === events.length) break;
  }

  return lanes;
};

const getNoEventRanges = (
  events: (BrandListingsQuery["listings"]["listings"][0] & {
    displayStartDate: Date;
    displayEndDate: Date;
  })[],
  firstDay: Date,
  lastDay: Date
) => {
  const noEventRanges = [];
  let currentDate = max([firstDay, new Date()]); // Start from today or firstDay, whichever is later

  events.forEach((event) => {
    if (event.displayStartDate > currentDate) {
      noEventRanges.push({
        start: currentDate,
        end: subDays(event.displayStartDate, 1),
      });
    }
    currentDate = max([currentDate, addDays(event.displayEndDate, 1)]);
  });

  if (currentDate <= lastDay) {
    noEventRanges.push({
      start: currentDate,
      end: lastDay,
    });
  }

  return noEventRanges.filter((range) => range.start >= new Date());
};

const NoEventsIndicator = React.memo(
  ({
    startDate,
    endDate,
    firstDay,
    lastDay,
  }: {
    startDate: Date;
    endDate: Date;
    firstDay: Date;
    lastDay: Date;
  }) => {
    const totalDays = differenceInDays(lastDay, firstDay) + 1;
    const startIndex = Math.max(differenceInDays(startDate, firstDay), 0);
    const endIndex = Math.min(
      differenceInDays(endDate, firstDay),
      totalDays - 1
    );

    const left = `${(startIndex / totalDays) * 100}%`;
    const width = `${((endIndex - startIndex + 1) / totalDays) * 100}%`;
    const theme = useTheme();

    return (
      <NoEventsContainer
        style={{ left, width, top: `${CALENDAR_HEADER_OFFSET}px` }}
      >
        <NoEventsCard>
          <InfoIcon width={14} color={theme.color.warning} />
          <Text
            weight="semi"
            size="xxs"
            margin="xs 0 0"
            align="center"
            isCompact
            color={theme.color.warning}
          >
            No scheduled listings
          </Text>
        </NoEventsCard>
      </NoEventsContainer>
    );
  }
);

const NoEventsContainer = styled.div`
  position: absolute;
  overflow: visible;
  height: ${LANE_HEIGHT - 14}px;
  z-index: 4;
  pointer-events: none;
`;

const NoEventsCard = styled.div`
  border: 1px dashed ${(p) => p.theme.color.warning};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  background: ${(p) => p.theme.color.warning}20;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 0 0 2px;
  pointer-events: none;
`;

const MonthNavWrap = styled(Flex)`
  align-items: center;
  margin-left: ${(p) => p.theme.spacing.xs};
  user-select: none;
  display: flex;
  flex: 1;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 auto;
    justify-content: space-between;
    width: 100%;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
    box-sizing: border-box;
  }
`;

const ChevronWrap = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventContainer = styled.div<{ left: string; width: string; top: string }>`
  position: absolute;
  overflow: visible;
  left: ${(props) => props.left};
  width: ${(props) => props.width};
  top: ${(props) => props.top};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

const CalendarBody = styled.div`
  flex: 1;
  position: relative;
  overflow-y: scroll;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 ${(p) => p.theme.spacing.l};
`;

const CalendarContent = styled.div<{ columnCount: number; minHeight: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnCount}, 1fr);
  width: 100%;
  position: relative;
  min-height: ${(props) => props.minHeight}px;
  height: 100%;
`;

const CalendarHeader = styled.div`
  position: sticky;
  top: 0;
  background: ${(p) => p.theme.color.card.background};
  z-index: 2;
`;

const DayHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DayHeaderColumn = styled.div<{
  isWeekend: boolean;
  isToday: boolean;
  isHovered: boolean;
  dateText: string;
}>`
  flex: 1;
  text-align: center;
  padding: ${(p) => p.theme.spacing.s} 0;
  border-left: 1px solid ${(p) => p.theme.color.card.divider}60;
  box-sizing: border-box;
  position: relative;

  &:first-of-type {
    border-left-color: transparent;
  }

  ${(p) =>
    p.isWeekend &&
    css`
      background: ${p.theme.color.card.divider}40;
    `}

  ${(p) =>
    p.isToday
      ? css`
          background: ${p.theme.color.primary}20;
          border-left: 1px solid ${(p) => p.theme.color.primary};
          &::before {
            content: "";
            position: absolute;
            left: -6px;
            top: 0px;
            width: 0px;
            height: 0px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid ${p.theme.color.primary};
          }
        `
      : css`
          &::before {
            content: "${p.dateText}";
            position: absolute;
            left: -32px;
            top: 28px;
            padding: ${p.theme.spacing.xxs} 0;
            font-size: 13px;
            z-index: 2;
            font-weight: ${p.theme.typography.weight.semi};
            width: 62px;
            text-align: center;
            padding-top: 2px;
            padding-bottom: 3px;
            height: 18px;
            color: #fff;
            border-radius: 999px;
            background: ${p.theme.color.primary};
            box-shadow: ${p.theme.shadow.filters};
            opacity: 0;
            pointer-events: none;
          }
        `}

  ${(p) =>
    p.isHovered && p.isToday
      ? css`
          border-left: 1px solid ${p.theme.color.primary};

          &::before {
            opacity: 1;
            content: "Today";
            position: absolute;
            left: -32px;
            top: 34px;
            padding: ${p.theme.spacing.xxs} 0;
            font-size: 13px;
            z-index: 2;
            font-weight: ${p.theme.typography.weight.semi};
            width: 62px;
            text-align: center;
            padding-top: 2px;
            padding-bottom: 3px;
            height: 18px;
            color: #fff;
            border-radius: 999px;
            background: ${p.theme.color.primary};
            box-shadow: ${p.theme.shadow.filters};
            border-width: 0px;
            pointer-events: none;
          }
        `
      : p.isHovered
      ? css`
          border-left: 1px solid ${p.theme.color.primary};
          &::before {
            opacity: 1;
          }
        `
      : null}
`;

const ListingCard = styled.div<{
  startsBeforeRange: boolean;
  endsAfterRange: boolean;
}>`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  margin: 0
    ${(p) => (p.startsBeforeRange || p.endsAfterRange ? 0 : p.theme.spacing.xs)};
  background: ${(p) => p.theme.color.card.callout};
  box-sizing: border-box;
  white-space: nowrap;
  box-shadow: ${(p) => p.theme.shadow.cardMedium};
  overflow: hidden;
  border-top-left-radius: ${(p) =>
    p.startsBeforeRange ? 0 : p.theme.misc.borderRadiusSmall};
  border-bottom-left-radius: ${(p) =>
    p.startsBeforeRange ? 0 : p.theme.misc.borderRadiusSmall};
  border-top-right-radius: ${(p) =>
    p.endsAfterRange ? 0 : p.theme.misc.borderRadiusSmall};
  border-bottom-right-radius: ${(p) =>
    p.endsAfterRange ? 0 : p.theme.misc.borderRadiusSmall};

  :hover {
    overflow: visible;
  }
`;

const ContainerColumn = styled(Flex)<{
  isWeekend: boolean;
  isToday: boolean;
  showDayIndicator: boolean;
  top: string;
}>`
  position: relative;
  border-left: 1px solid ${(p) => p.theme.color.card.divider}60;
  box-sizing: border-box;
  background: ${(p) => p.theme.color.card.background};
  height: 100%;

  .add-button-wrap {
    display: none;
  }

  ${(p) =>
    p.isWeekend &&
    css`
      background: ${p.theme.color.card.divider}40;
    `}

  ${(p) =>
    p.isToday &&
    css`
      background: ${p.theme.color.primary}20;
      border-left: 1px solid ${(p) => p.theme.color.primary};
    `}

    &:first-of-type {
    border-left: none;
  }

  :hover {
    .add-button-wrap {
      cursor: pointer;
      position: absolute;
      left: -21px;
      top: ${(p) => p.top};
      display: flex;
      justify-content: center;
      height: 100px;
      width: 42px;
    }

    border-left: 1px solid ${(p) => p.theme.color.primary};
  }

  &:last-of-type {
    border-right: none;
  }
`;

const AddEventButtonContainer = styled.div`
  margin-top: 20px;
  background: ${(p) => p.theme.color.primary};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  position: absolute;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background: ${(p) => p.theme.color.primary};
  box-shadow: ${(p) => p.theme.shadow.filters};
`;

const StartsBeforeRangeOverlay = styled.div<{ startsBeforeRange: boolean }>`
  display: ${(p) => (p.startsBeforeRange ? "block" : "none")};
  position: absolute;
  top: -4px;
  left: -${(p) => p.theme.spacing.l};
  bottom: -4px;
  width: 30px;
  background: linear-gradient(
    to right,
    ${(p) => p.theme.color.card.background} 50%,
    transparent
  );
`;

const EndsAfterRangeOverlay = styled.div<{ endsAfterRange: boolean }>`
  display: ${(p) => (p.endsAfterRange ? "block" : "none")};

  position: absolute;
  top: -4px;
  right: -${(p) => p.theme.spacing.l};
  bottom: -4px;
  width: 30px;
  background: linear-gradient(
    to left,
    ${(p) => p.theme.color.card.background} 50%,
    transparent
  );
`;

const TodayButton = styled.div`
  border: 2px solid ${(p) => p.theme.color.divider};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  font-size: 14px;
  font-family: ${(p) => p.theme.typography.bodyFamily};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  padding: 5px ${(p) => p.theme.spacing.l} 3px;
  margin-left: ${(p) => p.theme.spacing.l};
  user-select: none;
  cursor: pointer;
  color: ${(p) => p.theme.color.typography.text};
  transition: background 300ms ease-out-in;

  :hover {
    background: ${(p) => p.theme.color.card.callout};
  }
`;

const DesktopOnly = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;
