import { ListingCard } from "../.";
import { Flex } from "../../../../components/Flex";
import { H4 } from "../../../../components/Heading";
import { ReelIcon } from "../../../../components/icons/Instagram/ReelIcon";
import { TikTokIcon } from "../../../../components/icons/TikTokIcon";
import { TrashIcon } from "../../../../components/icons/TrashIcon";
import NumberCounter from "../../../../components/NumberCounter";
import { Underline } from "../../../../components/Tabs";
import { Text } from "../../../../components/Text";
import { View } from "../../../../components/View";
import { ListingOfferInput, Platform } from "../../../../graphql/generated";
import styled from "../../../../styles";
import Offers from "./Offers";
import { Tags } from "./Tags";
interface TikTokOffersProps {
  handles: string[];
  setHandles: React.Dispatch<React.SetStateAction<string[]>>;
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  tiktoks: number;
  setTiktoks: React.Dispatch<React.SetStateAction<number>>;
  tikTokOffers: ListingOfferInput[];
  setTikTokOffers: React.Dispatch<React.SetStateAction<ListingOfferInput[]>>;
  tiktokOffersRef?: React.RefObject<HTMLDivElement>;
}

export const TikTokOffers = ({
  handles,
  setHandles,
  tags,
  setTags,
  tiktoks,
  setTiktoks,
  tikTokOffers,
  setTikTokOffers,
  tiktokOffersRef,
}: TikTokOffersProps) => {
  if (tikTokOffers.length === 0) {
    return (
      <AddOfferWrap
        margin="0"
        onClick={() => {
          setTikTokOffers([
            {
              platform: Platform.Tiktok,
              minFollowers: 1500,
              maxFollowers: Infinity,
              maxGuests: 1,
              offer: "Complimentary meal for 2",
              toggleProductValue: null,
              toggleProductId: null,
            },
          ]);
          setTiktoks(1);
        }}
      >
        + Add TikTok Offers
      </AddOfferWrap>
    );
  }
  return (
    <ListingCard>
      <Flex align="flex-start" justify="space-between">
        <View>
          <H4 margin="0 0 m">TikTok Offers</H4>
          <Underline selected={true} />
        </View>
        <TrashWrap onClick={() => setTikTokOffers([])}>
          <TrashIcon width={16} />
        </TrashWrap>
      </Flex>
      <Flex margin="xl 0 0" justify="space-between" align="center">
        <Flex align="center" direction="row">
          <TikTokIcon width={28} height={28} />
          <View margin="0 0 0 m">
            <Text weight="bold" margin="xxs 0 0" size="s" isCompact>
              TikTok
            </Text>
            <Text colorPreset="secondary" margin="0" isCompact size="xs">
              Create offers for TikTok influencers
            </Text>
          </View>
        </Flex>
        <Flex>
          {/* <Text size="s" colorPreset="secondary" weight="semi" margin="0 s 0 0">
            What do I include?
          </Text> */}
          {/* <Tooltip>
            <Text margin="0">Hello</Text>
          </Tooltip> */}
        </Flex>
      </Flex>
      <Offers
        offers={tikTokOffers}
        setOffers={setTikTokOffers}
        platform={Platform.Tiktok}
        ref={tiktokOffersRef}
      />
      <View margin="xxl 0 m">
        <Tags
          tags={tags}
          handles={handles}
          setTags={setTags}
          setHandles={setHandles}
          platform={Platform.Tiktok}
        />
      </View>
      <View margin="xl 0 m">
        <Text weight="bold" margin="0 0 0">
          Deliverables
        </Text>
        <Text colorPreset="secondary" size="s" margin="0 0 l" isCompact>
          What content would you like in return
        </Text>
        <Flex align="center" direction="row">
          <Grid>
            <Flex align="center" justify="center">
              <ReelIcon width={28} height={28} colorPreset="text" />
            </Flex>
            <Text weight="semi" margin="0">
              TikTok Video
            </Text>
            <NumberCounter
              value={tiktoks}
              onIncrease={() => setTiktoks(tiktoks + 1)}
              onDecrease={() => setTiktoks(tiktoks - 1)}
            />
          </Grid>
        </Flex>
      </View>
    </ListingCard>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 140px auto;
  gap: ${(p) => p.theme.spacing.m};
  align-items: center;
`;

const TrashWrap = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.typography.text};
      }
    }
  }
`;

const AddOfferWrap = styled(Flex)`
  cursor: pointer;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};
  border: 1px dashed ${(p) => p.theme.color.typography.secondary};
  color: ${(p) => p.theme.color.typography.secondary};
  font-weight: ${(p) => p.theme.typography.weight.semi};

  &:hover {
    border: 1px dashed ${(p) => p.theme.color.typography.text};
    color: ${(p) => p.theme.color.typography.heading};
  }
`;
