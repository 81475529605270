import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const BillingSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 4.75H20.5M1 5.5H20.5M4 10.75H10M4 13H7M3.25 16H18.25C18.8467 16 19.419 15.7629 19.841 15.341C20.2629 14.919 20.5 14.3467 20.5 13.75V3.25C20.5 2.65326 20.2629 2.08097 19.841 1.65901C19.419 1.23705 18.8467 1 18.25 1H3.25C2.65326 1 2.08097 1.23705 1.65901 1.65901C1.23705 2.08097 1 2.65326 1 3.25V13.75C1 14.3467 1.23705 14.919 1.65901 15.341C2.08097 15.7629 2.65326 16 3.25 16Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const BillingIcon = styled(BillingSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
