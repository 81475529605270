import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const VideoSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.30811 10.5225C1.007 9.36686 1.07617 8.41878 1.51562 7.67822C1.95508 6.92952 2.75667 6.39648 3.92041 6.0791L23.3052 0.866699C24.4771 0.557454 25.4455 0.614421 26.2104 1.0376C26.9754 1.46077 27.5044 2.25423 27.7974 3.41797L28.1147 4.61426C28.2775 5.25716 28.2165 5.83089 27.9316 6.33545C27.6468 6.84001 27.1667 7.1818 26.4912 7.36084L13.1123 10.9497H27.3701C28.0618 10.9497 28.6112 11.1532 29.0181 11.5601C29.425 11.967 29.6284 12.5081 29.6284 13.1836V24.5728C29.6284 25.769 29.3232 26.6683 28.7129 27.2705C28.1025 27.8809 27.187 28.186 25.9663 28.186H5.89795C4.67725 28.186 3.75765 27.8809 3.13916 27.2705C2.52881 26.6683 2.22363 25.769 2.22363 24.5728V13.8794L1.30811 10.5225ZM4.12793 24.4629C4.12793 25.0651 4.28255 25.5168 4.5918 25.8179C4.90104 26.1271 5.34456 26.2817 5.92236 26.2817H25.9297C26.5075 26.2817 26.951 26.1271 27.2603 25.8179C27.5776 25.5168 27.7363 25.0651 27.7363 24.4629V17.9932H4.12793V24.4629ZM4.5918 11.377L8.48584 10.3271L9.36475 6.57959L5.50732 7.62939L4.5918 11.377ZM12.0991 9.36279L16.2617 8.26416L17.1406 4.50439L12.978 5.61523L12.0991 9.36279ZM19.8506 7.2876L23.7324 6.25L24.5991 2.52686C24.4526 2.55941 24.3021 2.59196 24.1475 2.62451C24.001 2.65706 23.8464 2.69368 23.6836 2.73438L20.7417 3.54004L19.8506 7.2876ZM5.17773 16.2476H9.19385L11.0249 12.854H10.1094L6.92334 13.0005L5.17773 16.2476ZM12.9292 16.2476H17.2383L19.0693 12.854H14.748L12.9292 16.2476ZM20.9736 16.2476H24.9775L26.8086 12.854H22.7925L20.9736 16.2476Z"
      fill="#788AA5"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const VideoIcon = styled(VideoSVG)<Props>`
  path {
    opacity: 0.8;
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
