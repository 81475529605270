import { getUnixTime } from "date-fns";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { BackLink } from "../../components/BackLink";
import { Button } from "../../components/CTA";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import Loading from "../../components/Loading";
import { Text } from "../../components/Text";
import {
  useResendEmailVerificationMutation,
  useVerifyEmailMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { actions } from "../../store/auth/slice";
import styled from "../../styles";
import { SignupCard } from "./signup/CreateBrand";
const Wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 500px;
  align-self: center;
  text-align: left;
  justify-content: center;
`;

export function EmailVerified() {
  const { token } = useParams<{ token: string }>();

  const client = useGqlClient();
  const verifyEmail = useVerifyEmailMutation(client);
  const resendEmail = useResendEmailVerificationMutation(client);
  const queryClient = useQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    verifyEmail.mutate(
      {
        token,
      },
      {
        onSuccess: () => {
          dispatch(
            actions.setEmailVerified({
              emailVerified: getUnixTime(new Date()),
            })
          );
          queryClient
            .resetQueries(["Accounts", "BillingBanner"], {
              exact: false,
            })
            .then(() => {
              history.push("/signup/b/confirm-details?ev=true");
            });
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (verifyEmail.error) {
    return (
      <Wrap>
        <SignupCard>
          <H1 margin="0 0 s 0">Verify your email</H1>
          <Text margin="0">
            This verification link seems to be invalid or expired. Please click
            the button below to receive a new confirmation link via email
          </Text>
          {resendEmail.isSuccess ? (
            <Text colorPreset="success">Resent!</Text>
          ) : (
            <Button
              margin="l 0 0"
              onClick={() => {
                if (resendEmail.isLoading) {
                  return;
                }

                resendEmail.mutate({});
              }}
            >
              {resendEmail.isLoading ? "Loading..." : "Resend link"}
            </Button>
          )}
        </SignupCard>
        <Flex margin="l 0 0" justify="center">
          <BackLink margin="0" to="/b/login">
            Back to login
          </BackLink>
        </Flex>
      </Wrap>
    );
  }

  // while we redirect
  return (
    <Flex justify="center" align="center" style={{ height: "100%" }}>
      <Loading />
    </Flex>
  );
}
