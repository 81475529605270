import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import withPadding, { WithPaddingProp } from "../styles/withPadding";

export const Card = styled.div<
  {
    hover?: boolean;
    shadow?: "normal" | "heavy" | "none";
    flex?: number;
    borderColor?: string;
    overflow?: "visible" | "hidden";
  } & WithPaddingProp &
    WithMarginProp
>`
  background: ${(p) => p.theme.color.card.background};

  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};

  overflow: ${(p) => p.overflow || "hidden"};

  box-sizing: border-box;

  ${(p) =>
    p.flex
      ? css`
          flex: ${p.flex};
        `
      : ``}

  ${(p) =>
    p.hover
      ? css`
          &:hover {
            box-shadow: ${(p) => p.theme.shadow.cardHeavy};
          }
        `
      : null}

  ${(p) =>
    p.shadow === "heavy"
      ? css`
          box-shadow: ${(p) => p.theme.shadow.cardHeavy};
        `
      : null}

      ${(p) =>
    p.shadow === "none"
      ? css`
          box-shadow: none;
        `
      : null}

  ${(p) =>
    p.onClick
      ? css`
          cursor: pointer;
        `
      : ``};

  ${withPadding};
  ${withMargin};
`;
