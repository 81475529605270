import { useGoogleLogin } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, CTA } from "../../../components/CTA";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H1 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import { Logo } from "../../../components/Logo";
import { LogoCloud } from "../../../components/LogoCloud";
import { Text } from "../../../components/Text";
import { ExternalTextLink, TextLink } from "../../../components/TextLink";
import { View } from "../../../components/View";
import { FacebookIconCircle } from "../../../components/icons/FacebookIconCircle";
import { GoogleIcon } from "../../../components/icons/GoogleIcon";
import config, { MOBILE_BREAKPOINT } from "../../../config";
import {
  AuthType,
  AuthenticateError,
  useLoginMutation,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import { actions } from "../../../store/auth/slice";
import styled, { css } from "../../../styles";
import { SignupCard } from "../../brands/signup/CreateBrand";

const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: ${(p) => p.theme.spacing.l};
  }
`;

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: -22px;
  }
`;

const PageWrap = styled.div`
  flex: 1;
  max-width: 460px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: ${(p) => p.theme.spacing.xxxl};
  flex: 1;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const HideOnMobile = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useHash() {
  return new URLSearchParams(useLocation().hash.substring(1));
}

export const BrandLogin = (props: { mode: "login" | "signup" }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authData = useSelector(authSelectors.data);
  const { track } = useAnalytics();
  const theme = useTheme();
  const query = useQuery();
  const client = useGqlClient();
  const loginMutation = useLoginMutation(client, {});
  const hash = useHash();

  const [loginMode, setLoginMode] = useState<"email" | "facebook" | "google">(
    "email"
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailPasswordErr, setEmailPasswordErr] = useState<string>("");
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const stateHash = (+new Date().setHours(0, 0, 0, 0)).toString(36);

  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    const error = query.get("error");
    if (error) {
      setError("Failed to login with Facebook, please try again");

      track("Login failed");

      return;
    }

    const state = hash.get("state");
    if (state && state !== stateHash) {
      setError("Failed to login with Facebook, please try again");
      return;
    }

    const accessToken = hash.get("access_token");
    if (!accessToken) {
      return;
    }

    const code = query.get("c");
    setLoginMode("facebook");

    loginMutation.mutate(
      {
        fbToken: accessToken,
        code,
        authType: AuthType.AuthTypeBrand,
        isSignup: props.mode === "signup",
      },
      {
        onSuccess: (data) => {
          dispatch(actions.loginSuccess({ mutation: data, type: "brand" }));
          track(data.authenticate.signup ? "Signup sucess" : "Login success");

          if (
            !data.authenticate.account.email ||
            data.authenticate.account.email === "" ||
            !data.authenticate.account.firstName ||
            data.authenticate.account.firstName === "" ||
            !data.authenticate.account.lastName ||
            data.authenticate.account.lastName === ""
          ) {
            history.push("/signup/b/confirm-details");
          } else if (
            data.authenticate.account.brands &&
            data.authenticate.account.brands.length === 0
          ) {
            history.push("/signup/b/create-brand");
          } else {
            history.push("/b/");
          }
        },
        onError: (error: any) => {
          const graphQLErrors = error?.response?.errors;
          if (graphQLErrors && graphQLErrors.length > 0) {
            switch (graphQLErrors[0].message) {
              case AuthenticateError.AuthenticateNoAccountFoundError:
                setError(
                  "No account found — please check your login details or create a new account"
                );
                break;
              default:
                setError("Failed to login with Facebook, please try again");
                break;
            }
          }
          track("Facebook login failure");
          dispatch(actions.loginFailure());
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash.toString(), query.toString(), props.mode]);

  const fbLogin = () => {
    setLoginMode("facebook");
    dispatch(actions.fbLoginStart());
    track("Login start");

    const redirectUri = `${window.location.origin}/${
      props.mode === "signup" ? "signup/b" : "b/login"
    }`;

    const uri = encodeURI(
      `https://www.facebook.com/v10.0/dialog/oauth?client_id=${config.facebookAppId}&redirect_uri=${redirectUri}&response_type=token&scope=public_profile&state=${stateHash}`
    );
    window.location.href = uri;
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (res) => {
      setLoginMode("google");
      loginMutation.mutate(
        {
          googleToken: res.access_token,
          authType: AuthType.AuthTypeBrand,
          isSignup: props.mode === "signup",
        },
        {
          onSuccess: (data) => {
            dispatch(
              actions.loginSuccess({
                mutation: data,
                type: "brand",
              })
            );
            track(
              data.authenticate.signup ? "Signup success" : "Login success"
            );

            if (
              !data.authenticate.account.email ||
              data.authenticate.account.email === "" ||
              !data.authenticate.account.firstName ||
              data.authenticate.account.firstName === "" ||
              !data.authenticate.account.lastName ||
              data.authenticate.account.lastName === ""
            ) {
              history.push("/signup/b/confirm-details");
            } else if (
              data.authenticate.account.brands &&
              data.authenticate.account.brands.length === 0
            ) {
              history.push("/signup/b/create-brand");
            } else {
              history.push("/b/");
            }
          },
          onError: (error: any) => {
            const graphQLErrors = error?.response?.errors;
            if (graphQLErrors && graphQLErrors.length > 0) {
              switch (graphQLErrors[0].message) {
                case AuthenticateError.AuthenticateNoAccountFoundError:
                  setError(
                    "No account found — please check your login details or create a new account"
                  );
                  break;
                default:
                  setError("Failed to login with Google, please try again");
                  break;
              }
            }
            track("Google login failure");
            dispatch(actions.loginFailure());
          },
        }
      );
    },
  });

  const renderAuthError = (errorCode: string) => {
    switch (errorCode) {
      case "FACEBOOK_FAILED":
        return "Facebook login error";
      default:
        return "Please try again";
    }
  };

  return (
    <Wrap>
      <Flex margin="0 0 0" align="center" direction="column" justify="center">
        <Logo height={38} />
      </Flex>
      <Grid>
        <HideOnMobile>
          <LogoCloud faded />
        </HideOnMobile>
        <CenteredContainer>
          <PageWrap>
            <SignupCard>
              <H1 margin="0 0 xs 0">
                {props.mode === "signup"
                  ? "Create your free account"
                  : "Sign into Joli"}
              </H1>
              {props.mode === "signup" ? (
                <Text margin="0 0 l" color={theme.color.typography.secondary}>
                  Already have an account?{" "}
                  <TextLink to="/b/login">Login</TextLink>
                </Text>
              ) : (
                <Text margin="0 0 l" color={theme.color.typography.secondary}>
                  Don't have an account?{" "}
                  <TextLink to="/signup/b">Sign up for free</TextLink>
                </Text>
              )}
              {error && (
                <Alert padding="s m" margin="0 0 xl">
                  <Flex>
                    <Text margin="0 m 0 0">⚠️</Text>
                    <Text margin="0">{error}</Text>
                  </Flex>
                </Alert>
              )}
              {authData.error && (
                <Alert padding="s m" margin="0 0 xl">
                  <Flex>
                    <Text margin="0 m 0 0">⚠️</Text>
                    <Text margin="0">
                      {renderAuthError(authData.error.code)}
                    </Text>
                  </Flex>
                </Alert>
              )}

              <CTA
                to="#"
                onClick={() => {
                  setLoginMode("facebook");
                  fbLogin();
                }}
                margin="l 0"
                style={{
                  background: "#3C72EA",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Flex align="center" justify="center" margin="0 s 0 0">
                  <FacebookIconCircle />
                </Flex>
                {authData.isLoading ||
                (loginMutation.isLoading && loginMode === "facebook")
                  ? "Loading..."
                  : props.mode === "signup"
                  ? "Sign up with Facebook"
                  : "Sign in with Facebook"}
              </CTA>

              <CTA
                to="#"
                margin="0 0 l 0"
                onClick={() => {
                  setLoginMode("google");
                  googleLogin();
                }}
                style={{
                  background: "#fff",
                  border: `1px solid ${theme.color.button.secondaryBorder}`,
                  color: "#445363",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Flex align="center" justify="center" margin="0 s 0 0">
                  <GoogleIcon />
                </Flex>
                {props.mode === "signup"
                  ? "Sign up with Google"
                  : "Sign in with Google"}
              </CTA>

              <Flex justify="center" margin="m 0" align="center">
                <CardDivider />
                <Text
                  style={{
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                  size="s"
                  colorPreset="secondary"
                  margin="0 xxl"
                >
                  OR
                </Text>
                <CardDivider />
              </Flex>

              <Input
                placeholder="Enter your email"
                margin="m 0 0 0"
                value={email}
                onChange={(e) => {
                  setError(null);
                  setEmailPasswordErr("");
                  setEmail(e.currentTarget.value);
                }}
              />
              <Input
                margin="l 0 0 0"
                placeholder={
                  props.mode === "signup"
                    ? "Create a password"
                    : "Enter your password"
                }
                type="password"
                onChange={(e) => {
                  setError(null);
                  setEmailPasswordErr("");
                  setPassword(e.currentTarget.value);
                }}
                value={password}
              />
              {emailPasswordErr ? (
                <Alert padding="s m" margin="l 0">
                  <Flex>
                    <Text margin="0 m 0 0">⚠️</Text>
                    <Text size="s" margin="0">
                      {emailPasswordErr}
                    </Text>
                  </Flex>
                </Alert>
              ) : null}
              <ReCAPTCHA
                size="invisible"
                sitekey={config.recaptchaSiteKey}
                ref={recaptchaRef}
              />
              <Button
                margin="l 0 0 0"
                onClick={async () => {
                  if (!email || !password) {
                    setEmailPasswordErr("Please enter your email and password");
                    return;
                  }

                  const recaptchaToken = await recaptchaRef.current?.execute();

                  if (!recaptchaToken) {
                    alert("Something went wrong, please try again");
                    track("Recaptcha error");
                    Sentry.captureException("Recaptcha error");
                    return;
                  }

                  loginMutation.mutate(
                    {
                      email,
                      password,
                      authType: AuthType.AuthTypeBrand,
                      isSignup: props.mode === "signup",
                      recaptchaToken: recaptchaToken,
                    },
                    {
                      onSuccess: (data) => {
                        dispatch(
                          actions.loginSuccess({
                            mutation: data,
                            type: "brand",
                          })
                        );
                        track(
                          data.authenticate.signup
                            ? "Signup success"
                            : "Login success"
                        );

                        if (
                          !data.authenticate.account.email ||
                          data.authenticate.account.email === "" ||
                          !data.authenticate.account.firstName ||
                          data.authenticate.account.firstName === "" ||
                          !data.authenticate.account.lastName ||
                          data.authenticate.account.lastName === ""
                        ) {
                          history.push("/signup/b/confirm-details");
                        } else if (
                          data.authenticate.account.brands &&
                          data.authenticate.account.brands.length === 0
                        ) {
                          history.push("/signup/b/create-brand");
                        } else {
                          history.push("/b/");
                        }
                      },
                      onError: (error: any) => {
                        const graphQLErrors = error?.response?.errors;
                        if (graphQLErrors && graphQLErrors.length > 0) {
                          switch (graphQLErrors[0].message) {
                            case AuthenticateError.AuthenticateIncorrectPasswordError:
                              setEmailPasswordErr(
                                "Oops, that password doesn't look right. Please try again."
                              );
                              break;
                            case AuthenticateError.AuthenticateNoAccountFoundError:
                              setEmailPasswordErr(
                                "No account found — please check your login details or create a new account"
                              );
                              break;
                            default:
                              alert(
                                "Something went wrong — please contact us via the in app chat"
                              );
                              break;
                          }
                        }
                      },
                    }
                  );
                }}
              >
                {loginMutation.isLoading && loginMode === "email"
                  ? "Loading..."
                  : props.mode === "login"
                  ? "Login"
                  : "Create account"}
              </Button>
              {props.mode === "signup" ? (
                <Text
                  size="xs"
                  align="center"
                  margin="l 0 0"
                  colorPreset="secondary"
                >
                  By continuing you agree to Joli's{" "}
                  <ExternalTextLink
                    size="xs"
                    href="https://joliapp.com/legal/privacy-policy"
                  >
                    Privacy Policy
                  </ExternalTextLink>{" "}
                  and{" "}
                  <ExternalTextLink
                    size="xs"
                    href="https://docs.google.com/document/d/1Pk7mgoDz2jW73MHmM0wOlrRiyUtJyuvkqZSqXpeZ_0Q/edit?usp=sharing"
                  >
                    Terms of Service
                  </ExternalTextLink>
                </Text>
              ) : (
                <Flex margin="l 0 0">
                  <TextLink
                    size="s"
                    to="/forgot-password"
                    style={{ margin: "0 auto" }}
                  >
                    Forgot password
                  </TextLink>
                </Flex>
              )}
            </SignupCard>
          </PageWrap>
        </CenteredContainer>
      </Grid>
    </Wrap>
  );
};

const Alert = styled(View)<{ type?: "positive" | "warning" }>`
  background-color: ${(p) => p.theme.color.warning}30;
  border-left: 3px solid ${(p) => p.theme.color.warning};

  ${(p) =>
    p.type === "positive"
      ? css`
          background-color: ${(p) => p.theme.color.tags.lightGreen};
          border-left: 3px solid ${(p) => p.theme.color.tags.darkGreen}CC;
        `
      : css``}

  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;

  color: ${(p) => p.theme.color.warning};
`;
