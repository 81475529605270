import styled from "styled-components";
import { css } from "../styles";

interface Props {
  imageUrl: string;
  height?: number;
}

const ImageWrap = styled.div<{ height?: number }>`
  position: relative;
  scroll-snap-align: center;
  min-width: 100%;
  overflow: hidden;

  ${(p) =>
    p.height
      ? css`
          height: ${p.height}px;
        `
      : ``}
`;

const Image = styled.div<Props>`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))
      no-repeat center center fixed,
    url(${(p) => p.imageUrl});
  background-blend-mode: overlay;
  height: 100%;
  width: 100%;
  background-size: cover;
  z-index: 99;
`;

export function LocationImage(props: Props) {
  return (
    <ImageWrap height={props.height}>
      <Image {...props} />
    </ImageWrap>
  );
}
