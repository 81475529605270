import { useEffect, useState } from "react";
import { Dropdown } from "../../../../components/Dropdown";
import { Flex } from "../../../../components/Flex";
import { TrashIcon } from "../../../../components/icons/TrashIcon";
import { Input } from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import { Text } from "../../../../components/Text";
import { View } from "../../../../components/View";
import {
  ToggleProduct,
  useToggleProductsQuery,
} from "../../../../graphql/generated";
import useGqlClient from "../../../../hooks/useGqlClient";
import styled from "../../../../styles";

interface ToggleVoucherProps {
  toggleProductValue?: number | null;
  setToggleProductValue: (value: number | null) => void;
  toggleProductId?: number | null;
  setToggleProductId: (id: number | null) => void;
}

const ToggleVoucher = (props: ToggleVoucherProps) => {
  const client = useGqlClient();
  const { data: toggleData, isLoading, error } = useToggleProductsQuery(client);
  const [selectedProduct, setSelectedProduct] = useState<
    ToggleProduct | undefined
  >(() => {
    return toggleData?.toggleProducts.find(
      (p) => p.id === props.toggleProductId
    );
  });

  useEffect(() => {
    if (!toggleData) return;

    const product = toggleData.toggleProducts.find(
      (p) => p.id === props.toggleProductId
    );
    setSelectedProduct(product);

    if (!product) {
      props.setToggleProductValue(null);
    } else if (product.priceModifiers.length === 0) {
      props.setToggleProductValue(null);
    } else if (!props.toggleProductValue) {
      props.setToggleProductValue(product.priceModifiers[0].value);
    }
  }, [props.toggleProductId, toggleData]);

  if (isLoading) {
    return <Loader />;
  }

  if (error || !toggleData) {
    return (
      <Text size="s" margin="0" colorPreset="link" weight="semi">
        Select Toggle Voucher
      </Text>
    );
  }

  if (toggleData.toggleProducts.length === 0) {
    return null;
  }

  return (
    <ToggleWrap direction="row">
      <View margin="0 m 0 0">
        <Text margin="0 0 xs" size="s" weight="semi">
          Toggle Product
        </Text>
        <Dropdown
          width={220}
          size="s"
          selectionMode="single"
          renderLabel={() => {
            const selected = toggleData.toggleProducts.find(
              (p) => p.id === props.toggleProductId
            );
            return selected ? selected.name : "Select a product";
          }}
          options={
            toggleData.toggleProducts.map((product) => ({
              label: product.name,
              value: product.id.toString(),
            })) || []
          }
          selectedOptions={[
            props.toggleProductId ? props.toggleProductId.toString() : "",
          ]}
          setSelectedOptions={(value) => {
            props.setToggleProductId(Number(value[0]));
            setSelectedProduct(
              toggleData.toggleProducts.find(
                (product) => product.id === Number(value[0])
              )
            );
          }}
        />
      </View>
      <View>
        <Text margin="0 0 xs" size="s" weight="semi">
          Voucher Value
        </Text>
        {selectedProduct?.priceModifiers &&
        selectedProduct.priceModifiers.filter((pm) => pm.value > 0).length >
          0 ? (
          <Dropdown
            size="s"
            width={150}
            selectionMode="single"
            renderLabel={() => {
              if (!props.toggleProductValue) {
                return "Select value";
              }

              return `£${props.toggleProductValue / 100}.00`;
            }}
            options={selectedProduct?.priceModifiers
              .sort((a, b) => a.value - b.value)
              .map((pm) => ({
                label: `£${pm.value / 100}.00`,
                value: pm.value.toString(),
              }))}
            selectedOptions={[
              props.toggleProductValue
                ? props.toggleProductValue.toString()
                : "",
            ]}
            disableOptionSort
            setSelectedOptions={(value) => {
              props.setToggleProductValue(Number(value[0]));
            }}
          />
        ) : (
          <Flex direction="row" align="center">
            <Input
              prefix="£"
              inputSize="s"
              value={props.toggleProductValue?.toString() || ""}
              onChange={(e) => {
                props.setToggleProductValue(Number(e.target.value));
              }}
            />
          </Flex>
        )}
      </View>
    </ToggleWrap>
  );
};

export const Toggle = (props: ToggleVoucherProps) => {
  const [toggleEnabled, setToggleEnabled] = useState(
    !!props.toggleProductValue && !!props.toggleProductId
  );

  useEffect(() => {
    setToggleEnabled(!!props.toggleProductValue && !!props.toggleProductId);
  }, [props.toggleProductValue, props.toggleProductId]);

  if (toggleEnabled) {
    return (
      <Flex direction="row" align="center">
        <ToggleVoucher {...props} />
        <View margin="0 0 0 m">
          <TrashWrap
            onClick={() => {
              setToggleEnabled(false);
              props.setToggleProductValue(null);
              props.setToggleProductId(null);
            }}
          >
            <TrashIcon width={14} />
          </TrashWrap>
        </View>
      </Flex>
    );
  }

  return (
    <Flex style={{ flex: 1 }}>
      <Text
        onClick={() => setToggleEnabled(true)}
        size="s"
        margin="0"
        colorPreset="link"
        weight="semi"
        style={{ marginLeft: "auto" }}
      >
        + Toggle Voucher
      </Text>
    </Flex>
  );
};

const ToggleWrap = styled(Flex)`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};
  margin: ${(p) => p.theme.spacing.s} 0 0 0;
`;

const TrashWrap = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.typography.text};
      }
    }
  }
`;
