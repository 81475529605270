import "@uploadcare/react-uploader/core.css";
import { isFuture } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListingCard } from "..";
import { SingleCheckBox } from "../../../../components/Checkbox";
import { DateInput, DatePicker } from "../../../../components/DatePicker";
import { DayPicker } from "../../../../components/DayPicker";
import { Dropdown } from "../../../../components/Dropdown";
import { Flex } from "../../../../components/Flex";
import { H4 } from "../../../../components/Heading";
import { BookingsIcon } from "../../../../components/icons/BookingsIcon";
import NumberCounter from "../../../../components/NumberCounter";
import { Radio } from "../../../../components/RadioButton";
import { Underline } from "../../../../components/Tabs";
import { Text } from "../../../../components/Text";
import { TextLink } from "../../../../components/TextLink";
import { Tooltip } from "../../../../components/Tooltip";
import { View } from "../../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../../config";
import {
  Days,
  ListingType,
  ScheduleInput,
  useBrandLocationsQuery,
} from "../../../../graphql/generated";
import useGqlClient from "../../../../hooks/useGqlClient";
import { authSelectors } from "../../../../store/auth/selector";
import styled, { css } from "../../../../styles";
import { lightTheme } from "../../../../styles/theme";
import { AccessCollinsPicker } from "./AccessCollins";
import { Events } from "./Event";
import { RepeatEvent } from "./RepeatEvent";

export function daysToDaysEnum(days: number[]): Days[] {
  const daysEnum: Days[] = [];
  for (const d of days) {
    switch (d) {
      case 0:
        daysEnum.push(Days.Sunday);
        break;
      case 1:
        daysEnum.push(Days.Monday);
        break;
      case 2:
        daysEnum.push(Days.Tuesday);
        break;
      case 3:
        daysEnum.push(Days.Wednesday);
        break;
      case 4:
        daysEnum.push(Days.Thursday);
        break;
      case 5:
        daysEnum.push(Days.Friday);
        break;
      case 6:
        daysEnum.push(Days.Saturday);
        break;
    }
  }

  return daysEnum;
}

export function dayEnumToDays(days: Days[]): number[] {
  const daysEnum: number[] = [];
  for (const d of days) {
    switch (d) {
      case Days.Sunday:
        daysEnum.push(0);
        break;
      case Days.Monday:
        daysEnum.push(1);
        break;
      case Days.Tuesday:
        daysEnum.push(2);
        break;
      case Days.Wednesday:
        daysEnum.push(3);
        break;
      case Days.Thursday:
        daysEnum.push(4);
        break;
      case Days.Friday:
        daysEnum.push(5);
        break;
      case Days.Saturday:
        daysEnum.push(6);
        break;
    }
  }

  return daysEnum;
}

interface AvailabilityProps {
  listingType: ListingType;
  setListingType: (listingType: ListingType) => void;
  days: Days[];
  setDays: (days: Days[]) => void;
  startDate: Date | null;
  setStartDate: (startDate: Date) => void;
  endDate: Date | null;
  setEndDate: (endDate: Date | null) => void;
  locations: string[];
  setLocations: (locations: string[]) => void;
  visibleFromDate: Date | null;
  setVisibleFromDate: (visibleFromDate: Date | null) => void;
  schedules: ScheduleInput[];
  setSchedules: (schedules: ScheduleInput[]) => void;
  timeSlots: number[];
  setTimeSlots: (timeSlots: number[]) => void;
  dmnBookingTypeId: string;
  setDmnBookingTypeId: (dmnBookingTypeId: string) => void;
  locationsRef?: React.RefObject<HTMLDivElement>;
  locationsError?: string;
  requiredDaysNotice: number;
  setRequiredDaysNotice: (requiredDaysNotice: number) => void;
  endDateError?: string;
  daysError?: string;
  numberOfVisitors: number;
}

const Availability = (props: AvailabilityProps) => {
  const brand = useSelector(authSelectors.activeBrand);

  const isCollinsEnabled = brand?.dmnEnabled;

  useEffect(() => {
    if (props.listingType === ListingType.Event) {
      props.setVisibleFromDate(null);
      props.setEndDate(null);
    }

    if (
      props.listingType !== ListingType.Ongoing &&
      props.listingType !== ListingType.RedeemAnytime &&
      props.days.length === 0
    ) {
      props.setDays([
        Days.Monday,
        Days.Tuesday,
        Days.Wednesday,
        Days.Thursday,
        Days.Friday,
        Days.Saturday,
        Days.Sunday,
      ]);
    }

    if (
      props.listingType !== ListingType.Scheduled &&
      props.schedules.length > 0
    ) {
      props.setSchedules([]);
    }

    if (props.listingType !== ListingType.AccessCollins) {
      props.setDmnBookingTypeId("");
    }
  }, [props.listingType]);

  return (
    <ListingCard>
      <H4 margin="0 0 m">Availability Options</H4>
      <Underline selected={true} />
      <View margin="l 0 m">
        <Text weight="bold" margin="l 0 m">
          Availability Type
        </Text>
        <ListingTypeGrid>
          <ListingTypeSelect
            listingType={ListingType.Ongoing}
            selected={props.listingType === ListingType.Ongoing}
            onClick={() => props.setListingType(ListingType.Ongoing)}
          />
          <ListingTypeSelect
            listingType={ListingType.RedeemAnytime}
            selected={props.listingType === ListingType.RedeemAnytime}
            onClick={() => props.setListingType(ListingType.RedeemAnytime)}
          />
          {isCollinsEnabled && (
            <ListingTypeSelect
              listingType={ListingType.AccessCollins}
              selected={props.listingType === ListingType.AccessCollins}
              onClick={() => props.setListingType(ListingType.AccessCollins)}
            />
          )}
          <ListingTypeSelect
            listingType={ListingType.Scheduled}
            selected={props.listingType === ListingType.Scheduled}
            onClick={() => props.setListingType(ListingType.Scheduled)}
          />
          <ListingTypeSelect
            listingType={ListingType.Event}
            selected={props.listingType === ListingType.Event}
            onClick={() => props.setListingType(ListingType.Event)}
          />
        </ListingTypeGrid>
        <AvailabilityOptions {...props} />
      </View>
    </ListingCard>
  );
};

export default Availability;

const AvailabilityOptions = (props: AvailabilityProps) => {
  const renderDefaultOptions = () => (
    <>
      <View margin="0">
        <CampaignDates
          startDate={props.startDate}
          setStartDate={props.setStartDate}
          endDate={props.endDate}
          setEndDate={props.setEndDate}
          visibleFromDate={props.visibleFromDate}
          setVisibleFromDate={props.setVisibleFromDate}
          requiredDaysNotice={props.requiredDaysNotice}
          setRequiredDaysNotice={props.setRequiredDaysNotice}
        />
        <Text weight="bold" margin="xxl 0 s">
          Available Days
        </Text>
        <DayPicker
          margin="0 0 xl 0"
          initialValue={dayEnumToDays(props.days)}
          onChange={(days) => {
            props.setDays(daysToDaysEnum(days));
          }}
        />
        {props.daysError && (
          <Text size="xs" colorPreset="error" margin="s 0 0">
            {props.daysError}
          </Text>
        )}
        <BrandLocationPicker
          locationsRef={props.locationsRef}
          locations={props.locations}
          setLocations={props.setLocations}
          locationsError={props.locationsError}
        />
      </View>
    </>
  );

  const renderScheduledOptions = () => (
    <>
      <CampaignDates
        startDate={props.startDate}
        setStartDate={props.setStartDate}
        endDate={props.endDate}
        setEndDate={props.setEndDate}
        endDateError={props.endDateError}
        visibleFromDate={props.visibleFromDate}
        setVisibleFromDate={props.setVisibleFromDate}
        requiredDaysNotice={props.requiredDaysNotice}
        setRequiredDaysNotice={props.setRequiredDaysNotice}
      />
      <RepeatEvent
        schedules={props.schedules}
        setSchedules={props.setSchedules}
      />
      <BrandLocationPicker
        locations={props.locations}
        setLocations={props.setLocations}
      />
    </>
  );

  const renderEventOptions = () => (
    <>
      <Events timeSlots={props.timeSlots} setTimeSlots={props.setTimeSlots} />
      <BrandLocationPicker
        locations={props.locations}
        setLocations={props.setLocations}
      />
    </>
  );

  const renderIntegrationOptions = () => (
    <>
      {renderDefaultOptions()}
      <AccessCollinsPicker
        locations={props.locations}
        dmnBookingTypeId={props.dmnBookingTypeId}
        setDmnBookingTypeId={props.setDmnBookingTypeId}
        numberOfVisitors={props.numberOfVisitors}
      />
    </>
  );

  switch (props.listingType) {
    case ListingType.Event:
      return renderEventOptions();
    case ListingType.Scheduled:
      return renderScheduledOptions();
    case ListingType.Ongoing:
      return renderDefaultOptions();
    case ListingType.RedeemAnytime:
      return renderDefaultOptions();
    case ListingType.AccessCollins:
      return renderIntegrationOptions();
    default:
      return renderIntegrationOptions();
  }
};

const BrandLocationPicker = (props: {
  locations: string[];
  setLocations: (locations: string[]) => void;
  locationsRef?: React.RefObject<HTMLDivElement>;
  locationsError?: string;
}) => {
  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);

  const { data: locationsData } = useBrandLocationsQuery(client, {
    brandId: activeBrandId ?? "",
  });

  let locations = locationsData?.brand?.locations.locations.map((l) => ({
    label: l.name,
    value: l.id,
  }));

  // Default to all locations if none are passed through
  const [initializedLocations, setInitializedLocations] = useState(false);
  useEffect(() => {
    if (locations && locations.length > 0 && !initializedLocations) {
      if (props.locations.length === 0) {
        props.setLocations(locations.map((l) => l.value));
      }
      setInitializedLocations(true);
    }
  }, [locations, props.locations, props.setLocations, initializedLocations]);

  return (
    <div
      style={{ position: "relative", userSelect: "none" }}
      ref={props.locationsRef}
    >
      <Text weight="bold" margin="xl 0 s">
        Locations
      </Text>
      <Dropdown
        width={420}
        showSelectAll
        disableSelection={locations && locations.length === 0}
        selectionMode="multiple"
        options={locations ?? []}
        selectedOptions={props.locations}
        setSelectedOptions={(value) => props.setLocations(value)}
        renderLabel={() => {
          if (!locations || !props.locations || props.locations.length === 0) {
            return "Select locations";
          }

          const firstLocation = locations
            .filter((l) => props.locations.includes(l.value))
            .sort((a, b) => a.value.localeCompare(b.label))[0];

          if (props.locations.length === 1) {
            return firstLocation ? firstLocation.label : "Select locations";
          }

          if (props.locations.length === locations.length) {
            return "All locations";
          }

          const otherLocationsCount = props.locations.length - 1;
          return `${
            firstLocation ? firstLocation.label : ""
          } and ${otherLocationsCount} more`;
        }}
        placeholder="Select locations"
      />
      {props.locationsError ? (
        <Text colorPreset="error" margin="m 0 0" size="xs">
          {props.locationsError}
        </Text>
      ) : !locations || locations.length === 0 ? (
        <Text colorPreset="error" margin="m 0 0 xxs" size="xs">
          At least one location is required —{" "}
          <TextLink colorPreset="error" size="xs" to={`/b/locations`}>
            create a location here
          </TextLink>
        </Text>
      ) : null}
    </div>
  );
};

const CampaignDates = (props: {
  startDate: Date | null;
  setStartDate: (startDate: Date) => void;
  endDate: Date | null;
  setEndDate: (endDate: Date | null) => void;
  endDateError?: string;
  visibleFromDate: Date | null;
  setVisibleFromDate: (visibleFromDate: Date | null) => void;
  requiredDaysNotice: number;
  setRequiredDaysNotice: (requiredDaysNotice: number) => void;
}) => {
  const [showVisibleFromDate, setShowVisibleFromDate] = useState(
    !!props.visibleFromDate
  );

  useEffect(() => {
    if (props.visibleFromDate) {
      setShowVisibleFromDate(true);
    }
  }, [props.visibleFromDate]);

  return (
    <>
      <Text weight="bold" margin="xxl 0 0">
        Campaign Dates
      </Text>
      <Text colorPreset="secondary" size="s" margin="0 0 m">
        Influencers can apply from these dates. Leave the end date for an
        indefinite campaign.
      </Text>
      <DatesWrap direction="row" align="center">
        <Flex>
          <DateLabel>Start</DateLabel>
          <DatePicker
            placeholderText="Start Date"
            date={props.startDate}
            setDate={(date) => props.setStartDate(date ? date : new Date())}
            customInput={
              <StyledDateInput
                hasIcon={false}
                inputType="dates"
                clearable={false}
                noDates={!props.startDate ? true : false}
              />
            }
          />
        </Flex>
        <DateLine className="date-line" />
        <Flex direction="column">
          <Flex>
            <DateLabel>End</DateLabel>
            <DatePicker
              isClearable={true}
              placeholderText="Ongoing"
              date={props.endDate}
              setDate={(date) => props.setEndDate(date)}
              startDate={
                props.startDate && isFuture(props.startDate)
                  ? props.startDate
                  : new Date()
              }
              customInput={
                <StyledDateInput
                  hasIcon={false}
                  inputType="dates"
                  clearable={true}
                  noDates={!props.endDate ? true : false}
                />
              }
            />
          </Flex>
        </Flex>
      </DatesWrap>
      {props.endDateError ? (
        <Text margin="m 0 0" colorPreset="warning" size="xs">
          {props.endDateError}
        </Text>
      ) : null}

      <Flex
        direction="row"
        align="center"
        margin="xl 0 0 0"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setShowVisibleFromDate(!showVisibleFromDate);
          if (!showVisibleFromDate) {
            props.setVisibleFromDate(props.startDate);
          } else {
            props.setVisibleFromDate(null);
          }
        }}
      >
        <SingleCheckBox checked={showVisibleFromDate} />
        <Flex margin="0 0 0 l" style={{ flex: 1 }} align="center">
          <Text
            size="s"
            weight="semi"
            margin="0 s 0 0 "
            style={{ userSelect: "none" }}
          >
            Visible to influencers before Campaign Start Date
          </Text>
          <Tooltip>
            <Text margin="0" size="s">
              Influencers can see and apply for the offer before the campaign
              start date, but they can only select dates on or after the
              campaign start date.
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
      {showVisibleFromDate ? (
        <View margin="xl 0 0 0">
          <Text weight="bold" margin="0 0 s 0 " style={{ userSelect: "none" }}>
            Visible From
          </Text>
          <DatePicker
            placeholderText="Start Date"
            isClearable={false}
            date={props.visibleFromDate}
            startDate={new Date()}
            maxEndDate={props.startDate}
            setDate={(date) => props.setVisibleFromDate(date)}
            icon={
              <div
                style={{
                  margin: "auto 0",
                }}
              >
                <div
                  style={{
                    marginTop: 3,
                    marginRight: lightTheme.spacing.xl,
                    marginLeft: lightTheme.spacing.s,
                  }}
                >
                  <BookingsIcon colorPreset="secondary" width={17} />
                </div>
              </div>
            }
            customInput={
              <StyledDateInput
                hasIcon={true}
                clearable={false}
                noDates={!props.visibleFromDate ? true : false}
                inputType="visible-from"
              />
            }
          />
        </View>
      ) : null}

      <Flex align="center" margin="xxl 0 0">
        <Text weight="bold" margin="0 s 0 0">
          Required Days Notice
        </Text>
        <Tooltip>
          <Text margin="0" size="s">
            Eg. Choosing 3 days means they can’t select any dates sooner than 3
            days from today.
            <br />
            <Text margin="0" colorPreset="secondary" size="xs">
              This is to give your team time to review and approve their
              application and communicate with sites.
            </Text>
          </Text>
        </Tooltip>
      </Flex>
      <Text colorPreset="secondary" size="s" margin="0 s 0 0">
        Set the minimum number of days ahead an influencer can book
      </Text>
      <Flex margin="m 0 0 0">
        <NumberCounter
          size="l"
          minValue={1}
          value={props.requiredDaysNotice}
          onIncrease={() =>
            props.setRequiredDaysNotice(props.requiredDaysNotice + 1)
          }
          onDecrease={() =>
            props.setRequiredDaysNotice(props.requiredDaysNotice - 1)
          }
        />
      </Flex>
    </>
  );
};

const ListingTypeSelect = (props: {
  listingType: ListingType;
  selected: boolean;
  onClick: () => void;
}) => {
  let title = "Ongoing";
  let subtitle = "A scheduled visit based on location opening hours";
  switch (props.listingType) {
    case ListingType.AccessCollins:
      title = "Access Collins";
      subtitle = "Use real-time availability from Access Collins";
      break;
    case ListingType.Ongoing:
      title = "Ongoing";
      subtitle = "A scheduled visit based on location opening hours";
      break;
    case ListingType.Scheduled:
      title = "Scheduled";
      subtitle = "eg. Brunch at 1pm and 3pm on Saturdays and Sundays";
      break;
    case ListingType.Event:
      title = "Event";
      subtitle = "e.g. Launch party on 16th August at 7:30pm";
      break;
    case ListingType.RedeemAnytime:
      title = "Redeem Anytime";
      subtitle = "Available anytime without the need for prior scheduling";
      break;
    default:
      title = "Unknown";
  }

  function getTooltipContent(listingType: ListingType | "INTEGRATION") {
    if (listingType === ListingType.Ongoing) {
      return (
        <View>
          <Text margin="0 0 0" weight="bold">
            How it works
          </Text>
          <Text margin="0" size="s">
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              1.
            </span>{" "}
            Influencers apply to the campaign with some suggested dates/times.
            <br />
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              2.
            </span>{" "}
            You can accept a day/time for their visit, creating a booking.
          </Text>
        </View>
      );
    }

    if (listingType === ListingType.Scheduled) {
      return (
        <View>
          <Text margin="0 0 0" weight="bold">
            How it works
          </Text>
          <Text margin="0" size="s">
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              1.
            </span>{" "}
            Influencers apply to the campaign with some suggested dates/times.
            <br />
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              2.
            </span>{" "}
            You can accept a day/time for their visit, creating a booking.
          </Text>
        </View>
      );
    }

    if (listingType === ListingType.Event) {
      return (
        <View>
          <Text margin="0 0 0" weight="bold">
            How it works
          </Text>
          <Text margin="0" size="s">
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              1.
            </span>{" "}
            Influencers apply to your event dates
            <br />
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              2.
            </span>{" "}
            You can accept a day/time for their visit, creating a booking.
          </Text>
        </View>
      );
    }

    if (listingType === ListingType.RedeemAnytime) {
      return (
        <View>
          <Text margin="0 0 0" weight="bold">
            How it works
          </Text>
          <Text margin="0" size="s">
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              1.
            </span>{" "}
            Approved influencers have 30 days to activate the offer
            <br />
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              2.
            </span>{" "}
            Once activated, the offer is available for 24-hours
            <br />
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              3.
            </span>{" "}
            The offer is automatically removed from their app once used or the
            24-hours have passed
          </Text>
          <Text margin="0" colorPreset="secondary" size="xs">
            This is best suited for businesses without table reservations
          </Text>
        </View>
      );
    }

    if (listingType === ListingType.AccessCollins) {
      return (
        <View>
          <Text margin="0 0 0" weight="bold">
            How it works
          </Text>
          <Text margin="0" size="s">
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              1.
            </span>{" "}
            Influencers see live availability from Access Collins.
            <br />
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              2.
            </span>{" "}
            Influencers apply to the campaign with suggested dates/times.
            <br />
            <span style={{ fontWeight: lightTheme.typography.weight.bold }}>
              3.
            </span>{" "}
            You can accept a day/time for their visit, creating a booking.
          </Text>
        </View>
      );
    }

    return null;
  }

  return (
    <div style={{ position: "relative" }}>
      <AbsoluteWrap
        style={{
          display: props.listingType === ListingType.Ongoing ? "flex" : "none",
        }}
      >
        <MostPopular>Most Popular</MostPopular>
      </AbsoluteWrap>
      <ListingTypeWrap onClick={props.onClick} selected={props.selected}>
        <Flex align="center" direction="row" justify="space-between">
          <Flex align="center" direction="row" margin="0 s 0 0">
            <Flex align="center" justify="center">
              <Radio width={10} height={10} active={props.selected} />
            </Flex>
            <View margin="0 0 0 m">
              <Text margin="0" weight="bold">
                {title}
              </Text>
              <Text size="xs" margin="0" colorPreset="secondary">
                {subtitle}
              </Text>
            </View>
          </Flex>
          <Tooltip
            width={props.listingType === ListingType.RedeemAnytime ? 420 : 400}
          >
            {getTooltipContent(props.listingType)}
          </Tooltip>
        </Flex>
      </ListingTypeWrap>
    </div>
  );
};

const AbsoluteWrap = styled.div`
  position: absolute;
  top: -9px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
`;

const MostPopular = styled.span`
  background-color: ${(p) => p.theme.color.primary};
  padding: 2px ${(p) => p.theme.spacing.m};
  color: #fff;
  font-size: ${(p) => p.theme.typography.size.xxxs};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
`;

const ListingTypeWrap = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.l};
  cursor: pointer;
  ${(p) =>
    p.selected
      ? css`
          border-color: ${p.theme.color.primary};
          box-shadow: inset 0 0 0 1px ${p.theme.color.primary};
        `
      : null}
`;

const ListingTypeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${(p) => p.theme.spacing.m};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledDateInput = styled(DateInput)<{
  inputType?: "dates" | "visible-from";
}>`
  ${(p) =>
    p.inputType === "dates"
      ? css`
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `
      : css`
          width: 92px;
          padding-left: 42px !important;
        `}
`;

const DateLine = styled.div`
  width: 36px;
  margin: 0 ${(p) => p.theme.spacing.m};
  height: 1px;
  background-color: ${(p) => p.theme.color.typography.secondary}50;
`;

const DateLabel = styled.div`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.xl};
  background-color: ${(p) => p.theme.color.card.callout};
  border-top-left-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  border-bottom-left-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.typography.size.m};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.secondary};
  width: ${(p) => p.theme.spacing.xl};
`;

const DatesWrap = styled(Flex)`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    gap: ${(p) => p.theme.spacing.m};
    align-items: flex-start;
    margin-top: ${(p) => p.theme.spacing.s};

    .date-line {
      display: none;
    }
  }
`;
