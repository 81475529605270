import * as React from "react";
import { useTheme } from "../../hooks/useTheme";

export const ListingEmptyStateIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="160"
      height="158"
      viewBox="0 0 160 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2083_977)">
        <path
          d="M101.575 35.8819H58.4615C57.4791 35.8831 56.5372 36.2753 55.8425 36.9725C55.1478 37.6697 54.757 38.6149 54.7558 39.6008V135.76L54.2617 135.912L43.6853 139.162C43.1841 139.315 42.6427 139.263 42.18 139.016C41.7173 138.769 41.3711 138.348 41.2173 137.845L9.75763 34.7191C9.60453 34.2161 9.65671 33.6726 9.9027 33.2082C10.1487 32.7438 10.5684 32.3963 11.0695 32.2423L27.3676 27.2341L74.6163 12.7203L90.9143 7.71214C91.1623 7.63554 91.423 7.60877 91.6813 7.63337C91.9397 7.65797 92.1907 7.73345 92.4199 7.8555C92.6491 7.97755 92.8522 8.14377 93.0173 8.34465C93.1825 8.54553 93.3065 8.77712 93.3824 9.02617L101.424 35.386L101.575 35.8819Z"
          fill={theme.color.card.background}
        />
        <path
          d="M110.983 35.3865L101.291 3.61678C101.13 3.08744 100.866 2.59517 100.515 2.1681C100.164 1.74104 99.733 1.38755 99.2459 1.12783C98.7588 0.868115 98.2255 0.707259 97.6765 0.654463C97.1274 0.601667 96.5734 0.657964 96.046 0.820133L73.1319 7.85886L25.8858 22.3751L2.97168 29.4163C1.90721 29.7444 1.01592 30.4828 0.493435 31.4697C-0.0290461 32.4565 -0.140028 33.611 0.184853 34.6799L33.3096 143.256C33.5735 144.119 34.1061 144.874 34.829 145.411C35.552 145.947 36.4274 146.237 37.3268 146.238C37.743 146.239 38.1569 146.176 38.5545 146.052L54.2621 141.228L54.7562 141.074V140.556L54.2621 140.707L38.4088 145.579C37.4692 145.866 36.4544 145.768 35.5869 145.306C34.7195 144.843 34.0702 144.054 33.7815 143.112L0.659295 34.5336C0.516274 34.0668 0.466427 33.5761 0.512607 33.0899C0.558788 32.6037 0.700084 32.1314 0.928415 31.7C1.15675 31.2687 1.46762 30.8868 1.84324 30.5761C2.21886 30.2655 2.65184 30.0323 3.11739 29.8899L26.0315 22.8487L73.2778 8.33489L96.1919 1.29368C96.545 1.18554 96.9121 1.13039 97.2813 1.13005C98.0736 1.13183 98.8446 1.38818 99.4812 1.86155C100.118 2.33491 100.587 3.00042 100.819 3.76058L110.466 35.3865L110.62 35.8824H111.134L110.983 35.3865Z"
          fill={theme.color.typography.text}
        />
        <path
          d="M30.3084 32.3235C29.8322 32.3231 29.3687 32.1697 28.9858 31.8856C28.6029 31.6015 28.3209 31.2017 28.181 30.745L24.9988 20.3141C24.9133 20.0339 24.8836 19.7396 24.9115 19.4479C24.9394 19.1562 25.0242 18.8729 25.1612 18.6141C25.2982 18.3553 25.4847 18.1261 25.7099 17.9397C25.9352 17.7532 26.1948 17.6131 26.474 17.5274L69.9402 4.17294C70.504 4.00026 71.1131 4.05915 71.6338 4.33668C72.1544 4.61421 72.5441 5.08771 72.7172 5.65325L75.8993 16.0842C76.0713 16.6501 76.0125 17.2613 75.736 17.7838C75.4595 18.3062 74.9877 18.6973 74.4243 18.8711L30.958 32.2255C30.7475 32.2904 30.5286 32.3234 30.3084 32.3235Z"
          fill={theme.color.primary}
        />
        <path
          d="M46.9777 11.7785C49.7065 11.7785 51.9187 9.55847 51.9187 6.81992C51.9187 4.08137 49.7065 1.86133 46.9777 1.86133C44.2488 1.86133 42.0366 4.08137 42.0366 6.81992C42.0366 9.55847 44.2488 11.7785 46.9777 11.7785Z"
          fill={theme.color.primary}
        />
        <path
          d="M46.9779 9.95956C48.7059 9.95956 50.1068 8.55376 50.1068 6.81962C50.1068 5.08548 48.7059 3.67969 46.9779 3.67969C45.2499 3.67969 43.8491 5.08548 43.8491 6.81962C43.8491 8.55376 45.2499 9.95956 46.9779 9.95956Z"
          fill={theme.color.card.background}
        />
        <path
          d="M148.883 144.971H65.3792C64.8225 144.97 64.2887 144.748 63.8951 144.353C63.5014 143.958 63.2799 143.422 63.2793 142.864V42.4521C63.2799 41.8934 63.5014 41.3577 63.895 40.9627C64.2887 40.5676 64.8225 40.3454 65.3792 40.3447H148.883C149.44 40.3454 149.973 40.5676 150.367 40.9627C150.761 41.3578 150.982 41.8934 150.983 42.4521V142.864C150.982 143.422 150.761 143.958 150.367 144.353C149.973 144.748 149.44 144.97 148.883 144.971Z"
          fill={theme.color.card.background}
        />
        <path
          d="M110.466 35.3862H58.4616C57.3482 35.3878 56.2809 35.8324 55.4936 36.6225C54.7063 37.4126 54.2633 38.4837 54.2617 39.601V140.707L54.7558 140.555V39.601C54.757 38.6151 55.1478 37.6698 55.8425 36.9727C56.5373 36.2755 57.4791 35.8833 58.4616 35.8821H110.619L110.466 35.3862ZM155.8 35.3862H58.4616C57.3482 35.3878 56.2809 35.8324 55.4936 36.6225C54.7063 37.4126 54.2633 38.4837 54.2617 39.601V153.153C54.2633 154.27 54.7063 155.341 55.4936 156.131C56.2809 156.921 57.3482 157.366 58.4616 157.368H155.8C156.914 157.366 157.981 156.921 158.768 156.131C159.556 155.341 159.999 154.27 160 153.153V39.601C159.999 38.4837 159.556 37.4126 158.768 36.6225C157.981 35.8324 156.914 35.3878 155.8 35.3862ZM159.506 153.153C159.505 154.139 159.114 155.084 158.419 155.781C157.725 156.478 156.783 156.871 155.8 156.872H58.4616C57.4791 156.871 56.5373 156.478 55.8425 155.781C55.1478 155.084 54.757 154.139 54.7558 153.153V39.601C54.757 38.6151 55.1478 37.6698 55.8425 36.9727C56.5373 36.2755 57.4791 35.8833 58.4616 35.8821H155.8C156.783 35.8833 157.725 36.2755 158.419 36.9727C159.114 37.6698 159.505 38.6151 159.506 39.601V153.153Z"
          fill={theme.color.typography.text}
        />
        <path
          d="M129.86 46.295H84.4022C83.8127 46.2943 83.2475 46.059 82.8307 45.6407C82.4138 45.2224 82.1794 44.6552 82.1787 44.0636V33.1547C82.1794 32.5631 82.4139 31.9959 82.8307 31.5776C83.2475 31.1593 83.8127 30.924 84.4022 30.9233H129.86C130.449 30.924 131.014 31.1593 131.431 31.5776C131.848 31.9959 132.083 32.5631 132.083 33.1547V44.0636C132.083 44.6552 131.848 45.2224 131.431 45.6407C131.014 46.059 130.449 46.2943 129.86 46.295Z"
          fill={theme.color.primary}
        />
        <path
          d="M107.131 31.6672C109.86 31.6672 112.072 29.4471 112.072 26.7086C112.072 23.97 109.86 21.75 107.131 21.75C104.402 21.75 102.19 23.97 102.19 26.7086C102.19 29.4471 104.402 31.6672 107.131 31.6672Z"
          fill={theme.color.primary}
        />
        <path
          d="M107.131 29.729C108.793 29.729 110.14 28.3768 110.14 26.7087C110.14 25.0407 108.793 23.6885 107.131 23.6885C105.469 23.6885 104.121 25.0407 104.121 26.7087C104.121 28.3768 105.469 29.729 107.131 29.729Z"
          fill={theme.color.card.background}
        />
      </g>
      <defs>
        <clipPath id="clip0_2083_977">
          <rect
            width="160"
            height="156.735"
            fill={theme.color.card.background}
            transform="translate(0 0.632812)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
