import * as React from "react";
import styled from "../../../styles";
import theme from "../../../styles/theme";

type colors = typeof theme.color.typography;

const ReelIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.2 6.00001L2.99995 11L2.09995 8.60001C1.79995 7.50001 2.39995 6.40001 3.39995 6.10001L16.9 2.10001C18 1.80001 19.1 2.40001 19.4 3.40001L20.2 6.00001Z"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.19995 5.29999L9.29995 9.19999"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3999 3.40002L15.4999 7.40002"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 11H21V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V11Z"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const ReelIcon = styled(ReelIconSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
