import { H1 } from "../../components/Heading";
import { Text } from "../../components/Text";
import { styled } from "../../styles";

const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 500px;
  max-width: 100%;
  align-self: center;
  text-align: center;
  margin-top: 50px;
`;

export const BookingResponseConfirmed = () => {
  return (
    <Wrap>
      <H1 margin="xxl 0 0">All done!</H1>
      <Text>Thanks, we'll let them know</Text>
    </Wrap>
  );
};
