import { useHistory, useRouteMatch } from "react-router-dom";
import { BookingTabs } from ".";
import { FakeBackLink } from "../../../components/BackLink";
import { BookingDetails } from "../../../components/Brand/BookingDetails";
import { Chevron } from "../../../components/Chevron";
import { View } from "../../../components/View";
import styled from "../../../styles";
import { ArchivedBookingDetails } from "./Archived";
import { CompletedBookingDetails } from "./Completed";

export interface MatchParams {
  page: BookingTabs;
  id: string | undefined;
}

const Wrap = styled.div`
  background-color: ${(p) => p.theme.color.card.background};
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
`;

const BookingDetailsView = () => {
  let idMatch = useRouteMatch<MatchParams>("/b/bookings/:page/:id");
  let history = useHistory();

  if (!idMatch) {
    return <View>Invalid URL</View>;
  }

  const { id, page } = idMatch.params;

  return (
    <Wrap>
      <FakeBackLink onClick={() => history.goBack()} margin="l l s">
        <Chevron direction="left" /> Back to bookings
      </FakeBackLink>
      {page === BookingTabs.Archived ? (
        <View margin="l">
          <ArchivedBookingDetails bookingId={id ? id : null} />
        </View>
      ) : page === BookingTabs.Completed ? (
        <View margin="l">
          <CompletedBookingDetails bookingId={id ? id : null} />
        </View>
      ) : (
        <BookingDetails bookingId={id ? id : null} />
      )}
    </Wrap>
  );
};

export default BookingDetailsView;
