import { forwardRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BookingPostMediaType, BookingPostType } from "../graphql/generated";
import { css } from "../styles";
import { renderBookingPostTypeTag } from "../utils/enums";
import { Text } from "./Text";
import { View } from "./View";

interface Props {
  name?: string;
  username: string;
  mediaUrl: string;
  thumbnailUrl?: string;
  caption: string;
  metricValue?: number;
  mediaType: BookingPostMediaType;
  postType: BookingPostType;
  id: string;
  square?: boolean;
  maxWidth?: number;
  showCreator?: boolean;
  exampleData?: boolean;
}

const Wrap = styled.div<{ postType: BookingPostType }>`
  width: 100%;
  position: relative;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.008);
  }
`;

const Image = styled.img<{ square?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};

  ${(p) =>
    p.square
      ? css`
          aspect-ratio: 1 / 1;
        `
      : css`
          aspect-ratio: 9 / 14;
        `};
`;

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: -8px;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 59.5%,
    rgba(35, 50, 73, 0.7) 100%
  );
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  font-weight: 500;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-sizing: border-box;
`;

const Video = styled.video<{ showCreator?: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-shadow: ${(p) => p.theme.shadow.card};
  aspect-ratio: 9 / 14;
`;

const TagWrap = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.s};

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 25%
  );
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  background: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  &:hover {
    transform: scale(1.005);
  }
`;

export const BookingPost = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const imageURL = props.thumbnailUrl ? props.thumbnailUrl : props.mediaUrl;
  const path = new URL(imageURL).pathname;
  const maxWidth = props.maxWidth ? props.maxWidth : 300;
  const newURL = `https://ik.imagekit.io/fxuomw4xy/${path}?tr=w-${maxWidth}`;

  return (
    <div ref={ref} style={{ margin: "0 2px" }}>
      <StyledLink
        to={"/b/reports/" + props.id}
        style={props.exampleData ? { pointerEvents: "none" } : undefined}
      >
        {props.mediaType === BookingPostMediaType.BookingPostMediaTypeVideo &&
        !props.thumbnailUrl ? (
          <Wrap postType={props.postType}>
            <Video
              src={props.mediaUrl}
              poster={props.thumbnailUrl}
              showCreator={props.showCreator}
              muted
              loop
            />
            <TagWrap>{renderBookingPostTypeTag(props.postType)}</TagWrap>
            {props.metricValue ? (
              <Overlay>
                {props.metricValue > 0 && props.metricValue.toLocaleString()}
              </Overlay>
            ) : null}
          </Wrap>
        ) : (
          <Wrap postType={props.postType}>
            <Image
              square={
                props.square ||
                props.postType === BookingPostType.BookingPostTypeFeed
              }
              src={newURL}
            />
            <TagWrap>{renderBookingPostTypeTag(props.postType)}</TagWrap>
            {props.metricValue ? (
              <Overlay>
                {props.metricValue > 0 && props.metricValue.toLocaleString()}
              </Overlay>
            ) : null}
          </Wrap>
        )}
        {props.showCreator ? (
          <View padding="xs 0 s">
            <Text weight="bold" size="s" margin="0" isCompact>
              {props.name}
            </Text>
            <Text size="s" margin="0" colorPreset="secondary">
              @{props.username}
            </Text>
          </View>
        ) : null}
      </StyledLink>
    </div>
  );
});
