import * as React from "react";
import styled from "../../styles";

const OutlookIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="41"
    height="36"
    viewBox="0 0 41 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.2553 17.5451L33.4453 13.5781V25.1721H41.0003V18.8411C41.0003 18.3071 40.7163 17.8141 40.2553 17.5451Z"
      fill="#103262"
    />
    <path d="M10 4H20V13H10V4Z" fill="#0084D7" />
    <path d="M20 4H30V13H20V4Z" fill="#33AFEC" />
    <path d="M30 4H40V13H30V4Z" fill="#54DAFF" />
    <path d="M20 13H30V22H20V13Z" fill="#027AD4" />
    <path d="M20 22H30V31H20V22Z" fill="#0553A4" />
    <path d="M30 13H40V22H30V13Z" fill="#25A2E5" />
    <path d="M30 22H40V31H30V22Z" fill="#0262B8" />
    <path
      opacity="0.019"
      d="M10 30.9964H40V18.2344L25.99 26.2344L10 18.2344V30.9964Z"
      fill="black"
    />
    <path
      opacity="0.038"
      d="M10 31.0006H40V18.4766L25.99 26.4766L10 18.4766V31.0006Z"
      fill="black"
    />
    <path
      opacity="0.057"
      d="M10 30.9969H40V18.7109L25.99 26.7109L10 18.7109V30.9969Z"
      fill="black"
    />
    <path
      opacity="0.076"
      d="M10 31.0011H40V18.9531L25.99 26.9531L10 18.9531V31.0011Z"
      fill="black"
    />
    <path
      opacity="0.095"
      d="M10 30.9975H40V19.1875L25.99 27.1875L10 19.1875V30.9975Z"
      fill="black"
    />
    <path
      opacity="0.114"
      d="M10 31.0007H40V19.4297L25.99 27.4297L10 19.4297V31.0007Z"
      fill="black"
    />
    <path
      opacity="0.133"
      d="M10 30.9971H40V19.6641L25.99 27.6641L10 19.6641V30.9971Z"
      fill="black"
    />
    <path
      opacity="0.152"
      d="M10 31.0012H40V19.9062L25.99 27.9062L10 19.9062V31.0012Z"
      fill="black"
    />
    <path
      opacity="0.171"
      d="M10 30.9976H40V20.1406L25.99 28.1406L10 20.1406V30.9976Z"
      fill="black"
    />
    <path
      opacity="0.191"
      d="M10 31.0018H40V20.3828L25.99 28.3828L10 20.3828V31.0018Z"
      fill="black"
    />
    <path
      opacity="0.209"
      d="M10 30.9982H40V20.6172L25.99 28.6172L10 20.6172V30.9982Z"
      fill="black"
    />
    <path
      opacity="0.229"
      d="M10 31.0024H40V20.8594L25.99 28.8594L10 20.8594V31.0024Z"
      fill="black"
    />
    <path
      opacity="0.248"
      d="M10 30.9988H40V21.0938L25.99 29.0938L10 21.0938V30.9988Z"
      fill="black"
    />
    <path
      opacity="0.267"
      d="M10 31.0029H40V21.3359L25.99 29.3359L10 21.3359V31.0029Z"
      fill="black"
    />
    <path
      opacity="0.286"
      d="M10 30.9993H40V21.5703L25.99 29.5703L10 21.5703V30.9993Z"
      fill="black"
    />
    <path
      opacity="0.305"
      d="M10 31.0025H40V21.8125L25.99 29.8125L10 21.8125V31.0025Z"
      fill="black"
    />
    <path
      opacity="0.324"
      d="M10 30.9989H40V22.0469L25.99 30.0469L10 22.0469V30.9989Z"
      fill="black"
    />
    <path
      opacity="0.343"
      d="M10 31.0031H40V22.2891L25.99 30.2891L10 22.2891V31.0031Z"
      fill="black"
    />
    <path
      opacity="0.362"
      d="M10 30.9994H40V22.5234L25.99 30.5234L10 22.5234V30.9994Z"
      fill="black"
    />
    <path
      opacity="0.381"
      d="M10 31.0036H40V22.7656L25.99 30.7656L10 22.7656V31.0036Z"
      fill="black"
    />
    <path opacity="0.4" d="M10 31H40V23L25.99 31L10 23V31Z" fill="black" />
    <path
      d="M12.4392 36.0007H39.0022C40.1062 36.0007 41.0012 35.1118 41.0022 34.0068C41.0052 29.4858 40.9982 18.8438 40.9982 18.8438C40.9982 18.8438 40.9912 19.0657 39.2472 20.0557C37.5032 21.0457 11.7422 35.5668 11.7422 35.5668C11.7422 35.5668 11.9762 36.0007 12.4392 36.0007Z"
      fill="url(#paint0_linear_8792_63)"
    />
    <path
      d="M40 4H10V2C10 0.895 10.895 0 12 0H38C39.105 0 40 0.895 40 2V4Z"
      fill="url(#paint1_linear_8792_63)"
    />
    <path
      d="M10 19V34C10 35.105 10.895 36 12 36H27H39.004C39.466 36 39.887 35.838 40.225 35.575L10 19Z"
      fill="url(#paint2_linear_8792_63)"
    />
    <path
      opacity="0.05"
      d="M18.319 7H10V31H18.319C20.352 31 22 29.352 22 27.319V10.681C22 8.648 20.352 7 18.319 7Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M18.213 30.0029H10V7.33594H18.213C19.937 7.33594 21.334 8.73294 21.334 10.4569V26.8819C21.333 28.6059 19.936 30.0029 18.213 30.0029Z"
      fill="black"
    />
    <path
      opacity="0.09"
      d="M18.106 28.9971H10V7.66406H18.106C19.52 7.66406 20.666 8.81006 20.666 10.2241V26.4371C20.667 27.8511 19.52 28.9971 18.106 28.9971Z"
      fill="black"
    />
    <path
      d="M18 28H2C0.895 28 0 27.105 0 26V10C0 8.895 0.895 8 2 8H18C19.105 8 20 8.895 20 10V26C20 27.105 19.105 28 18 28Z"
      fill="url(#paint3_linear_8792_63)"
    />
    <path
      d="M10.0006 12.6875C6.88962 12.6875 5.01562 15.0645 5.01562 17.9965C5.01562 20.9285 6.88262 23.3055 10.0006 23.3055C13.1196 23.3055 14.9856 20.9285 14.9856 17.9975C14.9856 15.0645 13.1116 12.6875 10.0006 12.6875ZM10.0006 21.5135C8.23562 21.5135 7.18062 19.9395 7.18062 17.9975C7.18062 16.0555 8.24062 14.4815 10.0006 14.4815C11.7606 14.4815 12.8216 16.0565 12.8216 17.9975C12.8216 19.9385 11.7646 21.5135 10.0006 21.5135Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8792_63"
        x1="35.9232"
        y1="18.5578"
        x2="29.2842"
        y2="30.0248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#31ABEC" />
        <stop offset="1" stop-color="#1582D5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8792_63"
        x1="10.665"
        y1="0.992"
        x2="38.285"
        y2="3.074"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.042" stop-color="#076DB4" />
        <stop offset="0.85" stop-color="#0461AF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8792_63"
        x1="25.153"
        y1="27.218"
        x2="20.638"
        y2="35.1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#33ACEE" />
        <stop offset="1" stop-color="#1B8EDF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8792_63"
        x1="0.53"
        y1="8.53"
        x2="19.41"
        y2="27.41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1784D8" />
        <stop offset="1" stop-color="#0864C5" />
      </linearGradient>
    </defs>
  </svg>
);

interface Props {
  width?: number;
  height?: number;
}

export const OutlookIcon = styled(OutlookIconSVG)<Props>`
  width: ${(p) => p.width}px;
  min-width: ${(p) => p.width}px;
  top: 6px;
`;
