import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const InfoIconSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99967 14.1668C11.6816 14.1668 14.6663 11.1821 14.6663 7.50016C14.6663 3.81826 11.6816 0.833496 7.99967 0.833496C4.31778 0.833496 1.33301 3.81826 1.33301 7.50016C1.33301 11.1821 4.31778 14.1668 7.99967 14.1668Z"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 10.1667V7.5"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 4.8335H8.00667"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const InfoIcon = styled(InfoIconSvg)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.color
        ? p.color
        : "#41D975"};
  }
`;
