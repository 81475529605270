import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BillingStatus, LoginMutation } from "../../graphql/generated";
import { StateError } from "../error";

export type AuthState = {
  isLoggedIn: boolean;
  isLoading: boolean;
  accessToken?: string;
  refreshToken?: string;
  fb: fb.StatusResponse | null;
  fbSdkStatus: "loading" | "loaded";
  error: StateError;
  activeCreatorId: string | null;
  activeBrandId: string | null;
  account?: LoginMutation["authenticate"]["account"];
  brandBillingStatus?: BillingStatus;
};

const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: false,
  error: null,
  fb: null,
  activeCreatorId: null,
  activeBrandId: null,
  fbSdkStatus: "loading",
  account: undefined,
};

export const { actions, reducer } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setBrandBillingStatus: (state, action: PayloadAction<BillingStatus>) => {
      state.brandBillingStatus = action.payload;
    },
    setFBLoginStatus: (state, action: PayloadAction<fb.StatusResponse>) => {
      state.fb = action.payload;
      state.isLoading = false;
    },
    fbLoginStart: (state) => {
      state.isLoading = true;
    },
    fbLoginSuccess: (state, action: PayloadAction<fb.StatusResponse>) => {
      state.fb = action.payload;
    },
    fbLoginFailure: (state) => {
      state.error = { code: "FACEBOOK_FAILED" };
      state.isLoading = false;
    },
    setFbSdkStatus: (
      state,
      action: PayloadAction<{ status: "loading" | "loaded" }>
    ) => {
      state.fbSdkStatus = action.payload.status;
    },
    loginSuccess: (
      state,
      action: PayloadAction<{
        mutation: LoginMutation;
        type: "brand" | "creator";
      }>
    ) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.accessToken =
        action.payload.mutation.authenticate.tokens.accessToken;
      state.refreshToken =
        action.payload.mutation.authenticate.tokens.refreshToken;
      state.account = action.payload.mutation.authenticate.account;

      if (
        action.payload.type === "creator" &&
        action.payload.mutation.authenticate.account.creators
      ) {
        const defaultCreator =
          action.payload.mutation.authenticate.account.creators[0];
        if (defaultCreator) {
          state.activeCreatorId = defaultCreator.id;
          return;
        }
      }
      if (
        action.payload.type === "brand" &&
        action.payload.mutation.authenticate.account.brands
      ) {
        const defaultBrand =
          action.payload.mutation.authenticate.account.brands[0];
        if (defaultBrand) {
          state.activeBrandId = defaultBrand.id;
          return;
        }
      }
    },
    loginFailure: (state) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.accessToken = undefined;
      state.refreshToken = undefined;
    },
    logout: (state) => initialState,
    setActiveCreator: (state, action: PayloadAction<{ creatorId: string }>) => {
      state.activeCreatorId = action.payload.creatorId;
      state.activeBrandId = "";
    },
    setActiveBrand: (state, action: PayloadAction<{ brandId: string }>) => {
      state.activeBrandId = action.payload.brandId;
      state.activeCreatorId = "";
    },
    setEmailVerified: (
      state,
      action: PayloadAction<{ emailVerified: number }>
    ) => {
      if (state.account) {
        state.account.emailVerifiedAt = action.payload.emailVerified;
      }
    },
    refreshAccount: (
      state,
      action: PayloadAction<LoginMutation["authenticate"]["account"]>
    ) => {
      state.account = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<{ token: string }>) => {
      state.accessToken = action.payload.token;
    },
  },
});
