import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../components/CTA";
import { Flex } from "../../../../components/Flex";
import { InstagramIcon } from "../../../../components/icons/InstagramIcon";
import { TikTokIcon } from "../../../../components/icons/TikTokIcon";
import { Input } from "../../../../components/Input";
import { Text } from "../../../../components/Text";
import { View } from "../../../../components/View";
import {
  Platform,
  useGetBrandHandlesQuery,
} from "../../../../graphql/generated";
import useGqlClient from "../../../../hooks/useGqlClient";
import { authSelectors } from "../../../../store/auth/selector";
import styled from "../../../../styles";

interface Props {
  tags: string[];
  handles: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  setHandles: React.Dispatch<React.SetStateAction<string[]>>;
  platform: Platform;
}

export const Tags = ({
  tags,
  handles,
  setTags,
  setHandles,
  platform,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");
  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const { data: brandHandles } = useGetBrandHandlesQuery(client, {
    id: activeBrandId ?? "",
  });
  const [useLocationTags, setUseLocationTags] = useState(false);

  const instagramHandle = brandHandles?.brand?.instagramHandle;
  const tiktokHandle = brandHandles?.brand?.tikTokHandle;

  const handleAdd = (newItem: string) => {
    let trimmedItem = newItem.trim();

    trimmedItem = trimmedItem.startsWith("@")
      ? trimmedItem.slice(1)
      : trimmedItem;

    if (trimmedItem.length === 0) {
      setInputError("Please enter a handle");
      return;
    }

    if (platform === Platform.Instagram && trimmedItem === instagramHandle) {
      setInputError("Handle already applied");
      return;
    }

    if (platform === Platform.Tiktok && trimmedItem === tiktokHandle) {
      setInputError("Handle already applied");
      return;
    }

    if (handles.includes(trimmedItem)) {
      setInputError("Handle already applied");
      return;
    }

    if (trimmedItem.startsWith("@")) {
      setHandles([...handles, trimmedItem.slice(1)]);
    } else {
      setHandles([...handles, trimmedItem]);
    }
    setInputValue("");
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      handleAdd(inputValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputError("");
    setInputValue(e.target.value);
  };

  const removeHandle = (index: number) => {
    const newHandles = [...handles];
    newHandles.splice(index, 1);
    setHandles(newHandles);
  };

  const readOnlyHandle =
    platform === "INSTAGRAM" ? instagramHandle : tiktokHandle;

  return (
    <>
      <Text weight="bold" margin="0 0 0">
        Handles
      </Text>
      <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
        Let the influencer know who to tag in their content
      </Text>
      <Flex direction="row">
        <div style={{ flex: 1 }}>
          <Input
            prefix="@"
            inputSize="s"
            placeholder="Enter a handle and press Enter (or click Add)"
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleInputKeyDown}
            error={inputError}
          />
        </div>
        <View margin="0 0 0 s">
          <Button
            isDisabled={inputValue.trim() === ""}
            size="s"
            onClick={() => handleAdd(inputValue)}
          >
            Add
          </Button>
        </View>
      </Flex>
      <Flex direction="column" margin="m 0 0">
        <Flex direction="row" justify="space-between" align="flex-start">
          <Flex wrap="wrap" margin="0 0 s">
            {readOnlyHandle && (
              <TagItem isTag={false}>
                {platform === "INSTAGRAM" ? (
                  <InstagramIcon width={18} height={18} />
                ) : (
                  <TikTokIcon width={18} height={18} />
                )}
                <Text weight="semi" margin="0 m 0 xs" size="s">
                  @{readOnlyHandle}
                </Text>
              </TagItem>
            )}
            {handles.map((handle, index) => (
              <TagItem key={index} isTag={false}>
                {platform === "INSTAGRAM" ? (
                  <InstagramIcon width={18} height={18} />
                ) : (
                  <TikTokIcon width={18} height={18} />
                )}
                <Text weight="semi" margin="0 m 0 xs" size="s">
                  @{handle}
                </Text>
                <RemoveButton onClick={() => removeHandle(index)}>
                  ×
                </RemoveButton>
              </TagItem>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const TagItem = styled.span<{ isTag: boolean }>`
  background-color: ${(p) =>
    p.isTag ? p.theme.color.card.background : p.theme.color.card.callout};
  border: 1px solid
    ${(p) => (p.isTag ? p.theme.color.button.secondaryBorder : "transparent")};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  margin: 0 ${(p) => p.theme.spacing.s} 0 0;
  display: inline-flex;
  align-items: center;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: ${(p) => p.theme.color.typography.secondary};
  cursor: pointer;
  font-size: ${(p) => p.theme.typography.size.m};
  padding: 0;

  &:hover {
    color: ${(p) => p.theme.color.typography.text};
  }
`;
