import React from "react";
import styled from "../styles";

const CancelIconSVG = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 16.5078C10.1146 16.5078 11.1641 16.2943 12.1484 15.8672C13.1328 15.4453 13.9974 14.8594 14.7422 14.1094C15.4922 13.3594 16.0807 12.4948 16.5078 11.5156C16.9349 10.5312 17.1484 9.47917 17.1484 8.35938C17.1484 7.24479 16.9349 6.19792 16.5078 5.21875C16.0807 4.23438 15.4922 3.36719 14.7422 2.61719C13.9974 1.86719 13.1302 1.28125 12.1406 0.859375C11.1562 0.432292 10.1068 0.21875 8.99219 0.21875C7.8776 0.21875 6.82812 0.432292 5.84375 0.859375C4.85938 1.28125 3.99219 1.86719 3.24219 2.61719C2.4974 3.36719 1.91146 4.23438 1.48438 5.21875C1.0625 6.19792 0.851562 7.24479 0.851562 8.35938C0.851562 9.47917 1.0625 10.5312 1.48438 11.5156C1.91146 12.4948 2.5 13.3594 3.25 14.1094C4 14.8594 4.86719 15.4453 5.85156 15.8672C6.83594 16.2943 7.88542 16.5078 9 16.5078ZM6.47656 11.6875C6.2526 11.6875 6.0625 11.6094 5.90625 11.4531C5.75521 11.2969 5.67969 11.1042 5.67969 10.875C5.67969 10.6615 5.75781 10.4766 5.91406 10.3203L7.86719 8.36719L5.91406 6.42188C5.75781 6.26562 5.67969 6.08073 5.67969 5.86719C5.67969 5.63802 5.75521 5.44792 5.90625 5.29688C6.0625 5.14062 6.2526 5.0625 6.47656 5.0625C6.70573 5.0625 6.90104 5.14062 7.0625 5.29688L9.00781 7.23438L10.9609 5.28906C11.1172 5.13281 11.3099 5.05469 11.5391 5.05469C11.763 5.05469 11.9505 5.13281 12.1016 5.28906C12.2578 5.4401 12.3359 5.63021 12.3359 5.85938C12.3359 6.07292 12.2578 6.26042 12.1016 6.42188L10.1484 8.36719L12.0938 10.3125C12.2552 10.4635 12.3359 10.651 12.3359 10.875C12.3359 11.1042 12.2578 11.2969 12.1016 11.4531C11.9453 11.6094 11.7526 11.6875 11.5234 11.6875C11.2891 11.6875 11.0964 11.6094 10.9453 11.4531L9.00781 9.51562L7.07812 11.4531C6.91667 11.6094 6.71615 11.6875 6.47656 11.6875Z"
        fill="#788AA5"
      />
    </svg>
  );
};

export const CancelIcon = styled(CancelIconSVG)`
  transition: fill 150ms;
  path {
    fill: ${(p) => p.theme.color.typography.secondary};
  }
  :hover {
    path {
      fill: ${(p) => p.theme.color.typography.text};
    }
  }
`;
