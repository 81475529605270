import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Card } from "../../../components/Card";
import { CTA } from "../../../components/CTA";
import { Flex } from "../../../components/Flex";
import { H1 } from "../../../components/Heading";
import QuestionMark from "../../../components/QuestionMark";
import { Text } from "../../../components/Text";
import { ExternalTextLink, TextLink } from "../../../components/TextLink";
import config from "../../../config";
import { AuthType, useLoginMutation } from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import { actions } from "../../../store/auth/slice";
import styled from "../../../styles";

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageWrap = styled.div`
  flex: 1;
  max-width: 340px;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useHash() {
  return new URLSearchParams(useLocation().hash.substring(1));
}

export const Login = (props: { mode: "login" | "signup" }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authData = useSelector(authSelectors.data);
  const { track } = useAnalytics();
  const theme = useTheme();
  const query = useQuery();
  const client = useGqlClient();
  const loginMutation = useLoginMutation(client, {});

  const hash = useHash();

  const [error, setError] = useState<null | string>(null);

  const stateHash = (+new Date().setHours(0, 0, 0, 0)).toString(36);

  useEffect(() => {
    const error = query.get("error");
    if (error) {
      setError("Failed to login with Facebook, please try again");

      Sentry.captureException(
        `${query.get("error")} - ${query.get("error_reason")} - ${query.get(
          "error_description"
        )}`
      );
      return;
    }

    const state = hash.get("state");
    if (state && state !== stateHash) {
      setError("Failed to login with Facebook, please try again");
      return;
    }

    const accessToken = hash.get("access_token");
    if (!accessToken) {
      return;
    }

    const code = query.get("c");

    loginMutation.mutate(
      {
        fbToken: accessToken,
        code,
        authType: AuthType.AuthTypeCreator,
      },
      {
        onSuccess: (data) => {
          dispatch(actions.loginSuccess({ mutation: data, type: "creator" }));
          track(data.authenticate.signup ? "Signup sucess" : "Login success");

          if (
            !data.authenticate.account.email ||
            data.authenticate.account.email === ""
          ) {
            history.push("/signup/i/confirm-details");
          } else {
            history.push("/i");
          }
        },
        onError: (error) => {
          Sentry.captureException(error);
          setError(
            "Oops, that didn't work. Make sure you've selected a page AND the Instagram business account"
          );
          track("Facebook login failure");
          dispatch(actions.loginFailure());
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash.toString(), query.toString()]);

  const fbLogin = () => {
    dispatch(actions.fbLoginStart());
    track("Login start");

    const uri = encodeURI(
      `https://www.facebook.com/v10.0/dialog/oauth?client_id=${config.facebookAppId}&redirect_uri=${window.location.origin}/i/signup&response_type=token&scope=pages_show_list,pages_read_engagement,instagram_basic,public_profile,instagram_manage_insights&state=${stateHash}`
    );

    window.location.href = uri;
  };

  const renderAuthError = (errorCode: string) => {
    switch (errorCode) {
      case "FACEBOOK_FAILED":
        return "Facebook login error";
      default:
        return "Please try again";
    }
  };

  return (
    <CenteredContainer>
      <PageWrap>
        <H1 margin="0 0 xl 0">
          {props.mode === "signup"
            ? "Let's get started"
            : "Nice to see you again"}
        </H1>
        {error && (
          <Card padding="m">
            <Flex>
              <Text margin="0 m 0 0">⚠️</Text>
              <Text margin="0">{error}</Text>
            </Flex>
          </Card>
        )}
        {authData.error && (
          <Card padding="m">
            <Flex>
              <Text margin="0 m 0 0">⚠️</Text>
              <Text margin="0">{renderAuthError(authData.error.code)}</Text>
            </Flex>
          </Card>
        )}
        {props.mode === "signup" ? (
          <>
            {/* <Text>
              We help influencers connect with brands they love. <br />
            </Text> */}
            <Text>A few bits you'll need:</Text>
            <Text>1️⃣ &nbsp; An Instagram business/creator account</Text>
            <Text>
              2️⃣ &nbsp; A Facebook page connected to your Instagram
              account&nbsp;
              {/* <br /> */}
              <ExternalTextLink href="http://answers.wenibble.com/en/articles/5250845-connect-a-facebook-page-to-your-business-account">
                <QuestionMark />
              </ExternalTextLink>
            </Text>
            <Text>3️⃣ &nbsp; An appetite! For exclusive offers 😋</Text>
          </>
        ) : (
          <Text>Jump back in to access your exclusive offers</Text>
        )}

        <CTA to="#" onClick={() => fbLogin()} margin="0 0 xl 0">
          {authData.isLoading || loginMutation.isLoading
            ? "Loading..."
            : "Continue with Facebook"}
        </CTA>

        {props.mode === "signup" ? (
          <Text size="s" color={theme.color.typography.secondary}>
            Already signed up? <TextLink to="/i/login">Login here</TextLink>
          </Text>
        ) : (
          <Text size="s" color={theme.color.typography.secondary}>
            New here? <TextLink to="/i/signup">Tap to sign up</TextLink>
          </Text>
        )}
      </PageWrap>
    </CenteredContainer>
  );
};
