import { Link } from "react-router-dom";
import styled from "../styles";

export const CardLink = styled(Link)`
  text-decoration: none;
  display: block;
`;

export const ExternalCardLink = styled.a`
  text-decoration: none;
  display: block;
`;
