import { useRef, useState } from "react";
import styled, { css } from "../../styles";
import { Button } from "../CTA";
import { Card } from "../Card";
import { CardDivider } from "../Divider";
import { Flex } from "../Flex";
import { Text } from "../Text";
import { UpgradePlan } from "../UpgradePlan";
import { View } from "../View";
import { UpgradeIcon } from "../icons/UpgradeIcon";
import { UpgradeIconOutline } from "../icons/UpgradeIconOutline";

export const UpgradePrompt = ({
  feature,
  type,
}: {
  feature: "reports" | "content" | "team" | "control" | "toggle" | "dmn";
  type?: "icon" | "text";
}) => {
  const [position, setPosition] = useState<"above" | "below">("below");
  const [showUpgrade, setShowUpgrade] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  let featureBenefit = "advanced reporting";

  switch (feature) {
    case "content":
      featureBenefit = "content gallery access";
      break;
    case "team":
      featureBenefit = "team management";
      break;
    case "control":
      featureBenefit = "more control over booking approvals";
      break;
    case "toggle":
      featureBenefit = "gift card integrations";
      break;
    case "dmn":
      featureBenefit = "booking integrations";
      break;
    default:
      break;
  }

  const handleMouseEnter = () => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const popoverHeight = 160;

    if (rect.top < popoverHeight) {
      setPosition("below");
    } else {
      setPosition("above");
    }
  };

  return (
    <>
      <UpgradeWrapper
        ref={ref}
        onMouseEnter={handleMouseEnter}
        align="center"
        justify="center"
      >
        {type === "icon" ? <UpgradeIcon /> : <UpgradeWrap>UPGRADE</UpgradeWrap>}
        <PopoverContent
          type={type}
          className="popover"
          position={position}
          onClick={(e) => e.stopPropagation()}
        >
          <StyledCard position={position}>
            <View padding="m">
              <Flex align="flex-start">
                <View style={{ marginTop: 2 }}>
                  <UpgradeIconOutline />
                </View>
                <div>
                  <Text margin="0 0 0 s" size="m" weight="bold">
                    Upgrade to unlock this feature
                  </Text>
                  <Text margin="0 0 0 s" size="s">
                    Get {featureBenefit} and more
                  </Text>
                </div>
              </Flex>
            </View>

            <CardDivider />
            <View padding="m">
              <Button
                buttonType="upgrade"
                size="xs"
                onClick={() => setShowUpgrade(true)}
              >
                Get feature
              </Button>
            </View>
          </StyledCard>
        </PopoverContent>
      </UpgradeWrapper>
      {showUpgrade ? (
        <UpgradePlan onCancel={() => setShowUpgrade(false)} />
      ) : null}
    </>
  );
};

const UpgradeWrapper = styled(Flex)`
  position: relative;
  cursor: pointer;

  &:hover {
    .popover {
      display: block;
    }
  }
`;

const PopoverContent = styled.div<{
  position: "above" | "below";
  type?: "icon" | "text";
}>`
  display: none;
  position: absolute;
  width: 280px;
  z-index: 999;
  padding: 10px 0;

  cursor: auto;

  ${(props) =>
    props.type === "icon"
      ? css`
          left: -132px;
        `
      : css`
          left: -110px;
        `}

  ${(props) =>
    props.position === "above"
      ? css`
          bottom: calc(100% - 10px);
        `
      : css`
          top: calc(100% - 10px);
        `}
`;

const StyledCard = styled(Card)<{ position: "above" | "below" }>`
  box-shadow: ${(p) => p.theme.shadow.popover};

  &:after {
    ${(props) =>
      props.position === "below"
        ? css`
            content: "";
            width: 12px;
            height: 12px;
            background: inherit;
            z-index: 1;
            top: 6px; // half height minus card margin top
            left: calc(50% - 6px);
            display: block;
            position: absolute;
            transform: rotateZ(45deg);
          `
        : css`
            content: "";
            width: 12px;
            height: 12px;
            background: inherit;
            z-index: 1;
            bottom: 6px;
            left: calc(50% - 6px);
            display: block;
            position: absolute;
            transform: rotateZ(45deg);
          `}
  }
`;

const UpgradeWrap = styled.div`
  border-radius: 999px;
  padding: 4px 8px 3px;
  border: 1px solid ${(p) => p.theme.color.primary};
  background: ${(p) => p.theme.color.input.hover};
  font-size: 10px;
  color: ${(p) => p.theme.color.primary};
  line-height: 1;
  letter-spacing: 0.5px;
`;
