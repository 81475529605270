import { getTime, subDays } from "date-fns";

function generateTimestamps(values: number[]) {
  const metrics = [];
  for (let index = 0; index < values.length; index++) {
    metrics.push([getTime(subDays(new Date(), index)), values[index]]);
  }

  return metrics;
}

const impressions = {
  recent: [14694, 35998, 44558, 9146, 20212, 12388, 7320, 8048, 19424, 0],
  trailing: [4123, 14021, 8721, 2219, 1932, 2341, 5921, 1728, 4411, 0],
};

const reach = {
  recent: [13794, 33880, 42210, 8706, 18040, 12107, 7119, 8019, 19220, 0],
  trailing: [3738, 12633, 8452, 2057, 1829, 2236, 5003, 1628, 4214, 0],
};

const engagements = {
  recent: [1069, 2616, 3906, 686, 1615, 1159, 647, 671, 1759, 0],
  trailing: [386, 1319, 794, 181, 63, 206, 526, 168, 431, 0],
};

export const exampleReportData = {
  recent: {
    impressions: {
      total: impressions.recent.reduce((a, b) => a + b, 0),
      data: generateTimestamps(impressions.recent),
    },
    reach: {
      total: reach.recent.reduce((a, b) => a + b, 0),
      data: generateTimestamps(reach.recent),
    },
    engagements: {
      total: engagements.recent.reduce((a, b) => a + b, 0),
      data: generateTimestamps(engagements.recent),
    },
  },
  trailing: {
    impressions: {
      total: impressions.trailing.reduce((a, b) => a + b, 0),
      data: generateTimestamps(impressions.trailing),
    },
    reach: {
      total: reach.trailing.reduce((a, b) => a + b, 0),
      data: generateTimestamps(reach.trailing),
    },
    engagements: {
      total: engagements.trailing.reduce((a, b) => a + b, 0),
      data: generateTimestamps(engagements.trailing),
    },
  },
};

export const exampleReportPosts = [
  {
    id: "bp_2MrhzOBQhA7VQEhHRwfJuE8Sphy",
    createdAt: "2023-03-11T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2CWemUrvfvud5zLobGF6rpz9oW8/18200693893217696.jpg",
    creator: {
      name: "Yen 🇵🇭 | London Foodie Guide",
      instagram: {
        name: "Yen 🇵🇭 | London Foodie Guide",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841418053850428.jpg",
        username: "yensfoodstagram",
      },
      tikTok: {
        name: "yen 🫶 london food guide",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/98b9ca62-72d7-4928-9cec-da4859f4a354/avatar.jpg",
        username: "yensfoodstagram",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2CWemUrvfvud5zLobGF6rpz9oW8/18200693893217696.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2GLocrMX1JbuqDDPMo2dxEoXTKk",
    createdAt: "2022-10-19T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2Cc6nYDgMIDiMOTTRpYYCploEyZ/18049941145368574.jpg",
    creator: {
      name: "Foodie Couple Blogger | London",
      instagram: {
        name: "Foodie Couple Blogger | London",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841424970395931.jpg",
        username: "yourdailytreats",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2Cc6nYDgMIDiMOTTRpYYCploEyZ/18049941145368574.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2ELYOxB6hEQZivRbcp0VXr2vkIt",
    createdAt: "2022-09-05T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2CWemUrvfvud5zLobGF6rpz9oW8/17899372514600089.jpg",
    creator: {
      name: "Yen 🇵🇭 | London Foodie Guide",
      instagram: {
        name: "Yen 🇵🇭 | London Foodie Guide",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841418053850428.jpg",
        username: "yensfoodstagram",
      },
      tikTok: {
        name: "yen 🫶 london food guide",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/98b9ca62-72d7-4928-9cec-da4859f4a354/avatar.jpg",
        username: "yensfoodstagram",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2CWemUrvfvud5zLobGF6rpz9oW8/17899372514600089.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2GjD31d1kgyOxIQ5wsDyHZpzdZp",
    createdAt: "2022-10-27T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_thumbnails/cre_2BtZRa8naBubnqOLtRa2sZPUTSq/7159136982298266886.jpg",
    creator: {
      name: "The Hungriest Baddie 🤪 Foodie",
      instagram: {
        name: "Honest Food Reviewer",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841453075752476.jpg",
        username: "the_hungrybaddie",
      },
      tikTok: {
        name: "The Hungriest Baddie 🤪 Foodie",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/78cbc19f-8ae7-479f-a382-a3c11bb00d06/avatar.jpg",
        username: "thehungrybaddie",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2BtZRa8naBubnqOLtRa2sZPUTSq/7159136982298266886.jpg",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_IMAGE",
    postType: "BOOKING_POST_TYPE_TIKTOK",
  },
  {
    id: "bp_2IrzYhWeyEk0JGP6h5hkq0pNYds",
    createdAt: "2022-12-13T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2IH6uoFIfFDrrGXWJmdWqjtncjj/17945020292307140.jpg",
    creator: {
      name: "LickyPlate",
      instagram: {
        name: "LickyPlate",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841404999124695.jpg",
        username: "lickyplate",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2IH6uoFIfFDrrGXWJmdWqjtncjj/17945020292307140.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2LvxYzPfxFbHBfbipdEwzAuaSfL",
    createdAt: "2023-02-19T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2G8jSmXfubqZYLbHCQVvTxiySTJ/7198455650937031941.jpg",
    creator: {
      name: "weoutside247reviews",
      instagram: {
        name: "Elisha | Reviews🌮🍹🌃",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841451234873203.jpg",
        username: "weoutside247reviews",
      },
      tikTok: {
        name: "weoutside247reviews",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/94941697-66fd-4f39-bcfa-d4cab49196ba/avatar.jpg",
        username: "weoutside247reviews",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2G8jSmXfubqZYLbHCQVvTxiySTJ/7198455650937031941.jpg",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_IMAGE",
    postType: "BOOKING_POST_TYPE_TIKTOK",
  },
  {
    id: "bp_2L0HHwSzMro5ejVjOqTYBD43mO6",
    createdAt: "2023-01-29T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_thumbnails/cre_2EAA4zbIvdAucoUhuY8xCcrk0Lc/7194060049650699526.jpg",
    creator: {
      name: "Alex 🍔 Big Boy Burger Club",
      instagram: {
        name: "Alex | Big Boy Burger Club",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841408597559498.jpg",
        username: "bigboyburgerclub",
      },
      tikTok: {
        name: "Alex 🍔 Big Boy Burger Club",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/6b105479-5781-457f-b8e8-a42f581858e3/avatar.jpg",
        username: "bigboyburgerclub",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2EAA4zbIvdAucoUhuY8xCcrk0Lc/7194060049650699526.jpg",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_IMAGE",
    postType: "BOOKING_POST_TYPE_TIKTOK",
  },
  {
    id: "bp_2I0D4SmKpQy9ONvlDpNvs7VD7ZB",
    createdAt: "2022-11-24T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_thumbnails/cre_2Gu8tNfGwtklIj0aX5MV5jsfvlx/7169623809098632453.jpg",
    creator: {
      name: "MsPKCooks",
      instagram: null,
      tikTok: {
        name: "MsPKCooks",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/5ffc535a-8d1d-4c5a-80fd-ee50a4b8db47/avatar.jpg",
        username: "mspkcooks",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2Gu8tNfGwtklIj0aX5MV5jsfvlx/7169623809098632453.jpg",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_IMAGE",
    postType: "BOOKING_POST_TYPE_TIKTOK",
  },
  {
    id: "bp_2HgfdrB6AFk66HpsbU9LnfS09s8",
    createdAt: "2022-11-17T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2GigTs2Apja1RPeSQV9i63s6sde/17964627437045080.jpg",
    creator: {
      name: "London Food Blogger",
      instagram: {
        name: "London Food Blogger",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841421409192641.jpg",
        username: "foodyfella_",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2GigTs2Apja1RPeSQV9i63s6sde/17964627437045080.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2PcWqQ0UyeWO4BaFtKDMK6Ma7Vs",
    createdAt: "2023-05-10T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2EHLJxd8qK3DR6pd7ozcI6zIWMg/17981222965945608.jpg",
    creator: {
      name: "Leyla 🇲🇾🇮🇷| London Foodie",
      instagram: {
        name: "Leyla 🇲🇾🇮🇷| London Foodie",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841417056483983.jpg",
        username: "lelfoodworld",
      },
      tikTok: {
        name: "Leyla | London Foodie",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/b9c9840d-3106-4820-ab40-9fbbad0d4a43/avatar.jpg",
        username: "lelfoodworld",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2EHLJxd8qK3DR6pd7ozcI6zIWMg/17981222965945608.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2G7DsmMEABZI0ACw4RzWbQH4dgz",
    createdAt: "2022-10-14T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2E7i046USFhzbQsDDoFWysVUcr5/17950996886283505.jpg",
    creator: {
      name: "SERENA 🇮🇹| LDN ULTIMATE FOODIE",
      instagram: {
        name: "SERENA 🇮🇹| LDN ULTIMATE FOODIE",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841436094296836.jpg",
        username: "londonultimateguide",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2E7i046USFhzbQsDDoFWysVUcr5/17950996886283505.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2O32WvQLzg7syTCpoSEcoo3RJ4O",
    createdAt: "2023-04-06T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2CWemUrvfvud5zLobGF6rpz9oW8/7218866588332641563.jpg",
    creator: {
      name: "yen 🫶 london food guide",
      instagram: {
        name: "Yen 🇵🇭 | London Foodie Guide",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841418053850428.jpg",
        username: "yensfoodstagram",
      },
      tikTok: {
        name: "yen 🫶 london food guide",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/98b9ca62-72d7-4928-9cec-da4859f4a354/avatar.jpg",
        username: "yensfoodstagram",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2CWemUrvfvud5zLobGF6rpz9oW8/7218866588332641563.jpg",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_IMAGE",
    postType: "BOOKING_POST_TYPE_TIKTOK",
  },
  {
    id: "bp_2LbiGjdpLjzzvEB6C7i8NeVQTQY",
    createdAt: "2023-02-11T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2C9ojSEA0bp5CvM6rAzZeNgcjjR/18000376927575053.jpg",
    creator: {
      name: "Diana | London Blogger 🦋",
      instagram: {
        name: "Diana | London Blogger 🦋",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841447923616062.jpg",
        username: "dianas.delicacies",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2C9ojSEA0bp5CvM6rAzZeNgcjjR/18000376927575053.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2I2Ykb9Zxyf8H7QPrXm8ow2I3n4",
    createdAt: "2022-11-25T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2ApvKkKHdhc7d3KnLHk5EdA69tI/17963651744093517.jpg",
    creator: {
      name: "Lauren 📸 London foodie",
      instagram: {
        name: "Lauren 📸 London foodie",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841408210559533.jpg",
        username: "lcb_loves",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2NdVeOY7eOOssNk30A3IZ4xizMJ/17963651744093517.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2OdiVIAWzrP6tNKxpGJGGn2TDdz",
    createdAt: "2023-04-19T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_28HWJK7lJxEZBAKaG42aTRdnZu6/17998510327696878.jpg",
    creator: {
      name: "Food, Coffee & Cocktails!",
      instagram: {
        name: "Food, Coffee & Cocktails!",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841432517640992.jpg",
        username: "foodie_lilly",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_28HWJK7lJxEZBAKaG42aTRdnZu6/17998510327696878.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2Hm5GGM31Yl1DPdyxG6JVoeW459",
    createdAt: "2022-11-19T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2Gqarzr0ZWSMb8IDkywXPnkXcEx/17974971319892586.jpg",
    creator: {
      name: "Brandon K🇨🇩| Food Blogger",
      instagram: {
        name: "Brandon K🇨🇩| Food Blogger",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841455679372941.jpg",
        username: "loadedmunchh",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2Gqarzr0ZWSMb8IDkywXPnkXcEx/17974971319892586.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_2F5Rj9ebAgM1neAebdQpybXJmKd",
    createdAt: "2022-09-21T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl: "",
    creator: {
      name: "Emily",
      instagram: {
        name: "Emily",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841447610438640.jpg",
        username: "em.eats.the.world",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2DAXFdajxE0rIl7s5vfkOhB6U06/17978473636659156.jpg",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_IMAGE",
    postType: "BOOKING_POST_TYPE_FEED",
  },
  {
    id: "bp_2NNJQMPQiL2TjbfWnnyxMt90S5o",
    createdAt: "2023-03-22T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2M1djEt90jryZ2PnOQFt72SPix0/7206331290465963269.jpg",
    creator: {
      name: "Mariya",
      instagram: {
        name: "MARIYA |London Blogger| 🌼👑🍃🌍💋",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841401905454152.jpg",
        username: "mariyaa1227",
      },
      tikTok: {
        name: "Mariya",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/9cc59dd7-5184-4228-a195-695f13dff614/avatar.jpg",
        username: "mariyaa1227",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktoks/cre_2M1djEt90jryZ2PnOQFt72SPix0/7206331290465963269.jpg",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_IMAGE",
    postType: "BOOKING_POST_TYPE_TIKTOK",
  },
  {
    id: "bp_2FlDdCwryAMTWU43SiQos0MMH46",
    createdAt: "2022-10-06T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2E7i046USFhzbQsDDoFWysVUcr5/17973311695769051.jpg",
    creator: {
      name: "SERENA 🇮🇹| LDN ULTIMATE FOODIE",
      instagram: {
        name: "SERENA 🇮🇹| LDN ULTIMATE FOODIE",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841436094296836.jpg",
        username: "londonultimateguide",
      },
      tikTok: null,
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2E7i046USFhzbQsDDoFWysVUcr5/17973311695769051.mp4",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_VIDEO",
    postType: "BOOKING_POST_TYPE_REEL",
  },
  {
    id: "bp_29fCFk11Eyl0OYtISR1xkB7mpIe",
    createdAt: "2022-05-25T00:00:00.00Z",
    engagement: 0,
    impressions: 0,
    reach: 0,
    likes: 0,
    comments: 0,
    shares: 0,
    saves: 0,
    thumbnailUrl: "",
    creator: {
      name: "Angelica",
      instagram: {
        name: "Angelica",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/profile_pictures/17841422333502724.jpg",
        username: "tryingmybestathealth",
      },
      tikTok: {
        name: "Angelica Barnes",
        avatar:
          "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/d924f751-d4d6-42e2-b455-1d8688b433eb/avatar.jpg",
        username: "angelicabarnes",
      },
    },
    mediaUrl:
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/stories/cre_290oPdI3Yfdi7jVWc3d2x988AxB/17914553198381536.jpg",
    mediaCaption: "",
    mediaType: "BOOKING_POST_MEDIA_TYPE_IMAGE",
    postType: "BOOKING_POST_TYPE_FEED",
  },
];
