import React from "react";
import { useSelector } from "react-redux";
import { H1 } from "../../components/Heading";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 500px;
  align-self: center;
  text-align: left;
`;

export interface MatchParams {
  page: string;
}

export const BrandPage = () => {
  const profile = useSelector(authSelectors.activeBrand);

  return (
    <Wrap>
      <H1 margin="xl 0">{profile ? profile.name : "No Brand"}</H1>
    </Wrap>
  );
};
