import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const LogoutSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.351562 3.05469V12.0547C0.351562 12.8984 0.567708 13.5365 1 13.9688C1.42708 14.401 2.05729 14.6172 2.89062 14.6172H10.5781C11.4115 14.6172 12.0417 14.401 12.4688 13.9688C12.901 13.5365 13.1172 12.8984 13.1172 12.0547V9.92969H11.5781V11.9531C11.5781 12.3125 11.4818 12.5885 11.2891 12.7812C11.1016 12.9792 10.8177 13.0781 10.4375 13.0781L3.02344 13.0781C2.64323 13.0781 2.35938 12.9792 2.17188 12.7813C1.97917 12.5885 1.88281 12.3125 1.88281 11.9531V3.14844C1.88281 2.78385 1.97917 2.50521 2.17188 2.3125C2.35938 2.125 2.64323 2.03125 3.02344 2.03125L10.4375 2.03125C10.8177 2.03125 11.1016 2.125 11.2891 2.3125C11.4818 2.50521 11.5781 2.78385 11.5781 3.14844V5.1875H13.1172V3.05469C13.1172 2.21094 12.901 1.57292 12.4688 1.14063C12.0417 0.708333 11.4115 0.492188 10.5781 0.492188L2.89062 0.492188C2.05208 0.492188 1.41927 0.708333 0.992188 1.14063C0.565104 1.57292 0.351562 2.21094 0.351562 3.05469ZM5.41406 7.55469C5.41406 7.64844 5.42969 7.73958 5.46094 7.82813C5.4974 7.91667 5.5625 8.00781 5.65625 8.10156L8.1875 10.7266C8.32292 10.862 8.47656 10.9297 8.64844 10.9297C8.83073 10.9297 8.97917 10.8646 9.09375 10.7344C9.20833 10.6094 9.26562 10.4583 9.26562 10.2812C9.26562 10.0885 9.19531 9.92708 9.05469 9.79688L7.89062 8.70312L7.32031 8.20312L8.48438 8.28125H16.5781C16.7708 8.28125 16.9375 8.20833 17.0781 8.0625C17.2188 7.92188 17.2891 7.7526 17.2891 7.55469C17.2891 7.35677 17.2188 7.1849 17.0781 7.03906C16.9375 6.89844 16.7708 6.82812 16.5781 6.82812H8.48438L7.32031 6.90625L7.89062 6.39844L9.05469 5.30469C9.19531 5.17969 9.26562 5.01823 9.26562 4.82031C9.26562 4.63802 9.20833 4.48438 9.09375 4.35938C8.97917 4.23958 8.83073 4.17969 8.64844 4.17969C8.47656 4.17969 8.32292 4.24479 8.1875 4.375L5.65625 7C5.5625 7.09896 5.4974 7.19271 5.46094 7.28125C5.42969 7.36979 5.41406 7.46094 5.41406 7.55469Z"
      fill="#788AA5"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const LogoutIcon = styled(LogoutSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
