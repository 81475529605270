import { endOfDay, getUnixTime } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { BookingPost } from "../../components/BookingPost";
import {
  FilterOption,
  ReportFilter,
  content,
} from "../../components/Brand/ReportFilter";
import { CTA } from "../../components/CTA";
import { DateRangePicker } from "../../components/DateRangePicker";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import Loading from "../../components/Loading";
import MasonryList from "../../components/MasonryList";
import { Text } from "../../components/Text";
import { UpgradePlan } from "../../components/UpgradePlan";
import { MOBILE_BREAKPOINT } from "../../config";
import {
  BillingPlanType,
  BookingPostMediaType,
  BookingPostType,
  BookingPostsSortBy,
  BrandContentDocument,
  BrandContentQuery,
  useBrandListingsQuery,
  useBrandLocationsQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";
import { exampleReportPosts } from "../../utils/exampleReportData";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing.xl};
  box-sizing: border-box;
  text-align: left;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

export interface MatchParams {
  page: string;
}

export enum DateFilterOptions {
  AllTime = "allTime",
  Last30Days = "last30Days",
  Last7Days = "last7Days",
  ThisMonth = "thisMonth",
  LastMonth = "lastMonth",
}

const HeaderWrap = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${(p) => p.theme.spacing.m};
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

export const FilterGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${(p) => p.theme.spacing.s};
`;

export const BrandContent = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const initialStartDate = useMemo(() => new Date(0), []);
  const initialEndDate = useMemo(() => endOfDay(new Date()), []);
  const handleDateChange = ([newStartDate, newEndDate]: [
    Date | null,
    Date | null
  ]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  const [listings, setListings] = useState<FilterOption[]>([]);
  const [locations, setLocations] = useState<FilterOption[]>([]);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedContentType, setSelectedContentType] = useState<
    BookingPostType[]
  >([]);

  const client = useGqlClient();
  const { data: listingsData, isLoading: listingLoading } =
    useBrandListingsQuery(
      client,
      { brandId: activeBrandId ? activeBrandId : "" },
      {
        enabled: activeBrandId !== null,
      }
    );

  const { data: locationsData, isLoading: locationsLoading } =
    useBrandLocationsQuery(
      client,
      { brandId: activeBrandId ? activeBrandId : "" },
      {
        enabled: activeBrandId !== null,
      }
    );

  const transformDataToFilterOptions = (data: any[], key: string) =>
    data.map((item) => ({ id: item.id, label: item[key] }));

  useEffect(() => {
    if (
      !listingsData ||
      listingsData.listings.listings.length === 0 ||
      !locationsData ||
      locationsData.brand?.locations.locations.length === 0
    ) {
      return;
    }

    if (listingsData) {
      const transformedListings = transformDataToFilterOptions(
        listingsData.listings.listings,
        "name"
      );
      setListings(transformedListings);
      setSelectedListings(transformedListings.map((listing) => listing.id));
    }
    if (locationsData && locationsData.brand) {
      const transformedLocations = transformDataToFilterOptions(
        locationsData.brand.locations.locations,
        "name"
      );
      setLocations(transformedLocations);
      setSelectedLocations(transformedLocations.map((location) => location.id));
    }
    setSelectedContentType(content.map((c) => c.id as BookingPostType));
  }, [listingsData, locationsData]);

  if (
    !listingsData ||
    !locationsData ||
    locationsLoading ||
    listingLoading ||
    !activeBrandId
  ) {
    return <Loading />;
  }

  return (
    <Wrap>
      <HeaderWrap>
        <H1 margin="0">Content</H1>
        <Flex>
          <DateRangePicker
            initialStartDate={startDate}
            initialEndDate={endDate}
            onChange={handleDateChange}
            tooltip="Based on the content post date"
          />
          <ReportFilter
            selectedContentType={selectedContentType}
            setSelectedContentType={setSelectedContentType}
            listings={listings}
            selectedListings={selectedListings}
            setSelectedListings={setSelectedListings}
            locations={locations}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
          />
        </Flex>
      </HeaderWrap>
      <Reports
        activeBrandId={activeBrandId}
        startDate={startDate ? startDate : initialStartDate}
        endDate={endDate ? endDate : initialEndDate}
        selectedListings={selectedListings}
        selectedLocations={selectedLocations}
        selectedContentType={selectedContentType}
      />
    </Wrap>
  );
};

interface ReportsProps {
  activeBrandId: string;
  startDate: Date;
  endDate: Date;
  selectedListings: string[];
  selectedLocations: string[];
  selectedContentType: BookingPostType[];
}

function Reports(props: ReportsProps) {
  const client = useGqlClient();

  const [showExampleData, setShowExampleData] = useState(false);

  const [showUpgradeOverlay, setShowUpgradeOverlay] = useState(false);

  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);

  const [sortProperty] = useState<BookingPostsSortBy>(
    BookingPostsSortBy.BookingPostsSortByReach
  );

  const serializedFilters = JSON.stringify({
    startDate: props.startDate ? getUnixTime(props.startDate) : undefined,
    endDate: props.endDate ? getUnixTime(props.endDate) : undefined,
    listingIDs: props.selectedListings,
    locationIDs: props.selectedLocations,
    contentTypes: props.selectedContentType,
  });

  const fetchPosts = async ({ pageParam = "" }) => {
    return client.request<BrandContentQuery>(BrandContentDocument, {
      cursor: pageParam,
      filters: {
        startDate: props.startDate ? getUnixTime(props.startDate) : undefined,
        endDate: props.endDate ? getUnixTime(props.endDate) : undefined,
        listingIDs: props.selectedListings,
        sortBy: sortProperty,
        locationIDs: props.selectedLocations,
        contentTypes: props.selectedContentType,
      },
      brandID: props.activeBrandId,
    });
  };

  const { data, isLoading, error, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["BrandContent", props.activeBrandId, serializedFilters],
      fetchPosts,
      {
        getNextPageParam: (lastPage) =>
          lastPage.bookingPosts.cursor || undefined,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

  useEffect(() => {
    if (!data || !data.pages[0] || !data.pages[0].brand) {
      return;
    }

    const currentPlan = data.pages[0].billingPlans.find(
      (bp) => bp.id === data.pages[0].brand!.billingPlanId
    );

    const shouldShowUpgradePrompt = !currentPlan
      ? false
      : currentPlan &&
        currentPlan.planType === BillingPlanType.BillingPlanTypeStarter
      ? true
      : false;

    setShowUpgradePrompt(shouldShowUpgradePrompt);
  }, [data]);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, fetchNextPage]
  );

  if (error) {
    return (
      <Flex align="center" justify="center" direction="column">
        <Text>Something went wrong</Text>
      </Flex>
    );
  }

  if (showUpgradePrompt) {
    return (
      <>
        <Flex style={{ marginTop: "96px" }} direction="column" align="center">
          <Text
            isCompact
            weight="semi"
            margin="0"
            onClick={() => setShowUpgradeOverlay(true)}
            style={{ cursor: "pointer" }}
          >
            Upgrade plan to access content gallery
          </Text>
        </Flex>
        {showUpgradeOverlay && (
          <UpgradePlan onCancel={() => setShowUpgradeOverlay(false)} />
        )}
      </>
    );
  }

  const bookingPosts =
    data?.pages.flatMap((page) => page.bookingPosts.bookingPosts) || [];

  if (isLoading) return <Loading />;

  return (
    <>
      {showExampleData ? (
        <>
          <div
            style={{
              marginTop: 24,
              textAlign: "center",
              maxWidth: 200,
              margin: "0 auto",
            }}
          >
            <CTA
              type="tertiary"
              margin="l 0 xxl"
              onClick={() => setShowExampleData(false)}
              to="#"
            >
              Hide example content
            </CTA>
          </div>{" "}
          <MasonryList>
            {exampleReportPosts
              // is p.postType in the porps.selectedContentType array
              .filter(
                (p) =>
                  !props.selectedContentType ||
                  props.selectedContentType.includes(
                    p.postType as BookingPostType
                  )
              )
              .map((bp, index) => {
                return (
                  <BookingPost
                    exampleData={true}
                    ref={
                      index === bookingPosts.length - 1 ? lastElementRef : null
                    }
                    key={bp.id}
                    name={
                      bp.postType === BookingPostType.BookingPostTypeTiktok &&
                      bp.creator.tikTok
                        ? bp.creator.tikTok.name
                        : bp.creator.instagram
                        ? bp.creator.instagram.name
                        : ""
                    }
                    username={
                      bp.postType === BookingPostType.BookingPostTypeTiktok &&
                      bp.creator.tikTok
                        ? bp.creator.tikTok.username
                        : bp.creator.instagram
                        ? bp.creator.instagram.username
                        : ""
                    }
                    mediaUrl={bp.mediaUrl}
                    // metricValue={value}
                    thumbnailUrl={bp.thumbnailUrl}
                    caption={bp.mediaCaption}
                    mediaType={bp.mediaType as BookingPostMediaType}
                    postType={bp.postType as BookingPostType}
                    id={bp.id}
                    maxWidth={600}
                  />
                );
              })}
          </MasonryList>
        </>
      ) : (
        <>
          {bookingPosts.length > 0 ? (
            <MasonryList>
              {bookingPosts.map((bp, index) => {
                return (
                  <BookingPost
                    ref={
                      index === bookingPosts.length - 1 ? lastElementRef : null
                    }
                    key={bp.id}
                    name={
                      bp.postType === BookingPostType.BookingPostTypeTiktok &&
                      bp.creator.tikTok
                        ? bp.creator.tikTok.displayName
                        : bp.creator.instagram
                        ? bp.creator.instagram.name
                        : ""
                    }
                    username={
                      bp.postType === BookingPostType.BookingPostTypeTiktok &&
                      bp.creator.tikTok
                        ? bp.creator.tikTok.username
                        : bp.creator.instagram
                        ? bp.creator.instagram.username
                        : ""
                    }
                    mediaUrl={bp.mediaUrl}
                    // metricValue={bp.value}
                    thumbnailUrl={bp.thumbnailUrl}
                    caption={bp.mediaCaption}
                    mediaType={bp.mediaType}
                    postType={bp.postType}
                    id={bp.id}
                    maxWidth={600}
                  />
                );
              })}
            </MasonryList>
          ) : (
            <Flex
              style={{ marginTop: "120px" }}
              margin="0"
              direction="column"
              align="center"
            >
              <Text isCompact weight="semi" margin="0" colorPreset="secondary">
                No data for this date range
              </Text>
            </Flex>
          )}
        </>
      )}
    </>
  );
}
