import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BackLink } from "../../../components/BackLink";
import { SignupBrandSwitcher } from "../../../components/Brand/SignupBrandSwitcher";
import { SignupHeader } from "../../../components/Brand/SignupHeader";
import { Submit } from "../../../components/CTA";
import { Chevron } from "../../../components/Chevron";
import { Flex } from "../../../components/Flex";
import { H1 } from "../../../components/Heading";
import { Modal } from "../../../components/Modal";
import { ProgressBar } from "../../../components/ProgressBar";
import { Steps } from "../../../components/Steps";
import { Subtitle } from "../../../components/Subtitle";
import { Text } from "../../../components/Text";
import { TextLink } from "../../../components/TextLink";
import { ToggleButton } from "../../../components/ToggleButton";
import { MOBILE_BREAKPOINT } from "../../../config";
import { useUpdateBrandMutation } from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { authSelectors } from "../../../store/auth/selector";
import { styled } from "../../../styles";

export const brandCategories = [
  "Activities",
  "Bars/Pubs/Clubs",
  "Cafes/Coffee/Brunch",
  "Delivery",
  "Events",
  "Festivals",
  "Hotels",
  "Restaurants",
  "Retail",
  "Street Food",
  "Takeaway",
  "Other",
];

export const CategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${(p) => p.theme.spacing.s};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 100%;
  @media (min-width: 1200px) {
    width: 500px;
  }
`;

export interface MatchParams {
  page: string;
}

export const DescribeBrand = () => {
  const window = useWindowSize();
  const brand = useSelector(authSelectors.activeBrand);
  const account = useSelector(authSelectors.account);

  const [showAccountSwitcher, setShowAccountSwitcher] = useState(false);
  return (
    <Wrap>
      <Modal isOpen={showAccountSwitcher} setIsOpen={setShowAccountSwitcher}>
        <SignupBrandSwitcher setShowAccountSwitcher={setShowAccountSwitcher} />
      </Modal>

      {window.width && window.width < MOBILE_BREAKPOINT ? (
        <Flex margin="xs 0 xl 0" justify="space-between" align="center">
          <BackLink margin="0 0 0" to="/signup/b/confirm-details">
            <Chevron direction="left" /> Back
          </BackLink>
          <ProgressBar steps={{ currentStep: 2, totalSteps: 4 }} />
        </Flex>
      ) : (
        <>
          <SignupHeader />
        </>
      )}

      <ContentWrap>
        <div>
          <H1 margin="0 0 0 0">Describe your business</H1>
          <Subtitle margin="xs 0 xl 0">
            {" "}
            Pick all that apply - this help us find you the right influencers
          </Subtitle>
          <BrandCategories />
          {account &&
            account.brands &&
            account.brands.length > 1 &&
            brand &&
            window.width &&
            window.width < MOBILE_BREAKPOINT && (
              <Center>
                <Text
                  colorPreset="secondary"
                  weight="semi"
                  size="s"
                  margin="xl 0 0"
                  align="center"
                  isCompact
                >
                  {brand.name}
                </Text>
                <TextLink
                  size="s"
                  to="#"
                  onClick={() => setShowAccountSwitcher(true)}
                >
                  Switch account
                </TextLink>
              </Center>
            )}
        </div>
      </ContentWrap>
      {window.width && window.width > MOBILE_BREAKPOINT && (
        <Steps
          steps={[
            {
              link: "/signup/b/confirm-details",
              status: "complete",
              name: "Details",
            },
            {
              link: "/signup/b/describe-brand",
              status: "current",
              name: "Describe brand",
            },
            {
              link: "/signup/b/create-location",
              status: "upcoming",
              name: "Locations",
            },
          ]}
        />
      )}
    </Wrap>
  );
};

const BrandCategories = () => {
  const { track } = useAnalytics();
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const updateBrand = useUpdateBrandMutation(client, {});
  const brand = useSelector(authSelectors.activeBrand);
  const history = useHistory();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!brand) {
      return;
    }

    updateBrand.mutate(
      {
        input: {
          id: brand.id,
          categories: selectedType,
        },
      },
      {
        onError: (e: any) => {
          console.error(e);
          setError(e);
        },
        onSuccess: (res) => {
          track("Brand described", {
            id: res.updateBrand.id ? res.updateBrand.id : undefined,
            brand: brand ? brand.name : undefined,
            categories: selectedType,
          });
          queryClient.resetQueries(["Brands"], {
            exact: false,
          });
          history.push("/signup/b/create-location");
        },
      }
    );
  };

  return (
    <>
      <form style={{ flex: 1 }} onSubmit={onSubmit}>
        <CategoryWrapper>
          {brandCategories.map((b) => {
            return (
              <ToggleButton
                key={b}
                title={b}
                active={selectedType.includes(b)}
                margin="0 0 s 0"
                onClick={() => {
                  if (selectedType.includes(b)) {
                    setSelectedType(selectedType.filter((e) => e !== b));
                  } else {
                    setSelectedType((t) => [...t, b]);
                  }
                }}
              />
            );
          })}
        </CategoryWrapper>
        {error && (
          <Text size="s" colorPreset="warning" align="center">
            Please select at least one category
          </Text>
        )}
        <Submit
          type="submit"
          value={updateBrand.isLoading ? "Loading..." : "Next"}
          margin="l 0 0 0"
        />
      </form>
    </>
  );
};

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
