import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const PressLinkSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 5C14.4477 5 14 4.55225 14 4V2C14 1.44775 14.4477 1 15 1C15.5523 1 16 1.44775 16 2V4C16 4.55225 15.5523 5 15 5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9502 7.05077C19.6943 7.05077 19.4385 6.95311 19.2432 6.7578C18.8525 6.36718 18.8525 5.73436 19.2432 5.34374L20.6572 3.92968C21.0478 3.53906 21.6807 3.53906 22.0713 3.92968C22.4619 4.3203 22.4619 4.95312 22.0713 5.34374L20.6572 6.7578C20.4619 6.95312 20.2061 7.05077 19.9502 7.05077Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12H22C21.4477 12 21 11.5523 21 11C21 10.4477 21.4477 10 22 10H24C24.5523 10 25 10.4477 25 11C25 11.5523 24.5523 12 24 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0508 7.05077C9.79491 7.05077 9.53905 6.95311 9.34374 6.7578L7.92968 5.34374C7.53906 4.95312 7.53906 4.3203 7.92968 3.92968C8.3203 3.53906 8.95312 3.53906 9.34374 3.92968L10.7578 5.34374C11.1484 5.73436 11.1484 6.36718 10.7578 6.7578C10.5625 6.95312 10.3066 7.05077 10.0508 7.05077Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12H6C5.44775 12 5 11.5523 5 11C5 10.4477 5.44775 10 6 10H8C8.55225 10 9 10.4477 9 11C9 11.5523 8.55225 12 8 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1276 18.4777L17 17V10C17 8.89545 16.1046 8 15 8C13.8954 8 13 8.89545 13 10V23H12V20H11C9.89545 20 9 20.8954 9 22V24.8953C9 26.2578 9.46368 27.5797 10.3148 28.6436L13 32H26L27.5561 21.8855C27.8032 20.2794 26.7265 18.7684 25.1276 18.4777Z"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const PressLinkIcon = styled(PressLinkSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
