import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "..";
import { AuthState } from "./slice";

function data(state: AppState): AuthState {
  return state.auth;
}

function isLoading(state: AppState): boolean {
  return state.auth.isLoading;
}

function isLoggedIn(state: AppState): boolean {
  return state.auth.isLoggedIn;
}

function accessToken(state: AppState) {
  return state.auth.accessToken;
}

function activeCreator(state: AppState) {
  const activeCreator =
    state.auth.account &&
    state.auth.account.creators &&
    state.auth.account.creators.find(
      (c) => c.id === state.auth.activeCreatorId
    );

  return activeCreator ? activeCreator : null;
}

function activeCreatorId(state: AppState) {
  return state.auth.activeCreatorId;
}

function activeBrand(state: AppState) {
  const activeBrand =
    state.auth.account &&
    state.auth.account.brands &&
    state.auth.account.brands.find((c) => c.id === state.auth.activeBrandId);

  return activeBrand ? activeBrand : null;
}

const activeBrandId = createSelector(
  (state: AppState) => state.auth,
  (auth) => auth.activeBrandId
);

function account(state: AppState) {
  if (!state.auth.account) {
    return null;
  }
  return state.auth.account;
}

function fbSdkStatus(state: AppState) {
  return state.auth.fbSdkStatus;
}

function brandBillingStatus(state: AppState) {
  return state.auth.brandBillingStatus;
}

export const authSelectors = {
  data,
  isLoading,
  isLoggedIn,
  accessToken,
  activeCreator,
  activeCreatorId,
  activeBrand,
  activeBrandId,
  account,
  fbSdkStatus,
  brandBillingStatus,
};
