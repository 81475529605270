import * as React from "react";
import styled from "../../styles";

const FacebookIconCircleSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 14.991 3.657 19.128 8.438 19.878V12.891H5.898V10H8.438V7.797C8.438 5.291 9.93 3.907 12.215 3.907C13.309 3.907 14.453 4.102 14.453 4.102V6.562H13.193C11.95 6.562 11.563 7.333 11.563 8.124V10H14.336L13.893 12.89H11.563V19.878C16.343 19.128 20 14.991 20 10Z"
      fill="#fff"
    />
  </svg>
);

interface Props {
  width?: number;
  height?: number;
}

export const FacebookIconCircle = styled(FacebookIconCircleSVG)<Props>`
  width: ${(p) => p.width}px;
  min-width: ${(p) => p.width}px;
  margin-top: -2px;
`;
