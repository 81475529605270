import { useTheme } from "../../hooks/useTheme";

interface Props {
  width?: number;
}

export const LikesIcon = (props: Props) => {
  const theme = useTheme();
  return (
    <svg
      width={props.width ? props.width : 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="46"
        height="46"
        rx="23"
        stroke="url(#paint0_linear_2042_9145)"
        stroke-width="2"
      />
      <path
        d="M33.75 20.4375C33.75 17.7454 31.4761 15.5625 28.6713 15.5625C26.5751 15.5625 24.7746 16.7823 24 18.5232C23.2254 16.7823 21.4249 15.5625 19.3276 15.5625C16.525 15.5625 14.25 17.7454 14.25 20.4375C14.25 28.2592 24 33.4375 24 33.4375C24 33.4375 33.75 28.2592 33.75 20.4375Z"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2042_9145"
          x1="0"
          y1="24.0005"
          x2="47.9991"
          y2="24.0005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
