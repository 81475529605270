import ReactMarkdown from "react-markdown";
import { ExternalTextLink } from "./TextLink";
import { Text } from "./Text";

interface MarkdownProps {
  markdown: string;
  margin?: string;
}

export const StyledMarkdown = (props: MarkdownProps) => {
  return (
    <ReactMarkdown
      children={props.markdown}
      components={{
        a: ExternalTextLink,
        p: (props2) => (
          <Text margin={props.margin ? props.margin : "m 0 m 0"}>
            {props2.children}
          </Text>
        ),
      }}
    />
  );
};
