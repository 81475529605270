import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const ApplySVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 2V22L6 21L8 22L10 21L12 22L14 21L16 22L18 21L20 22V2L18 3L16 2L14 3L12 2L10 3L8 2L6 3L4 2Z"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 8H8"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 12H8"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 16H8"
      stroke="#706A7A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const ApplyIcon = styled(ApplySVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
