import { Tag } from "../components/Tag";
import {
  InstagramFollowerDemographicsHelpText,
  InstagramReachedAudienceDemographicsHelpText,
} from "../config";
import {
  BookingPostType,
  BookingStatus,
  DemographicSource,
  ListingStatus,
  PostType,
} from "../graphql/generated";

export const renderBookingStatus = (status: BookingStatus, isPast: boolean) => {
  switch (status) {
    case BookingStatus.BookingStatusApproved:
      return isPast ? "Awaiting content" : "Upcoming";
    case BookingStatus.BookingStatusPending:
      return "Awaiting approval";
    case BookingStatus.BookingStatusRejected:
      return "Application declined";
    case BookingStatus.BookingStatusCancelled:
      return "Cancelled";
    case BookingStatus.BookingStatusCompleted:
      return "Completed";
    case BookingStatus.BookingStatusCreatorRescheduled:
      return "Reschedule requested";
    case BookingStatus.BookingStatusRescheduled:
      return "Reschedule requested";
    case BookingStatus.BookingStatusExpired:
      return "Expired";
    default:
      return "Unknown status";
  }
};

export const renderListingStatus = (status: ListingStatus) => {
  switch (status) {
    case ListingStatus.StatusActive:
      return "Active";
    case ListingStatus.StatusDraft:
      return "Draft";
    case ListingStatus.StatusExpired:
      return "Expired";
    case ListingStatus.StatusScheduled:
      return "Scheduled";
    default:
      return "Unknown status";
  }
};

export const renderBookingPostType = (postType: BookingPostType | PostType) => {
  switch (postType) {
    case BookingPostType.BookingPostTypeFeed:
      return "Post";
    case BookingPostType.BookingPostTypeStory:
      return "Story";
    case BookingPostType.BookingPostTypeReel:
      return "Reel";
    case BookingPostType.BookingPostTypeTiktok:
      return "TikTok";
  }
};

export const renderBookingPostTypeTag = (
  postType: BookingPostType
): React.ReactNode => {
  switch (postType) {
    case BookingPostType.BookingPostTypeFeed:
      return <Tag color="blue" text="Post" />;
    case BookingPostType.BookingPostTypeStory:
      return <Tag color="purple" text="Story" />;
    case BookingPostType.BookingPostTypeReel:
      return <Tag color="green" text="Reel" />;
    case BookingPostType.BookingPostTypeTiktok:
      return <Tag color="red" text="TikTok" />;
  }
};

export const renderPostTypeTag = (postType: PostType): React.ReactNode => {
  switch (postType) {
    case PostType.Feed:
      return <Tag color="blue" text="Post" />;
    case PostType.Story:
      return <Tag color="purple" text="Story" />;
    case PostType.Reels:
      return <Tag color="green" text="Reel" />;
    case PostType.Tiktok:
      return <Tag color="red" text="TikTok" />;
  }
};

export const renderDemographicSource = (source: DemographicSource) => {
  switch (source) {
    case DemographicSource.DemographicSourceFollowers:
      return "Followers";
    case DemographicSource.DemographicSourceEngaged:
      return "People Engaged";
    case DemographicSource.DemographicSourceReached:
      return "People Reached";
  }
};

export const renderDemographicTimeframe = (source: DemographicSource) => {
  switch (source) {
    case DemographicSource.DemographicSourceFollowers:
      return "All time";
    case DemographicSource.DemographicSourceReached:
      return "Last 30 days";
    case DemographicSource.DemographicSourceEngaged:
      return "Last 30 days";
  }
};

export const renderDemographicSourceTooltip = (source: DemographicSource) => {
  switch (source) {
    case DemographicSource.DemographicSourceFollowers:
      return InstagramFollowerDemographicsHelpText;
    case DemographicSource.DemographicSourceReached:
      return InstagramReachedAudienceDemographicsHelpText;
  }
};
