import * as React from "react";
import styled from "../../styles";

interface Props {
  color?: string;
}

const TickIconSVG = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1.5em"
      height="1.5em"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const TickIcon = styled(TickIconSVG)<Props>`
  fill: ${(p) => (p.color ? p.color : "#059669")};
`;
