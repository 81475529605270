import { useEffect } from "react";
import { MOBILE_BREAKPOINT } from "../config";
import { useTheme } from "../hooks/useTheme";
import styled, { ThemeProvider } from "../styles";
import { ButtonWrap, CTA } from "./CTA";
import { Card } from "./Card";
import { Flex } from "./Flex";
import { H2 } from "./Heading";
import { Overlay } from "./Overlay";
import { Text } from "./Text";
import { TickIcon } from "./icons/TickIcon";

interface Props {
  onCancel: () => void;
}

const ModalWrap = styled(Card)`
  width: 100%;
  max-width: 620px;
  overflow-y: scroll;
  cursor: default;

  // prettier-ignore
  padding: ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.xl} 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing.m};
  grid-row-gap: ${(p) => p.theme.spacing.xl};
  padding: ${(p) => p.theme.spacing.s} 0;
  text-align: left;
  align-items: start;
  margin-bottom: ${(p) => p.theme.spacing.xl};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

export function Checklist({
  header,
  subheader,
}: {
  header: string;
  subheader: string;
}) {
  return (
    <Flex align="flex-start" direction="row">
      <div>
        <TickIcon />
      </div>
      <Flex margin="0 0 0 s" direction="column">
        <Text weight="bold" margin="0">
          {header}
        </Text>
        <Text margin="0" size="s">
          {subheader}
        </Text>
      </Flex>
    </Flex>
  );
}

export const UpgradePlan = (props: Props) => {
  const theme = useTheme();
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onCancel();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [props]);

  return (
    <ThemeProvider theme={theme}>
      <Overlay onClick={props.onCancel}>
        <ModalWrap onClick={(e) => e.stopPropagation()}>
          <H2 margin="0">Upgrade plan</H2>
          <Text size="l" margin="s 0 l">
            Get instant access to more powerful features on the Business Plan
          </Text>
          <FeatureGrid>
            <Checklist
              header="Advanced reporting"
              subheader="Access exclusive performance metrics & identify top-performing posts"
            />
            <Checklist
              header="Content gallery"
              subheader="View & download all Joli generated content with full usage rights"
            />
            <Checklist
              header="Booking approval control"
              subheader="Approve or reject bookings based on performance & demographic data"
            />
            <Checklist
              header="Campaign targetting"
              subheader="Reach specific segments of Joli's creator community"
            />
          </FeatureGrid>
          <ButtonWrap margin="0 0 xl">
            <CTA onClick={props.onCancel} to="/b/billing">
              Manage plan
            </CTA>
            <CTA type="secondary" to="#" onClick={props.onCancel}>
              Maybe later
            </CTA>
          </ButtonWrap>
        </ModalWrap>
      </Overlay>
    </ThemeProvider>
  );
};
