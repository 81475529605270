import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const PhoneIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.136 10.161a17.292 17.292 0 0 0 2.078 1.777c.729.53 1.458.948 2.187 1.258.73.31 1.42.465 2.072.465.442 0 .854-.08 1.237-.24.383-.159.73-.41 1.039-.751.182-.2.326-.417.43-.65.105-.227.158-.457.158-.69 0-.173-.034-.337-.103-.492a.962.962 0 0 0-.348-.41l-2.099-1.49a2.332 2.332 0 0 0-.444-.247 1.05 1.05 0 0 0-.39-.082.873.873 0 0 0-.451.13 2.13 2.13 0 0 0-.451.356l-.486.478a.34.34 0 0 1-.26.11.456.456 0 0 1-.157-.028 5.268 5.268 0 0 1-.116-.048c-.214-.114-.487-.31-.82-.587a16.86 16.86 0 0 1-1.928-1.935c-.282-.333-.478-.604-.588-.814a1.629 1.629 0 0 1-.054-.123.57.57 0 0 1-.02-.15c0-.1.033-.185.102-.253l.485-.499c.15-.155.269-.305.355-.451a.873.873 0 0 0 .13-.451.98.98 0 0 0-.089-.39 2.163 2.163 0 0 0-.252-.444l-1.47-2.072a1.046 1.046 0 0 0-.424-.355 1.202 1.202 0 0 0-.52-.116c-.469 0-.909.198-1.319.595a3.018 3.018 0 0 0-.731 1.06c-.15.382-.226.792-.226 1.23 0 .656.153 1.346.458 2.07.305.72.72 1.443 1.244 2.168a17.106 17.106 0 0 0 1.77 2.071Z"
      fill="#9570ED"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const PhoneIcon = styled(PhoneIconSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
