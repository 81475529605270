import * as React from "react";
import { css, styled } from "../styles";
import withMargin from "../styles/withMargin";
import { Text } from "./Text";

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  ${withMargin};
`;

const TableBody = styled.tbody`
  width: 100%;
`;

export const TableRow = styled.tr<{ variant: DetailListVariant }>`
  width: 100%;
  ${(p) => {
    if (p.variant === "stacked") {
      return css`
        display: block;
        & + & {
          margin-top: ${p.theme.spacing.s};
        }
      `;
    }
    return css`
      display: table-row;
    `;
  }}
`;

export const TableLabel = styled((props) => <Text as="th" {...props} />)`
  color: ${(p) => p.theme.color.typography.text};
  font-size: ${(p) => p.theme.typography.size.m};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  display: flex;
  white-space: pre;
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.xl} ${theme.spacing.s} 0;
  `}
`;

export const TableValue = styled((props) => <Text as="td" {...props} />)`
  display: table-cell;
  padding: 0 0 ${(p) => p.theme.spacing.s};
`;

interface DataField {
  label: string;
  value: React.ReactNode;
}

export type DetailListVariant = "horizontal" | "stacked";

export interface DetailListProps {
  className?: string;
  margin?: string;
  keyExtractor?: (field: DataField, index: number) => string;
  variant?: DetailListVariant;

  // For it to be easy to conditionally render fields, this
  // accepts false and undefined (which will be filtered out).
  //
  // This allows you to do things like:
  // <DetailList
  //   fields={[
  //     {
  //       field: 'Name',
  //       value: 'Grace Hopper'
  //     },
  //     props.shouldRender && {
  //       field: 'Conditional field',
  //       value: 'Yes'
  //     }
  //   ]}
  // />
  fields: Array<DataField | false | undefined | null>;
}

const DetailList = ({ variant = "horizontal", ...props }: DetailListProps) => {
  return (
    <Table
      margin={props.margin}
      data-testid="detail-list"
      className={props.className}
    >
      <TableBody>
        {props.fields.map((field, index) => {
          if (!field) {
            return null;
          }

          return (
            <TableRow
              variant={variant}
              key={
                props.keyExtractor ? props.keyExtractor(field, index) : index
              }
            >
              <TableLabel>{field.label}</TableLabel>
              <TableValue>{field.value}</TableValue>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
export default DetailList;
