import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Submit } from "../components/CTA";
import { H1, H3 } from "../components/Heading";
import { CheckCircle } from "../components/icons/CheckCircle";
import { Input } from "../components/Input";
import Loading from "../components/Loading";
import { Text } from "../components/Text";
import {
  useAccountsQuery,
  useUpdateAcccountMutation,
} from "../graphql/generated";
import useAnalytics from "../hooks/useAnalytics";
import useGqlClient from "../hooks/useGqlClient";
import { styled } from "../styles";
import { SignupCard } from "./brands/signup/CreateBrand";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
`;

export interface MatchParams {
  page: string;
}

export const ConfirmDetails = () => {
  return (
    <Wrap>
      <ContentWrap>
        <UserForm />
      </ContentWrap>
    </Wrap>
  );
};

const UserForm = () => {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState("");
  const { track, identifyUser } = useAnalytics();
  const accountQuery = useAccountsQuery(
    client,
    {},
    {
      retry: false,
    }
  );

  const updateAccount = useUpdateAcccountMutation(client, {});

  const { isLoading, data, isError } = accountQuery;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    if (data?.account) {
      const newFormData = {
        firstName: data.account.firstName || "",
        lastName: data.account.lastName || "",
        email: data.account.email || "",
      };
      setFormData(newFormData);
    }
  }, [data]);

  if (isLoading) {
    return (
      <Wrap>
        <Loading defer />
      </Wrap>
    );
  }

  if (isError || !data) {
    return (
      <Wrap>
        <H3>Oops</H3>

        <Text margin={"0 0 xl 0"}>
          Couldn't load this profile, please try again later.
        </Text>
      </Wrap>
    );
  }

  const validateForm = () => {
    const newErrors = { firstName: "", lastName: "", email: "" };
    let isValid = true;

    if (!formData.firstName.trim()) {
      newErrors.firstName = "Please enter your first name";
      isValid = false;
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Please enter your last name";
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) return;

    const submissionData = {
      ...formData,
      email: formData.email || data?.account?.email || "",
    };

    updateAccount.mutate(submissionData, {
      onError: (e) => setError(e as string),
      onSuccess: (res) => {
        track("Account created");
        identifyUser(data.account.id, {
          email: res.updateAccount.email,
          name: `${res.updateAccount.firstName} ${res.updateAccount.lastName}`,
        });
        queryClient
          .resetQueries(["Accounts"], {
            exact: false,
          })
          .then(() => {
            history.push("/b");
          });
      },
    });
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <>
      {error && <Text>Error updating</Text>}

      <form onSubmit={onSubmit}>
        <SignupCard>
          <H1 margin="0 0 l 0">Confirm your details</H1>
          <Input
            label="First name"
            value={formData.firstName}
            name="firstName"
            onChange={handleInputChange}
            margin="0 0 xl 0"
            error={errors.firstName}
          />
          <Input
            label="Last name"
            value={formData.lastName}
            name="lastName"
            onChange={handleInputChange}
            margin="0 0 xl 0"
            error={errors.lastName}
          />
          <Text weight="semi" margin="0 0 s">
            Email
          </Text>
          <div style={{ position: "relative" }}>
            {data.account.email && data.account.emailVerifiedAt && (
              <AbsoluteWrap>
                <CheckCircle checked={true} />
              </AbsoluteWrap>
            )}
            <Input
              margin="0 0 s 0"
              isDisabled={
                data.account.email && data.account.emailVerifiedAt
                  ? true
                  : false
              }
              onChange={handleInputChange}
              value={formData.email}
              name="email"
              error={errors.email}
            />
          </div>
          <Submit
            margin="xl 0 0 0"
            type="submit"
            value={
              updateAccount.isLoading || updateAccount.isSuccess
                ? "Saving..."
                : "Next"
            }
          />
        </SignupCard>
      </form>
    </>
  );
};

const AbsoluteWrap = styled.div`
  position: absolute;
  top: 0;
  right: ${(p) => p.theme.spacing.m};
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
`;
