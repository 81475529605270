import * as React from "react";
import styled from "../../styles";

const YahooIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36 31C36 33.762 33.762 36 31 36H5C2.239 36 0 33.762 0 31V5C0 2.238 2.239 0 5 0H31C33.762 0 36 2.238 36 5V31Z"
      fill="#5C6BC0"
    />
    <path
      d="M28 7H8C7.448 7 7 7.448 7 8V20C7 20.552 7.448 21 8 21H28C28.552 21 29 20.552 29 20V8C29 7.448 28.552 7 28 7ZM27 11L18 15L9 11V9H27V11Z"
      fill="white"
    />
    <path
      d="M5.35938 26.9531H6.86364L7.7397 29.1934L8.62714 26.9531H10.0917L7.88666 32.2575H6.41268L7.01628 30.8522L5.35938 26.9531Z"
      fill="white"
    />
    <path
      d="M11.6177 26.8594C10.4874 26.8594 9.77344 27.8729 9.77344 28.8821C9.77344 30.018 10.5568 30.9181 11.5962 30.9181C12.372 30.9181 12.664 30.4456 12.664 30.4456V30.814H13.9759V26.9489H12.664V27.3002C12.664 27.3002 12.338 26.8594 11.6177 26.8594ZM11.8971 28.1019C12.4187 28.1019 12.6874 28.5144 12.6874 28.8865C12.6874 29.2876 12.3991 29.6806 11.8971 29.6806C11.4808 29.6806 11.1049 29.3406 11.1049 28.9035C11.1043 28.4601 11.407 28.1019 11.8971 28.1019Z"
      fill="white"
    />
    <path
      d="M14.4258 30.814V25.2422H15.7976V27.3135C15.7976 27.3135 16.1237 26.86 16.8061 26.86C17.6412 26.86 18.13 27.4819 18.13 28.3712V30.814H16.7683V28.7055C16.7683 28.4046 16.6251 28.1139 16.3003 28.1139C15.9698 28.1139 15.7976 28.409 15.7976 28.7055V30.814H14.4258Z"
      fill="white"
    />
    <path
      d="M20.4712 26.8594C19.177 26.8594 18.4062 27.8433 18.4062 28.8979C18.4062 30.0975 19.3391 30.9181 20.4756 30.9181C21.5775 30.9181 22.5412 30.1353 22.5412 28.918C22.5419 27.5866 21.5327 26.8594 20.4712 26.8594ZM20.4838 28.1126C20.9411 28.1126 21.2571 28.4936 21.2571 28.8991C21.2571 29.2454 20.9626 29.6724 20.4838 29.6724C20.0455 29.6724 19.7163 29.3204 19.7163 28.8953C19.7163 28.486 19.9894 28.1126 20.4838 28.1126Z"
      fill="white"
    />
    <path
      d="M24.8267 26.8594C23.5325 26.8594 22.7617 27.8433 22.7617 28.8979C22.7617 30.0975 23.6946 30.9181 24.8311 30.9181C25.933 30.9181 26.8967 30.1353 26.8967 28.918C26.8973 27.5866 25.8882 26.8594 24.8267 26.8594ZM24.8393 28.1126C25.2966 28.1126 25.6126 28.4936 25.6126 28.8991C25.6126 29.2454 25.318 29.6724 24.8393 29.6724C24.401 29.6724 24.0717 29.3204 24.0717 28.8953C24.0711 28.486 24.3448 28.1126 24.8393 28.1126Z"
      fill="white"
    />
    <path
      d="M27.9817 30.9009C28.485 30.9009 28.8931 30.4929 28.8931 29.9895C28.8931 29.4862 28.485 29.0781 27.9817 29.0781C27.4784 29.0781 27.0703 29.4862 27.0703 29.9895C27.0703 30.4929 27.4784 30.9009 27.9817 30.9009Z"
      fill="white"
    />
    <path
      d="M29.1913 28.7395H27.5508L29.0065 25.2422H30.6407L29.1913 28.7395Z"
      fill="white"
    />
  </svg>
);

interface Props {
  width?: number;
  height?: number;
}

export const YahooIcon = styled(YahooIconSVG)<Props>`
  width: ${(p) => p.width}px;
  min-width: ${(p) => p.width}px;
  top: 6px;
`;
