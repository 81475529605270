import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const ViewSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.33301 8.50016C1.33301 8.50016 3.33301 3.8335 7.99967 3.8335C12.6663 3.8335 14.6663 8.50016 14.6663 8.50016C14.6663 8.50016 12.6663 13.1668 7.99967 13.1668C3.33301 13.1668 1.33301 8.50016 1.33301 8.50016Z"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 10.5C9.10457 10.5 10 9.60457 10 8.5C10 7.39543 9.10457 6.5 8 6.5C6.89543 6.5 6 7.39543 6 8.5C6 9.60457 6.89543 10.5 8 10.5Z"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
  color?: string;
}

export const ViewIcon = styled(ViewSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.color
        ? p.color
        : "#fff"};
  }
`;
