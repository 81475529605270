export function extractSocialMediaHandles(caption: string): string[] {
  const competitors = [
    "@invyted.app",
    "@pingfluenceapp",
    "@gifta_uk",
    "@hemblem.app",
    "@joinbulba",
  ];

  // Simplified Regex to match potential handles
  const handleRegex = /(^|\s)(@[a-zA-Z0-9_]{1,30})(?=\s|$)/g;

  let matches: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = handleRegex.exec(caption)) !== null) {
    matches.push(match[2]); // match[2] contains the actual handle
  }

  // Filter out competitor handles
  const filteredMatches = matches.filter(
    (handle) => !competitors.includes(handle.toLowerCase())
  );

  // Remove duplicates
  const uniqueHandles = Array.from(new Set(filteredMatches));

  // Check if there are more than 3 handles
  if (uniqueHandles.length > 3) {
    const extraCount = uniqueHandles.length - 3;
    return [...uniqueHandles.slice(0, 3), `+ ${extraCount} more`];
  }

  return uniqueHandles;
}
