import { useState } from "react";
import { dayEnumToDays, daysToDaysEnum } from ".";
import { Button } from "../../../../components/CTA";
import { DayPicker } from "../../../../components/DayPicker";
import { CardDivider } from "../../../../components/Divider";
import { Flex } from "../../../../components/Flex";
import { TrashIcon } from "../../../../components/icons/TrashIcon";
import { Option, Select } from "../../../../components/Select";
import { Text } from "../../../../components/Text";
import { View } from "../../../../components/View";
import { Days, ScheduleInput } from "../../../../graphql/generated";
import styled from "../../../../styles";

function generateRandomId(): string {
  return Math.random().toString(36).substr(2, 9);
}

interface ScheduleWithId extends ScheduleInput {
  id: string;
}

export const RepeatEvent = (props: {
  schedules: ScheduleInput[];
  setSchedules: (schedules: ScheduleInput[]) => void;
}) => {
  const addSchedule = () => {
    const newSchedules = [...props.schedules];
    newSchedules.push({
      days: [
        Days.Monday,
        Days.Tuesday,
        Days.Wednesday,
        Days.Thursday,
        Days.Friday,
        Days.Saturday,
        Days.Sunday,
      ],
      startHour: 10,
      startMinute: 0,
    });
    props.setSchedules(newSchedules);
  };

  const localSchedules: ScheduleWithId[] = props.schedules.map((s, index) => ({
    ...s,
    id: generateRandomId(),
  }));

  if (localSchedules.length === 0) {
    addSchedule();
  }

  return (
    <View margin="xl 0 0">
      {localSchedules.map((s, index) => (
        <Schedule
          key={s.id}
          id={s.id}
          index={index}
          initialValue={s}
          onChange={(schedule) => {
            const newSchedules = props.schedules.map((existingSchedule, i) =>
              i === index ? { ...schedule } : existingSchedule
            );
            props.setSchedules(newSchedules);
          }}
          onRemove={() => {
            props.setSchedules(props.schedules.filter((_, i) => i !== index));
          }}
        />
      ))}
      <Flex margin="l 0 0 0">
        <div>
          <Button buttonType="add" size="s" onClick={addSchedule}>
            + Add Schedule
          </Button>
        </div>
      </Flex>
    </View>
  );
};

interface ScheduleProps {
  id: string;
  index: number;
  onChange: (schedule: ScheduleInput) => void;
  onRemove: () => void;
  initialValue?: ScheduleWithId;
}

function getHours(): Option[] {
  const hours: Option[] = [];
  for (let i = 0; i < 24; i++) {
    hours.push({
      value: {
        hour: i,
        minute: 0,
      },
      label: `${i < 10 ? "0" : ""}${i}`,
    });
  }
  return hours;
}

const Schedule = (props: ScheduleProps) => {
  const [days, setDays] = useState<Days[]>(
    props.initialValue ? props.initialValue.days : []
  );
  const [hour, setHour] = useState<number>(
    props.initialValue ? props.initialValue.startHour : 0
  );
  const [minute, setMinute] = useState<number>(
    props.initialValue ? props.initialValue.startMinute : 0
  );

  return (
    <ScheduleWrap>
      {props.index === 0 ? null : (
        <>
          <CardDivider margin="xl 0" />
          <TrashWrap
            justify="center"
            align="center"
            margin="0 0 0 m"
            onClick={() => {
              props.onRemove();
            }}
            style={{
              cursor: "pointer",
              display: props.index === 0 ? "none" : "flex",
            }}
          >
            <TrashIcon width={14} />
          </TrashWrap>
        </>
      )}

      <Text weight="bold" margin="xxl 0 s">
        Available Days
      </Text>
      <DayPicker
        initialValue={dayEnumToDays(days)}
        onChange={(days) => {
          setDays(daysToDaysEnum(days));
          props.onChange({
            days: daysToDaysEnum(days),
            startHour: hour,
            startMinute: minute,
          });
        }}
      />
      <Text weight="bold" margin="l 0 s 0">
        Time
      </Text>
      <Flex align="center">
        <Select
          name="hour"
          value={`${hour < 10 ? "0" : ""}${hour.toString()}`}
          onSelect={(option) => {
            setHour(option.value.hour);
            props.onChange({
              days,
              startHour: option.value.hour,
              startMinute: minute,
            });
          }}
          options={getHours()}
        ></Select>
        <Text weight="bold" margin="0 s">
          :
        </Text>
        <Select
          name="minute"
          value={minute.toString()}
          onSelect={(option) => {
            setMinute(option.value);
            props.onChange({
              days,
              startHour: hour,
              startMinute: option.value,
            });
          }}
          options={[
            { label: "00", value: 0 },
            { label: "15", value: 15 },
            { label: "30", value: 30 },
            { label: "45", value: 45 },
          ]}
        ></Select>
      </Flex>
    </ScheduleWrap>
  );
};

const ScheduleWrap = styled.div`
  position: relative;
  margin-bottom: ${(p) => p.theme.spacing.xl};
`;

const TrashWrap = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: ${(p) => p.theme.spacing.xl};
  right: 0;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.typography.text};
      }
    }
  }
`;
