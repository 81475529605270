import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

interface Props extends React.SVGProps<SVGSVGElement> {
  opacity?: number;
}

const PlayIconSVG = (props: Props) => (
  <svg
    width="48"
    height="54"
    viewBox="0 0 48 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={props.opacity ? props.opacity : 0.75}
      d="M4.92383 53.0977C5.56836 53.0977 6.19336 52.9902 6.79883 52.7754C7.4043 52.5605 8.06836 52.2383 8.79102 51.8086L43.5957 31.6523C44.9043 30.8906 45.8613 30.1582 46.4668 29.4551C47.0723 28.752 47.375 27.8926 47.375 26.877C47.375 25.8809 47.0723 25.0312 46.4668 24.3281C45.8613 23.6055 44.9043 22.873 43.5957 22.1309L8.79102 1.94531C8.06836 1.53516 7.4043 1.22266 6.79883 1.00781C6.19336 0.792969 5.56836 0.685547 4.92383 0.685547C3.6543 0.685547 2.58984 1.14453 1.73047 2.0625C0.890625 2.96094 0.470703 4.19141 0.470703 5.75391V48C0.470703 49.582 0.890625 50.8223 1.73047 51.7207C2.58984 52.6387 3.6543 53.0977 4.92383 53.0977Z"
      fill="white"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const PlayIcon = styled(PlayIconSVG)<Props>`
  margin-left: 2px;
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
