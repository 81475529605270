import styled, { css, ThemeProp } from "../styles";

export const Radio = styled.div<{
  active: boolean;
  isDisabled?: boolean;
  width?: number;
  height?: number;
}>`
  width: ${(p) => p.width || 14}px;
  height: ${(p) => p.height || 14}px;
  border-radius: 20px;
  border: ${(p) => (p.width && p.width > 14 ? 1.5 : 1)}px solid
    ${(p) => p.theme.color.typography.secondary};

  padding: ${(p) => (p.width && p.width > 14 ? 3 : 2)}px;

  opacity: ${(p) => (p.isDisabled ? 0.2 : 1)};

  ${(p: ThemeProp & { width?: number; height?: number; active: boolean }) =>
    p.active
      ? css`
          background: ${(p) => p.theme.color.primary} content-box;
          border: ${(p: ThemeProp & { width?: number; height?: number }) =>
              p.width && p.width > 14 ? 1.5 : 1}px
            solid ${(p) => p.theme.color.primary};
        `
      : ``};
`;
