import { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled from "../../styles";
import { Logo } from "../Logo";
import { SegmentedControl, SegmentOptions } from "../SegmentedControl";
import { AccountSwitcher } from "../AccountSwitcher";
import { useWindowSize } from "../../hooks/useWindowSize";
import { MOBILE_BREAKPOINT } from "../../config";
import { BookingStatus, useMyBookingsQuery } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";

const Wrap = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.l};
  padding-top: ${(p) => p.theme.spacing.l};

  @media (min-width: 500px) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`;

const NavWrap = styled.div`
  display: flex;
  justify-content: center;

  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 3;

  margin-top: ${(p) => p.theme.spacing.l};

  @media (min-width: 500px) {
    margin-top: 0;
    grid-row-start: auto;
    grid-column-start: auto;
    grid-column-end: auto;
  }

  & > div {
    max-width: 500px;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

export interface MatchParams {
  page: string;
}

export const InfluencerHeader = () => {
  const [page, setPage] = useState("map");
  let pageMatch = useRouteMatch<MatchParams>("/i/:page");
  const history = useHistory();
  const location = useLocation();
  const window = useWindowSize();
  const client = useGqlClient();
  const { data } = useMyBookingsQuery(
    client,
    {},
    {
      retry: false,
    }
  );

  const confirmedCount =
    data &&
    data.bookings.bookings.filter(
      (a) =>
        a.confirmedTimeslot && a.status === BookingStatus.BookingStatusApproved
    ).length;

  useEffect(() => {
    if (pageMatch) {
      setPage(pageMatch.params.page);
    }
  }, [pageMatch]);

  if (location.pathname.endsWith("settings")) {
    return null;
  }

  if (window.width !== undefined && window.width <= MOBILE_BREAKPOINT) {
    if (
      !location.pathname.endsWith("bookings") &&
      !location.pathname.endsWith("map")
    ) {
      return null;
    }
  }

  const options: SegmentOptions<string>[] = [
    {
      label: "Map",
      value: "map",
    },
    {
      label: "Bookings",
      value: "bookings",
      count: confirmedCount && confirmedCount > 0 ? confirmedCount : undefined,
    },
  ];

  return (
    <Wrap>
      <Left>
        <Logo />
      </Left>
      <NavWrap>
        <SegmentedControl
          value={page}
          options={options}
          onChange={(e) => {
            setPage(e);
            history.push(`/i/${e}`);
          }}
        />
      </NavWrap>
      <Right>
        <AccountSwitcher />
      </Right>
    </Wrap>
  );
};
