import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { CollapsableSection } from "../../../components/CollapsableSection";
import { Flex } from "../../../components/Flex";
import { ActiveStatusIcon } from "../../../components/icons/ActiveStatusIcon";
import { CompleteStatusIcon } from "../../../components/icons/CompleteStatusIcon";
import { DraftStatusIcon } from "../../../components/icons/DraftStatusIcon";
import { InfoIcon } from "../../../components/icons/InfoIcon";
import { ScheduledStatusIcon } from "../../../components/icons/ScheduledStatusIcon";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { BrandListingsQuery, ListingStatus } from "../../../graphql/generated";
import { useTheme } from "../../../hooks/useTheme";
import styled, { css } from "../../../styles";
import {
  getListingEndDate,
  getListingStartDate,
} from "../../../utils/listings";

export const ListView = ({
  listings,
}: {
  listings: BrandListingsQuery["listings"]["listings"];
}) => {
  const active = listings.filter(
    (l) => l.status === ListingStatus.StatusActive
  );

  const scheduled = listings.filter(
    (l) => l.status === ListingStatus.StatusScheduled
  );

  const draft = listings.filter((l) => l.status === ListingStatus.StatusDraft);

  const completed = listings
    .filter(
      (l) =>
        l.status === ListingStatus.StatusExpired ||
        l.status === ListingStatus.StatusUnknown
    )
    .sort((a, b) => {
      return b.endDate! - a.endDate!;
    });

  return (
    <ListWrap>
      <View margin="m 0 xl 0">
        <CollapsableSection
          transparentPanelBackground={true}
          alwaysOpen={true}
          defaultOpen={true}
          title="Active"
          badgeCount={active.length}
          chevronPlacement="left"
          panelHorizontalPadding="none"
          verticalPaddingHeight="s"
          headerSize="m"
        >
          <ListingTable type="active" listings={active} />
        </CollapsableSection>
      </View>
      <View margin="0 0 xl 0">
        <CollapsableSection
          alwaysOpen={true}
          transparentPanelBackground={true}
          defaultOpen={true}
          title="Scheduled"
          badgeCount={scheduled.length}
          chevronPlacement="left"
          panelHorizontalPadding="none"
          verticalPaddingHeight="s"
          headerSize="m"
        >
          <ListingTable type="scheduled" listings={scheduled} />
        </CollapsableSection>
      </View>
      <View margin="0 0 xl 0">
        <CollapsableSection
          transparentPanelBackground={true}
          defaultOpen={false}
          title="Draft"
          badgeCount={draft.length}
          chevronPlacement="left"
          panelHorizontalPadding="none"
          verticalPaddingHeight="s"
          headerSize="m"
        >
          <ListingTable type="draft" listings={draft} />
        </CollapsableSection>
      </View>
      <View margin="0 0 xl 0">
        <CollapsableSection
          transparentPanelBackground={true}
          defaultOpen={false}
          title="Completed"
          badgeCount={completed.length}
          chevronPlacement="left"
          panelHorizontalPadding="none"
          verticalPaddingHeight="s"
          headerSize="m"
        >
          <ListingTable type="completed" listings={completed} />
        </CollapsableSection>
      </View>
    </ListWrap>
  );
};

const ListWrap = styled.div`
  margin: 0 ${(p) => p.theme.spacing.l};
  overflow-y: scroll;
`;

const NoListingsCard = styled.div<{ warning?: boolean }>`
  margin-top: ${(p) => p.theme.spacing.l};
  border: 1px dashed
    ${(p) =>
      p.warning
        ? p.theme.color.warning
        : `${p.theme.color.typography.secondary}70`};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  background: ${(p) => (p.warning ? p.theme.color.warning : "transparent")}18;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 150px;
`;

const ListingTable = (props: {
  listings: BrandListingsQuery["listings"]["listings"];
  type: "active" | "scheduled" | "draft" | "completed";
}) => {
  const theme = useTheme();
  const history = useHistory();

  if (props.listings.length === 0) {
    return (
      <NoListingsCard
        warning={props.type === "active" || props.type === "scheduled"}
      >
        <InfoIcon
          width={16}
          color={
            props.type === "active" || props.type === "scheduled"
              ? theme.color.warning
              : theme.color.typography.secondary
          }
        />
        <Text
          size="xs"
          margin="s 0 0"
          color={
            props.type === "active" || props.type === "scheduled"
              ? theme.color.warning
              : theme.color.typography.secondary
          }
        >
          No {props.type} campaigns
        </Text>
      </NoListingsCard>
    );
  }

  return (
    <TableWrap>
      <Table>
        <thead>
          <tr>
            <th scope="col" className="nameColumn">
              Name
            </th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Applications</th>
            <th scope="col">Bookings</th>
            <th scope="col" className="locationsColumn">
              Locations
            </th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {props.listings.map((l) => {
            const startDate = getListingStartDate(l);
            const endDate = l.endDate ? getListingEndDate(l) : null;
            return (
              <Row
                key={l.id}
                onClick={() => history.push(`/b/listings/${l.id}`)}
              >
                <td className="nameColumn">
                  <Text
                    margin="0"
                    colorPreset="text"
                    isCompact
                    truncate
                    size="s"
                  >
                    {l.name}
                  </Text>
                </td>
                <td>
                  <Text margin="0" colorPreset="text" truncate size="s">
                    {startDate ? format(startDate, "d/M/yyyy") : " - "}
                  </Text>
                </td>
                <td>
                  <Text margin="0" colorPreset="text" size="s" truncate>
                    {endDate ? format(endDate, "d/M/yyyy") : " - "}
                  </Text>
                </td>
                <td>
                  <Text margin="0" colorPreset="text" size="s">
                    {l.applicationsCount}
                  </Text>
                </td>
                <td>
                  <Text margin="0" colorPreset="text" size="s">
                    {l.bookingsCount}
                  </Text>
                </td>
                <td className="locationsColumn">
                  <Text margin="0" colorPreset="text" size="s" truncate>
                    {l.locations.length}
                  </Text>
                </td>
                <td>
                  <Flex align="center" justify="flex-start">
                    <div>
                      <StatusTag status={l.status} />
                    </div>
                  </Flex>
                </td>
              </Row>
            );
          })}
        </tbody>
      </Table>
    </TableWrap>
  );
};

export function StatusTag(props: { status: ListingStatus }) {
  const theme = useTheme();
  if (props.status === ListingStatus.StatusActive) {
    return (
      <TagWrap color="green">
        <ActiveStatusIcon color={theme.color.pills.greenValue} />
        <span>Active</span>
      </TagWrap>
    );
  }

  if (props.status === ListingStatus.StatusScheduled) {
    return (
      <TagWrap color="blue">
        <ScheduledStatusIcon color={theme.color.pills.blueValue} />
        <span>Scheduled</span>
      </TagWrap>
    );
  }

  if (props.status === ListingStatus.StatusDraft) {
    return (
      <TagWrap color="yellow">
        <DraftStatusIcon color={theme.color.pills.yellowValue} />
        <span>Draft</span>
      </TagWrap>
    );
  }

  if (props.status === ListingStatus.StatusExpired) {
    return (
      <TagWrap color="grey">
        <CompleteStatusIcon color={theme.color.pills.greyValue} />
        <span>Completed</span>
      </TagWrap>
    );
  }

  return <TagWrap color="grey">Unknown</TagWrap>;
}

const TableWrap = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

const TagWrap = styled.div`
  padding: 5px 10px;
  font-size: ${(p) => p.theme.typography.size.xs};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    margin-bottom: -2px;
  }

  ${(p) => {
    if (p.color === "green") {
      return css`
        background-color: ${p.theme.color.pills.greenBackground};
        color: ${p.theme.color.pills.greenValue};
      `;
    } else if (p.color === "blue") {
      return css`
        background-color: ${p.theme.color.pills.blueBackground};
        color: ${p.theme.color.pills.blueValue};
      `;
    } else if (p.color === "yellow") {
      return css`
        background-color: ${p.theme.color.pills.yellowBackground};
        color: ${p.theme.color.pills.yellowValue};
      `;
    } else {
      return css`
        background-color: ${p.theme.color.pills.greyBackground};
        color: ${p.theme.color.pills.greyValue};
      `;
    }
  }}
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  box-sizing: border-box;

  .nameColumn {
    width: 280px;
  }

  .locationsColumn {
    width: 160px;
  }

  thead {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
    overflow-y: scroll;
    width: 100%;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    text-align: left;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
    width: 120px;
  }

  th {
    font-size: ${(p) => p.theme.typography.size.xxs};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    color: ${(p) => p.theme.color.typography.secondary};
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l}
      ${(p) => p.theme.spacing.xs};
    background-color: ${(p) =>
      p.theme.color.card.background}; // Add background color to header
  }
`;

const Row = styled.tr`
  display: table;
  width: 100%;
  cursor: pointer;
  table-layout: fixed;

  background-color: ${(p) => p.theme.color.card.background};
  border-bottom: 1px solid ${(p) => p.theme.color.divider};

  &:last-of-type {
    border-bottom: none;
  }

  :hover {
    background-color: ${(p) => p.theme.color.card.callout};
  }
`;
