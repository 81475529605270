import { useEffect, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import styled from "../styles";

const Container = styled.div<{
  enabled: boolean;
  size?: "s";
  isDisabled?: boolean;
}>`
  cursor: pointer;
  width: ${(p) => (p.size === "s" ? 1.75 : 2)}rem;
  height: ${(p) => (p.size === "s" ? 0.875 : 1)}rem;
  border: ${(p) => (p.size === "s" ? 3 : 3)}px
    ${(p) =>
      p.enabled ? p.theme.color.primary : p.theme.color.typography.secondary}
    solid;
  border-radius: 999px;

  & * {
    z-index: 2;
  }

  ${(p) =>
    p.isDisabled
      ? `
    cursor: not-allowed;
    opacity: 0.5;
  `
      : ""}

  background: ${(p) =>
    p.enabled ? p.theme.color.primary : p.theme.color.typography.secondary};

  -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
`;

const Puck = styled.div<{ size?: "s" }>`
  background: white;
  width: ${(p) => (p.size === "s" ? 0.875 : 1)}rem;
  height: ${(p) => (p.size === "s" ? 0.875 : 1)}rem;
  -webkit-transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 999px;
  background: rgba(255, 255, 255, 0.95);
`;

export type SwitchProps = {
  value: boolean;
  onChange: () => void;
  className?: any;
  size?: "s";
  isDisabled?: boolean;
};

export function Switch(props: SwitchProps) {
  const [x, setX] = useState(0);
  const size = useWindowSize();

  useEffect(() => {
    setX(props.value ? (props.size === "s" ? 0.875 : 1) : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, size]);

  return (
    <Container
      onClick={() => {
        props.onChange();
      }}
      isDisabled={props.isDisabled}
      size={props.size}
      enabled={props.value}
      className={props.className}
    >
      <Puck
        size={props.size}
        style={{
          transform: `translate3d(${x}rem, 0, 0)`,
        }}
      />
    </Container>
  );
}
