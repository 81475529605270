import { useTheme } from "../../hooks/useTheme";

interface Props {
  width?: number;
}

export const CommentsIcon = (props: Props) => {
  const theme = useTheme();
  return (
    <svg
      width={props.width ? props.width : 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="46"
        height="46"
        rx="23"
        stroke="url(#paint0_linear_2058_3891)"
        stroke-width="2"
      />
      <path
        d="M20.625 22.25C20.625 22.3495 20.5855 22.4448 20.5152 22.5152C20.4448 22.5855 20.3495 22.625 20.25 22.625C20.1505 22.625 20.0552 22.5855 19.9848 22.5152C19.9145 22.4448 19.875 22.3495 19.875 22.25C19.875 22.1505 19.9145 22.0552 19.9848 21.9848C20.0552 21.9145 20.1505 21.875 20.25 21.875C20.3495 21.875 20.4448 21.9145 20.5152 21.9848C20.5855 22.0552 20.625 22.1505 20.625 22.25ZM20.625 22.25H20.25M24.375 22.25C24.375 22.3495 24.3355 22.4448 24.2652 22.5152C24.1948 22.5855 24.0995 22.625 24 22.625C23.9005 22.625 23.8052 22.5855 23.7348 22.5152C23.6645 22.4448 23.625 22.3495 23.625 22.25C23.625 22.1505 23.6645 22.0552 23.7348 21.9848C23.8052 21.9145 23.9005 21.875 24 21.875C24.0995 21.875 24.1948 21.9145 24.2652 21.9848C24.3355 22.0552 24.375 22.1505 24.375 22.25ZM24.375 22.25H24M28.125 22.25C28.125 22.3495 28.0855 22.4448 28.0152 22.5152C27.9448 22.5855 27.8495 22.625 27.75 22.625C27.6505 22.625 27.5552 22.5855 27.4848 22.5152C27.4145 22.4448 27.375 22.3495 27.375 22.25C27.375 22.1505 27.4145 22.0552 27.4848 21.9848C27.5552 21.9145 27.6505 21.875 27.75 21.875C27.8495 21.875 27.9448 21.9145 28.0152 21.9848C28.0855 22.0552 28.125 22.1505 28.125 22.25ZM28.125 22.25H27.75M14.25 25.26C14.25 26.86 15.373 28.254 16.957 28.487C18.044 28.647 19.142 28.77 20.25 28.856V33.5L24.434 29.317C24.6412 29.1107 24.9197 28.9918 25.212 28.985C27.1636 28.937 29.1105 28.7707 31.042 28.487C32.627 28.254 33.75 26.861 33.75 25.259V19.241C33.75 17.639 32.627 16.246 31.043 16.013C28.711 15.6707 26.357 15.4993 24 15.5C21.608 15.5 19.256 15.675 16.957 16.013C15.373 16.246 14.25 17.64 14.25 19.241V25.259V25.26Z"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2058_3891"
          x1="0"
          y1="24.0005"
          x2="47.9991"
          y2="24.0005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
