import { Route, Switch } from "react-router-dom";
import SignupRoute from "../../../components/SignupRoute";
import { Signup } from "../../Signup";
import { ConfirmDetails } from "../../signup/ConfirmDetails";
import { ConnectSocials } from "../../signup/ConnectSocials";
import { BrandLogin } from "../../unauthenticated/brands/login";
import { CreateBrand } from "./CreateBrand";
import { CreateLocation } from "./CreateLocation";
import { DescribeBrand } from "./DescribeBrand";

export const SignupRoot = () => {
  return (
    <Switch>
      <Route path="/signup" exact>
        <Signup />
      </Route>
      <SignupRoute exact={true} path="/signup/i/connect-socials">
        <ConnectSocials />
      </SignupRoute>
      <SignupRoute exact={true} path="/signup/i/confirm-details">
        <ConfirmDetails accountType="creator" />
      </SignupRoute>
      <SignupRoute exact={true} path="/signup/b/confirm-details">
        <ConfirmDetails accountType="brand" />
      </SignupRoute>
      <SignupRoute exact={true} path="/signup/b/describe-brand">
        <DescribeBrand />
      </SignupRoute>
      <SignupRoute exact={true} path="/signup/b/create-brand">
        <CreateBrand />
      </SignupRoute>
      <SignupRoute exact={true} path="/signup/b/create-location">
        <CreateLocation />
      </SignupRoute>
      <Route exact={true} path="/signup/b">
        <BrandLogin mode="signup" />
      </Route>
    </Switch>
  );
};
