import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const ActiveStatusSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height="15"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.3335 10.0002L10.0002 7.00016L5.3335 4.00016V10.0002ZM7.00016 13.6668C6.07794 13.6668 5.21127 13.4918 4.40016 13.1418C3.58905 12.7918 2.8835 12.3168 2.2835 11.7168C1.6835 11.1168 1.2085 10.4113 0.858496 9.60016C0.508496 8.78905 0.333496 7.92238 0.333496 7.00016C0.333496 6.07794 0.508496 5.21127 0.858496 4.40016C1.2085 3.58905 1.6835 2.8835 2.2835 2.2835C2.8835 1.6835 3.58905 1.2085 4.40016 0.858496C5.21127 0.508496 6.07794 0.333496 7.00016 0.333496C7.92238 0.333496 8.78905 0.508496 9.60016 0.858496C10.4113 1.2085 11.1168 1.6835 11.7168 2.2835C12.3168 2.8835 12.7918 3.58905 13.1418 4.40016C13.4918 5.21127 13.6668 6.07794 13.6668 7.00016C13.6668 7.92238 13.4918 8.78905 13.1418 9.60016C12.7918 10.4113 12.3168 11.1168 11.7168 11.7168C11.1168 12.3168 10.4113 12.7918 9.60016 13.1418C8.78905 13.4918 7.92238 13.6668 7.00016 13.6668ZM7.00016 12.3335C8.48905 12.3335 9.75016 11.8168 10.7835 10.7835C11.8168 9.75016 12.3335 8.48905 12.3335 7.00016C12.3335 5.51127 11.8168 4.25016 10.7835 3.21683C9.75016 2.1835 8.48905 1.66683 7.00016 1.66683C5.51127 1.66683 4.25016 2.1835 3.21683 3.21683C2.1835 4.25016 1.66683 5.51127 1.66683 7.00016C1.66683 8.48905 2.1835 9.75016 3.21683 10.7835C4.25016 11.8168 5.51127 12.3335 7.00016 12.3335Z"
      fill="#3C6040"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const ActiveStatusIcon = styled(ActiveStatusSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.color
        ? p.color
        : "#fff"};
  }
`;
