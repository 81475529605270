import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const UpgradeSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.02539 12.7461C5.20898 12.7461 4.43945 12.5898 3.7168 12.2773C2.99805 11.9648 2.36328 11.5332 1.8125 10.9824C1.26172 10.4316 0.830078 9.79688 0.517578 9.07812C0.205078 8.35547 0.0488281 7.58594 0.0488281 6.76953C0.0488281 5.95312 0.205078 5.18555 0.517578 4.4668C0.830078 3.74414 1.25977 3.10742 1.80664 2.55664C2.35742 2.00586 2.99219 1.57422 3.71094 1.26172C4.43359 0.949219 5.20312 0.792969 6.01953 0.792969C6.83594 0.792969 7.60547 0.949219 8.32812 1.26172C9.05078 1.57422 9.6875 2.00586 10.2383 2.55664C10.7891 3.10742 11.2207 3.74414 11.5332 4.4668C11.8457 5.18555 12.002 5.95312 12.002 6.76953C12.002 7.58594 11.8457 8.35547 11.5332 9.07812C11.2207 9.79688 10.7891 10.4316 10.2383 10.9824C9.6875 11.5332 9.05078 11.9648 8.32812 12.2773C7.60938 12.5898 6.8418 12.7461 6.02539 12.7461ZM6.03711 9.78711C6.16992 9.78711 6.27734 9.74609 6.35938 9.66406C6.44531 9.57812 6.48828 9.4668 6.48828 9.33008V6.32422L6.44141 5.03516L7.05078 5.76758L7.75977 6.48828C7.8418 6.57812 7.94922 6.62305 8.08203 6.62305C8.20703 6.62305 8.3125 6.58203 8.39844 6.5C8.48438 6.41406 8.52734 6.30859 8.52734 6.18359C8.52734 6.05859 8.48633 5.95312 8.4043 5.86719L6.38867 3.86328C6.27539 3.74609 6.1582 3.6875 6.03711 3.6875C5.91602 3.6875 5.79883 3.74609 5.68555 3.86328L3.66992 5.86719C3.58789 5.94922 3.54688 6.05469 3.54688 6.18359C3.54688 6.30859 3.58789 6.41406 3.66992 6.5C3.75586 6.58203 3.86133 6.62305 3.98633 6.62305C4.11133 6.62305 4.21875 6.57812 4.30859 6.48828L5.02344 5.76758L5.62695 5.04102L5.58008 6.32422V9.33008C5.58008 9.4668 5.62305 9.57812 5.70898 9.66406C5.79492 9.74609 5.9043 9.78711 6.03711 9.78711Z" />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const UpgradeIcon = styled(UpgradeSVG)<Props>`
  path {
    fill: ${(p) => p.theme.color.primary} !important;
  }
`;
