import * as React from "react";
import styled from "../../styles";

const GmailIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="42"
    height="32"
    viewBox="0 0 42 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42 8.20312L37 10.9531L32 15.7031V32.0031H39C40.657 32.0031 42 30.6601 42 29.0031V8.20312Z"
      fill="#4CAF50"
    />
    <path
      d="M0 8.20312L3.614 9.91312L10 15.7031V32.0031H3C1.343 32.0031 0 30.6601 0 29.0031V8.20312Z"
      fill="#1E88E5"
    />
    <path
      d="M32 3.20312L21 11.4531L10 3.20312L9 9.00313L10 15.7031L21 23.9531L32 15.7031L33 9.00313L32 3.20312Z"
      fill="#E53935"
    />
    <path
      d="M0 4.298V8.2L10 15.7V3.2L6.876 0.859C6.132 0.301 5.228 0 4.298 0C1.924 0 0 1.924 0 4.298Z"
      fill="#C62828"
    />
    <path
      d="M42 4.298V8.2L32 15.7V3.2L35.124 0.859C35.868 0.301 36.772 0 37.702 0C40.076 0 42 1.924 42 4.298Z"
      fill="#FBC02D"
    />
  </svg>
);

interface Props {
  width?: number;
  height?: number;
}

export const GmailIcon = styled(GmailIconSVG)<Props>`
  width: ${(p) => p.width}px;
  min-width: ${(p) => p.width}px;
  top: 6px;
`;
