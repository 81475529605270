import React from "react";
import styled from "../styles";
import withMargin from "../styles/withMargin";

export interface Option {
  label: string;
  value: any;
}

interface Props {
  margin?: string;
  options: Option[];
  value?: string;
  defaultValue?: string;
  name?: string;
  onSelect: (option: Option) => void;
}

const StyledOption = styled.option`
  color: ${(p) => p.theme.color.typography.text};
  font-weight: ${(p) => p.theme.typography.weight["semi"]};
`;

const SelectWrapper = styled.select`
  display: flex;
  align-items: center;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m};
  cursor: pointer;
  max-width: 128px;
  min-width: 72px;
  background-color: ${(p) => p.theme.color.card.background};
  color: ${(p) => p.theme.color.typography["text"]};
  ${withMargin}

  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
  }

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;

export function Select(props: Props) {
  return (
    <SelectWrapper
      defaultValue={props.defaultValue}
      name={props.name}
      margin={props.margin}
      value={props.value}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
        props.onSelect(
          props.options.find((o) => o.label === e.currentTarget.value)!
        );
      }}
    >
      {props.options.map((o, i) => {
        return (
          <StyledOption key={i} value={o.label}>
            {o.label}
          </StyledOption>
        );
      })}
    </SelectWrapper>
  );
}
