import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";

type Props = {
  direction?: "row" | "column";
  align?: "center" | "baseline" | "flex-start" | "flex-end";
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-evenly";
  wrap?: "wrap";
  backgroundColor?: string;
};

export const Flex = styled.div<Props & WithMarginProp>`
  display: flex;
  flex-direction: ${(p) => (p.direction ? p.direction : "row")};

  ${(p) =>
    p.backgroundColor &&
    css`
      background-color: ${p.backgroundColor};
    `}

  ${(p) =>
    p.align
      ? css`
          align-items: ${p.align};
        `
      : null};

  ${(p) =>
    p.wrap
      ? css`
          flex-wrap: ${p.wrap};
        `
      : null};

  ${(p) =>
    p.justify
      ? css`
          justify-content: ${p.justify};
        `
      : null};

  ${withMargin}
`;
