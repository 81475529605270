import { MOBILE_BREAKPOINT } from "../../config";
import { useWindowSize } from "../../hooks/useWindowSize";
import styled from "../../styles";
import { AccountSwitcher } from "../AccountSwitcher";
import { Logo } from "../Logo";
import { ProgressBar, Steps } from "../ProgressBar";

const Wrap = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const SignupHeader = (props: { steps?: Steps }) => {
  const window = useWindowSize();

  if (window.width && window.width > MOBILE_BREAKPOINT) {
    return (
      <Wrap>
        <Left>
          <Logo />
        </Left>
        <Right>
          <AccountSwitcher isSignupHeader />
        </Right>
      </Wrap>
    );
  }

  return (
    <Wrap>
      <Left>
        <Logo />
      </Left>
      {props.steps && (
        <Right>
          <ProgressBar steps={props.steps} />
        </Right>
      )}
    </Wrap>
  );
};
