import { useTheme } from "../../hooks/useTheme";

export const FollowersIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="54"
        height="54"
        rx="27"
        stroke="url(#paint0_linear_2041_524)"
        stroke-width="2"
      />
      <path
        d="M18 37C17.9999 35.4603 18.4441 33.9533 19.2793 32.6598C20.1146 31.3664 21.3053 30.3414 22.7086 29.708C24.1119 29.0745 25.6682 28.8595 27.1908 29.0886C28.7133 29.3178 30.1373 29.9815 31.292 31"
        stroke={theme.color.typography.text}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26 29C28.7614 29 31 26.7614 31 24C31 21.2386 28.7614 19 26 19C23.2386 19 21 21.2386 21 24C21 26.7614 23.2386 29 26 29Z"
        stroke={theme.color.typography.text}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32 35L34 37L38 33"
        stroke={theme.color.typography.text}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2041_524"
          x1="0"
          y1="28.0006"
          x2="55.999"
          y2="28.0006"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
