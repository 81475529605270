import { format, fromUnixTime, isSameDay } from "date-fns";
import { useEffect, useState } from "react";
import { MOBILE_BREAKPOINT } from "../config";
import {
  GetBookingQuery,
  TimeSlotRequestType,
  TimeSlotsQuery,
  useTimeSlotsQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { useTheme } from "../hooks/useTheme";
import styled from "../styles";
import { Flex } from "./Flex";
import Loader from "./Loader";
import { Text } from "./Text";
import { ToggleButton } from "./ToggleButton";
import { View } from "./View";

interface Props {
  location: GetBookingQuery["booking"]["location"];
  listing: GetBookingQuery["booking"]["listing"];
  selectedTimeSlots: Date[];
  onToggle: (date: Date) => void;
  title?: string;
  m?: string;
}

export function TimeSlots(props: Props) {
  const theme = useTheme();
  const [availableDays, setAvailableDays] = useState<
    TimeSlotsQuery["timeSlots"] | undefined
  >(undefined);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [generatedTimeSlots, setGeneratedTimeSlots] = useState<number[] | null>(
    null
  );
  const [previousSlotCount, setPreviousSlotCount] = useState<number>(6);
  const [showAllDates, setShowAllDates] = useState(false);

  const client = useGqlClient();

  const {
    data: daysData,
    refetch: refetchDaysData,
    isLoading: daysIsLoading,
    isFetching: daysIsFetching,
    isError: daysIsError,
  } = useTimeSlotsQuery(client, {
    listingId: props.listing.id,
    locationId: props.location ? props.location.id : "",
    type: TimeSlotRequestType.TimeslotRequestTypeDays,
  });

  const {
    refetch: refetchTimeslotData,
    isLoading: timeSlotIsLoading,
    isFetching: timeSlotIsFetching,
    isError: timeSlotIsError,
  } = useTimeSlotsQuery(
    client,
    {
      listingId: props.listing ? props.listing.id : "",
      locationId: props.location ? props.location.id : "",
      type: TimeSlotRequestType.TimeslotRequestTypeTimes,
      date: selectedDay,
    },
    {
      enabled: selectedDay !== null,
    }
  );

  useEffect(() => {
    if (generatedTimeSlots) {
      setPreviousSlotCount(generatedTimeSlots.length);
    }
  }, [generatedTimeSlots]);

  useEffect(() => {
    if (selectedDay) {
      refetchTimeslotData().then((res) => {
        if (res.data) {
          setGeneratedTimeSlots(res.data.timeSlots[0].slots);
        }
      });
    }
  }, [selectedDay, refetchTimeslotData]);

  useEffect(() => {
    refetchDaysData().then((res) => {
      if (res.data && res.data.timeSlots && res.data.timeSlots.length !== 0) {
        setAvailableDays(res.data.timeSlots);
        setSelectedDay(res.data.timeSlots[0].date);
      }
    });
  }, [props.location, props.listing, refetchDaysData]);

  if (daysIsLoading || daysIsFetching || !daysData) {
    return (
      <Flex align="center" justify="center" margin="xl 0">
        <Loader />
      </Flex>
    );
  }

  if (daysIsError) {
    return (
      <View margin="0 m">
        <Text align="center">Something went wrong</Text>
      </View>
    );
  }

  return (
    <View margin={props.m}>
      {props.title ? (
        <Text
          margin={"s 0 l 0"}
          size="s"
          color={theme.color.typography.secondary}
        >
          Choose up to 5 time slots when you can visit and the restaurant will
          confirm one.
        </Text>
      ) : null}
      {!availableDays || (availableDays && availableDays.length === 0) ? (
        <Text margin={"s 0 xl 0"} color={theme.color.typography.warning}>
          No availability, please check your campaign settings
        </Text>
      ) : (
        <>
          <DaysWrap>
            {(showAllDates
              ? availableDays.slice(0, 30)
              : availableDays.slice(0, 7)
            ).map((ts) => {
              return (
                <DayWrap
                  key={ts.date}
                  onClick={() => setSelectedDay(ts.date)}
                  active={selectedDay === ts.date}
                >
                  <DayIndicator
                    active={
                      props.selectedTimeSlots.filter((e) =>
                        isSameDay(e, fromUnixTime(ts.date))
                      ).length > 0
                    }
                  >
                    •
                  </DayIndicator>

                  <Dates
                    margin="0 0 0 0"
                    size="s"
                    weight="semi"
                    color={
                      selectedDay === ts.date
                        ? theme.color.primary
                        : theme.color.typography.secondary
                    }
                  >
                    {format(fromUnixTime(ts.date), "MMM")}
                  </Dates>
                  <DayNames
                    margin="0 0 0 0"
                    weight="semi"
                    color={
                      selectedDay === ts.date
                        ? theme.color.primary
                        : theme.color.typography.secondary
                    }
                  >
                    {format(fromUnixTime(ts.date), "do")}
                  </DayNames>
                  <Dates
                    margin="0 0 m 0"
                    size="s"
                    weight="semi"
                    color={
                      selectedDay === ts.date
                        ? theme.color.primary
                        : theme.color.typography.secondary
                    }
                  >
                    {format(fromUnixTime(ts.date), "eee")}
                  </Dates>
                </DayWrap>
              );
            })}
          </DaysWrap>
          {availableDays && availableDays.length > 7 && (
            <Flex align="center" direction="column">
              <ClickableText
                margin={"s 0 m"}
                size="xs"
                weight="semi"
                colorPreset="secondary"
                onClick={() => setShowAllDates((s) => !s)}
              >
                {showAllDates ? "Show fewer dates" : "Show more dates"}
              </ClickableText>
            </Flex>
          )}
        </>
      )}

      <TimeSlotWrapper>
        {timeSlotIsError ? (
          <Text margin="s l" color={theme.color.warning}>
            Something went wrong, please try again later
          </Text>
        ) : !availableDays ||
          (availableDays &&
            availableDays.length === 0) ? null : timeSlotIsLoading ||
          !selectedDay ||
          timeSlotIsFetching ? (
          Array.from({ length: previousSlotCount }, (_, index) => (
            <FakeTimeSlotButton key={index} />
          ))
        ) : (
          <>
            {generatedTimeSlots && generatedTimeSlots.length > 0 ? (
              <>
                {generatedTimeSlots.map((t, i) => (
                  <TimeSlotButton
                    key={i}
                    timeSlot={fromUnixTime(t)}
                    selectedTimeSlots={props.selectedTimeSlots}
                    onToggle={(date: Date) => {
                      props.onToggle(date);
                    }}
                  />
                ))}
              </>
            ) : (
              <Text margin="s l" color={theme.color.warning}>
                No availability
              </Text>
            )}
          </>
        )}
      </TimeSlotWrapper>
    </View>
  );
}

interface TimeSlotProps {
  selectedTimeSlots: Date[];
  timeSlot: Date;
  onToggle: (date: Date) => void;
}

function TimeSlotButton(props: TimeSlotProps) {
  return (
    <ToggleButton
      title={`${format(props.timeSlot, "HH:mm")}`}
      active={
        props.selectedTimeSlots.filter(
          (sts) => sts.getTime() === props.timeSlot.getTime()
        ).length > 0
      }
      margin="0 0 s 0"
      onClick={() => {
        props.onToggle(props.timeSlot);
      }}
    />
  );
}

function FakeTimeSlotButton() {
  return (
    <ToggleButton
      title={` `}
      active={false}
      margin="0 0 s 0"
      onClick={() => {}}
    />
  );
}

const DaysWrap = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: ${(p) => p.theme.spacing.s};
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const DayWrap = styled.div<{ active: boolean }>`
  cursor: pointer;
  background-color: ${(p) =>
    p.active ? p.theme.color.card.callout : "transparent"};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.s} 0 0;
`;

const DayIndicator = styled.div<{ active: boolean }>`
  text-align: center;
  opacity: ${(p) => (p.active ? 1 : 0)};
  line-height: 60%;
  color: ${(p) => p.theme.color.primary};
`;

const DayNames = styled(Text)`
  text-transform: uppercase;
  text-align: center;
  line-height: 130%;
`;
const Dates = styled(Text)`
  /* text-transform: uppercase; */
  text-align: center;
  line-height: 130%;
  opacity: 0.75;
`;

const ClickableText = styled(Text)`
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.color.primary};
  }
`;

const TimeSlotWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.m};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${(p) => p.theme.spacing.s};
`;
