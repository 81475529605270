import { useState } from "react";
import { useTheme } from "../../../hooks/useTheme";
import styled, { css } from "../../../styles";
import { ButtonWrap, CTA } from "../../CTA";
import { SingleCheckBox } from "../../Checkbox";
import { Flex } from "../../Flex";
import Loader from "../../Loader";
import { Text } from "../../Text";
import { TextArea } from "../../TextArea";
import { Tooltip } from "../../Tooltip";
import { View } from "../../View";

const StyledFlex = styled(Flex)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

interface Props {
  onConfirm: (issue: string, blockCreator: boolean) => void;
  onCancel: () => void;
  isLoading: boolean;
  creatorName: string;
  brandName: string;
}

export const ReportIssueOverlay = (props: Props) => {
  const [error, setError] = useState("");
  const [issue, setIssue] = useState("");
  const [blockCreator, setBlockCreator] = useState(false);
  const theme = useTheme();

  return (
    <>
      {props.isLoading ? (
        <Flex align="center" justify="center">
          <Loader />
        </Flex>
      ) : (
        <StyledFlex>
          <Text
            weight="bold"
            size="xl"
            margin={"0 0 0 0"}
            colorPreset="heading"
          >
            Let <span style={{ color: theme.color.primary }}>Joli</span> know
            what the issue is
          </Text>
          <Text margin={"0 0 m 0"} colorPreset="secondary">
            Help us improve your Joli experience by letting us about any issues.{" "}
            <Text margin="0" weight="bold" colorPreset="text" isInline>
              This information will not be shared with @{props.creatorName}.
            </Text>
          </Text>

          <Text weight="bold" margin="l 0 xs">
            Details
          </Text>
          <TextArea
            rows={4}
            placeholder={"Please leave any notes for the Joli team"}
            onChange={(e) => {
              setIssue(e.currentTarget.value);
            }}
          />
          {error ? (
            <Text margin="0 0 0" colorPreset="error">
              {error}
            </Text>
          ) : null}

          <Flex direction="row" margin="l 0 0">
            <PointerFlex
              dir="row"
              align="center"
              onClick={() => setBlockCreator(!blockCreator)}
            >
              <SingleCheckBox checked={blockCreator} />
              <Text margin="0 s 0 s">Block future applications</Text>{" "}
            </PointerFlex>
            <Tooltip>
              <Text size="s" margin="0">
                {`@${props.creatorName} will lose access to all current and future ${props.brandName} listings`}
              </Text>
              <Text size="xs" margin="0" colorPreset="secondary">
                They will not be aware of this action
              </Text>
            </Tooltip>
          </Flex>

          <ButtonWrap margin="xl 0 0">
            <CTA type="secondary" to="#" onClick={props.onCancel}>
              Cancel
            </CTA>
            <CTA
              to="#"
              onClick={() => {
                if (!issue) {
                  setError("Please provide some more details about the issue");
                }
                props.onConfirm(issue, blockCreator);
              }}
            >
              {props.isLoading ? "Loading..." : "Submit"}
            </CTA>
          </ButtonWrap>
        </StyledFlex>
      )}
    </>
  );
};

export const Alert = styled(View)`
  background-color: ${(p) => p.theme.color.secondary}30;
  border-left: 3px solid ${(p) => p.theme.color.secondary};
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;

  color: ${(p) => p.theme.color.secondary};
`;

const PointerFlex = styled(Flex)<{ showUpgradePrompt?: boolean }>`
  cursor: pointer;
  user-select: none;

  ${(p) =>
    p.showUpgradePrompt
      ? css`
          pointer-events: none;
          opacity: 0.44;
        `
      : css`
          opacity: 1;
        `}
`;
