import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const LocationsSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2477 19V11.5C12.2477 11.3011 12.3267 11.1103 12.4673 10.9697C12.608 10.829 12.7988 10.75 12.9977 10.75H15.9977C16.1966 10.75 16.3873 10.829 16.528 10.9697C16.6687 11.1103 16.7477 11.3011 16.7477 11.5V19M12.2477 19H1.10767M12.2477 19H16.7477M16.7477 19H20.3877M18.9977 19V7.349M18.9977 7.349C18.3978 7.6951 17.6966 7.82304 17.0131 7.71113C16.3296 7.59922 15.7059 7.25435 15.2477 6.735C14.6977 7.357 13.8937 7.75 12.9977 7.75C12.5719 7.75041 12.151 7.66 11.763 7.48479C11.375 7.30959 11.0289 7.05363 10.7477 6.734C10.1977 7.357 9.39367 7.75 8.49767 7.75C8.07195 7.75041 7.65104 7.66 7.26304 7.48479C6.87504 7.30959 6.52888 7.05363 6.24767 6.734C5.78959 7.25351 5.16591 7.59858 4.48242 7.71067C3.79892 7.82276 3.09769 7.69498 2.49767 7.349M18.9977 7.349C19.3963 7.11891 19.7366 6.80019 19.9923 6.41751C20.248 6.03484 20.4122 5.59846 20.4722 5.14215C20.5323 4.68584 20.4865 4.22184 20.3385 3.78605C20.1905 3.35026 19.9442 2.95436 19.6187 2.629L18.4287 1.44C18.1476 1.15862 17.7664 1.00035 17.3687 1H4.12567C3.72812 1.00008 3.34687 1.15798 3.06567 1.439L1.87667 2.629C1.55191 2.95474 1.30629 3.3507 1.15874 3.78637C1.01119 4.22204 0.965667 4.68577 1.02567 5.14182C1.08567 5.59786 1.24959 6.03403 1.50481 6.41671C1.76002 6.79939 2.09971 7.11835 2.49767 7.349M2.49767 19V7.349M5.49767 16H9.24767C9.44658 16 9.63735 15.921 9.778 15.7803C9.91865 15.6397 9.99767 15.4489 9.99767 15.25V11.5C9.99767 11.3011 9.91865 11.1103 9.778 10.9697C9.63735 10.829 9.44658 10.75 9.24767 10.75H5.49767C5.29876 10.75 5.10799 10.829 4.96734 10.9697C4.82669 11.1103 4.74767 11.3011 4.74767 11.5V15.25C4.74767 15.664 5.08367 16 5.49767 16Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const LocationsIcon = styled(LocationsSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
