import styled from "../styles";

export const Page = styled.div<{ noHorizontalPadding?: true }>`
  padding: 0 ${(p) => (p.noHorizontalPadding ? 0 : p.theme.spacing.l)};
  padding-bottom: ${(p) => (p.noHorizontalPadding ? 0 : p.theme.spacing.l)};
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    padding: 0 ${(p) => (p.noHorizontalPadding ? 0 : p.theme.spacing.xl)};
    padding-bottom: ${(p) => (p.noHorizontalPadding ? 0 : p.theme.spacing.xl)};
  }
`;
