import { useTheme } from "../hooks/useTheme";
import RocketEmoji from "../images/rocket-emoji.png";
import styled, { ThemeProp } from "../styles";
import { Flex } from "./Flex";
import { Text } from "./Text";
import { View } from "./View";
import { InfoIcon } from "./icons/InfoIcon";

type StyledViewProps = ThemeProp & {
  type: "success" | "info" | "warning";
};

const StyledView = styled(View)<StyledViewProps>`
  background-color: ${(p: StyledViewProps) =>
    p.type === "success"
      ? p.theme.color.stats.positiveBackground
      : p.type === "warning"
      ? `${p.theme.color.warning}18`
      : p.theme.color.card.callout};
  border: 1px solid
    ${(p) =>
      p.type === "success"
        ? p.theme.color.stats.positiveValue
        : p.type === "warning"
        ? p.theme.color.warning
        : `transparent`};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  flex: 1;
`;

export function Callout(props: {
  type: "success" | "info" | "warning";
  header?: string;
  text?: string;
  smallPrint?: string;
  children?: React.ReactNode;
  emoji?: "rocket";
  align?: "flex-start" | "center";
}) {
  const theme = useTheme();

  return (
    <StyledView type={props.type} padding="s m">
      <Flex direction="row" align={props.align ? props.align : "center"}>
        <View margin="0 s 0 0">
          {props.type === "info" ? (
            <div style={{ marginTop: 3 }}>
              <InfoIcon color={theme.color.typography.text} />
            </div>
          ) : null}
        </View>
        <View>
          <Flex align="center">
            <Text
              margin="0 0 0"
              size="s"
              weight="bold"
              color={
                props.type === "success"
                  ? theme.color.stats.positiveValue
                  : theme.color.typography.text
              }
            >
              {props.header}
            </Text>
            {props.emoji === "rocket" ? (
              <div style={{ marginBottom: -3, marginLeft: 3 }}>
                <img alt="rocket icon" width={16} src={RocketEmoji} />
              </div>
            ) : null}
          </Flex>
          <Text
            margin="0"
            size="s"
            color={
              props.type === "success"
                ? theme.color.stats.positiveValue
                : theme.color.typography.text
            }
          >
            {props.text}
          </Text>
          {props.smallPrint ? (
            <Text
              margin="0"
              size="xs"
              isCompact
              style={{ opacity: 0.8 }}
              color={
                props.type === "success"
                  ? theme.color.stats.positiveValue
                  : theme.color.typography.text
              }
            >
              {props.smallPrint}
            </Text>
          ) : null}
          {props.children}
        </View>
      </Flex>
    </StyledView>
  );
}
