import { Switch } from "react-router-dom";
import { InfluencerHeader } from "../../components/Influencer/InfluencerHeader";
import { Page } from "../../components/Page";

export const InfluencerRoot = () => {
  return (
    <Page>
      <InfluencerHeader />
      <Switch>{/* Now redirects to app store now */}</Switch>
    </Page>
  );
};
