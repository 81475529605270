import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import ScrollToTop from "./hooks/scrollToTop";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

const render = async () => {
  let persistor = persistStore(store);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

let releaseName = "dev";

if (process.env.NODE_ENV === "production") {
  releaseName =
    "nibble-web-" +
    process.env.REACT_APP_CONTEXT +
    "@" +
    process.env.REACT_APP_COMMIT_REF;
}

Sentry.init({
  dsn: "https://bff5457d4faf4fe9823cfbcfe251929e@o673510.ingest.sentry.io/5768222",
  integrations: [new Sentry.BrowserTracing()],
  environment: process.env.NODE_ENV,
  release: releaseName,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
