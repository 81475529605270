import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { toast } from "sonner";
import { BackLink } from "../../../components/BackLink";
import { SignupHeader } from "../../../components/Brand/SignupHeader";
import { Submit } from "../../../components/CTA";
import { Card } from "../../../components/Card";
import { Chevron } from "../../../components/Chevron";
import { Flex } from "../../../components/Flex";
import { H1 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import { ProgressBar } from "../../../components/ProgressBar";
import { Steps } from "../../../components/Steps";
import { Subtitle } from "../../../components/Subtitle";
import { Text } from "../../../components/Text";
import { FakeTextLink } from "../../../components/TextLink";
import { ToggleButton } from "../../../components/ToggleButton";
import { MOBILE_BREAKPOINT } from "../../../config";
import { useCreateBrandMutation } from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { actions } from "../../../store/auth/slice";
import { styled } from "../../../styles";
import { brandCategories } from "./DescribeBrand";

const CategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: ${(p) => p.theme.spacing.s};
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
`;

const DesktopOnlySubtitle = styled(Subtitle)`
  margin-top: -${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.s};

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SignupCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.l};
  }
`;

export interface MatchParams {
  page: string;
}

export const CreateBrand = () => {
  const window = useWindowSize();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();
  const { showNewMessages } = useIntercom();

  const client = useGqlClient();
  const createBrand = useCreateBrandMutation(client);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (createBrand.isLoading) {
      return;
    }

    if (!name || name === "") {
      setNameError("Please enter a name for your business");
      return;
    }

    if (selectedCategories.length === 0) {
      setError("Please choose at least one category");
      return;
    }

    createBrand.mutate(
      {
        input: {
          name,
          categories: selectedCategories,
        },
      },
      {
        onSuccess: (data) => {
          toast.success("Brand created");
          track("Brand created", {
            id: data.createBrand.id,
            brand: data.createBrand.name,
          });

          queryClient
            .resetQueries(["Accounts"], {
              exact: false,
            })
            .then(() => {
              history.push("/signup/b/create-location");
              dispatch(
                actions.setActiveBrand({ brandId: data.createBrand.id })
              );
            });
        },
        onError: () => {
          setError("Something went wrong");
        },
      }
    );
  };

  return (
    <Wrap>
      {window.width && window.width < MOBILE_BREAKPOINT ? (
        <Flex margin="xs 0 xl 0" justify="space-between" align="center">
          <BackLink margin="0 0 0" to="/signup/b/confirm-details">
            <Chevron direction="left" /> Back
          </BackLink>
          <ProgressBar steps={{ currentStep: 2, totalSteps: 4 }} />
        </Flex>
      ) : (
        <>
          <SignupHeader />
        </>
      )}

      <ContentWrap>
        <form onSubmit={onSubmit}>
          <SignupCard>
            <H1 margin="0 0 0 0">Describe your business</H1>
            <Text margin="xs 0 s">
              Looking to join an existing team?{" "}
              <FakeTextLink
                onClick={() =>
                  showNewMessages(
                    "Hi Joli team 👋 Can you help me join an existing team?"
                  )
                }
              >
                Chat to us
              </FakeTextLink>
            </Text>
            <Input
              name="name"
              error={nameError}
              value={name}
              autoFocus
              onChange={(e) => {
                setNameError("");
                setName(e.currentTarget.value);
              }}
              margin="m 0 xl 0"
              label="Name"
              placeholder="e.g. Honest Burgers"
            />
            <Text weight="semi" margin="0 0 s">
              Category
            </Text>
            <DesktopOnlySubtitle size="s">
              Pick all that apply - this help us find the right influencers
            </DesktopOnlySubtitle>
            <CategoryWrapper>
              {brandCategories.map((b) => {
                return (
                  <ToggleButton
                    key={b}
                    title={b}
                    active={selectedCategories.includes(b)}
                    margin="0 0 s 0"
                    onClick={() => {
                      setError("");
                      if (selectedCategories.includes(b)) {
                        setSelectedCategories(
                          selectedCategories.filter((e) => e !== b)
                        );
                      } else {
                        setSelectedCategories((t) => [...t, b]);
                      }
                    }}
                  />
                );
              })}
            </CategoryWrapper>
            {error && (
              <Text
                margin="s 0 0"
                size="s"
                colorPreset="warning"
                align="center"
              >
                {error}
              </Text>
            )}
            <Submit
              type="submit"
              value={createBrand.isLoading ? "Loading..." : "Next"}
              margin="xl 0 0 0"
            />
          </SignupCard>
        </form>
      </ContentWrap>
      {window.width && window.width > MOBILE_BREAKPOINT && (
        <Steps
          margin="xxl 0 0 0"
          steps={[
            {
              link: "/signup/b/confirm-details",
              status: "complete",
              name: "Details",
            },
            {
              link: "/signup/b/create-brand",
              status: "current",
              name: "Create brand",
            },
            {
              link: "/signup/b/create-location",
              status: "upcoming",
              name: "Locations",
            },
          ]}
        />
      )}
    </Wrap>
  );
};
