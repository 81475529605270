import React from "react";
import styled from "../styles";
import theme from "../styles/theme";

type colors = typeof theme.color.typography;

interface Props {
  colorPreset?: keyof colors;
  height?: number;
}

const LogoSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="110"
    height="34"
    viewBox="0 0 398 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M189.34 60.4799C189.34 46.3599 189.26 35.0599 188.95 31.6799C187.77 19.2099 177.57 20.4599 177.57 13.7899C177.57 11.1999 178.98 9.15991 183.14 9.15991C187.61 9.15991 196.24 9.54993 201.5 9.54993C206.76 9.54993 211.62 9.15991 216.41 9.15991C220.73 9.15991 222.06 11.1999 222.06 13.7899C222.06 19.7499 215.39 19.0499 214.37 31.3699C214.06 34.8999 213.98 57.5 213.98 68.09C213.98 97.2 208.8 118.62 176.24 118.62C152.23 118.62 143.84 105.99 143.84 93.6699C143.84 85.4299 148.78 76.5699 158.67 76.5699C166.36 76.5699 169.5 80.89 169.5 85.59C169.5 88.88 166.52 92.8899 166.52 98.0599C166.52 102.3 169.11 106.3 175.54 106.3C185.19 106.3 189.35 96.4099 189.35 79.0699V60.4799H189.34Z"
      fill="white"
    />
    <path
      d="M266.08 35.0599C287.81 35.0599 307.27 50.67 307.27 78.53C307.27 104.81 289.07 117.84 267.02 117.84C242.46 117.84 226.69 100.74 226.69 77.1199C226.69 52.2499 243.25 35.0699 266.08 35.0699V35.0599ZM250.94 68.7999C250.94 76.0999 252.12 85.4299 255.41 92.5699C258.94 99.9499 264.51 103.48 270.08 103.48C277.3 103.48 284.05 98.2199 284.05 84.4099C284.05 76.4099 281.93 66.5199 278.71 59.5399C274.71 51.3799 269.29 47.2999 263.96 47.2999C257.13 47.2999 250.94 53.1799 250.94 68.7999Z"
      fill="white"
    />
    <path
      d="M343.91 35.84V94.6799C343.91 100.88 345.16 103.23 347.52 105.67C349.8 108.02 352.46 108.57 352.46 112.1C352.46 114.77 350.97 116.65 346.42 116.65C342.34 116.65 336.93 116.26 332.85 116.26C327.67 116.26 323.2 116.65 317.55 116.65C313.71 116.65 312.45 114.69 312.45 112.33C312.45 108.96 315.43 107.23 316.84 105.74C320.76 101.66 321.23 97.58 321.23 93.58V25.1599C321.23 21.2399 318.48 19.1999 316.13 18.0999C314.48 17.1599 312.91 16.14 312.91 14.02C312.91 10.25 317.78 9.54992 323.82 7.03992C331.51 3.97992 336.14 0.919922 339.59 0.919922C342.65 0.919922 344.61 2.48991 344.61 6.09991C344.61 14.4199 343.9 15.36 343.9 35.84H343.91Z"
      fill="white"
    />
    <path
      d="M389.34 69.58V94.6899C389.34 100.89 390.6 103.24 392.95 105.68C395.23 108.03 397.89 108.58 397.89 112.11C397.89 114.78 396.4 116.66 391.85 116.66C387.3 116.66 382.75 116.27 378.28 116.27C372.94 116.27 369.65 116.66 362.98 116.66C359.14 116.66 357.88 114.7 357.88 112.34C357.88 108.97 360.86 107.24 362.27 105.75C366.19 101.67 366.66 97.59 366.66 93.59V61.9699C366.66 57.0299 364.31 54.1999 361.72 52.8699C360.07 51.7699 358.19 50.5899 358.19 48.3199C358.19 44.0799 363.21 43.6899 369.25 41.2599C376.94 38.1999 382.35 35.0599 385.1 35.0599C388.4 35.0599 390.12 36.6299 390.12 40.2399C390.12 44.9499 389.34 53.26 389.34 69.58ZM381.18 1.54993C387.46 1.54993 392.4 5.62994 392.4 12.0599C392.4 20.8499 382.98 27.9899 373.65 27.9899C366.9 27.9899 363.76 24.1499 363.76 18.9699C363.76 10.3399 372.16 1.54993 381.18 1.54993Z"
      fill="white"
    />
    <path
      d="M100.92 88.0599C96.5301 87.9899 93.04 84.4499 93.09 80.0899C93.14 75.7699 96.7301 72.2299 101.01 72.2799C105.42 72.3299 108.94 75.9099 108.88 80.2999C108.82 84.6199 105.23 88.1199 100.92 88.0599Z"
      fill="white"
    />
    <path
      d="M19.0301 70.8399C23.4201 70.8799 26.9401 74.4099 26.9201 78.7599C26.9001 83.1199 23.32 86.6499 18.97 86.6199C14.61 86.5899 11.07 82.9999 11.12 78.6599C11.17 74.3299 14.7401 70.7999 19.0301 70.8399Z"
      fill="white"
    />
    <path
      d="M68.0699 109.55C68.0699 113.95 64.5699 117.46 60.1899 117.46C55.8299 117.46 52.3 113.93 52.3 109.55C52.3 105.21 55.88 101.65 60.23 101.67C64.56 101.69 68.0699 105.22 68.0699 109.55Z"
      fill="white"
    />
    <path
      d="M43.6199 30.25C43.5999 34.58 40.0599 38.1099 35.7499 38.0999C31.3799 38.0899 27.8299 34.53 27.8599 30.18C27.8899 25.85 31.3899 22.3 35.6199 22.31C40.1499 22.32 43.6399 25.78 43.6199 30.24V30.25Z"
      fill="white"
    />
    <path
      d="M93.85 32.09C93.85 36.41 90.32 39.95 86 39.96C81.6 39.97 78.06 36.4199 78.07 32.0099C78.08 27.7099 81.64 24.1899 85.96 24.1799C90.32 24.1799 93.84 27.71 93.84 32.09H93.85Z"
      fill="white"
    />
    <path
      d="M39.62 85.4C28.22 93.08 22.29 103.81 21.78 117.42H37.5601C37.9601 108.82 41.96 102.32 49.34 97.9099C52.51 96.0199 56.0099 95.1099 59.6899 94.9699V79.1699C52.4099 79.3599 45.65 81.33 39.62 85.39V85.4Z"
      fill="white"
    />
    <path
      d="M35.5601 53.2299C25.3901 43.9799 13.57 40.7599 0.22998 43.4899L3.96997 58.8199C12.42 57.1699 19.68 59.51 25.71 65.64C28.3 68.27 30.01 71.4499 31.02 74.9999L46.37 71.26C44.46 64.24 40.9399 58.1299 35.5699 53.2399L35.5601 53.2299Z"
      fill="white"
    />
    <path
      d="M66.23 38.39C71.2 25.58 69.96 13.3799 62.6 1.91992L49.64 10.9299C54.22 18.2199 54.65 25.8299 51.1 33.6699C49.58 37.0299 47.22 39.7799 44.28 41.9899L53.3 54.96C59.17 50.65 63.6 45.17 66.23 38.39Z"
      fill="white"
    />
    <path
      d="M88.72 63.08C102.45 63.76 113.63 58.74 122.19 48.15L109.56 38.6899C104.09 45.3399 96.9899 48.1399 88.4399 47.2399C84.7699 46.8599 81.42 45.48 78.4 43.39L68.9301 56.03C74.8701 60.24 81.47 62.72 88.73 63.08H88.72Z"
      fill="white"
    />
    <path
      d="M72.03 92.7599C75.95 105.94 84.3999 114.81 97.2299 119.37L101.96 104.31C93.8699 101.36 88.8799 95.5899 86.8799 87.2299C86.0199 83.6399 86.2 80.0299 87.18 76.4799L72.1099 71.7499C70.1099 78.7499 69.96 85.7999 72.03 92.7599Z"
      fill="white"
    />
    <path
      d="M85.72 40.1799C81.14 40.1799 77.54 37.1999 77.54 32.5099C77.54 26.0999 84.41 20.9 91.21 20.9C96.13 20.9 98.42 23.6999 98.42 27.4799C98.42 33.7699 92.3 40.1799 85.72 40.1799Z"
      fill="white"
    />
    <path
      d="M43.61 30.0699C43.84 34.6399 41.04 38.3899 36.36 38.6199C29.96 38.9399 24.42 32.3399 24.08 25.5399C23.84 20.6199 26.5201 18.1999 30.3001 18.0099C36.5901 17.6999 43.29 23.4999 43.61 30.0699Z"
      fill="white"
    />
    <path
      d="M23.16 72.07C27.09 74.42 28.6499 78.8299 26.2299 82.8499C22.9299 88.3499 14.37 89.28 8.53 85.78C4.31 83.25 3.78995 79.67 5.72995 76.43C8.96995 71.03 17.51 68.69 23.16 72.07Z"
      fill="white"
    />
    <path
      d="M56.63 102.44C60.7 100.34 65.2701 101.33 67.4201 105.5C70.3601 111.2 66.64 118.97 60.59 122.09C56.22 124.35 52.9001 122.9 51.1701 119.55C48.2901 113.96 50.79 105.45 56.64 102.44H56.63Z"
      fill="white"
    />
    <path
      d="M92.6 82.38C91.41 77.96 93.35 73.7099 97.88 72.4899C104.07 70.8299 110.88 76.1099 112.64 82.6799C113.92 87.4299 111.8 90.3699 108.15 91.3499C102.07 92.9799 94.2899 88.73 92.5899 82.38H92.6Z"
      fill="white"
    />
  </svg>
);

export const Logo = styled(LogoSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.theme.color.typography.heading};
  }
`;
