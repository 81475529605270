import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../components/CTA";
import { Dropdown } from "../../../../components/Dropdown";
import { Flex } from "../../../../components/Flex";
import { TrashIcon } from "../../../../components/icons/TrashIcon";
import { Input } from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import { View } from "../../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../../config";
import {
  ListingOfferInput,
  Platform,
  useGetBrandQuery,
} from "../../../../graphql/generated";
import useGqlClient from "../../../../hooks/useGqlClient";
import { authSelectors } from "../../../../store/auth/selector";
import styled from "../../../../styles";
import { Toggle } from "./Toggle";

const guests = [
  { label: "None", value: 0 },
  { label: "+1 Guest", value: 1 },
  { label: "+2 Guests", value: 2 },
  { label: "+3 Guests", value: 3 },
  { label: "+4 Guests", value: 4 },
  { label: "+5 Guests", value: 5 },
  { label: "+6 Guests", value: 6 },
  { label: "+7 Guests", value: 7 },
  { label: "+8 Guests", value: 8 },
  { label: "+9 Guests", value: 9 },
  { label: "+10 Guests", value: 10 },
];

interface OffersProps {
  offers: ListingOfferInput[];
  setOffers: React.Dispatch<React.SetStateAction<ListingOfferInput[]>>;
  platform: Platform;
  ref?: React.RefObject<HTMLDivElement>;
}

const Offers = ({ offers, setOffers, platform, ref }: OffersProps) => {
  const [maxFollowersOfferErrors, setMaxFollowersOfferErrors] = useState<
    Record<number, string>
  >({});
  const [offerErrors, setOfferErrors] = useState<Record<number, string>>({});
  const maxFollowersErrorTimer = useRef<Record<number, NodeJS.Timeout>>({});
  const offerErrorTimer = useRef<Record<number, NodeJS.Timeout>>({});
  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);

  const { data: brandData, isLoading: brandLoading } = useGetBrandQuery(
    client,
    {
      id: activeBrandId ? activeBrandId : "",
    }
  );

  const addOffer = () => {
    setOffers((prevOffers: ListingOfferInput[]) => {
      const lastOffer = prevOffers[prevOffers.length - 1];
      if (
        !lastOffer ||
        lastOffer.maxFollowers === Infinity ||
        !lastOffer.maxFollowers
      ) {
        setMaxFollowersOfferErrors((prev) => ({
          ...prev,
          [offers.length - 1]: "Max followers are required",
        }));

        // Clear the error after 2 seconds
        setTimeout(() => {
          setMaxFollowersOfferErrors((prev) => {
            const newErrors = { ...prev };
            delete newErrors[offers.length - 1];
            return newErrors;
          });
        }, 2000);

        return prevOffers;
      }

      const newMinFollowers = lastOffer.maxFollowers! + 1;
      return [
        ...prevOffers,
        {
          platform: platform,
          minFollowers: newMinFollowers,
          maxFollowers: Infinity,
          maxGuests: lastOffer.maxGuests,
          offer: lastOffer.offer,
          toggleProductValue: null,
          toggleProductId: null,
        },
      ];
    });
  };

  const removeOffer = (index: number) => {
    if (offers.length > 1) {
      const newOffers = [...offers];
      newOffers.splice(index, 1);

      // Adjust the minFollowers of the offer that comes after the removed one
      if (index < newOffers.length) {
        const previousOffer = newOffers[index - 1];
        newOffers[index].minFollowers =
          previousOffer.maxFollowers === Infinity
            ? previousOffer.minFollowers + 1
            : previousOffer.maxFollowers ?? 0 + 1;
      }

      setOffers(newOffers);
    }
  };

  if (brandLoading || !brandData) {
    return <Loader />;
  }

  return (
    <>
      <TableWrap>
        <Table>
          <thead>
            <tr>
              <th scope="col">
                <span style={{ marginLeft: 4 }}>Min Followers</span>
              </th>
              <th scope="col">
                <span style={{ marginLeft: 4 }}>Max Followers</span>
              </th>
              <th scope="col">
                <span style={{ marginLeft: 4 }}>Max Guests</span>
              </th>
              <th scope="col" className="offerColumn">
                <span style={{ marginLeft: 4 }}>Offer</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {offers.map((offer, index) => (
              <Row key={index}>
                <View>
                  <td>
                    <Input
                      inputSize="s"
                      value={offer.minFollowers}
                      onChange={(e) => {
                        if (index === 0) {
                          const newOffers = [...offers];
                          newOffers[index].minFollowers =
                            parseInt(e.target.value) || 0;
                          setOffers(newOffers);
                        }
                      }}
                      placeholder="Min followers"
                      isDisabled={index !== 0}
                    />
                  </td>
                  <td>
                    <Input
                      inputSize="s"
                      value={offer.maxFollowers ?? Infinity}
                      onChange={(e) => {
                        const newOffers = [...offers];
                        const inputValue = e.target.value.trim();
                        const newValue =
                          inputValue === "" || inputValue === "0"
                            ? Infinity
                            : parseInt(inputValue) || 0;
                        newOffers[index].maxFollowers = newValue;
                        if (index < offers.length - 1) {
                          newOffers[index + 1].minFollowers =
                            newValue === Infinity
                              ? newOffers[index].minFollowers + 1
                              : newValue + 1;
                        }

                        // Clear any existing timer for this index
                        if (maxFollowersErrorTimer.current[index]) {
                          clearTimeout(maxFollowersErrorTimer.current[index]);
                        }

                        if (newValue === 0 || newValue >= offer.minFollowers) {
                          // Immediately clear the error if the value is valid
                          setMaxFollowersOfferErrors((prev) => {
                            const newErrors = { ...prev };
                            delete newErrors[index];
                            return newErrors;
                          });
                        } else {
                          // Set a new timer to check and set the error after 500ms
                          maxFollowersErrorTimer.current[index] = setTimeout(
                            () => {
                              setMaxFollowersOfferErrors((prev) => ({
                                ...prev,
                                [index]: `Must be more than ${offer.minFollowers.toString()}`,
                              }));
                            },
                            500
                          );
                        }

                        setOffers(newOffers);
                      }}
                      error={maxFollowersOfferErrors[index]}
                      placeholder="Max followers"
                    />
                  </td>
                  <td>
                    <Dropdown
                      size="s"
                      selectedOptions={[offer.maxGuests.toString()]}
                      setSelectedOptions={(value) => {
                        const newOffers = [...offers];
                        newOffers[index].maxGuests = Number(value[0]);
                        setOffers(newOffers);
                      }}
                      renderLabel={() => {
                        const selectedGuest = guests.find(
                          (e) => e.value === Number(offer.maxGuests)
                        );
                        return selectedGuest ? selectedGuest.label : "";
                      }}
                      selectionMode="single"
                      disableOptionSort
                      options={guests
                        .sort((a, b) => a.value - b.value)
                        .map((guest) => ({
                          ...guest,
                          value: guest.value.toString(),
                        }))}
                    />
                  </td>
                  <td className="offerColumn">
                    <Flex direction="column" align="flex-end">
                      <Flex
                        direction="row"
                        align="center"
                        style={{ width: "100%" }}
                      >
                        <div style={{ width: "100%" }}>
                          <Input
                            inputSize="s"
                            value={offer.offer}
                            onChange={(e) => {
                              const newOffers = [...offers];
                              const inputValue = e.target.value;
                              newOffers[index].offer = inputValue;

                              // Clear any existing timer for this index
                              if (offerErrorTimer.current[index]) {
                                clearTimeout(offerErrorTimer.current[index]);
                              }

                              // Set a new timer to check and set the error after 500ms
                              offerErrorTimer.current[index] = setTimeout(
                                () => {
                                  if (inputValue.trim() === "") {
                                    setOfferErrors((prev) => ({
                                      ...prev,
                                      [index]: "Offer is required",
                                    }));
                                  } else {
                                    setOfferErrors((prev) => {
                                      const newErrors = { ...prev };
                                      delete newErrors[index];
                                      return newErrors;
                                    });
                                  }
                                },
                                500
                              );

                              setOffers(newOffers);
                            }}
                            placeholder="Offer"
                            error={offerErrors[index]}
                          />
                        </div>
                        <TrashWrap
                          justify="center"
                          align="center"
                          margin="0 0 0 m"
                          onClick={() => {
                            removeOffer(index);
                          }}
                          style={{
                            cursor: "pointer",
                            display: index === 0 ? "none" : "flex",
                          }}
                        >
                          <TrashIcon width={16} />
                        </TrashWrap>
                      </Flex>
                      {brandData?.brand?.toggleIntegrationEnabled ? (
                        <View margin="m 0 0 0">
                          <Toggle
                            toggleProductValue={offer.toggleProductValue}
                            setToggleProductValue={(value: number | null) => {
                              console.log(
                                "setToggleProductValue called with:",
                                value
                              );
                              const newOffers = [...offers];
                              newOffers[index].toggleProductValue = value;
                              setOffers(newOffers);
                            }}
                            toggleProductId={offer.toggleProductId}
                            setToggleProductId={(id: number | null) => {
                              console.log(
                                "setToggleProductId called with:",
                                id
                              );
                              const newOffers = [...offers];
                              newOffers[index].toggleProductId = id;
                              setOffers(newOffers);
                            }}
                          />
                        </View>
                      ) : null}
                    </Flex>
                  </td>
                </View>
              </Row>
            ))}
          </tbody>
        </Table>
      </TableWrap>
      <Flex
        margin={
          brandData?.brand?.toggleIntegrationEnabled ? "0 0 0 s" : "l 0 0 m"
        }
      >
        <div>
          <Button buttonType="add" size="s" onClick={addOffer}>
            + Add Offer
          </Button>
        </div>
      </Flex>
    </>
  );
};

export default Offers;

const TableWrap = styled.div`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing.xl};
  overflow-y: visible;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-x: scroll;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  box-sizing: border-box;

  .offerColumn {
    width: 500px;
  }

  thead {
    display: table;
    width: 100%;
    table-layout: fixed;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      display: table-header-group;
    }
  }

  tbody {
    display: block;
    width: 100%;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    text-align: left;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s};
    width: 120px;
    vertical-align: top;
  }

  thead {
    background-color: ${(p) => p.theme.color.card.callout};
    border-radius: ${(p) => p.theme.misc.borderRadius};
  }

  th {
    font-size: ${(p) => p.theme.typography.size.xxs};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    color: ${(p) => p.theme.color.typography.secondary};
    text-transform: uppercase;
  }
`;

const Row = styled.tr`
  display: table;
  width: 100%;
  cursor: pointer;
  table-layout: fixed;
  margin-top: ${(p) => p.theme.spacing.xs};

  background-color: ${(p) => p.theme.color.card.background};

  border-bottom: 1px solid ${(p) => p.theme.color.divider};

  &:last-of-type {
    border-bottom: none;
  }
`;

const TrashWrap = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.typography.text};
      }
    }
  }
`;
