import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "../styles";

const DatePickerWrap = styled.div<{ showTime?: boolean }>`
  background-color: ${(p) => p.theme.color.card.background};
  position: relative;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  color: ${(p) => p.theme.color.typography.secondary};

  .react-datepicker {
    background-color: ${(p) => p.theme.color.card.background} !important;
    color: ${(p) => p.theme.color.typography.secondary} !important;
    border: 0px solid transparent;
    box-shadow: ${(p) => p.theme.shadow.cardHeavy};
    box-sizing: border-box !important;
    font-family: ${(p) => p.theme.typography.bodyFamily} !important;
    overflow: hidden !important;
    border-radius: ${(p) => p.theme.misc.borderRadiusSmall} !important;

    ${(p) =>
      p.showTime
        ? css`
            min-width: 382px;
          `
        : css`
            min-width: 100%;
          `}
  }

  .react-datepicker__time-container,
  .react-datepicker__month-container,
  .react-datepicker__time {
    background-color: ${(p) => p.theme.color.card.background} !important;
  }

  .react-datepicker__month-container {
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s};
    height: 100%;
    box-sizing: border-box;

    ${(p) =>
      p.showTime
        ? css`
            border-top-left-radius: ${(p) => p.theme.misc.borderRadiusSmall};
            border-bottom-left-radius: ${(p) => p.theme.misc.borderRadiusSmall};
          `
        : css`
            border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
          `}
  }

  .react-datepicker__time-box {
    padding: 0px !important;
    width: 100% !important;
  }

  .react-datepicker-time__header {
    font-size: ${(p) => p.theme.typography.size.m};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    font-family: ${(p) => p.theme.typography.bodyFamily};
    margin: 11px 0 ${(p) => p.theme.spacing.s};
  }

  .react-datepicker__time-container {
    margin: 0;
    border-left: 1px solid ${(p) => p.theme.color.card.divider};
    height: 100%;
    width: 120px;
  }

  .react-datepicker__header--time {
    padding: 0px !important;
  }

  .react-datepicker__time-list-item {
    padding: 0px !important;
    background-color: ${(p) => p.theme.color.card.background} !important;
    color: ${(p) => p.theme.color.typography.text};
    font-weight: ${(p) => p.theme.typography.weight.semi} !important;
    height: 28px !important;
    width: 82px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
    margin: ${(p) => p.theme.spacing.s} auto;
    border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
    font-size: ${(p) => p.theme.typography.size.xs};

    &:hover {
      background-color: ${(p) => p.theme.color.primary} !important;
      color: #fff;
    }
  }

  .react-datepicker__time-list-item--selected {
    background-color: ${(p) => p.theme.color.primary} !important;
    color: #fff;
    font-weight: ${(p) => p.theme.typography.weight.semi} !important;
  }

  .react-datepicker__navigation {
    margin: ${(p) => p.theme.spacing.s} !important;
  }

  .react-datepicker__navigation--next {
    ${(p) =>
      p.showTime
        ? css`
            right: 126px !important;
          `
        : css`
            right: 6px !important;
          `}
  }

  .react-datepicker__navigation--previous {
    left: 8px !important;
  }

  .react-datepicker__header,
  .react-datepicker__current-month {
    background-color: ${(p) => p.theme.color.card.background};
    color: ${(p) => p.theme.color.typography.text};
  }

  .react-datepicker__current-month {
    padding-bottom: 10px;
    font-size: ${(p) => p.theme.typography.size.m};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    font-family: ${(p) => p.theme.typography.bodyFamily};
  }

  .react-datepicker__header {
    border-bottom: 0px solid ${(p) => p.theme.color.card.divider};
    padding: ${(p) => p.theme.spacing.xs} 0 ${(p) => p.theme.spacing.xs};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    margin-bottom: 2px;
  }

  .react-datepicker__day-name {
    color: ${(p) => p.theme.color.typography.text};
    font-size: ${(p) => p.theme.typography.size.s};
  }

  .react-datepicker__day,
  .react-datepicker__time-name {
    background-color: ${(p) => p.theme.color.card.background} !important;
    color: ${(p) => p.theme.color.typography.text};
    font-size: ${(p) => p.theme.typography.size.s};
    &:hover {
      background-color: ${(p) => p.theme.color.primary} !important;
      color: #fff;
    }
  }

  .react-datepicker__month {
    margin: 0 ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s};
  }

  .react-datepicker__day--selected {
    background-color: ${(p) => p.theme.color.primary} !important;
    color: #fff;
  }

  .react-datepicker__close-icon {
    margin-right: ${(p) => p.theme.spacing.s};

    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .react-datepicker__day--disabled {
    color: ${(p) => p.theme.color.typography.secondary} !important;
    opacity: 0.7 !important;
    cursor: not-allowed !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .react-datepicker__close-icon::after {
    background-color: ${(p) => p.theme.color.typography.secondary} !important;
    height: 14px;
    width: 14px;
    padding: 0;
  }
`;

interface DatePickerProps {
  date: Date | null;
  setDate: (date: Date | null) => void;
  customInput?: React.ReactNode;
  showTime?: boolean;
  customTimeInput?: React.ReactNode;
  icon?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  isClearable?: boolean;
  placeholderText?: string;
  dateFormat?: string;
  startDate?: Date | null;
  maxEndDate?: Date | null;
}

export const DatePicker = (props: DatePickerProps) => {
  return (
    <DatePickerWrap showTime={props.showTime}>
      <ReactDatePicker
        placeholderText={props.placeholderText}
        isClearable={props.isClearable}
        showIcon={!!props.icon}
        icon={props.icon}
        key="start-date"
        dateFormat={
          props.dateFormat
            ? props.dateFormat
            : props.showTime
            ? "dd/MM/yyyy HH:mm"
            : "dd/MM/yyyy"
        }
        selected={props.date}
        onChange={(date: Date) => {
          props.setDate(date || null);
        }}
        minDate={props.startDate}
        maxDate={props.maxEndDate}
        showTimeSelect={props.showTime}
        timeIntervals={props.showTime ? 30 : undefined}
        showPopperArrow={false}
        customInput={
          props.customInput ? (
            props.customInput
          ) : (
            <DateInput
              hasIcon={!!props.icon}
              clearable={false}
              noDates={!props.date ? true : false}
            />
          )
        }
      />
    </DatePickerWrap>
  );
};

export const DateInput = styled.input.attrs({ readOnly: true })<{
  noDates: boolean;
  clearable: boolean;
  hasIcon: boolean;
  className?: string;
}>`
  background-color: ${(p) => p.theme.color.card.background} !important;
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.xl} !important;
  width: ${(p) => (p.clearable ? (p.noDates ? 70 : 104) : 82)}px;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  color: ${(p) =>
    p.noDates
      ? p.theme.color.typography.secondary
      : p.theme.color.typography.text};
  font-size: ${(p) => p.theme.typography.size.m};
  font-family: ${(p) => p.theme.typography.bodyFamily};
  font-weight: ${(p) =>
    p.noDates
      ? p.theme.typography.weight.normal
      : p.theme.typography.weight.semi};
  cursor: pointer;

  ${(p) =>
    p.hasIcon &&
    css`
      width: 92px;
      padding-left: 42px !important;
    `}

  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
  }
  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }
  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;
