import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AccountStatus } from "../graphql/generated";
import { PageContent } from "../routes/brands";
import { PendingApproval } from "../routes/PendingApproval";
import { authSelectors } from "../store/auth/selector";
import Loading from "./Loading";

const ProtectedRoute = (
  props: RouteProps & {
    children: React.ReactNode;
  }
) => {
  const auth = useSelector(authSelectors.data);
  const activeCreatorId = useSelector(authSelectors.activeCreatorId);

  let children = props.children;

  const renderChildren = () => {
    if (auth.isLoading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    if (!auth.isLoggedIn) {
      return <Redirect to="/signup" />;
    }

    if (auth.account && auth.account.status !== AccountStatus.Active) {
      return (
        <PageContent noNav>
          <PendingApproval />
        </PageContent>
      );
    }

    if (auth.account && (!auth.account.email || auth.account.email === "")) {
      if (activeCreatorId) {
        return <Redirect to="/signup/i/confirm-details" />;
      } else {
        return <Redirect to="/signup/b/confirm-details" />;
      }
    }

    return children;
  };

  return <Route {...props}>{renderChildren}</Route>;
};

export default ProtectedRoute;
