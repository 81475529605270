import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const ReportsSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 1V12.25C2.5 12.8467 2.73705 13.419 3.15901 13.841C3.58097 14.2629 4.15326 14.5 4.75 14.5H7M2.5 1H1M2.5 1H19M7 14.5H14.5M7 14.5L6 17.5M19 1H20.5M19 1V12.25C19 12.8467 18.7629 13.419 18.341 13.841C17.919 14.2629 17.3467 14.5 16.75 14.5H14.5M14.5 14.5L15.5 17.5M6 17.5H15.5M6 17.5L5.5 19M15.5 17.5L16 19M7.75 9.25V10.75M10.75 7V10.75M13.75 4.75V10.75"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const ReportsIcon = styled(ReportsSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
