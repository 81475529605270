import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SignupHeader } from "../../components/Brand/SignupHeader";
import { H1 } from "../../components/Heading";
import Loading from "../../components/Loading";
import { Subtitle } from "../../components/Subtitle";
import { useGetCreatorQuery } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 100%;
  @media (min-width: 1200px) {
    width: 420px;
  }
`;

export interface MatchParams {
  page: string;
}

interface Props {
  accountType?: "brand" | "creator";
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ConnectSocials = (props: Props) => {
  const client = useGqlClient();

  const activeCreatorId = useSelector(authSelectors.activeCreatorId);
  const query = useQuery();
  const [showSteps, setShowSteps] = useState(false);

  const { isLoading, data } = useGetCreatorQuery(
    client,
    {
      id: activeCreatorId ? activeCreatorId : "",
    },
    {
      enabled: activeCreatorId !== "",
    }
  );

  useEffect(() => {
    setShowSteps(query.get("onboarding") !== null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.toString()]);

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <Wrap>
      <SignupHeader
        steps={showSteps ? { currentStep: 2, totalSteps: 2 } : undefined}
      />
      <ContentWrap>
        <H1 margin="0 0 0 0">Connect your socials</H1>
        <Subtitle margin="m 0">
          Unlock additional collaborations by connecting your social accounts.
        </Subtitle>
        {/* <SocialProfiles ctaLink="/i" data={data.creator} refetch={refetch} /> */}
      </ContentWrap>
    </Wrap>
  );
};
