import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AccountStatus } from "../graphql/generated";
import { PageContent } from "../routes/brands";
import { VerifyEmail } from "../routes/brands/VerifyEmail";
import { PendingApproval } from "../routes/PendingApproval";
import { authSelectors } from "../store/auth/selector";
import styled from "../styles";
import { Page } from "./Page";

const SignupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;

  @media (min-width: 1200px) {
    min-width: 500px;
  }
`;

const SignupRoute = (
  props: RouteProps & {
    children: React.ReactNode;
  }
) => {
  let children = props.children;

  const auth = useSelector(authSelectors.data);

  const renderChildren = () => {
    if (!auth.isLoggedIn) {
      return <Redirect to="/signup/b" />;
    }

    if (auth.account && auth.account.status === AccountStatus.Pending) {
      return (
        <PageContent noNav>
          <PendingApproval />
        </PageContent>
      );
    }

    if (
      auth.account &&
      auth.account.email !== "" &&
      !auth.account.emailVerifiedAt
    ) {
      return <VerifyEmail />;
    }

    return children;
  };

  return (
    <Page>
      <SignupWrapper>
        <Route {...props}>{renderChildren()}</Route>
      </SignupWrapper>
    </Page>
  );
};

export default SignupRoute;
