import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const BellIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_9200_449)">
      <path
        d="M4 5.33334C4 4.27248 4.42143 3.25506 5.17157 2.50492C5.92172 1.75477 6.93913 1.33334 8 1.33334C9.06087 1.33334 10.0783 1.75477 10.8284 2.50492C11.5786 3.25506 12 4.27248 12 5.33334C12 10 14 11.3333 14 11.3333H2C2 11.3333 4 10 4 5.33334Z"
        stroke="#E6437D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.86667 14C6.97826 14.203 7.1423 14.3722 7.34166 14.4901C7.54102 14.608 7.76839 14.6702 8 14.6702C8.23162 14.6702 8.45898 14.608 8.65834 14.4901C8.8577 14.3722 9.02175 14.203 9.13334 14"
        stroke="#E6437D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66668 1.33334C1.86668 2.46668 1.33334 3.80001 1.33334 5.33334"
        stroke="#E6437D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6667 5.33334C14.6667 3.80001 14.1333 2.46668 13.3333 1.33334"
        stroke="#E6437D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9200_449">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const BellIcon = styled(BellIconSVG)<Props>`
  margin-bottom: -4.5px;
  margin-right: 6px;
  path {
    stroke: ${(p) =>
      p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.theme.color.primary};
  }
`;
