import styled, { css } from "../styles";
import { Text } from "./Text";
import { Arrow } from "./ArrowIcon";
import { useTheme } from "../hooks/useTheme";
import { TrendArrow } from "./icons/TrendArrow";
import { Flex } from "./Flex";

interface Props {
  percentage: number;
  size?: "l";
}

const ValueText = styled(Text)<{ percentage: number }>`
  line-height: 100%;
  display: inline;
  color: ${(p) => p.theme.color.stats.positiveValue};
  ${(p) =>
    p.percentage < 0 &&
    css`
      color: ${(p) => p.theme.color.stats.negativeValue};
    `}
`;

export function PercentageChange(props: Props) {
  const theme = useTheme();

  return (
    <Flex direction="row" align="center">
      {props.percentage >= 0 ? (
        <TrendArrow
          width={props.size === "l" ? 22 : 18}
          height={props.size === "l" ? 22 : 18}
          style={{ verticalAlign: "middle", marginTop: -2 }}
          color={theme.color.stats.positiveValue}
        />
      ) : (
        <Arrow
          style={{ verticalAlign: "middle", marginTop: "-1px" }}
          color={theme.color.stats.negativeValue}
          direction="down"
        />
      )}
      <ValueText
        percentage={props.percentage}
        isCompact
        size={props.size === "l" ? "l" : "s"}
        margin="0 0 0 xs"
        weight="semi"
      >
        {Math.abs(props.percentage).toFixed(1)}%
      </ValueText>
    </Flex>
  );
}
