import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { BackLink } from "../../components/BackLink";
import { Button } from "../../components/CTA";
import { Card } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Logo } from "../../components/Logo";
import { Text } from "../../components/Text";
import { View } from "../../components/View";
import { MOBILE_BREAKPOINT } from "../../config";
import { useSendResetPasswordMutation } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import styled from "../../styles";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
  align-items: center;
  margin-left: ${(p) => p.theme.spacing.m};
  margin-right: ${(p) => p.theme.spacing.m};
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
`;

const Alert = styled(View)`
  background-color: ${(p) => p.theme.color.warning}30;
  border-left: 3px solid ${(p) => p.theme.color.warning};
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;

  color: ${(p) => p.theme.color.warning};
`;

export function ForgotPassword() {
  const [email, setEmail] = React.useState<string>("");

  const client = useGqlClient();
  const sendResetPassword = useSendResetPasswordMutation(client);

  const loggedIn = useSelector(authSelectors.isLoggedIn);

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Wrap>
      <Logo />
      <ContentWrap>
        <StyledCard>
          <H1 margin="0 0 s 0">Forgot password</H1>
          <Text margin="0">
            Enter your email and we'll send a password reset link if it matches
            a registered account
          </Text>
          {sendResetPassword.error ? (
            <Alert padding="s m" margin="m 0 m 0">
              <Flex>
                <Text margin="0 m 0 0">⚠️</Text>
                <Text margin="0">Something went wrong</Text>
              </Flex>
            </Alert>
          ) : null}
          {sendResetPassword.isSuccess ? (
            <Text margin="m 0 0" colorPreset="success">
              Password reset link sent to {email}
            </Text>
          ) : (
            <>
              <Input
                placeholder="Enter your email"
                value={email}
                margin="l 0 l 0"
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
              <Button
                margin="0 0 0 0"
                onClick={() => {
                  if (sendResetPassword.isLoading) {
                    return;
                  }
                  sendResetPassword.mutate({
                    email,
                  });
                }}
              >
                {sendResetPassword.isLoading ? "Loading..." : "Reset password"}
              </Button>
            </>
          )}
        </StyledCard>
        <Flex margin="l 0 0" justify="center">
          <BackLink margin="0" to="/b/login">
            Back to login
          </BackLink>
        </Flex>
      </ContentWrap>
    </Wrap>
  );
}

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }
`;
