import { Link } from "react-router-dom";
import styled from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import withPadding, { WithPaddingProp } from "../styles/withPadding";

export const BackLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;

  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.secondary};

  margin-bottom: ${(p) => p.theme.spacing.xl};

  ${withMargin}
`;

export const FakeBackLink = styled.p`
  text-decoration: none;
  display: flex;
  align-items: center;

  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.secondary};
  cursor: pointer;

  margin-bottom: ${(p) => p.theme.spacing.xl};

  ${withMargin}
`;

export const BackIcon = styled(Link)<WithPaddingProp & WithMarginProp>`
  text-decoration: none;
  display: flex;
  border-radius: 999px;
  box-shadow: ${(p) => p.theme.shadow.imageFloatingIcon};
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.color.card.background};
  border: 1px solid ${(p) => p.theme.color.card.divider};

  ${withMargin}
  ${withPadding}
`;
