import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const CameraSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.577 3.42521C5.39699 3.71012 5.15682 3.95219 4.87334 4.13444C4.58985 4.31669 4.26993 4.43471 3.936 4.48021C3.556 4.53421 3.179 4.59221 2.802 4.65521C1.749 4.83021 1 5.75721 1 6.82421V15.2502C1 15.8469 1.23705 16.4192 1.65901 16.8412C2.08097 17.2632 2.65326 17.5002 3.25 17.5002H18.25C18.8467 17.5002 19.419 17.2632 19.841 16.8412C20.2629 16.4192 20.5 15.8469 20.5 15.2502V6.82421C20.5 5.75721 19.75 4.83021 18.698 4.65521C18.3207 4.59234 17.9427 4.53401 17.564 4.48021C17.2302 4.43457 16.9105 4.31649 16.6272 4.13424C16.3439 3.952 16.1039 3.71 15.924 3.42521L15.102 2.10921C14.9174 1.8093 14.6632 1.5583 14.361 1.37744C14.0589 1.19658 13.7175 1.0912 13.366 1.07021C11.6233 0.976598 9.87674 0.976598 8.134 1.07021C7.78245 1.0912 7.44114 1.19658 7.13896 1.37744C6.83678 1.5583 6.58262 1.8093 6.398 2.10921L5.577 3.42521Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.25 10.0002C15.25 11.1937 14.7759 12.3382 13.932 13.1822C13.0881 14.0261 11.9435 14.5002 10.75 14.5002C9.55653 14.5002 8.41193 14.0261 7.56802 13.1822C6.72411 12.3382 6.25 11.1937 6.25 10.0002C6.25 8.80671 6.72411 7.66212 7.56802 6.8182C8.41193 5.97429 9.55653 5.50018 10.75 5.50018C11.9435 5.50018 13.0881 5.97429 13.932 6.8182C14.7759 7.66212 15.25 8.80671 15.25 10.0002ZM17.5 7.75018H17.508V7.75818H17.5V7.75018Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const CameraIcon = styled(CameraSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
