import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ListingsState = {
  data: Listing[];
};

export type Listing = {
  id: string;
  lat: number;
  lng: number;
  title: string;
  subtitle: string;
  popup: boolean;
  active: boolean;
  description: string;
  startTime: number;
};

const initialState: ListingsState = {
  data: [
    {
      id: "1",
      title: "Nobu",
      subtitle: "Park lane",
      lat: 51.50015,
      lng: -0.12624,
      popup: false,
      active: false,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris",
      startTime: 1613941988,
    },
    {
      id: "2",
      title: "Nobu Hotel",
      subtitle: "Shoreditch",
      lat: 51.52944,
      lng: -0.07754,
      popup: false,
      active: false,
      description:
        "Nestled amidst the energy of East London’s creative district and the financial centre of the City, Nobu Hotel London Shoreditch delivers the perfect blend of industrial chic and Japanese simplicity. Offering 164 guest rooms and suites, including the newly introduced 6th floor, with stylish penthouse suites and a private rooftop terrace, the hotel also boasts a unique, open-air courtyard adjacent to Nobu restaurant, extensive event space and a luxury spa offering signature Nobu treatments.",
      startTime: 1613941988,
    },
  ],
};

export const { actions, reducer } = createSlice({
  name: "listings",
  initialState,
  reducers: {
    togglePopup: (state, action: PayloadAction<{ id: string }>) => {
      state.data.forEach((d) => {
        d.popup = false;
        if (d.id === action.payload.id) {
          d.popup = !d.popup;
        }
      });
    },
    setActive: (state, action: PayloadAction<{ id: string }>) => {
      state.data.forEach((d) => {
        d.active = false;
        if (d.id === action.payload.id) {
          d.active = true;
        }
      });
    },
    clearActive: (state, action: PayloadAction<{ id: string }>) => {
      state.data.forEach((d) => {
        d.active = false;
      });
    },
  },
});
