import React, { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";
import { BackLink } from "../../components/BackLink";
import { CancelIcon } from "../../components/CancelIcon";
import { Card } from "../../components/Card";
import { Chevron } from "../../components/Chevron";
import { CTA } from "../../components/CTA";
import DetailList from "../../components/DetailList";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { InlineAddBtn, InlineBtn } from "../../components/NewButton";
import { Select } from "../../components/Select";
import { Text } from "../../components/Text";
import { TextArea } from "../../components/TextArea";
import {
  ExcludedHoursInput,
  OpeningHoursInput,
  Place,
  PlaceSearchResult,
  useCreateLocationMutation,
  usePlaceQuery,
  useSearchPlacesQuery,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import styled from "../../styles";
import { ExcludedHours } from "./Location";

export const daysSortMap: { [key: string]: number } = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

const PageWrap = styled.div`
  flex: 1;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  max-width: 500px;
  align-self: center;
  text-align: left;
`;

const SearchResultsWrap = styled.div`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  background-color: ${(p) => p.theme.color.card.background};
  margin-top: ${(p) => p.theme.spacing.s};
`;

const SearchResultItem = styled.div`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s};
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
  &:hover {
    background-color: ${(p) => p.theme.color.card.divider};
    cursor: pointer;
  }
  &:last-of-type {
    padding-bottom: ${(p) => p.theme.spacing.s};
    border-radius: 0 0 ${(p) => p.theme.misc.borderRadius}
      ${(p) => p.theme.misc.borderRadius};
    border-bottom: none;
  }
  &:first-of-type {
    border-radius: ${(p) => p.theme.misc.borderRadius}
      ${(p) => p.theme.misc.borderRadius} 0 0;
    padding-top: ${(p) => p.theme.spacing.s};
  }
`;

const OptionsToggleWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

let searchPlacesTimeout: NodeJS.Timeout | null = null;

export function CreateLocation() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<PlaceSearchResult[]>([]);
  const [selectedPlaceId, setSelectedPlaceId] = useState("");

  const client = useGqlClient();
  const { isLoading, error, refetch } = useSearchPlacesQuery(
    client,
    {
      input: searchTerm,
    },
    {
      enabled: false,
    }
  );

  return (
    <Wrap>
      <PageWrap>
        <BackLink to="/b/locations">
          <Chevron direction="left" /> Back to locations
        </BackLink>
        <Card padding="l" margin="xl 0 xl">
          <H2 margin="0 0 xl">Create new location</H2>

          {selectedPlaceId ? (
            <PlaceForm
              placeId={selectedPlaceId}
              setSelectedPlaceId={setSelectedPlaceId}
              setSearchTerm={setSearchTerm}
            ></PlaceForm>
          ) : (
            <>
              <Input
                label="Search for address"
                placeholder="e.g. Honest Burgers Borough"
                value={searchTerm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedPlaceId("");
                  if (e.currentTarget.value === searchTerm) {
                    return;
                  }

                  if (e.currentTarget.value.length <= 2) {
                    setSearchResults([]);
                    setSearchTerm(e.currentTarget.value);
                    return;
                  }

                  if (searchPlacesTimeout) {
                    clearTimeout(searchPlacesTimeout);
                  }

                  setSearchTerm(e.currentTarget.value);

                  searchPlacesTimeout = setTimeout(async () => {
                    if (searchTerm === "") {
                      return;
                    }

                    const res = await refetch();
                    if (res.data) {
                      setSearchResults(res.data.searchPlaces.places);
                    }
                  }, 1000);
                }}
              />
              <SearchResults
                isLoading={isLoading}
                results={searchResults}
                error={error}
                onPlaceSelected={(place) => {
                  setSearchResults([]);
                  setSelectedPlaceId(place.placeId);
                }}
              />
            </>
          )}
        </Card>
      </PageWrap>
    </Wrap>
  );
}

interface SearchResultsProps {
  isLoading: boolean;
  error: unknown | null;
  results: PlaceSearchResult[];
  onPlaceSelected: (place: PlaceSearchResult) => void;
}

function SearchResults(props: SearchResultsProps) {
  if (props.isLoading) {
    return <Loading />;
  }

  if (props.error) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <>
      {props.results && (
        <SearchResultsWrap>
          {props.results.map((place) => {
            return (
              <SearchResultItem
                onClick={() => {
                  props.onPlaceSelected(place);
                }}
                key={place.placeId}
              >
                <Text margin="0">{place.description}</Text>
              </SearchResultItem>
            );
          })}
        </SearchResultsWrap>
      )}
    </>
  );
}

interface PlaceFormProps {
  placeId: string;
  setSelectedPlaceId: React.Dispatch<SetStateAction<string>>;
  setSearchTerm: React.Dispatch<SetStateAction<string>>;
}

function PlaceForm(props: PlaceFormProps) {
  const { track } = useAnalytics();
  const client = useGqlClient();
  const { data, isLoading, error } = usePlaceQuery(client, {
    id: props.placeId,
  });

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [excludedHours, setExcludedHours] = useState<ExcludedHoursInput[]>([]);
  const [editingOpeningHours, setEditingOpeningHours] = useState(false);
  const [openingHours, setOpeningHours] = useState<OpeningHoursInput[]>([
    {
      dayOfWeek: "Monday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Tuesday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Wednesday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Thursday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Friday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Saturday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Sunday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
  ]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const createLocation = useCreateLocationMutation(client);
  const brand = useSelector(authSelectors.activeBrand);
  const account = useSelector(authSelectors.account);
  const domain = account
    ? account.email.substring(account?.email.lastIndexOf("@") + 1)
    : "example.com";

  const history = useHistory();

  useEffect(() => {
    if (!data) {
      return;
    }

    setAddress(data.place.address);
    setPhone(data.place.phone);

    if (data.place.openingHours && data.place.openingHours.length > 0) {
      const ohs: OpeningHoursInput[] = data.place.openingHours.map((oh) => {
        const dow = oh.dayOfWeek;
        const startHour = parseInt(oh.openTime.substring(0, 2), 10);
        const startMinute = parseInt(oh.openTime.substring(3, 5), 10);
        const endHour = parseInt(oh.closeTime.substring(0, 2), 10);
        const endMinute = parseInt(oh.closeTime.substring(3, 5), 10);

        return {
          dayOfWeek: dow,
          startHour,
          startMinute,
          endHour,
          endMinute,
        };
      });

      setOpeningHours(ohs);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data || !brand) {
    return <Text>Something went wrong</Text>;
  }

  const defaultExcludedHours: ExcludedHoursInput = {
    startHour: openingHours[0].startHour,
    startMinute: 0,
    endHour: openingHours[0].endHour,
    endMinute: 0,
    dayOfWeek: openingHours[0].dayOfWeek,
  };

  return (
    <>
      <Input
        margin="0 0 l 0"
        label="Name"
        value={name}
        autoFocus
        placeholder="eg. Borough"
        error={nameError}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNameError("");
          setName(e.currentTarget.value);
        }}
      />
      <TextArea
        margin="0 0 l 0"
        label="Address"
        error={addressError}
        defaultValue={data.place.address}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setAddressError("");
          setAddress(e.currentTarget.value);
        }}
        rows={3}
      />
      <Input
        margin="0 0 l 0"
        label="Email"
        error={emailError}
        value={email}
        type="email"
        placeholder={`e.g. borough@${domain}`}
        help="Add a location email to let them know about confirmed bookings"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmailError("");
          setEmail(e.currentTarget.value);
        }}
      />
      <Input
        margin="0 0 l 0"
        label="Phone"
        value={phone}
        type="tel"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPhone(e.currentTarget.value);
        }}
      />

      <Flex margin="l 0 0 0" justify="space-between" align="center">
        <div>
          <Text margin="0" weight="semi">
            Opening Hours
          </Text>
        </div>
        <InlineBtn
          label={editingOpeningHours ? "Done" : "Edit"}
          onClick={() => {
            setEditingOpeningHours(!editingOpeningHours);
          }}
        />
      </Flex>
      {editingOpeningHours ? (
        <>
          {openingHours.map((ehs, i) => {
            return (
              <Hours
                place={data.place}
                index={i}
                key={`${ehs.dayOfWeek}-${ehs.startHour}-${ehs.startMinute}-${ehs.endHour}-${ehs.endMinute}`}
                defaultValue={ehs}
                onChange={(index, ehs) => {
                  const newOpeningHours = [...openingHours];
                  newOpeningHours.splice(index, 1);
                  newOpeningHours.splice(index, 0, ehs);

                  setOpeningHours(newOpeningHours);
                }}
                onRemove={(index) => {
                  const newOpeningHours = [...openingHours];
                  newOpeningHours.splice(index, 1);

                  setOpeningHours(newOpeningHours);
                }}
              />
            );
          })}
          <InlineAddBtn
            label="Add hours"
            margin="m 0 xl 0"
            onClick={() => {
              const newOpeningHours = [...openingHours];
              newOpeningHours.push({
                dayOfWeek: "Monday",
                startHour: 10,
                startMinute: 0,
                endHour: 18,
                endMinute: 0,
              });
              setOpeningHours(newOpeningHours);
            }}
          />
        </>
      ) : (
        <DetailList
          margin="s 0 xl 0"
          fields={openingHours
            .sort((a, b) => {
              const daysOfWeek = [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ];
              return (
                daysOfWeek.indexOf(a.dayOfWeek) -
                daysOfWeek.indexOf(b.dayOfWeek)
              );
            })
            .map((ohs, i) => ({
              label: ohs.dayOfWeek,
              value: `${ohs.startHour < 10 ? "0" : ""}${ohs.startHour}:${
                ohs.startMinute
              }${ohs.startMinute < 10 ? "0" : ""} - ${
                ohs.endHour < 10 ? "0" : ""
              }${ohs.endHour}:${ohs.endMinute}${ohs.endMinute < 10 ? "0" : ""}`,
            }))}
        />
      )}

      {showAdvancedOptions ? (
        <OptionsToggleWrap onClick={() => setShowAdvancedOptions(false)}>
          <Chevron colorPreset="link" direction="down" />
          <Text weight="semi" colorPreset="link" margin="0 0 0 s">
            Hide advanced options
          </Text>
        </OptionsToggleWrap>
      ) : (
        <OptionsToggleWrap onClick={() => setShowAdvancedOptions(true)}>
          <Chevron colorPreset="link" />
          <Text weight="semi" colorPreset="link" margin="0 0 0 s">
            Show advanced options
          </Text>
        </OptionsToggleWrap>
      )}

      {showAdvancedOptions && (
        <>
          <Text margin="xl 0 0 0" weight="semi">
            Excluded Hours
          </Text>
          <Text size="s" colorPreset="secondary" margin="xs 0 0 0">
            Block off certain hours of the week to prevent influencers applying
          </Text>

          {excludedHours.map((ehs, i) => {
            return (
              <ExcludedHours
                openingHours={openingHours}
                index={i}
                key={`${ehs.dayOfWeek}-${ehs.startHour}-${ehs.startMinute}-${ehs.endHour}-${ehs.endMinute}`}
                defaultValue={ehs}
                onChange={(index, ehs) => {
                  const newExcludedHours = [...excludedHours];
                  newExcludedHours.splice(index, 1);
                  newExcludedHours.splice(index, 0, ehs);

                  setExcludedHours(newExcludedHours);
                }}
                onRemove={(index) => {
                  const newExcludedHours = [...excludedHours];
                  newExcludedHours.splice(index, 1);

                  setExcludedHours(newExcludedHours);
                }}
              />
            );
          })}
          <InlineAddBtn
            label="Add hours"
            margin="m 0 0"
            onClick={() => {
              const newExcludedHours = [...excludedHours];
              newExcludedHours.push(defaultExcludedHours);
              setExcludedHours(newExcludedHours);
            }}
          />
        </>
      )}

      <CTA
        to="#"
        margin="xl 0 0 0"
        onClick={() => {
          if (!name) {
            setNameError("Name cannot be blank");
            return;
          }

          createLocation.mutate(
            {
              brandId: brand.id,
              placesId: props.placeId,
              name,
              lat: data.place.lat,
              lng: data.place.lng,
              email,
              phone,
              address,
              excludedHours,
              openingHours,
            },
            {
              onSuccess: (res) => {
                toast.success("Location created");
                track("Create location", {
                  id: res.createLocation.location?.id
                    ? res.createLocation.location.id
                    : undefined,
                  brand: brand ? brand.name : undefined,
                });
                history.push("/b/locations");
              },
            }
          );
        }}
      >
        {createLocation.isLoading ? "Loading..." : "Save"}
      </CTA>
    </>
  );
}

function getAvailableHours() {
  const hours = [];

  for (let i = 0; i < 24; i++) {
    hours.push({
      label: `${i < 10 ? "0" : ""}${i}:00`,
      value: {
        hour: i,
        minute: 0,
      },
    });

    hours.push({
      label: `${i < 10 ? "0" : ""}${i}:30`,
      value: {
        hour: i,
        minute: 30,
      },
    });
  }

  return hours;
}

interface HoursProps {
  defaultValue: ExcludedHoursInput | OpeningHoursInput;
  place: Place;
  onChange: (
    index: number,
    ehs: ExcludedHoursInput | OpeningHoursInput
  ) => void;
  onRemove: (index: number) => void;
  index: number;
}

export function Hours(props: HoursProps) {
  const [selectedDay, setSelectedDay] = useState("");
  const [startHour, setStartHour] = useState<number | undefined>();
  const [endHour, setEndHour] = useState<number | undefined>();
  const [startMinute, setStartMinute] = useState<number | undefined>();
  const [endMinute, setEndMinute] = useState<number | undefined>();

  useEffect(() => {
    setSelectedDay(props.defaultValue.dayOfWeek);
    setStartHour(props.defaultValue.startHour);
    setStartMinute(props.defaultValue.startMinute);
    setEndHour(props.defaultValue.endHour);
    setEndMinute(props.defaultValue.endMinute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedDay === "" ||
      !startHour ||
      !endHour ||
      startMinute === undefined ||
      endMinute === undefined
    ) {
      return;
    }

    props.onChange(props.index, {
      startHour,
      startMinute,
      endHour,
      endMinute,
      dayOfWeek: selectedDay,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, startHour, startMinute, endHour, endMinute]);

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <Flex align="center" margin="m 0 0 0">
      <Select
        options={weekDays
          .sort((a, b) => {
            if (daysSortMap[a] < daysSortMap[b]) {
              return -1;
            }

            if (daysSortMap[a] > daysSortMap[b]) {
              return 1;
            }

            return 0;
          })
          .map((ohs) => {
            return {
              value: ohs,
              label: ohs,
            };
          })}
        value={selectedDay}
        onSelect={(option) => {
          setSelectedDay(option.value);
        }}
      />

      <Select
        margin="0 0 0 m"
        onSelect={(option) => {
          setStartHour(option.value.hour);
          setStartMinute(option.value.minute);
        }}
        value={`${
          startHour && startHour < 10 ? "0" : ""
        }${startHour}:${startMinute}${
          (startMinute && startMinute < 10) || startMinute === 0 ? "0" : ""
        }`}
        options={getAvailableHours()}
      ></Select>
      <Text margin="0 s 0 s">to</Text>
      <Select
        margin="0 m 0 0"
        onSelect={(option) => {
          setEndHour(option.value.hour);
          setEndMinute(option.value.minute);
        }}
        value={`${endHour && endHour < 10 ? "0" : ""}${endHour}:${endMinute}${
          (endMinute && endMinute < 10) || endMinute === 0 ? "0" : ""
        }`}
        options={getAvailableHours()}
      ></Select>
      <div
        onClick={() => {
          props.onRemove(props.index);
        }}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <CancelIcon />
      </div>
    </Flex>
  );
}
