import { useSelector } from "react-redux";
import styled from "../styles";
import { Card } from "./Card";
import { Chevron } from "./Chevron";
import { Flex } from "./Flex";
import { H3 } from "./Heading";
import { Text } from "./Text";
import { View } from "./View";
import { authSelectors } from "../store/auth/selector";
import { useTheme } from "../hooks/useTheme";

interface Props {
  lat: number;
  lng: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  dmnVenueId: string;
}

const StyledCard = styled(Card)`
  position: relative;
  text-align: left;
`;

const CardContent = styled.div`
  flex: 1;
`;

const TruncatedH3 = styled(H3)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function LocationCard(props: Props) {
  const brand = useSelector(authSelectors.activeBrand);

  return (
    <StyledCard margin="0 m l 0" hover padding="s l xs">
      <Flex>
        <CardContent>
          <View margin="s 0">
            <Flex justify="space-between" direction="row">
              <TruncatedH3 margin="0">{props.name}</TruncatedH3>
            </Flex>

            <Text margin="s 0">{props.address}</Text>
            {props.email && props.phone ? (
              <Text isCompact margin="m 0">
                {props.email} | {props.phone}
              </Text>
            ) : (
              <Text isCompact margin="0">
                {props.email}
                {props.phone}
              </Text>
            )}
            {brand && brand.dmnEnabled ? (
              <DMNConnectionStatus dmnVenueId={props.dmnVenueId} />
            ) : null}
          </View>
        </CardContent>
        <Flex direction="column" align="center" justify="center">
          <Chevron width={12} height={12} />
        </Flex>
      </Flex>
    </StyledCard>
  );
}

const StatusBadgeOuter = styled.div<{ dmnConnected: boolean }>`
  padding: 3px;
  background-color: ${(p) =>
    p.dmnConnected
      ? `${p.theme.color.constructive}20`
      : `${p.theme.color.warning}20`};
  border-radius: 999px;
`;

const StatusBadgeInner = styled.div<{ dmnConnected: boolean }>`
  width: 6px;
  height: 6px;
  background-color: ${(p) =>
    p.dmnConnected
      ? `${p.theme.color.constructive}`
      : `${p.theme.color.warning}`};
  border-radius: 999px;
`;

function DMNConnectionStatus({ dmnVenueId }: { dmnVenueId: string }) {
  const theme = useTheme();
  return (
    <Flex margin="s 0 0" align="center" direction="row">
      <StatusBadgeOuter dmnConnected={!!dmnVenueId}>
        <StatusBadgeInner dmnConnected={!!dmnVenueId} />
      </StatusBadgeOuter>
      <Text
        color={!dmnVenueId ? theme.color.warning : theme.color.constructive}
        size="xs"
        margin="0 0 0 xs"
        style={{ marginBottom: -2 }}
      >
        {!dmnVenueId ? "Not connected to Collins" : "Connected to Collins"}
      </Text>
    </Flex>
  );
}
