import { format, isToday, isYesterday } from "date-fns";

export function formatTime(hour: number, minute: number) {
  return `${hour < 10 ? "0" : ""}${hour}:${minute}${minute < 10 ? "0" : ""}`;
}

export function formatDateTime(date: Date): string {
  if (isToday(date)) {
    return `Today, ${format(date, "p")}`; // e.g., "Today, 4:41pm"
  } else if (isYesterday(date)) {
    return `Yesterday, ${format(date, "p")}`; // e.g., "Yesterday, 9:51am"
  } else {
    // If it's not today or yesterday, return the formatted date
    return format(date, "do MMM, p");
  }
}
