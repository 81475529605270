import { MOBILE_BREAKPOINT } from "../config";
import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";

export const Avatar = styled.div<
  {
    url?: string | null;
    size?: number;
    withShadow?: boolean;
    isBlurry?: boolean;
    borderSize?: number;
    borderColor?: string;
  } & WithMarginProp
>`
  width: 36px;
  height: 36px;

  ${(p) =>
    p.size
      ? css`
          width: ${p.size}px;
          height: ${p.size}px;

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            width: calc(${p.size}px - 4px);
            height: calc(${p.size}px - 4px);
          }
        `
      : null}

  background: #ffffff;
  ${(p) =>
    p.url
      ? css`
          background-image: url("${p.url}");
          background-size: cover;
        `
      : ""}

  border-radius: 100%;

  ${(p) =>
    p.borderSize || p.borderSize === 0
      ? css`
          border: ${p.borderSize}px solid #ffffff;
        `
      : `border: 3px solid #ffffff;`}

  ${(p) =>
    p.withShadow === undefined || p.withShadow
      ? css`
          box-shadow: 0px 4px 10px rgba(100, 57, 145, 0.17);
        `
      : ``}

  ${(p) =>
    p.isBlurry
      ? css`
          filter: blur(${p.theme.misc.blur});
          -webkit-filter: blur(${p.theme.misc.blur});
        `
      : ``};

  cursor: pointer;
  user-select: none;

  flex-shrink: 0;

  ${withMargin}
`;

Avatar.defaultProps = {
  url: "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/empty.jpeg",
};

export default Avatar;
