import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const NameIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.88867 19.9873C2.26562 19.9873 1.77507 19.8441 1.41699 19.5576C1.06608 19.2783 0.890625 18.8916 0.890625 18.3975C0.890625 17.71 1.10189 16.9867 1.52441 16.2275C1.94694 15.4684 2.55566 14.7594 3.35059 14.1006C4.14551 13.4346 5.10156 12.8939 6.21875 12.4785C7.3431 12.0632 8.59993 11.8555 9.98926 11.8555C11.3857 11.8555 12.6426 12.0632 13.7598 12.4785C14.8841 12.8939 15.8402 13.4346 16.6279 14.1006C17.4229 14.7594 18.0316 15.4684 18.4541 16.2275C18.8838 16.9867 19.0986 17.71 19.0986 18.3975C19.0986 18.8916 18.9196 19.2783 18.5615 19.5576C18.2106 19.8441 17.7236 19.9873 17.1006 19.9873H2.88867ZM10 9.96484C9.20508 9.96484 8.47819 9.75358 7.81934 9.33105C7.16048 8.90137 6.63053 8.32845 6.22949 7.6123C5.83561 6.889 5.63867 6.07975 5.63867 5.18457C5.63867 4.31803 5.83561 3.53027 6.22949 2.82129C6.63053 2.10514 7.16048 1.53939 7.81934 1.12402C8.48535 0.701497 9.21224 0.490234 10 0.490234C10.7878 0.490234 11.5111 0.697917 12.1699 1.11328C12.8288 1.52865 13.3587 2.09082 13.7598 2.7998C14.1608 3.50163 14.3613 4.29297 14.3613 5.17383C14.3613 6.06901 14.1608 6.87826 13.7598 7.60156C13.3659 8.32487 12.8359 8.90137 12.1699 9.33105C11.5111 9.75358 10.7878 9.96484 10 9.96484Z"
      fill="black"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const NameIcon = styled(NameIconSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
