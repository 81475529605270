import { useTheme } from "../../hooks/useTheme";

interface Props {
  width?: number;
}

export const EngagementRateIcon = (props: Props) => {
  const theme = useTheme();

  return (
    <svg
      width={props.width ? props.width : 56}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="47"
        height="47"
        rx="23.5"
        stroke="url(#paint0_linear_2058_4002)"
        stroke-width="2"
      />
      <path
        d="M16.0107 20.9792C15.8587 20.2944 15.882 19.5822 16.0786 18.9087C16.2752 18.2353 16.6386 17.6224 17.1352 17.1269C17.6318 16.6313 18.2454 16.2692 18.9193 16.074C19.5931 15.8789 20.3054 15.857 20.9899 16.0105C21.3667 15.4212 21.8857 14.9363 22.4992 14.6004C23.1127 14.2644 23.8009 14.0884 24.5003 14.0884C25.1998 14.0884 25.8879 14.2644 26.5014 14.6004C27.1149 14.9363 27.634 15.4212 28.0107 16.0105C28.6963 15.8563 29.4098 15.8781 30.0847 16.0737C30.7596 16.2694 31.3741 16.6325 31.871 17.1294C32.3679 17.6263 32.731 18.2408 32.9267 18.9157C33.1223 19.5906 33.144 20.3041 32.9899 20.9896C33.5792 21.3664 34.0641 21.8855 34.4 22.499C34.7359 23.1125 34.912 23.8006 34.912 24.5001C34.912 25.1995 34.7359 25.8877 34.4 26.5012C34.0641 27.1146 33.5792 27.6337 32.9899 28.0105C33.1434 28.695 33.1215 29.4072 32.9264 30.0811C32.7312 30.7549 32.3691 31.3686 31.8735 31.8652C31.378 32.3618 30.7651 32.7252 30.0916 32.9218C29.4182 33.1183 28.706 33.1417 28.0212 32.9896C27.6449 33.5812 27.1254 34.0682 26.5109 34.4056C25.8963 34.743 25.2066 34.9199 24.5055 34.9199C23.8045 34.9199 23.1147 34.743 22.5002 34.4056C21.8857 34.0682 21.3662 33.5812 20.9899 32.9896C20.3054 33.1431 19.5931 33.1213 18.9193 32.9261C18.2454 32.731 17.6318 32.3688 17.1352 31.8733C16.6386 31.3777 16.2752 30.7648 16.0786 30.0914C15.882 29.4179 15.8587 28.7058 16.0107 28.0209C15.4169 27.6451 14.9278 27.1252 14.5889 26.5097C14.25 25.8941 14.0723 25.2028 14.0723 24.5001C14.0723 23.7973 14.25 23.106 14.5889 22.4905C14.9278 21.8749 15.4169 21.355 16.0107 20.9792Z"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.625 21.375L21.375 27.625"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.375 21.375H21.385"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.625 27.625H27.635"
        stroke={theme.color.typography.text}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2058_4002"
          x1="0"
          y1="24.5005"
          x2="48.9991"
          y2="24.5005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
