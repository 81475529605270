import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useBrandBillingQuery } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { Checklist, ChecklistWrap } from "../../routes/brands/Billing";
import { authSelectors } from "../../store/auth/selector";
import { ButtonWrap, CTA } from "../CTA";
import { CollapsableSection } from "../CollapsableSection";
import { Flex } from "../Flex";
import { H2 } from "../Heading";
import Loading from "../Loading";
import { Modal } from "../Modal";
import ProgressCircle from "../ProgressCircle";
import { Text } from "../Text";
import { ExternalTextLink } from "../TextLink";
import { View } from "../View";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const SetUpGuideModal: React.FC<Props> = ({ open, setOpen }) => {
  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const { data, isLoading } = useBrandBillingQuery(client, {
    brandID: activeBrandId ? activeBrandId : "",
  });

  const steps = useMemo(() => {
    const hasLocations =
      data && data.brand && data.brand.numLocations > 0 ? true : false;
    const hasListings =
      data && data.brand && data.brand.numListings > 0 ? true : false;
    const hasSubscription =
      data && data.brand && data.brand.stripeCustomerId ? true : false;
    const stepsCompleted = [hasLocations, hasListings, hasSubscription].filter(
      Boolean
    ).length;
    const nextStep = !hasLocations ? 1 : !hasListings ? 2 : 3;

    return {
      hasLocations,
      hasListings,
      hasSubscription,
      stepsCompleted,
      nextStep,
    };
  }, [data]);

  if (isLoading || !data) {
    return (
      <Modal isOpen={open} onClose={() => setOpen(false)} maxWidth={620}>
        <Loading />
      </Modal>
    );
  }

  const handleClose = () => setOpen(!open);

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      maxWidth={620}
      // setIsOpen={setOpen}
    >
      <Flex direction="row" justify="space-between" margin="0 0 0">
        <View>
          <H2 margin="0">Setup Guide</H2>
          <Text size="l" margin="xs 0 l">
            Everything you need to go live on Joli
          </Text>
        </View>
        <ProgressCircle currentStep={steps.stepsCompleted + 1} totalSteps={4} />
      </Flex>
      <View margin="0 0 m">
        <CollapsableSection
          showCheckbox
          isCheckboxComplete={true}
          title="Add brand details"
          defaultOpen={steps.nextStep === 0}
          headerSize="l"
        >
          <Text margin="0">
            Provide basic information about your brand, including name,
            description, and categories. This helps us match you with the right
            influencers.
          </Text>
          <Flex align="flex-start">
            <View margin="s 0 0">
              <CTA size="xs" to="/b/profile" onClick={handleClose}>
                Add details
              </CTA>
            </View>
          </Flex>
        </CollapsableSection>
      </View>
      <View margin="0 0 m">
        <CollapsableSection
          showCheckbox
          isCheckboxComplete={steps.hasLocations}
          title="Add your locations"
          defaultOpen={steps.nextStep === 1}
          headerSize="l"
        >
          <Text margin="0" size="s">
            A location can be any physical place for influencer visits, such as
            hotels, restaurants, cafes, entertainment venues, and more.
          </Text>
          <Flex align="flex-start">
            <View margin="s 0 0">
              <CTA size="xs" to="/b/locations" onClick={handleClose}>
                Add locations
              </CTA>
            </View>
          </Flex>
        </CollapsableSection>
      </View>
      <View margin="0 0 m">
        <CollapsableSection
          showCheckbox
          isCheckboxComplete={steps.hasListings}
          title="Create your first campaign"
          defaultOpen={steps.nextStep === 2}
          headerSize="l"
        >
          <Text margin="0" size="s">
            Describe what you're offering, and what you'd like in return. Target
            influencers by category, dietary preferences, minimum followers, and
            more.
          </Text>
          <Flex align="flex-start">
            <ButtonWrap margin="s 0 0">
              <CTA size="xs" to="/b/listings" onClick={handleClose}>
                Create listing
              </CTA>
            </ButtonWrap>
          </Flex>
        </CollapsableSection>
      </View>
      <View margin="0 0 m">
        <CollapsableSection
          showCheckbox
          isCheckboxComplete={steps.hasSubscription}
          title="Go live"
          emoji="rocket"
          defaultOpen={steps.nextStep === 3}
          headerSize="l"
        >
          <Text margin="0 0 s" size="s">
            You'll need an active subscription in order to set your campaigns
            live
          </Text>
          <Text margin="xs 0 s" size="xs" weight="bold">
            With your subscription, you'll get:
          </Text>
          <ChecklistWrap margin="0 0 m">
            <Checklist header="Access vetted influencers" />
            <Checklist header="Content gallery" />
            <Checklist header="Unlimited campaigns & events" />
            <Checklist header="Team management" />
            <Checklist header="Advanced reporting" />
            <Checklist header="Live chat & email support" />
          </ChecklistWrap>
          <Flex align="flex-start">
            <View margin="0 0 0">
              <CTA size="xs" to="/b/billing" onClick={handleClose}>
                Manage subscription
              </CTA>
            </View>
          </Flex>
        </CollapsableSection>
      </View>
      <Text align="center" margin="l 0 0" size="s">
        Need help setting up?{" "}
        <ExternalTextLink
          margin="0"
          href="https://joliapp.com/book-a-demo/"
          size="s"
          target="_blank"
        >
          Book a call →
        </ExternalTextLink>
      </Text>
    </Modal>
  );
};

export default SetUpGuideModal;
