import posthog from "posthog-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { FakeBackLink } from "../../components/BackLink";
import { Card } from "../../components/Card";
import { Chevron } from "../../components/Chevron";
import { CardDivider } from "../../components/Divider";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { GmailIcon } from "../../components/icons/GmailIcon";
import { OutlookIcon } from "../../components/icons/OutlookIcon";
import { YahooIcon } from "../../components/icons/YahooIcon";
import { Text } from "../../components/Text";
import { View } from "../../components/View";
import { MOBILE_BREAKPOINT } from "../../config";
import { useResendEmailVerificationMutation } from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { actions } from "../../store/auth/slice";
import styled from "../../styles";
import { SignupCard } from "./signup/CreateBrand";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
  position: relative;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 560px;
`;

const openLink = (
  isMobile: boolean,
  mobileUrl: string,
  fallbackUrl: string
) => {
  if (!isMobile) {
    // Non-mobile: Open fallback URL directly in a new tab
    window.open(fallbackUrl, "_blank");
    return;
  }

  const tryOpenUrl = (url: string): void => {
    // Attempt to navigate to the deep link directly in the current tab
    window.location.href = url;

    // Set a fallback in case the app doesn't handle the URL
    setTimeout(() => {
      window.location.replace(fallbackUrl); // Use replace to avoid adding the deep link to the browser history
    }, 1000); // Adjust timeout as needed (1 second is usually enough)
  };

  tryOpenUrl(mobileUrl);
};

export function VerifyEmail() {
  const [emailSent, setEmailSent] = useState(false);
  const client = useGqlClient();
  const resend = useResendEmailVerificationMutation(client);
  const account = useSelector(authSelectors.account);
  const [isMobile, setIsMobile] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const dispatch = useDispatch();
  const { hardShutdown } = useIntercom();
  const { track } = useAnalytics();
  const senderEmail = "notifications@joliapp.com";

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();

    const android = /(android)/g.test(ua);
    const iOS = /(ipad|iphone|ipod)/g.test(ua);

    if (android || iOS) {
      if (iOS) {
        setIsIos(true);
      }
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        window.location.reload();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Wrap>
      <AbosluteWrap>
        <FakeBackLink
          onClick={() => {
            dispatch(actions.logout());
            posthog.reset();
            hardShutdown();
            track("Logout");
          }}
        >
          <Chevron direction="left" /> Back to login
        </FakeBackLink>
      </AbosluteWrap>
      <ContentWrap>
        <SignupCard>
          <View padding="s 0 s">
            <H1 margin={"0 0 0"} style={{ textAlign: "center" }}>
              Check your inbox
            </H1>
            <Text size="m" margin={"l 0 0"} align="center">
              We've just sent a confirmation link to your email.
              <br />
              Please check your inbox at{" "}
              <span style={{ fontWeight: 600 }}>{account?.email}</span>
            </Text>
            <CardDivider margin="xl 0 xl" />
            <View margin="0 0 0">
              <ButtonGrid>
                <EmailLinkButton
                  padding="s m"
                  hover
                  onClick={() => {
                    openLink(
                      isMobile,
                      isIos ? "message://" : `googlegmail://`,
                      `https://mail.google.com/mail/u/0/#search/from%3A(${senderEmail})`
                    );
                  }}
                >
                  <Flex align="center" justify="center">
                    <GmailIcon width={26} />
                    <Text size="s" margin="0 0 0 s">
                      Open Gmail
                    </Text>
                  </Flex>
                </EmailLinkButton>
                <EmailLinkButton
                  padding="s m"
                  hover
                  onClick={() => {
                    openLink(
                      isMobile,
                      "ms-outlook://",
                      `https://outlook.live.com/mail/0/`
                    );
                  }}
                >
                  <Flex align="center" justify="center">
                    <OutlookIcon width={26} />
                    <Text size="s" margin="0 0 0 s">
                      Open Outlook
                    </Text>
                  </Flex>
                </EmailLinkButton>
                <EmailLinkButton
                  padding="s m"
                  hover
                  onClick={() => {
                    openLink(
                      isMobile,
                      "ymail://",
                      `https://mail.yahoo.com/d/search/keyword=${senderEmail}`
                    );
                  }}
                >
                  <Flex align="center" justify="center">
                    <YahooIcon width={26} />
                    <Text size="s" margin="0 0 0 s">
                      Open Yahoo
                    </Text>
                  </Flex>
                </EmailLinkButton>
              </ButtonGrid>
            </View>
          </View>
        </SignupCard>
        <Flex margin="l 0 0" justify="center">
          <Text size="s" align="center" colorPreset="secondary" margin="0">
            Can't find the email? Try your spam folder
            <br />
            Still no luck?{" "}
            <Text
              margin="0"
              size="s"
              isInline
              weight="semi"
              colorPreset={emailSent ? "success" : "link"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (resend.isLoading) {
                  return;
                }

                resend.mutate(
                  {},
                  {
                    onSuccess: () => {
                      setEmailSent(true);
                    },
                  }
                );
              }}
            >
              {emailSent ? "Resent!" : "Resend email"}
            </Text>
          </Text>
        </Flex>
      </ContentWrap>
    </Wrap>
  );
}

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  gap: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    gap: ${(p) => p.theme.spacing.l};
  }
`;

const EmailLinkButton = styled(Card)`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AbosluteWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
