import styled from "../styles";
import withMargin from "../styles/withMargin";

export const Divider = styled.div.attrs({ "aria-hidden": true })`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${(p) => p.theme.color.divider};
  ${withMargin}
`;

export const CardDivider = styled(Divider)`
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
  ${withMargin}
`;
