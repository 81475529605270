import * as React from "react";
import styled from "../../styles";

export const TikTokFaviconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2859 3333"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
  </svg>
);

const TikTokIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 428 429"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M356.83 0.949951H71.1598C31.8647 0.949951 0.00976562 32.8049 0.00976562 72.0999V357.77C0.00976562 397.065 31.8647 428.92 71.1598 428.92H356.83C396.125 428.92 427.98 397.065 427.98 357.77V72.0999C427.98 32.8049 396.125 0.949951 356.83 0.949951Z"
      fill="black"
    />
    <path
      d="M347.39 179.92V122.85C272.79 119.7 270.39 51.9101 270.39 45.5401V45.0601H212.73V267.33C212.724 276.935 209.689 286.293 204.057 294.074C198.425 301.854 190.482 307.66 181.36 310.666C172.238 313.672 162.4 313.725 153.245 310.817C144.091 307.909 136.087 302.188 130.372 294.469C124.657 286.749 121.522 277.424 121.413 267.819C121.304 258.215 124.226 248.821 129.764 240.973C135.302 233.126 143.174 227.224 152.259 224.109C161.345 220.993 171.182 220.822 180.37 223.62V164.76C175.953 164.193 171.504 163.909 167.05 163.91C146.595 163.91 126.6 169.976 109.593 181.339C92.5855 192.703 79.3299 208.855 71.5023 227.753C63.6747 246.65 61.6266 267.445 65.6171 287.506C69.6076 307.568 79.4574 325.995 93.9209 340.459C108.384 354.923 126.812 364.772 146.874 368.763C166.935 372.753 187.73 370.705 206.627 362.878C225.525 355.05 241.677 341.794 253.041 324.787C264.404 307.78 270.47 287.785 270.47 267.33C270.47 265.88 270.47 264.44 270.37 263.01V153.56C296.92 177.85 347.39 179.92 347.39 179.92Z"
      fill="#00F6EF"
    />
    <path
      d="M364.37 194V136.9C289.76 133.75 287.37 65.9601 287.37 59.5901V59.1101H229.71V281.38C229.706 290.985 226.672 300.345 221.042 308.127C215.411 315.908 207.469 321.716 198.347 324.724C189.225 327.732 179.386 327.786 170.231 324.879C161.076 321.972 153.072 316.252 147.355 308.533C141.639 300.813 138.503 291.488 138.393 281.883C138.283 272.278 141.205 262.884 146.742 255.035C152.28 247.187 160.152 241.285 169.238 238.169C178.324 235.053 188.161 234.882 197.35 237.68V178.81C192.922 178.256 188.463 177.985 184 178C163.545 178.006 143.551 184.078 126.547 195.447C109.543 206.816 96.2917 222.973 88.4699 241.873C80.648 260.773 78.6066 281.569 82.6037 301.629C86.6008 321.69 96.4569 340.115 110.926 354.574C125.394 369.033 143.826 378.876 163.889 382.86C183.953 386.844 204.747 384.788 223.642 376.953C242.537 369.119 258.684 355.857 270.042 338.845C281.4 321.833 287.458 301.835 287.45 281.38C287.45 279.93 287.45 278.49 287.35 277.06V167.61C313.9 191.9 364.37 194 364.37 194Z"
      fill="white"
    />
    <path
      d="M271.82 59.1101C274.6 74.2501 282.72 97.9201 306.39 111.77C288.3 90.7001 287.39 63.5101 287.39 59.5901V59.1101H271.82Z"
      fill="#FF004F"
    />
    <path
      d="M364.37 194V136.9C358.659 136.691 352.974 136.022 347.37 134.9V179.85C347.37 179.85 296.9 177.78 270.37 153.49V262.91C270.43 264.34 270.47 265.78 270.47 267.23C270.467 285.941 265.389 304.301 255.776 320.354C246.163 336.407 232.375 349.552 215.882 358.387C199.388 367.223 180.807 371.419 162.117 370.529C143.427 369.638 125.329 363.694 109.75 353.33C124.077 368.126 142.496 378.307 162.646 382.568C182.796 386.829 203.759 384.976 222.85 377.246C241.94 369.517 258.287 356.263 269.797 339.183C281.306 322.103 287.453 301.976 287.45 281.38C287.45 279.93 287.45 278.49 287.35 277.06V167.61C313.9 191.9 364.37 194 364.37 194Z"
      fill="#FF004F"
    />
    <path
      d="M180.37 223.53C169.36 220.172 157.489 221.094 147.129 226.11C136.769 231.127 128.684 239.869 124.491 250.589C120.298 261.308 120.305 273.216 124.512 283.93C128.718 294.645 136.814 303.376 147.18 308.38C141.515 300.642 138.429 291.318 138.358 281.728C138.287 272.139 141.235 262.77 146.785 254.949C152.335 247.128 160.205 241.252 169.28 238.152C178.355 235.053 188.176 234.888 197.35 237.68V178.81C192.922 178.256 188.463 177.985 184 178C182.77 178 181.56 178 180.34 178.07L180.37 223.53Z"
      fill="#FF004F"
    />
  </svg>
);

interface Props {
  width?: number;
  height?: number;
}

export const TikTokIcon = styled(TikTokIconSVG)<Props>`
  width: ${(p) => p.width}px;
  min-width: ${(p) => p.width}px;
`;

export const TikTokFavicon = styled(TikTokFaviconSVG)<Props>`
  path {
    fill: ${(p) => p.theme.color.typography.heading};
  }
`;
